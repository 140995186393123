import React from 'react'
import { translate } from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'

class WeightBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      is_closed: false,
      tooltip_closed: true
    }
  }

  hideWeightBlock = () => {
    this.setState({ is_closed: !this.state.is_closed })
  }

  showTooltip = () => {
    this.setState({ tooltip_closed: !this.state.tooltip_closed })
  }

  renderValue = (item) => {
    // Sitzplätze
    if (['16', '46', '77'].includes(item.id)) {
      return item.value
    }

    return item.value.toLocaleString(window.currentLocalization.code) + ' kg'
  }

  renderInfo = (item) => {
    // 5% addieren = x * 1,05
    const sumAdd = Math.round(item.value * 1.05)

    // 5% subtrahieren = x * 0,95
    const sumSub = Math.round(item.value * 0.95)

    return (
      '(' +
      sumSub.toLocaleString(window.currentLocalization.code) +
      ' - ' +
      sumAdd.toLocaleString(window.currentLocalization.code) +
      ' kg)'
    )
  }

  render() {
    if (
      !this.context.cart.weightBlock ||
      !Array.isArray(this.context.cart.weightBlock)
    ) {
      return ''
    }

    return (
      <div className={`weightblock ${this.state.is_closed ? '' : 'is-opened'}`}>
        <div className={'weightblock__wrapper'}>
          <div className={'weightblock__container'}>
            <div
              onClick={(e) => this.showTooltip()}
              className={`weightblock__tooltip ${
                this.state.tooltip_closed ? true : 'is-visible'
              }`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('weightblock', 'tooltip')
                }}
              />

              <strong>{translate('global', 'close')}</strong>
            </div>

            <div
              className={`weightblock__content${
                this.context.cart.reachedMaxWeight
                  ? ' weightblock__content--red'
                  : ''
              }`}
            >
              <span
                className={'weightblock__info'}
                onClick={(e) => this.showTooltip()}
              >
                i
              </span>
              <div
                className={`weightblock__trigger text${
                  this.context.cart.reachedMaxWeight ? ' text-red' : ''
                }`}
                onClick={(e) => this.hideWeightBlock()}
              >
                <span>{translate('weightblock', 'information_title')}</span>
              </div>
              <div className={'weightblock__text text'}>
                {this.context.cart.weightBlock.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`weightblock__row text${
                        this.context.cart.reachedMaxWeight && index === 4
                          ? ' text-red'
                          : ''
                      }`}
                    >
                      <span className="weightblock__row-label">
                        {item.label}
                      </span>
                      <div>
                        <strong>{this.renderValue(item)}</strong>
                        {item.info ? (
                          <span>{this.renderInfo(item)}</span>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

WeightBlock.contextType = AppContext
export default WeightBlock
