import { history } from './Data/Store'
import { EXTERIEUR_IMAGE_RENDERING } from './config'

import axios from 'axios'

import translation from './translation.json'
import Parser from 'html-react-parser'
import {
  getApiUrl,
  getLanguage,
  getAssetUrl,
  getSlidesPath,
  getRimsPath,
  psMotore
} from './config'

export function translate(scope, key, html = false) {
  const language = window.currentLocalization.locale

  const translation_string = translation[language][scope][key]

  return translation_string
    ? html
      ? Parser(translation_string)
      : translation_string
    : ''
}

export function redirectToStart() {
  history.push('/')
}

export function checkPrice(item, psmotor) {
  if (!psmotor) {
    return formatPrice(item.price)
  }

  if (window.currentLocalization.locale !== 'at_AT') {
    return formatPrice(item.price)
  }

  if (item[psmotor]) {
    return formatPrice(item[psmotor])
  }

  return formatPrice(item.price)
}

export function formatPrice(number) {
  let _number = ''

  if (number !== null && number !== '') {
    if (number === 'Preis auf Anfrage' || number === 'Auf Anfrage') {
      return 'Preis auf Anfrage'
    }
    _number += number
  } else {
    return '0 €'
  }

  return parseFloat(_number).toLocaleString(window.currentLocalization.code, {
    style: 'currency',
    currency: window.currentLocalization.currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export function formatWeight(number) {
  if (number && number !== null && number !== '') {
    return number.toLocaleString(window.currentLocalization.code) + ' kg'
  }
  return '0 kg'
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export function renderMarkup(markup) {
  return { __html: markup }
}

export function arrSum(arr) {
  return arr.reduce(function (a, b) {
    return a + b
  }, 0)
}

/**
 * Pricing calculations
 */
export function calculateSelectedPrices(selected) {
  let sum = 0

  if (!selected.length) {
    return 0
  }

  for (var k = 0; k < selected.length; k++) {
    if (selected[k] && selected[k].item && selected[k].item.price) {
      sum += selected[k].item.price
    } else if (selected[k] && selected[k].items && selected[k].items[0]) {
      for (var i = 0; i < selected[k].items.length; i++) {
        if (selected[k].items[i] && selected[k].items[i].price) {
          sum += selected[k].items[i].price
        }
      }
    }
  }

  return sum
}

export function calculateTotalPrice(allPrices) {
  let totalPrice = 0
  totalPrice = arrSum(allPrices)
  return totalPrice
}

export function calculatePrices(allPrices, selected, key) {
  let prices = []
  allPrices[key] = calculateSelectedPrices(selected)
  prices['all'] = allPrices
  prices['total'] = calculateTotalPrice(allPrices)
  return prices
}

export async function getActionConditions(cartCodes, code, action, productId) {
  const response = fetch(
    getApiUrl() +
      `/data/conflictcheck?productId=${productId}&lang=` +
      getLanguage() +
      `&item=${code}` +
      `&action=${action}`,
    {
      method: 'POST',
      body: JSON.stringify({
        cartCodes
      })
    }
  )
    .then((response) => response.json())
    .then((response) => {
      return response
    })
    .catch((err) => console.log(err))

  return response
}

export async function loadExtendedAttributes(productId, code) {
  const response = await axios(
    getApiUrl() +
      `/data/ausstattung?productId=${productId}&lang=` +
      getLanguage() +
      `&item=${code}`
  )
  return response.data
}

export function flatCart(propsCart) {
  const cart = []
  const cartTabs = ['exterieur', 'aufbau', 'chassis', 'technik', 'interieur']

  cartTabs.forEach((tab) => {
    if (propsCart[tab]) {
      propsCart[tab].forEach((tabItem) => {
        if (tabItem && Array.isArray(tabItem.items)) {
          tabItem.items.forEach((item) => {
            cart.push(item)
          })
        } else if (tabItem && tabItem.item) {
          cart.push(tabItem.item)
        }
      })
    }
  })

  if (propsCart.packages) {
    propsCart.packages.forEach((paket) => {
      if (paket !== null) {
        paket.items.forEach((item) => {
          cart.push(item)

          if (item.details && Array.isArray(item.details)) {
            item.details.forEach((detail) => {
              detail.packageItem = true
              cart.push(detail)
            })
          }
        })
      }
    })
  }

  return cart
}

export async function loadAusstattungenSerieRegional(baureihe) {
  const response = await axios(
    getApiUrl() +
      `/data/ausstattungenserieregional?lang=` +
      getLanguage() +
      `&baureihe=${baureihe}`
  )
  return response.data
}

export async function filterOptionsAlreadyInCart(propsCart, items) {
  if (!items) return []

  const cart = flatCart(propsCart)
  if (cart.length === 0) {
    return []
  }

  const codesInCart = cart.map((item) => item.code)

  const ausstattungenSerieRegional = await loadAusstattungenSerieRegional(
    propsCart.model.imageKey
  )

  codesInCart.push(...ausstattungenSerieRegional)

  const filtered = items.filter((item) => {
    return codesInCart.includes(item.code)
  })

  return filtered
}

export function filterRawOptionsInCart(items, exclusions) {
  const codesInCart = exclusions.map((item) => item.code)

  const filtered = items.filter((item) => {
    return !codesInCart.includes(item.code)
  })
  return filtered
}

export async function isAtLeastOneOptionInCart(propsCart, items) {
  const options = await filterOptionsAlreadyInCart(propsCart, items)
  return options.length > 0
}

export function filterOptionsNotInCart(propsCart, items) {
  if (!items) return []

  const cart = flatCart(propsCart)

  if (cart.length === 0) {
    return items
  }

  const codesInCart = cart.map((item) => item.code)

  const filtered = items.filter((item) => {
    return !codesInCart.includes(item.code)
  })

  return filtered
}

export function filterOptionsInCart(propsCart, items) {
  if (!items) return []

  const cart = flatCart(propsCart)

  if (cart.length === 0) {
    return items
  }

  const codesInCart = cart.map((item) => item.code)

  const filtered = items.filter((item) => {
    return codesInCart.includes(item.code)
  })

  return filtered
}

export function isInCart(propsCart, item) {
  if (!item) return item

  const cart = flatCart(propsCart)

  if (cart.length === 0) {
    return false
  }

  const codesInCart = cart.map((item) => item.code)
  return codesInCart.includes(item.code)
}

// muessen duch automatische Inclusions bestimmte Produkte aus dem Warenkorb entfernt werden
// weil sie im Konflikt mit Inclusions sind
export async function doHaveInclusionsExclusions(cart, inclusions) {
  const exclusions = []
  inclusions.forEach((inclusion) => {
    if (
      inclusion.exclusions &&
      Array.isArray(inclusion.exclusions) &&
      inclusion.exclusions.length
    ) {
      exclusions.push(...inclusion.exclusions)
    }
  })

  return await filterOptionsAlreadyInCart(cart, exclusions)
}

//haben optionale Produkte Exclusionen die im Konflikt mit Produkten im Warenkorb stehen?
export function doHaveOptionsExclusions(propsCart, options) {
  let exclusions = []
  options.forEach(async (option) => {
    if (
      option.exclusions &&
      Array.isArray(option.exclusions) &&
      option.exclusions.length
    ) {
      exclusions.push(...option.exclusions)
      option.exclusionsInCart = await filterOptionsAlreadyInCart(
        propsCart,
        exclusions
      )
    }
    exclusions = []
  })
  return options
}

// haben optionale Produkte Inclusions?
export function doHaveOptionsInclusions(propsCart, options, selecteItem) {
  let inclusions = []
  options.forEach((option) => {
    if (
      option.inclusions &&
      Array.isArray(option.inclusions) &&
      option.inclusions.length
    ) {
      const filtered = option.inclusions.filter(
        (item) => item.code !== selecteItem.code
      )
      inclusions.push(...filtered)
      option.inclusionsNotInCart = filterOptionsNotInCart(propsCart, inclusions)
    }
    inclusions = []
  })
  return options
}

// haben optionale Produkte Options?
export function doHaveOptionsOptions(propsCart, options, selecteItem) {
  let optionsArray = []
  let newOptionsArray = []
  options.forEach((option) => {
    if (
      option.options &&
      Array.isArray(option.options) &&
      option.options.length
    ) {
      const filtered = option.options.filter(
        (item) => item.code !== selecteItem.code
      )
      optionsArray.push(...filtered)
      newOptionsArray = filterOptionsNotInCart(propsCart, optionsArray)
      if (newOptionsArray.length >= optionsArray.length) {
        option.optionsNotInCart = newOptionsArray
      }
    }
    optionsArray = []
  })
  return options
}

// muessen duch automatische exclusions bestimmte Produkte aus dem Warenkorb entfernt werden
// weil sie direkt von Exclusions abhaengen
export async function doHaveExclusionsDependencies(propsCart, exclusions) {
  const dependencies = []
  const codes = exclusions.map((item) => item.code)

  exclusions.forEach((exclusion) => {
    if (
      exclusion.dependencies &&
      Array.isArray(exclusion.dependencies) &&
      exclusion.dependencies.length
    ) {
      //Doppelungen vermeiden
      const filtered = exclusion.dependencies.filter(
        (item) => !codes.includes(item.code)
      )
      dependencies.push(...filtered)
    }
  })

  return await filterOptionsAlreadyInCart(propsCart, dependencies)
}

export function findSelectedPackagesByCode(propsCart, codes) {
  const selectedPackages = []

  if (propsCart.packages) {
    propsCart.packages.forEach((paket) => {
      if (paket !== null) {
        paket.items.forEach((item) => {
          if (codes.includes(item.code)) {
            selectedPackages.push(item)
          }
        })
      }
    })
  }
  return selectedPackages
}

export function findtPackagesByDetailItems(propsCart, items) {
  const selectedPackages = []
  const codes = items.map((item) => item.code)

  if (propsCart.packages) {
    propsCart.packages.forEach((paket) => {
      if (paket !== null) {
        paket.items.forEach((item) => {
          item.details.forEach((detail) => {
            if (codes.includes(detail.code)) {
              selectedPackages.push(item)
            }
          })
        })
      }
    })
  }
  return selectedPackages
}

/**
 * -----------------------------------------------------------------
 */

export function recalculatePricesAndWeights(cart) {
  const maxAddWeight = recalculateMaxAddWeight(cart)
  const totalWeight = recalculateWeights(cart)

  cart.reachedMaxWeight = totalWeight > maxAddWeight
  cart.weightBlock = recalculateWeightBlock(cart, totalWeight, maxAddWeight)
  cart.totalWeight = totalWeight
  cart.maxAddWeight = maxAddWeight
  cart.totalPrice = recalculatePrices(cart)
  return cart
}

function getPSMotorPrice(cart) {
  let price = 0

  if (cart.layout && cart.layout.price) {
    price = cart.layout.price
  }

  if (cart.layout && cart.layout.PSMotor) {
    const PSMotor = cart.layout.PSMotor

    if (cart.layout && cart.layout[PSMotor]) {
      price = cart.layout[PSMotor]
    }
  }

  return price
}

export function getPriceItem(item, psmotor) {
  if (!psmotor) {
    return item.price
  }

  if (window.currentLocalization.locale !== 'at_AT') {
    return item.price
  }

  if (item[psmotor]) {
    return item[psmotor]
  }

  return item.price
}

export function recalculatePrices(cart) {
  const psmotor = cart.layout?.PSMotor || null
  const flatCartItems = flatCart(cart)
  const currentLocale = window.currentLocalization.locale

  const artoPackageException = flatCartItems.some(
    (item) => item.code === 'V8040098A'
  )

  let totalPrice = cart.layout?.price || 0

  if (currentLocale === 'at_AT') {
    totalPrice = getPSMotorPrice(cart)
  }

  totalPrice += flatCartItems.reduce((total, item) => {
    if (
      item.packageItem ||
      (artoPackageException && item.code === 'V2021027A')
    ) {
      return total
    } else {
      let priceItem = getPriceItem(item, psmotor)
      priceItem = parseInt(priceItem, 10) || 0
      return total + priceItem
    }
  }, 0)

  return totalPrice
}

export function recalculateWeightBlock(cart, totalWeight, maxAddWeight) {
  let index = null
  const weightBlock = cart.weightBlock

  // Sitzplätze
  index = weightBlock.findIndex((item) => {
    return ['16', '46', '77'].includes(item.id)
  })

  weightBlock[index].value = recalculateSeats(cart)

  // Technisch zulässige Gesamtmasse
  index = weightBlock.findIndex((item) => {
    return ['12', '41', '72'].includes(item.id)
  })

  weightBlock[index].value = recalculateTotalMass(cart)

  // Herstellerseitig festgelegte Masse für Sonderausstattung
  index = weightBlock.findIndex((item) => {
    return ['14', '44', '75'].includes(item.id)
  })

  weightBlock[index].value = maxAddWeight

  // Verbleibende Masse für Sonderausstattung
  index = weightBlock.findIndex((item) => {
    return ['9999'].includes(item.id)
  })

  weightBlock[index].value = maxAddWeight - totalWeight

  // Verbleibende Masse für Sonderausstattung
  index = weightBlock.findIndex((item) => {
    return ['9999'].includes(item.id)
  })

  weightBlock[index].value = maxAddWeight - totalWeight

  return weightBlock
}

export function recalculateSeats(cart) {
  const flat_cart = flatCart(cart)

  let seats = 0

  if (cart.layout && cart.layout.seats) {
    seats = cart.layout.seats
  }

  seats += flat_cart.reduce((total, item) => {
    if (item && item.seats) {
      if (item.packageItem) {
        return total
      } else if (item.serie) {
        return total
      } else {
        return total + item.seats * 1
      }
    } else {
      return total
    }
  }, 0)

  return seats
}

export function recalculateTotalMass(cart) {
  const flat_cart = flatCart(cart)

  let totalMass = 0

  if (cart.layout && cart.layout.totalMass) {
    totalMass = cart.layout.totalMass
  }

  totalMass += flat_cart.reduce((total, item) => {
    if (item && item.totalMass) {
      if (item.packageItem) {
        return total
      } else {
        return total + item.totalMass * 1
      }
    } else {
      return total
    }
  }, 0)

  return totalMass
}

export function recalculateMaxAddWeight(cart) {
  // Nutzlast pro Meter und Person
  const payload = 10

  // Gewicht p. Person ohne Fahrer
  const personWeight = 75

  // Toleranz
  const tolerance = 30

  const seats = recalculateSeats(cart)

  const totalMass = recalculateTotalMass(cart)

  // Mitfahrer
  const passenger = seats - 1

  const maxAddWeight =
    totalMass -
    cart.layout.systemWeight -
    payload * (cart.layout.systemLength + seats) -
    personWeight * passenger -
    tolerance

  return maxAddWeight
}

export function recalculateWeights(cart) {
  const flat_cart = flatCart(cart)

  let totalWeight = 0

  // if (cart.layout && cart.layout.weight) {
  //   totalWeight = stringDecimalToFloat(cart.layout.weight)
  // }

  const artoPackageException = flat_cart.some(
    (item) => item.code === 'V8040098A'
  )

  totalWeight += flat_cart.reduce((total, item) => {
    if (item && item.weight) {
      if (
        item.packageItem ||
        (artoPackageException && item.code === 'V2021027A')
      ) {
        return total
      } else if (item.serie && !item.price) {
        // Kein Gewicht addieren für Serienausstattung (ohne Preis).
        // 2024-07-12 Hr. Kaiser:
        // Ausnahme für Arto: Das Gewicht der Plfichtausstattungen (mit Preis) muss addiert werden
        return total
      } else {
        return total + stringDecimalToFloat(item.weight)
      }
    } else {
      return total
    }
  }, 0)

  return totalWeight
}

export function stringDecimalToFloat(value) {
  return parseFloat(value.replace(/,/g, '.'))
}

export function saveToCart(item, type, cart, atIndex = 0, withTitle = '') {
  if (isInCart(cart, item)) {
    return cart
  }

  switch (type) {
    case 'exterieur':
      {
        const exterieur = cart.exterieur || []
        exterieur[item.category].item = item
        cart.exterieur = exterieur
      }
      break
    case 'packages':
      {
        const packages = cart.packages || []
        if (
          !packages[0] ||
          !packages[0].items ||
          !Array.isArray(packages[0].items)
        ) {
          packages[0] = {
            title: translate('pakete', 'equipment_packages'),
            items: []
          }
        }
        packages[0].items.push(item)
        cart.packages = packages
      }
      break
    case 'technik':
      {
        const technik = cart.technik || []
        if (technik[atIndex] === undefined || technik[atIndex] === null) {
          technik[atIndex] = {
            title: withTitle,
            items: []
          }
        }
        technik[atIndex].items.push(item)
        cart.technik = technik
      }
      break
    case 'chassis':
      if (atIndex === 0) {
        // AT NoVA Steuer basiert auf Motor Auswahl
        cart.layout.PSMotor = psMotore[item.code]
      }
      {
        const chassis = cart.chassis || []
        if (chassis[atIndex] === undefined || chassis[atIndex] === null) {
          chassis[atIndex] = {
            title: withTitle,
            items: []
          }
        }
        chassis[atIndex].items.push(item)
        cart.chassis = chassis
      }
      break
    case 'aufbau':
      {
        const aufbau = cart.aufbau || []
        if (aufbau[atIndex] === undefined || aufbau[atIndex] === null) {
          aufbau[atIndex] = {
            title: withTitle,
            items: []
          }
        }
        aufbau[atIndex].items.push(item)
        cart.aufbau = aufbau
      }
      break
    default: {
    }
  }

  recalculatePricesAndWeights(cart)

  return cart
}

export function deleteInCart(cart, itemToRemove) {
  const cartTabs = [
    'exterieur',
    'aufbau',
    'chassis',
    'technik',
    'packages',
    'interieur'
  ]

  cartTabs.forEach((tab) => {
    if (cart[tab]) {
      cart[tab].forEach((tabItem) => {
        if (tabItem && Array.isArray(tabItem.items)) {
          tabItem.items.forEach((item, index) => {
            if (item.code === itemToRemove.code) {
              tabItem.items.splice(index, 1)

              // AT NoVA Steuer
              if (window.currentLocalization.locale === 'at_AT') {
                if (psMotore.hasOwnProperty(itemToRemove.code)) {
                  if (tabItem.items.length < 1) {
                    cart.layout.PSMotor =
                      cart.model.imageKey === 'F' ? 'PS180' : 'PS140'
                  }
                }
              }
            }
          })
        } else if (tabItem && tabItem.item) {
          if (tabItem.code === itemToRemove.code) {
            tabItem.item = null
          }
        }
      })
    }
  })

  recalculatePricesAndWeights(cart)

  return cart
}

export function markItemsAsSelected(items, cart, cartTab) {
  const selected = []

  if (cart[cartTab] !== undefined) {
    cart[cartTab].forEach((row) => {
      if (row) {
        row.items.forEach((item) => {
          selected.push(item.code)
        })
      }
    })
  }

  // set selected Items
  items.forEach((row) => {
    if (row) {
      row.items.forEach((item, index) => {
        row.items[index].selected = selected.includes(item.code)
      })
    }
  })

  return items
}

function getSlidesImageKeys(propsCart) {
  let imageKeys = []

  imageKeys['basecolor'] = 'w'

  imageKeys['model'] = propsCart.layout.imageKey

  if (propsCart.exterieur !== undefined && propsCart.exterieur.length !== 0) {
    propsCart.exterieur.map(function (exterieur, index) {
      if (
        exterieur.item.typeKey !== undefined &&
        exterieur.item.imageKey !== undefined
      ) {
        return (imageKeys[exterieur.item.typeKey] = exterieur.item.imageKey)
      }
      return null
    })
  }

  if (
    imageKeys.model === undefined ||
    imageKeys.decor === undefined ||
    imageKeys.basecolor === undefined ||
    imageKeys.rim === undefined ||
    imageKeys.color === undefined
  ) {
    return false
  }

  return imageKeys
}

function getExteriorImagePath(propsCart, imageKeys, slide) {
  imageKeys.model = propsCart.model.imageKey
  imageKeys.systemName = propsCart.layout.systemName

  if (EXTERIEUR_IMAGE_RENDERING === 'static') {
    const modelID = imageKeys.model + imageKeys.systemName
    const basePath = `${getAssetUrl() + getSlidesPath() + modelID}/${slide}/`

    let filenameParts = [modelID, slide]

    ;['basecolor', 'decor', 'color', 'rim', 'detail'].forEach((option) => {
      filenameParts.push(imageKeys[option] ?? '-')
    })

    return `${basePath}${filenameParts.join('_')}.jpg`
  } else {
    const searchParams = new URLSearchParams({ slide, ...imageKeys })
    return `${getApiUrl()}/imagecreator/serveImageExterieur?${searchParams}`
  }
}

export function getSlides(propsCart, overview = false) {
  let slides = []

  if (
    !propsCart.layout ||
    !propsCart.exterieur ||
    !propsCart.exterieur.length
  ) {
    return slides
  }

  let imageKeys = getSlidesImageKeys(propsCart)

  if (propsCart.model.imageKey === 'A') {
    for (let i = 1; i <= 5; i++) {
      slides.push({ img: getExteriorImagePath(propsCart, imageKeys, i) })
    }
  } else {
    if (['iS69', 'F920'].includes(propsCart.layout.imageKey)) {
      for (let i = 1; i < 5; i++) {
        slides.push({
          img:
            getAssetUrl() +
            getSlidesPath() +
            `${propsCart.layout.imageKey}_${imageKeys.basecolor}_${imageKeys.decor}_${imageKeys.rim}_${imageKeys.color}_${imageKeys.detail}_${i}.jpg`
        })
      }
      slides.push({
        img:
          getAssetUrl() +
          getRimsPath() +
          `${propsCart.layout.imageKey}_${imageKeys.basecolor}_${imageKeys.decor}_${imageKeys.rim}_${imageKeys.color}_${imageKeys.detail}_5.jpg`
      })
    } else {
      for (let i = 1; i < 5; i++) {
        slides.push({
          img:
            getAssetUrl() +
            getSlidesPath() +
            `${propsCart.layout.imageKey}_${imageKeys.decor}_${imageKeys.basecolor}_${imageKeys.rim}_${imageKeys.color}_00${i}.jpg`
        })
      }
      if (imageKeys.basecolor === 'w') {
        slides.push({
          img:
            getAssetUrl() +
            getRimsPath() +
            `${propsCart.layout.imageKey}_${imageKeys.basecolor}_${imageKeys.rim}_${imageKeys.color}-beauty.jpg`
        })
      } else {
        slides.push({
          img:
            getAssetUrl() +
            getRimsPath() +
            `${propsCart.layout.imageKey}_${imageKeys.decor}_${imageKeys.basecolor}_${imageKeys.rim}_${imageKeys.color}_005.jpg`
        })
      }
    }
  }

  if (overview && propsCart.interieur && propsCart.interieur.length) {
    slides.push({
      img: getApiUrl() + buildParamsFromArgs(400, propsCart)
    })
    if (propsCart.model.imageKey === 'F') {
      slides.push({
        img: getApiUrl() + buildParamsFromArgs(980, propsCart)
      })
      slides.push({
        img: getApiUrl() + buildParamsFromArgs(970, propsCart)
      })
    }
  }

  return slides
}

export function getDiscountPrice(packageItem, psmotor) {
  let totalPrice = 0
  let discountPrice = 0

  let price = packageItem.price
  if (packageItem[psmotor]) {
    price = packageItem[psmotor]
  }

  totalPrice += packageItem.details.reduce((total, item) => {
    return total + getPriceItem(item, psmotor)
  }, 0)

  discountPrice = totalPrice - price

  return discountPrice
}

// 2021-07-20 Hr. Kaiser: Für alle Sprachen: Alle Serienausstattungen mit Betrag 0,- sollen auch ins PDF
// 2024-07-12 Hr. Kaiser: SAs, die als serie markiert sind werden in den Warenkorb/PDF übernommen (SA mit Preis: Pflichtausstattung)
export async function addStandardEquipmentToCart(context, items, chapterTitle) {
  let newItems = items
  let new_cart = context.cart

  const specialItem = items.flatMap((cg) => cg.items).filter((c) => c.serie)

  specialItem.map((item) => {
    new_cart = deleteInCart(new_cart, item)

    const parent_index = item.category || '0'
    const title = items[parent_index].title

    new_cart = saveToCart(item, chapterTitle, new_cart, parent_index, title)

    return ''
  })

  await context.updateCart(new_cart)

  newItems = markItemsAsSelected(items, new_cart, chapterTitle)

  const selectedItems = []

  if (context.cart.packages) {
    context.cart.packages.map((paket) => {
      if (paket !== null) {
        paket.items.map((item) => {
          if (item.details) {
            item.details.map((detail) => {
              return selectedItems.push(detail.code)
            })
          }
          return null
        })
      }
      return null
    })
  }

  newItems.map((level) => {
    level.items.map((item) => {
      if (selectedItems.includes(item.code)) {
        return (item.disabled = true)
      } else {
        return (item.disabled = false)
      }
    })
    return null
  })

  return newItems
}

export function getCartCodes(cart) {
  const data = []

  const cartTabs = [
    'exterieur',
    'interieur',
    'packages',
    'chassis',
    'aufbau',
    'technik'
  ]

  cartTabs.forEach((tab) => {
    if (cart[tab]) {
      cart[tab].forEach((tabItem) => {
        if (tabItem && Array.isArray(tabItem.items)) {
          tabItem.items.forEach((item) => {
            data.push(item.code)
          })
        } else if (tabItem && tabItem.item) {
          data.push(tabItem.item.code)
        }
      })
    }
  })

  return data
}

export function trackingConfigurationStart(country, series, model, user) {
  axios(
    getApiUrl() +
      `/data/tracking?type=start&country=` +
      country +
      `&series=` +
      series +
      `&model=` +
      model +
      `&user=` +
      user
  )
  return
}

function buildParamsFromArgs(view, cart) {
  let globalParams = {
    model: cart.model.imageKey,
    productId: cart.layout.productId,
    systemName: cart.layout.systemName,
    view: view
  }

  cart.interieur.forEach((item) => {
    if (item.item && item.item.typeKey && item.item.imageKey) {
      globalParams[item.item.typeKey] = item.item.imageKey
    }

    if (item.items && Array.isArray(item.items)) {
      item.items.forEach((item) => {
        if (item.typeKey && item.imageKey) {
          globalParams[item.typeKey] = item.imageKey
        }
      })
    }
  })

  const searchParams = new URLSearchParams(sortKeys(globalParams))

  return `/imagecreator/serveImage?${searchParams}`
}

export function sortKeys(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (map, key) {
      map[key] = obj[key]
      return map
    }, {})
}
