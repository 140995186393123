import React from 'react'

class Infobox extends React.Component {
  render() {
    return (
      <div className={'infobox'}>
        <div
          dangerouslySetInnerHTML={{ __html: this.props.content }}
          className={'infobox__content text'}
        />
      </div>
    )
  }
}

export default Infobox
