import React from 'react'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Avtryck</h1>
            <h2>
              <strong>Imprint:</strong>
            </h2>
            <p>
              Kombinerat imprint (leverantörs-id) enligt den tyska
              telemedialagen (TMG), det statliga avtalet för radio och
              telemedier (RStV) och informationer enligt förordningen om tjänst-
              och informationsplikter (DL-InfoV) baserade på EU:s direktiv (RL
              2006/123/EG, Officiell tidning EU2006, L376/36 “DRLR”)
            </p>
            <p>
              Leverantör och därmed ansvarig för den kommersiella och
              affärsmässiga webbplatsen enligt § 5 i TMG i dess funktion som
              grundläggande del av lagen om elektronisk handel
              (Elektronischer-Geschäftsverkehr-Vereinheitlichungsgesetz ElGVG)
              och lagen om reglering av ramvillkoren för informations- och
              kommunikationstjänster (IuKDG), är Niesmann+Bischoff GmbH som
              företräds av verkställande direktörerna Hubert Brandl samt
              prokurist Josef Henrichs.
            </p>
            <p>
              Informationer enligt § 2 (1) DL-InfoV
              <br />
              Denna onlinetjänst med tillhörande informationer&nbsp;
              tillhandahålls av:
            </p>
            <p>
              <strong>
                Niesmann+Bischoff GmbH
                <br />
              </strong>
              Cloustraße 1<br />
              D-56751 Polch/Tyskland
              <br />
              E-postadress:{' '}
              <a href="mailto:info@niesmann-bischoff.vom" className="local">
                info@niesmann-bischoff.com
              </a>
              <br />
              Webbplats:{' '}
              <a href="http://www.niesmann-bischoff.com/" className="local">
                www.niesmann-bischoff.com
              </a>
              <br />
              Telefon: +49 2654 933 -0
              <br />
              Fax: +49 2654 933 -100
            </p>
            <p>
              Huvudkontor Niesmann+Bischoff GmbH: D-56751 Polch
              <br />
              Amtsgericht Koblenz, HRB 23440
              <br />
              Företagsform: Bolag med begränsad ansvarighet (GmbH)
              <br />
              Gällande lag: Förbundsrepubliken Tysklands (BRD) lagstiftning
            </p>
            <p>
              Skattemyndighet Mayen
              <br />
              Skatteregistreringsnummer: DE 148 734 460
              <br />
              Rättsliga företrädare för bolaget&nbsp; VD Hubert Brandl,
              Prokurist Josef Henrichs
            </p>
            <p>
              <strong>
                Behörig industri- och handelskammare
                <br />
              </strong>
              Industrie- und Handelskammer Koblenz
              <br />
              Schlossstraße 8, D-56068 Koblenz
              <br />
              Telefon: +49 261 106 -0
              <br />
              Fax: +49 261 106 -234
              <br />
              E-postadress:{' '}
              <a href="mailto:info@koblenz.ihk.de" className="local">
                info@koblenz.ihk.de
                <br />
              </a>
              Hemsida:{' '}
              <a href="http://www.ihk-koblenz.de/" className="external">
                www.ihk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Behörig hantverkskammare
                <br />
              </strong>
              Handwerkskammer Koblenz
              <br />
              Friedrich-Ebert-Ring 33, D-56068 Koblenz
              <br />
              Telefon: +49 261 398-0
              <br />
              Fax: +49 261 398-398
              <br />
              E-postadress:{' '}
              <a href="mailto:hwk@hwk-koblenz.de" className="local">
                hwk@hwk-koblenz.de
                <br />
              </a>
              Webbplats:{' '}
              <a href="http://www.hwk-koblenz.de/" className="external">
                www.hwk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Informationer om online-tvistlösning enligt § 14 (2) ODR-VO:
                <br />
              </strong>
              EU-kommissionen tillhandahåller en online-plattform för
              tvistlösning (ODR) – se{' '}
              <a href="http://ec.europa.eu/consumers/odr" className="external">
                http://ec.europa.eu/consumers/odr
              </a>
              . ODR-plattformen fungerar som kontaktpunkt för reglering av
              tvister utanför domstol när det gäller ingångna avtal i samband
              med onlineköp.
              <br />
              Vi är varken tvungna eller villiga att delta i
              tvistlösningsförfaranden inför ett förlikningsorgan för
              konsumenter.
            </p>
            <p>
              <strong>
                Ansvarig för journalistiska redaktionsbidrag enligt § 55 (2) i
                det statliga avtalet för radio och telemedier (RStV):
                <br />
              </strong>
              Philipp Komers, försäljningschef D-A-CH,{' '}
              <a
                href="mailto:philipp.komers@niesmann-bischoff.com"
                className="local"
              >
                philipp.komers@niesmann-bischoff.com
                <br />
              </a>
              Cloustraße 1, D-56751 Polch
            </p>
            <p>
              <strong>
                Externt dataskyddsombud enligt § 37 EU-GDPR tillsammans med § 38
                (1) Tysk federal dataskyddslag (BDSG-neu):
                <br />
              </strong>
              Stefan Fischerkeller
              <br />
              Deutsche Datenschutzkanzlei
              <br />
              Office Bodensee
              <br />
              <a
                href="http://www.deutsche-datenschutzkanzlei.de/"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              Dataskyddsombudets e-postadress:{' '}
              <a
                href="mailto:fischerkeller@deutsche-datenschutzkanzlei.de"
                className="local"
              >
                fischerkeller@deutsche-datenschutzkanzlei.de
              </a>
              ;{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>{' '}
              eller över följande webbsida för dataskydd:{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de/"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              <strong>
                Tekniskt ansvarig för denna webbplats:
                <br />
              </strong>
              nbsp GmbH, Mario Kandler, Küstriner Straße 14, D-94315 Straubing
              <br />
              Telefon: +49 9421 78470, E-post:{' '}
              <a href="mailto:info@nbsp.de" className="local">
                info@nbsp.de
              </a>
            </p>
            <p>
              <strong>
                Ansvarig för webbplatsdesign:
                <br />
              </strong>
              3st Kommunikation GmbH, Florian Heine, Taunusstraße 56, D-55118
              Mainz
              <br />
              Telefon: +49 6131 49961-0, E-post:{' '}
              <a href="mailto:fheine@3st.de" className="local">
                fheine@3st.de
              </a>
            </p>
            <p>
              <strong>Verksamhetsbeskrivning firma Niesmann+Bischoff</strong>{' '}
              <strong>GmbH: </strong>Produktion av och handel med husbilar,
              husvagnar samt bostadsenheter och tillbehör. &nbsp;
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Prisuppgifter enligt § 4 DL-InfoV och PAngV (tysk
                prisregleringsförordning)
                <br />
              </strong>
              Information om priser anges på Niesmann+Bischoff GmbH:s webbplats.
              <br />
              Niesmann+Bischoff GmbH lämnar gärna en offert om så önskas.
            </p>
            <p>
              <strong>
                ANSVAR
                <br />
              </strong>
              Niesmann+Bischoff GmbH kan när som helst och efter eget tycke samt
              utan något som helst ansvar helt eller delvis ändra resp. ta bort
              sin webbplats. Genom att länka till främmande webbplatser
              (“hyperlänkar”) övertar Niesmann+Bischoff GmbH inte något ansvar
              för dessa webbplatser eller deras innehåll.&nbsp; Dessutom
              ansvarar Niesmann+Bischoff GmbH inte för att dessa webbplatser
              eller deras innehåll är tillgängliga.
            </p>
            <p>
              <strong>
                © Copyright
                <br />
              </strong>
              Med reservation för samtliga rättigheter. Text, bilder och grafik
              samt deras placering på webbplatsen skyddas enligt lagen om
              upphovsrätt samt andra skyddslagar. Innehållet på dessa webbsidor
              får inte kopieras för kommersiella ändamål, distribueras,
              förändras eller överlåtas till tredje part.
            </p>
            <p>
              <strong>© Copyright bildkällor: </strong>Niesmann+Bischoff GmbH
            </p>
            <p>
              <strong>
                Märk: Allmänna lagen om likabehandling (AGG)
                <br />
              </strong>
              För att underlätta läsbarheten avstår vi från könsspecifik
              differentiering vid hänvisning till personer. Enligt principen om
              likabehandling gäller dylika uttryck för båda könen.
            </p>
            <p>
              <strong>
                Både denna text och förklaringen av sekretesspolicy har
                utfärdats av:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –<br />
              <a
                href="http://www.deutsche-datenschutzkanzlei.de/"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
