import React from 'react'
import InfoButton from './../Overlay/InfoButton'
import {
  translate,
  formatWeight,
  checkPrice,
  renderMarkup,
  formatPrice,
  getDiscountPrice
} from '../../helpers'

var classNames = require('classnames')

class PaketItem extends React.Component {
  renderButtons(item) {
    if (item.serie) {
      return (
        <button className="not-allowed" name="remove" disabled={true}>
          <span>{translate('global', 'standard_equipment')}</span>
        </button>
      )
    }
    if (item.selected) {
      return (
        <button
          className="button"
          name="remove"
          onClick={(e) => this.props.removePackage(item)}
        >
          <span>{translate('global', 'delete_option')}</span>
        </button>
      )
    } else {
      return (
        <button
          className="button button--cta"
          name="add"
          onClick={(e) => this.props.selectPackage(item)}
        >
          <span>{translate('global', 'add_option')}</span>
        </button>
      )
    }
  }

  renderInfoButton = () => {
    let renderInfoButton = false

    this.props.item.details.map((item) => {
      if (item.img && item.img.length) {
        renderInfoButton = true
      }
      return ''
    })

    if (renderInfoButton) {
      return <InfoButton infos={this.props.item} {...this.props} />
    }
  }

  render() {
    this.props.item.discount = getDiscountPrice(
      this.props.item,
      this.props.psmotor
    )

    return (
      <div
        className={classNames(
          'konfigurator__container__item g-m--down-12 g-m--up-6 g-nested',
          this.props.item.selected ? 'is-active' : ''
        )}
      >
        <div className="g u-boxed">
          <div className="g-6 text">
            <h3>{this.props.item.title}</h3>
            <span className="small">
              {translate('global', 'option_code')} {this.props.item.code}
            </span>
            <br />
            <span className="small">
              {translate('global', 'additional_weight')}
              {' + '}
              {formatWeight(this.props.item.weight)}*
            </span>
          </div>
          <div className="g-6 text u-text-right">
            <strong>
              <em>{checkPrice(this.props.item, this.props.psmotor)}</em>
            </strong>
            <br />
            {this.props.item.discount > 0 ? (
              <span className="small">
                {translate('global', 'price_advantage')}{' '}
                {formatPrice(this.props.item.discount)}
              </span>
            ) : null}
            <br />
          </div>

          <div className="g-12 u-text-right">
            {this.renderInfoButton()}
            {!this.props.hideCTA ? this.renderButtons(this.props.item) : null}
          </div>

          <hr />
          <div className="text">
            <ul>
              {this.props.item.details &&
                this.props.item.details.map((detail, i) => (
                  <li key={i}>
                    <div
                      className="left"
                      // dangerouslySetInnerHTML={renderMarkup(detail.meta)}
                    >
                      <span className="small">{detail.code}</span>
                    </div>
                    <div
                      className="middle"
                      dangerouslySetInnerHTML={renderMarkup(detail.description)}
                    />
                    <div className="right">
                      {checkPrice(detail, this.props.psmotor)}
                      {detail.pricebpm && detail.pricebpm !== 0 ? (
                        <span className="small">
                          incl. Bpm {formatPrice(detail.pricebpm)}
                        </span>
                      ) : null}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default PaketItem
