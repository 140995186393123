import React from 'react'
import Loading from './../Loading/Loading'
import FooterLink from './../Overlay/FooterLink'
import { withRouter } from 'react-router-dom'
import {
  recalculatePricesAndWeights,
  formatPrice,
  checkPrice,
  redirectToStart,
  translate,
  renderMarkup,
  trackingConfigurationStart
} from '../../helpers'
import { getApiUrl, getLanguage, LAYOUT_PATH, getAssetUrl } from '../../config'
import SVG from './../SVG/SVG'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import LayoutSlider from './../Slider/LayoutSlider'
import { AppContext } from '../../Data/ContextProvider'
import { history } from '../../Data/Store'

class Grundriss extends React.Component {
  constructor(props) {
    super(props)
    this.handleLayout = this.handleLayout.bind(this)
    this.toggleClass = this.toggleClass.bind(this)
    this.collectGalleryItems = this.collectGalleryItems.bind(this)

    this.state = {
      isLoading: false,
      grundriss: [],
      footnote: [],
      infobox: '',
      weightBlock: []
    }
  }

  getBasePrices(key, basePrice) {
    let allPrices = []
    let prices = []

    allPrices[key] = basePrice
    prices['all'] = allPrices
    prices['total'] = basePrice

    return prices
  }

  async handleLayout(i) {
    const grundriss = this.state.grundriss[i]
    const weightBlock = this.state.weightBlock[i]

    const country = window.currentLocalization.locale
      .split('_')[0]
      .toLowerCase()
    const series = this.context.cart.model.title
    const model = grundriss.systemName
    const user = window.configurator.user

    if (
      grundriss &&
      this.context.cart &&
      this.context.cart.model &&
      this.context.cart.layout
    ) {
      if (this.context.cart.layout.title !== grundriss.title) {
        await this.context.clearLayout(this.context.cart.model)
        await this.context.clearStepsCompleted()
        await this.context.addStepsCompleted(1)

        trackingConfigurationStart(country, series, model, user)
      }
    } else {
      trackingConfigurationStart(country, series, model, user)
    }

    await this.context.updateWeightBlock(weightBlock)
    await this.context.updateLayout(grundriss)
    await this.context.addStepsCompleted(2)

    await recalculatePricesAndWeights(this.context.cart)
    history.push('/03-exterieur')
  }

  toggleClass(i) {
    let newLayout = this.state.grundriss.map((el, index) => {
      if (index === i) {
        return (el.details_open = !el.details_open)
      }
      return null
    })
    this.setState({ newLayout })
  }

  collectGalleryItems(collection) {
    let gallery_items = []
    collection.map((el, i) => {
      let gallery_item = {}
      gallery_item.title = el.title
      gallery_item.src = el.img_gallery
      if (el.imgGalleryFilename) {
        gallery_item.src = getAssetUrl() + LAYOUT_PATH + el.imgGalleryFilename
      }
      gallery_item.w = el.w
      gallery_item.h = el.h

      return gallery_items.push(gallery_item)
    })

    this.context.updateGalleryOverlay(gallery_items)
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ isLoading: true })
    await this.context.updateCurrentNav(2)
    this.context.updateJumpmarks([])

    try {
      fetch(
        getApiUrl() +
          `/data/grundriss?modell=${this.context.cart.model.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : ''
          const grundriss = data.items ? data.items : []
          const weightBlock = data.weightBlock ? data.weightBlock : []
          this.setState({
            grundriss,
            footnote,
            infobox,
            weightBlock,
            isLoading: false
          })
        })
        .then((data) => this.collectGalleryItems(this.state.grundriss))
    } catch (error) {
      console.log(error)
      redirectToStart()
    }
  }

  renderCatalogPrice() {
    if (getLanguage() === 'at') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.renderATPriceVat()
          }}
          className="product-comparison__item__price__vat"
        />
      )
    }

    if (getLanguage() === 'en') {
      let text = translate('grundriss', 'catalog_price')

      if (['Arto', 'iSmove'].includes(this.context.cart.model.title)) {
        text = text.replace('incl., customs duties not', '')
      }

      return (
        <span
          dangerouslySetInnerHTML={{
            __html: text
          }}
          className="product-comparison__item__price__vat"
        />
      )
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: translate('grundriss', 'catalog_price')
        }}
        className="product-comparison__item__price__vat"
      />
    )
  }

  renderATPriceVat() {
    if (this.context.cart.model.imageKey === 'F') {
      return 'Listenpreis in Euro (Serie 180PS) inkl. 20% MwSt., 31% NoVA und<br/>€ 4.740,- Malus, sowie Transport-, Typisierungskosten und Auslieferungspauschale'
    }
    return 'Listenpreis in Euro (Serie 140PS) inkl. 20% MwSt., 20% NoVA und<br/>€ 1.260,- Malus, sowie Transport-, Typisierungskosten und Auslieferungspauschale'
  }

  render() {
    const { grundriss, isLoading } = this.state

    if (isLoading) {
      return <Loading></Loading>
    }

    return (
      <div className="konfigurator__grundriss">
        <div className="g g-inset has-nested no-touch">
          <LayoutSlider extraClasses="v-inverted">
            {grundriss.map((item, i) => (
              <div
                key={i}
                className="slider__item g-m--down-12 g-m--up-4 g-nested"
              >
                <article
                  itemScope="itemscope"
                  itemType="http://schema.org/Product"
                  tabIndex="-1"
                  role="option"
                  aria-hidden="false"
                  aria-selected
                  className="product-comparison__item"
                >
                  <header className="product-comparison__item__header">
                    {item.imgFilename ? (
                      <img
                        src={getAssetUrl() + LAYOUT_PATH + item.imgFilename}
                        itemProp="image"
                        className="product-comparison__item__image"
                        alt={item.title}
                      />
                    ) : (
                      <img
                        src={item.img}
                        itemProp="image"
                        className="product-comparison__item__image"
                        alt={item.title}
                      />
                    )}
                    <h1
                      itemProp="name"
                      className="product-comparison__item__name"
                    >
                      {item.title}

                      {item.modelRemark && item.modelRemark.length > 0 ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.modelRemark
                          }}
                        />
                      ) : (
                        ''
                      )}

                      {item.imgGalleryFilename ? (
                        <a
                          href={
                            getAssetUrl() +
                            LAYOUT_PATH +
                            item.imgGalleryFilename
                          }
                          className="overlay-gallery__trigger-open"
                          onClick={(e) =>
                            this.context.toggleGalleryOverlay(e, i)
                          }
                        >
                          <SVG name="zoom" />
                          <h3>{item.title}</h3>
                        </a>
                      ) : (
                        <a
                          href={item.img_gallery}
                          className="overlay-gallery__trigger-open"
                          onClick={(e) =>
                            this.context.toggleGalleryOverlay(e, i)
                          }
                        >
                          <SVG name="zoom" />
                          <h3>{item.title}</h3>
                        </a>
                      )}
                    </h1>
                    {item.view360 && (
                      <a className="product-comparison__item__360">
                        <SVG name="360" />
                        <FooterLink
                          name="360°"
                          infos={{ content: '360°', link: item.view360 }}
                          {...this.props}
                        />
                      </a>
                    )}
                  </header>
                  <div className="product-comparison__item__content">
                    <div
                      itemScope="itemscope"
                      itemType="http://schema.org/Offer"
                      className="product-comparison__item__price"
                    >
                      <div
                        className="button button--cta"
                        onClick={() => this.handleLayout(i)}
                      >
                        <span>{translate('global', 'choose')}</span>
                      </div>
                      <span
                        itemProp="price"
                        className="product-comparison__item__price__value"
                      >
                        {checkPrice(item, item.PSMotor)}
                      </span>
                      {this.renderCatalogPrice()}
                      {item.pricebpm !== 0 && (
                        <table className="table-zebra">
                          <tbody>
                            <tr>
                              <td>
                                {translate('grundriss', 'consume_price', true)}
                              </td>
                              <td>{formatPrice(item.price + 1850)}</td>
                            </tr>
                            <tr>
                              <td>
                                {translate('grundriss', 'bpm_price', true)}
                              </td>
                              <td>{formatPrice(item.pricebpm)}</td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                    <dl
                      className={
                        'dl-zebra ' + (item.details_open ? 'is-active' : '')
                      }
                    >
                      {item.details.map((detail, i) => (
                        <React.Fragment key={i}>
                          <dt className={detail.classes ? detail.classes : ''}>
                            <div
                              dangerouslySetInnerHTML={renderMarkup(detail.dt)}
                            />
                          </dt>
                          <dd>
                            <div
                              dangerouslySetInnerHTML={renderMarkup(detail.dd)}
                            />
                          </dd>
                        </React.Fragment>
                      ))}
                    </dl>
                    {item.details.length > 5 && (
                      <button
                        className={
                          'button button--dl-zebra js-zebra-toggle ' +
                          (item.details_open ? 'is-active' : '')
                        }
                        onClick={() => this.toggleClass(i)}
                      >
                        {item.details_open ? (
                          <span>{translate('grundriss', 'details_on')}</span>
                        ) : (
                          <span>{translate('grundriss', 'details_off')}</span>
                        )}
                      </button>
                    )}
                  </div>
                </article>
              </div>
            ))}
          </LayoutSlider>
          <Infobox content={this.state.infobox} />
          {this.state.footnote.length > 0 ? (
            <Footnote footnote={this.state.footnote} />
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

Grundriss.contextType = AppContext
export default withRouter(Grundriss)
