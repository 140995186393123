import React from 'react'
// import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  checkPrice,
  formatWeight,
  translate,
  renderMarkup
} from '../../helpers'
import { getAssetUrl, MULTISELECT_SUBCATEGORY_ID } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

var classNames = require('classnames')

const noStandardLabel = ['V7004510', 'V3037213F', 'V2021027A']

class SimpleTabs extends React.Component {
  constructor(props) {
    super(props)
    this.updateArrowsDisabled = this.updateArrowsDisabled.bind(this)
    this.updateSelectedTab = this.updateSelectedTab.bind(this)
    this.state = {
      tabIndex_child: 0,
      previousDisabled: true,
      nextDisabled: false,
      menuOpened: false,
      arrowLeftDisabled: true,
      arrowRightDisabled: true
    }
  }

  handleSelections(parent_index, i) {
    this.updateNextPrevArrowsDisabled(parent_index)
    this.updateSelectedTab()
    this.props.handleSelections(parent_index, i)
  }

  handleParentSelections(newIndex, title, namespace) {
    if (namespace === 'exterieurTab1' || namespace === 'exterieurTab2') {
      this.props.handleSelectedTab(newIndex)
    }
    this.updateNextPrevArrowsDisabled(newIndex)
    this.context.changeSelectedTab(newIndex, namespace)
  }

  updateNextPrevArrowsDisabled(newIndex) {
    if (newIndex >= this.props.items.length - 1) {
      this.setState({
        nextDisabled: true
      })
    } else {
      this.setState({
        nextDisabled: false
      })
    }

    if (newIndex <= 0) {
      this.setState({
        previousDisabled: true
      })
    } else {
      this.setState({
        previousDisabled: false
      })
    }
  }

  updateSelectedTab = () => {
    this.props.items[this.context.config[this.props.name]].items.forEach(
      (element, index) => {
        if (!MULTISELECT_SUBCATEGORY_ID.includes(element.ukid)) {
          if (element.selected) {
            if (this.state.tabIndex_child !== index) {
              this.setState({ tabIndex_child: index })
            }
          }
        }
      }
    )
  }

  updateArrowsDisabled = () => {
    if (!this.$scrollArea || !this.$scrollArea.length) {
      return
    }

    let left = this.$scrollArea[0].scrollLeft

    if (left <= 0 && this.state.arrowLeftDisabled === false) {
      this.setState({
        arrowLeftDisabled: true
      })
    } else if (left > 0 && this.state.arrowLeftDisabled === true) {
      this.setState({
        arrowLeftDisabled: false
      })
    }

    if (left >= 0) {
      let el = this.$scrollArea[0]
      let hasReachedEnd = el.scrollWidth - el.scrollLeft <= el.offsetWidth
      let canNotScroll = el.scrollWidth === el.offsetWidth

      if (canNotScroll || hasReachedEnd) {
        if (this.state.arrowRightDisabled === false) {
          this.setState({
            arrowRightDisabled: true
          })
        }
      } else if (this.state.arrowRightDisabled) {
        this.setState({
          arrowRightDisabled: false
        })
      }
    }
  }

  handleScroll = () => {
    let left = this.$scrollArea[0].scrollLeft
    if (this.direction === 'right') {
      left += 152
    } else {
      left -= 152
    }

    this.$scrollArea[0].scrollLeft = left
    this.updateArrowsDisabled()
  }

  scrollStart(direction) {
    this.direction = direction
    this.handleScroll()
  }

  handleTabSelection = (tabIndex_child) => {
    this.setState({ tabIndex_child })
  }

  handleScrollLeftStart = () => this.scrollStart('left')
  handleScrollRightStart = () => this.scrollStart('right')

  componentDidUpdate() {
    this.updateArrowsDisabled()
    this.updateSelectedTab()
  }

  componentWillUnmount() {
    this.context.changeSelectedTab(0, this.props.name)
    this.context.updateCurrentParentTabs([])
  }

  async componentDidMount() {
    // await this.context.updateCurrentParentTabs(this.props.items)
    this.$scrollArea = document.getElementsByClassName(
      'tabs__content__items__inner'
    )

    // this.context.changeSelectedTab(0, this.props.name)
    this.updateSelectedTab()
  }

  renderMultiSelectTabPanelContent = (item, parent_index, equipment, i) => {
    if (
      !this.context.cart ||
      !this.context.cart.interieur ||
      !this.context.cart.interieur[parent_index]
    ) {
      return this.renderTabPanelContent(equipment)
    }

    if (
      this.context.cart.interieur[parent_index].items &&
      this.context.cart.interieur[parent_index].items.length > 0
    ) {
      return this.context.cart.interieur[parent_index].items.map(
        (cartItem, key) => {
          return this.renderTabPanelContent(cartItem, key)
        }
      )
    } else {
      return this.renderTabPanelContent(item.items[0])
    }
  }

  renderTabPanelContent = (item, key = 0) => {
    return (
      <div key={key} className="tabs__content__description text">
        <div className="g">
          <div className="g-9">
            <strong>
              <em>
                <div dangerouslySetInnerHTML={renderMarkup(item.title)} />
              </em>
            </strong>
            <br />
            <span className="small">
              {translate('global', 'option_code')} {item.code}
            </span>
            <br />
            <span className="small">
              {translate('global', 'additional_weight')}{' '}
              {formatWeight(item.weight)}*
            </span>
          </div>
          <div className="g-3 u-text-right">
            <strong>
              <em>{checkPrice(item, this.context.cart.layout.PSMotor)}</em>
            </strong>
            <br />
            {item.price === 0 && !noStandardLabel.includes(item.code) ? (
              <span className="small">
                {translate('global', 'standard_equipment_overlay')}
              </span>
            ) : null}
          </div>
        </div>
        <div className="g">
          {item.description && item.description.trim() !== '' ? (
            <p dangerouslySetInnerHTML={renderMarkup(item.description)} />
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    let previousClassNames = classNames(
      'tabs__trigger__menu__controls__previous',
      {
        'is-disabled': this.state.previousDisabled
      }
    )

    let nextClassNames = classNames('tabs__trigger__menu__controls__next', {
      'is-disabled': this.state.nextDisabled
    })

    let menuClassNames = classNames('tabs__trigger__menu__items', {
      'is-visible': this.state.menuOpened
    })

    let leftClasses = classNames(
      'tabs__content__arrow tabs__content__arrow--left',
      {
        'is-disabled': this.state.arrowLeftDisabled
      }
    )
    let rightClasses = classNames(
      'tabs__content__arrow tabs__content__arrow--right',
      {
        'is-disabled': this.state.arrowRightDisabled
      }
    )

    const { items } = this.props

    if (items.length === 0) {
      return (
        <div className="loading">
          <div className="spinner"></div>
          <span>{translate('global', 'loading')}</span>
        </div>
      )
    }

    return (
      <Tabs
        className="react-tabs tabs"
        name={this.props.name}
        selectedIndex={this.context.config[this.props.name]}
        // onSelect is required
        // onSelect == onClick in Tab, TabList
        onSelect=""
      >
        <TabList className="react-tabs__tab-list tabs__items">
          {items.map((item, parent_index) => (
            <Tab
              to={parent_index.toString()}
              key={parent_index}
              onClick={(e) =>
                this.handleParentSelections(
                  parent_index,
                  item.title,
                  this.props.name
                )
              }
            >
              {item.title}
            </Tab>
          ))}
        </TabList>

        {/*
          tabs__trigger__menu is mobile view
        */}
        <div className="tabs__trigger__menu">
          <div className={menuClassNames}>
            {items.map((item, index) => (
              <button
                className={
                  this.context.config[this.props.name] === index
                    ? 'is-selected'
                    : ''
                }
                key={index}
                onClick={(e) =>
                  this.handleParentSelections(
                    index,
                    item.title,
                    this.props.name
                  )
                }
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className="tabs__trigger__menu__controls">
            <button
              className={previousClassNames}
              name="prev"
              onClick={(e) =>
                this.handleParentSelections(
                  this.context.config[this.props.name] - 1,
                  items[this.context.config[this.props.name] - 1].title,
                  this.props.name
                )
              }
            />
            <button className="is-selected">
              {items[this.context.config[this.props.name]].title}
            </button>
            <button
              className={nextClassNames}
              name="next"
              onClick={(e) =>
                this.handleParentSelections(
                  this.context.config[this.props.name] + 1,
                  items[this.context.config[this.props.name] + 1].title,
                  this.props.name
                )
              }
            />
          </div>
        </div>

        {items.map((item, parent_index) => (
          <TabPanel key={parent_index} htmlFor={parent_index.toString()}>
            <div className="tabs__content">
              <Tabs
                selectedIndex={this.state.tabIndex_child}
                onSelect={(tabIndex_child) =>
                  this.handleTabSelection(tabIndex_child)
                }
              >
                <TabList>
                  <div
                    className={leftClasses}
                    onMouseDown={this.handleScrollLeftStart}
                    onTouchStart={this.handleScrollLeftStart}
                  />
                  <div className="tabs__content__items">
                    <div className="tabs__content__items__inner">
                      {MULTISELECT_SUBCATEGORY_ID.includes(item.UKID)
                        ? item.items.map((item, i) => {
                            return !item.hidden ? (
                              <Tab key={i}>
                                <button
                                  className={classNames({
                                    multiselect: i !== 0,
                                    'is-selected': item.selected
                                  })}
                                  onClick={() =>
                                    this.handleSelections(parent_index, i)
                                  }
                                >
                                  <img
                                    src={getAssetUrl() + item.img}
                                    alt={item.title}
                                  />
                                </button>
                              </Tab>
                            ) : (
                              <Tab key={i} />
                            )
                          })
                        : item.items.map((item, i) => {
                            return !item.hidden ? (
                              <Tab key={i}>
                                <button
                                  className={item.selected ? 'is-selected' : ''}
                                  onClick={() =>
                                    this.handleSelections(parent_index, i)
                                  }
                                >
                                  <img
                                    src={getAssetUrl() + item.img}
                                    alt={item.title}
                                  />
                                </button>
                              </Tab>
                            ) : (
                              <Tab key={i} />
                            )
                          })}
                    </div>
                  </div>
                  <div
                    className={rightClasses}
                    onMouseDown={this.handleScrollRightStart}
                    onTouchStart={this.handleScrollRightStart}
                  />
                </TabList>

                {item.items.map((equipment, i) => {
                  return (
                    <TabPanel key={i}>
                      {MULTISELECT_SUBCATEGORY_ID.includes(equipment.ukid)
                        ? this.renderMultiSelectTabPanelContent(
                            item,
                            parent_index,
                            equipment
                          )
                        : this.renderTabPanelContent(equipment)}
                      <div className="tabs__content__description text">
                        <p>{translate('global', 'image_disclaimer')}</p>
                      </div>
                    </TabPanel>
                  )
                })}
              </Tabs>
            </div>
          </TabPanel>
        ))}
      </Tabs>
    )
  }
}

SimpleTabs.contextType = AppContext
export default SimpleTabs
