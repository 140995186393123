import React from 'react'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'

class OverlayGallery extends React.Component {
  render() {
    const { config } = { ...this.props }

    return Object.keys(config).length !== 0 &&
      Object.keys(config.galleryOverlay).length ? (
      <PhotoSwipe
        isOpen={config.galleryOverlay_open}
        options={config.galleryOverlayOptions}
        items={config.galleryOverlay}
        onClose={this.props.toggleGalleryOverlay}
      />
    ) : (
      <div></div>
    )
  }
}

export default OverlayGallery
