import React from 'react'
import { renderMarkup } from '../../helpers'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Legal Notice</h1>
            <p>
              Privacy Policy
              <br />
              According to § 13 Article 1 German Teleservices Act (TMG), the
              provider of a teleservice has to inform his users at the beginning
              of the utilisation process, regarding type, extent and purpose of
              survey and use of personal data in a general and understandable
              form. The contents of the notification must be retrievable at any
              time for the user. Each company is obliged to inform the user on
              gathered or used person-related data.
            </p>
            <p>
              Niesmann+Bischoff GmbH places the greatest importance on security
              of user data and compliance with data protection regulations.
              Survey, processing and use of personal data is subject to the
              regulations of the currently valid applicable laws and the EU Data
              Protection Directive. Considered as personal data according to
              Article 4 No. 1 DSGVO (EU General Data Protection Regulation
              (GDPR)) are all information referring to an identifiable or
              identifiable natural person.
            </p>
            <p>
              Individual circumstances: For example – name, address, e-mail
              address, birthday, nationality, occupation, health data, religion,
              etc.
              <br />
              Factual circumstances: For example – income, financial situation,
              property, etc.
            </p>
            <p>
              <strong>Data privacy statement</strong>
              <br />
              Niesmann+Bischoff GmbH ensures that the accumulated user data are
              merely surveyed, processed, saved and used related to the
              processing of user inquiries, and for internal purposes only.
            </p>
            <p>
              <strong>
                Data transfer to third parties
                <br />
              </strong>
              No data transfer takes place to third parties without the consent
              of the person concerned or without statutory basis.
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Data transfer – SSL encryption
                <br />
              </strong>
              On this website is used a 256-bit-SSL (AES 256) cryptographic
              technique. User data are exclusively transferred cryptographically
              secured. This includes the IP address of the user.
            </p>
            <p>
              <strong>
                Cookies
                <br />
              </strong>
              Niesmann+Bischoff GmbH makes use of cookies. Cookies are small
              text files, which are locally saved in the cache of the user’s web
              browser. The cookies facilitate recognition of the web browser.
              The data are used for helping the browser to navigate through the
              website, and to use the full extent of all functions.
              <br />
              This website uses: browser cookies<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                User control of the cookies
                <br />
              </strong>
              The user is able to adjust all browsers such that cookies are only
              accepted upon request. Likewise, by setting it is possible to
              accept only cookies of which the pages are currently visited. All
              browsers offer functions facilitating the selective deletion of
              cookies. The acceptance of cookies can also be generally switched
              off. However, then the possible restrictions in the ease of
              operation of this website have to be accepted.
            </p>
            <p>
              <strong>
                Application of first-party cookies (Google Analytics Cookie)
                <br />
              </strong>
              Recording Google analytics cookies:
            </p>
            <ul>
              <li>
                <strong>Unique users</strong> – Google Analytics Cookies
                captures and groups user data. All activities during one visit
                are summarised. By placing Google Analytics Cookies a difference
                is made between users and unique users.
              </li>
              <li>
                <strong>User activities</strong> – Google Analytics Cookies do
                also save data of starting and end time of a website visit, and
                how many pages the user has looked at. When closing the browser
                or in case of a longer inactivity of the user (standard 30
                minutes), the user session is terminated and the cookie
                registers the visit as finished. Furthermore are registered date
                and time of the first visit. Also is registered the total number
                of visits per unique user. External link:{' '}
                <a
                  href="http://www.google.com/analytics/terms/de.html"
                  className="external"
                >
                  http://www.google.com/analytics/terms/de.html
                </a>
              </li>
            </ul>
            <p>
              The user can prevent recording of the data produced by the cookie
              and use of the data referring to the use of the website (incl.
              user IP-address) by Google, as well as processing of these data by
              Google, by downloading and installing the following link in the
              browser plug-in: External link:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .<br />
              Further information can be found under paragraph “web analytics
              service Google Analytics / Universal Analytics”.
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Lifetime of applied cookies
                <br />
              </strong>
              The cookies are managed by the web server of this website. This
              website uses:
              <br />
              Transient Cookies/Session Cookies (one-time utilisation process)
              <br />
              Lifetime: Until closing this website
              <br />
              Persistent cookie (permanent browser identification)
              <br />
              Lifetime: 30 minutes
            </p>
            <p>
              <strong>
                Deactivating or removing cookies (opt-out)
                <br />
              </strong>
              Each web browser offers options to delimit and delete cookies.
              Further information can be obtained on the following websites:
            </p>
            <ul>
              <li>
                <em>
                  Internet Explorer:
                  <br />
                </em>
                <a
                  href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                  className="external"
                >
                  http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9
                </a>
              </li>
              <li>
                <em>
                  Firefox:
                  <br />
                </em>
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  className="external"
                >
                  https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies
                </a>
              </li>
              <li>
                <em>
                  Google Chrome:
                  <br />
                </em>
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  className="external"
                >
                  https://support.google.com/chrome/answer/95647?hl=en
                </a>
              </li>
              <li>
                <em>
                  Safari:
                  <br />
                </em>
                <a
                  href="https://support.apple.com/de-de/HT201265"
                  className="external"
                >
                  https://support.apple.com/de-de/HT201265
                </a>
              </li>
            </ul>
            <p>
              <strong>
                <br />
                Web analytics service Google Analytics / Universal Analytics
                <br />
              </strong>
              Niesmann+Bischoff GmbH uses Google Analytics, a web analytics
              service provided by Google Inc. (“Google”). Google Analytics uses
              “cookies”, text files, which are saved on the computer of the
              user, and which facilitate an analysis of the website use. The
              information generated by the cookie regarding the use of this
              website are usually transmitted to a Google server in the USA
              where it is saved. When activating the IP anonymisation on this
              website, the IP-address of the user however, is truncated by
              Google within member states of the European Union or other
              contractual states of the treaty regarding the European Economic
              Area.
              <br />
              The full IP-address is transmitted to a Google server in the USA
              where it is truncated only in exceptional cases. On behalf of the
              operator of this website, Google will use these information to
              evaluate the use of the website for compiling reports on the
              website activities, and render further services to the website
              operator in connection with the use of the website and the
              Internet. The IP-address transmitted from the user browser in the
              outline of Google Analytics is not merged with other data by
              Google. The user is able to impede that the cookies are saved with
              a corresponding setting in the browser software. However,
              Niesmann+Bischoff GmbH indicates that in this case possibly not
              all functions of this website can be entirely used.
              <br />
              Furthermore, Niesmann+Bischoff GmbH uses Google Analytics reports
              for recording demographic characteristics and interests.
            </p>
            <p>
              In addition, the user can prevent recording of the data produced
              by the cookie and use of the data referring to the use of the
              website (incl. your IP-address) by Google, as well as processing
              of these data by Google, by downloading and installing the
              following link in the browser plug-in: Link:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              <br />
              Alternative to the browser plug-in or within browsers on mobile
              devices, the following link is valid for placing an opt-out
              cookie, which will impede in future recording by Google Analytics
              within this website (this opt-out cookie does only function in
              this browser and only for this domain, deleting the cookies in
              this browser, click again on the link): &lt;a
              href=”javascript:gaOptout()”&gt;Google Analytics disable&lt;/a&gt;
            </p>
            <p>
              Information regarding the incorporation of the opt-out cookie can
              be found via the following link:&nbsp;
              <a
                href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable"
                className="external"
              >
                https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
                <br />
              </a>
              Niesmann+Bischoff GmbH points out that the website Google
              Analytics is used with the extension “_anonymizeIp()” and the
              IP-addresses therefore are processed only in a truncated way
              precluding any direct personal identification.
            </p>
            <p>
              <strong>
                Web analysis service Matomo (previously PIWIK)
                <br />
              </strong>
              On this website data are gathered and stored using the web
              analysis service software Matomo (
              <a href="https://matomo.org/" className="external">
                www.matomo.org
              </a>
              ) for marketing and optimisation purposes. From these data user
              profiles are created using a pseudonym, for which cookies are
              used. Cookies are small text files, which are locally saved in the
              cache of the site user’s web browser. The cookies facilitate
              recognition of the web browser. The data collected with the Matomo
              technology (including your anonymised IP-address) are transmitted
              to the server of Niesmann+Bischoff GmbH and are stored for
              purposes of analysing usage, which is important for the website
              optimisation by Niesmann+Bischoff GmbH. The information generated
              by the cookie in the pseudonym user profile are not used to
              personally identify the user of this website, and are not merged
              with person-related data from the carrier of this pseudonym. In
              case the user does not agree with storage and evaluation of this
              data from the visit to the website from Niesmann+Bischoff GmbH,
              and wishes to disagree in future, the user can impede use of
              cookies and therefore the participation in tracking. For this
              purpose the user can set his browser such that the user becomes
              notified about cookies being established, and can individually
              decide acceptance or to exclude the acceptance of cookies for
              certain cases or generally. In case of not accepting cookies the
              function of the website Niesmann+Bischoff GmbH can be restricted.
            </p>
            <p>
              <strong>
                Web Analytics Service DoubleClick
                <br />
              </strong>
              In the context of using Google Analytics this website utilises the
              web analytics service DoubleClick, which allows to recognise the
              browser of the user while visiting other websites. The collected
              data are only evaluated for statistical purposes and in anonymised
              form. The information generated by the cookie regarding the use of
              the website from Niesmann+Bischoff GmbH are usually transmitted to
              a Google server in the USA where it is saved. Prior to
              transmission within member states of the European Union or other
              contractual states of the treaty regarding the European Economic
              Area, the IP-address is truncated by activating the
              IP-anonymisation.
              <br />
              Only in exceptional cases the IP-address is fully transmitted to a
              Google server in the USA before it is truncated there. The
              anonymised IP-address transmitted from the user browser in the
              outline of Google Analytics is not merged with other data by
              Google. DoubleClick cookies do not contain personal data.
              <br />
              The generated information are used for compiling reports about the
              activities&nbsp; on this website, and to render further services
              in connection with this website. As far as required by law, Google
              transmits this information to third parties if necessary, or if
              third parties are processing these data by order of Google.
            </p>
            <p>
              At any time the user has the choice to object for the future the
              tracking web analysis, or to deactivate the DoubleClick cookie.
              The extension required for the browser can be downloaded on the
              according Google website and installed. When using several
              terminals or browsers by the user, the opt-out must be executed
              for every terminal or browser. For this purpose the user can click
              on the following link:{' '}
              <a
                href="http://google.com/ads/preferences/html/opt-out.html"
                className="external"
              >
                http://google.com/ads/preferences/html/opt-out.html
              </a>
            </p>
            <p>
              <strong>U</strong>
              <strong>
                se of Google Maps
                <br />
              </strong>
              On the website of Niesmann+Bischoff GmbH is used Google Maps (API)
              from Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA (“Google”). Google Maps is a web service for
              presentation of interactive maps for visually presenting
              geographical information. Already when calling those subpages the
              map of Google Maps is integrated, information about use of the
              website from Niesmann+Bischoff GmbH (as e.g. the IP-address of the
              user) is transmitted to the Google server in the USA where it is
              saved.
              <br />
              As far as required by law, Google transmits this information to
              third parties if necessary, or if third parties are processing
              these data by order of Google. Google will not connect the
              IP-address of the user with other data from Google. However,
              technically it would be possible that Google applies the user data
              received by using Google Maps for identification of the users,
              creating from this personal profiles of users, or processing and
              using these for other purposes, beyond the control of
              Niesmann+Bischoff GmbH. The terms of use from Google can be read
              under{' '}
              <a
                href="http://www.google.de/intl/de/policies/terms/regional.html"
                className="external"
              >
                http://www.google.de/intl/de/policies/terms/regional.html
              </a>
              .<br />
              The additional terms of use for Google Maps can be read under{' '}
              <a
                href="https://www.google.com/intl/de_US/help/terms_maps.html"
                className="external"
              >
                https://www.google.com/intl/de_US/help/terms_maps.html
              </a>
              .<br />
              Detailed information regarding data protection related to the use
              of Google Maps can be found on the Google internet page („Google
              Privacy Policy“):{' '}
              <a
                href="http://www.google.de/intl/de/policies/privacy/"
                className="external"
              >
                http://www.google.de/intl/de/policies/privacy/
              </a>
            </p>
            <p>
              If the user disagrees with the future transmission of his data to
              Google in the context of using Google Maps, there is the option to
              completely deactivate Google Maps web service by disconnecting the
              JavaScript application in the user browser. Then it is not
              possible to use Google Maps and therefore neither the map display
              on the internet page from Niesmann+Bischoff GmbH.
            </p>
            <p>
              <strong>
                Google AdWords – Conversion-Tracking
                <br />
              </strong>
              The Niesmann+Bischoff GmbH website uses the online advertising
              programme “Google AdWords”, and Conversion-Tracking in the context
              of Google AdWords. The cookie for Conversion-Tracking is set if a
              user clicks on an advertisement placed by Google. Cookies are
              small text files, which are saved in your computer system. These
              cookies become invalid after 30 days and are not for personal
              identification. When the user visits this website and the cookie
              is still not expired, Google and Niesmann+Bischoff GmbH can
              identify that the user has clicked on the advertisement and was
              redirected to this page. Each Google AdWords user receives a
              different cookie. Therefore, it is not possible to trace cookies
              via the website of AdWords users.
            </p>
            <p>
              The information obtained with Conversion-Cookies are used for
              creating Conversion-Statistics for AdWords users having decided
              for Conversion-Tracking. Niesmann+Bischoff GmbH learns the total
              number of users having clicked on their advertisement and were
              redirected to a page provided with a Conversion-Tracking Tag.
              However, Niesmann+Bischoff GmbH does not receive information used
              for personal identification of users. If the user does not want to
              take part in Tracking he can object this use by deactivating the
              cookie of the Google Conversion-Tracking in the web browser under
              user settings. Thereafter, the user is not included in the
              Conversion-Tracking Statistics. Further information regarding the
              data protection regulations by Google:{' '}
              <a
                href="http://www.google.de/policies/privacy/"
                className="external"
              >
                http://www.google.de/policies/privacy/
              </a>
            </p>
            <p>
              <strong>
                Retargeting/Remarketing
                <br />
              </strong>
              Niesmann+Bischoff GmbH uses Retargeting technology provided by
              Google Inc. (“Google”). This allows to purposefully address users
              of the Niesmann+Bischoff GmbH website who already showed interest
              for offers from Niesmann+Bischoff GmbH with personalised,
              interest-based advertising. Display of the advertising material
              takes place on the base of cookie-based analysis of the earlier
              usage behaviour, but without storing personal data. In cases of
              Retargeting technology a cookie is stored on the computer or
              mobile terminal of the user for recording anonymised data on
              interests, for matching the advertisement individually to the
              stored information. These cookies are small text files, which are
              saved in your computer or mobile terminal. As a result the user
              receives display of advertisement most probably matching the
              respective interests in product and information. The user is able
              to permanently object setting of cookies for ads preferences by
              downloading and installing the browser plug-in available via the
              following link:{' '}
              <a
                href="https://www.google.com/settings/ads/onweb/"
                className="external"
              >
                https://www.google.com/settings/ads/onweb/
              </a>
              <br />
              More detailed information and the data protection regulations
              regarding advertisement and Google can be read here:{' '}
              <a href="http://www.google.com/privacy/ads/" className="external">
                http://www.google.com/privacy/ads/
              </a>
            </p>
            <p>
              <strong>
                Use of social media
                <br />
              </strong>
              Functions relating to social media can be used on our website.
              <br />
              When calling one of these sites a connection can be established to
              the respective servers of the social media. These social media
              will be informed hereby that you have visited our website with
              your IP address. Should you now comment, like or twitter
              something, etc. and you are logged into your respective account at
              this time, it is if applicable possible for the social medium to
              allocate your visit to our website to you and to your user
              account. We would like to point out to you that we as the provider
              of the sites neither have any knowledge of the contents of the
              transmitted data, nor their use.
              <br />
              These services are provided by the following companies:
            </p>
            <ul>
              <li>
                <u>Facebook Inc</u>., 1601 S. California Ave, Palo Alto, CA
                94304, USA
              </li>
              <li>
                <u>Google+ Inc</u>., 1600 Amphitheatre Parkway, Mountain View,
                CA 94043, USA
              </li>
              <li>
                <u>Linkedin Inc</u>., 2029 Stierlin Court, Mountain View, CA
                94043, USA
              </li>
              <li>
                <u>Twitter Inc</u>., 1355 Market St., Suite 900, San Francisco,
                CA 94103, USA
              </li>
              <li>
                <u>Xing AG</u>, Dammtorstraße 30, 20354 Hamburg, Germany
              </li>
              <li>
                <u>YouTube LLC</u>, 901 Cherry Ave., San Bruno, CA 94066, USA
              </li>
              <li>
                <u>Kununu GmbH</u>, Wollzeile 1-3 Top 5.1, 1010 Vienna, Austria
              </li>
              <li>
                <u>Vimeo LCC</u>, White Plains, Federal State of New York, USA
              </li>
              <li>
                <u>Pinterest Europe Ltd</u>., Palmerston House, 2nd Floor Fenian
                Street, Dublin 2, Ireland
              </li>
              <li>
                <u>Instagram LLC</u>, 1601 Willow Rd, Menlo Park CA 94025, USA
              </li>
              <li>
                <u>SoundCloud Ltd</u>., Rheinsberger Str. 76/77, 10115 Berlin,
                Germany
              </li>
              <li>
                <u>Spotify AB, </u>Birger Jarlsgatan 61, 113 56 Stockholm,
                Sweden
              </li>
              <li>
                <u>Tumblr, Inc</u>., 35 East 21st St, 10th Floor, New York, NY
                10010, USA
              </li>
              <li>
                <u>Myspace LLC, </u>8391 Beverly Blvd., #349, Los Angeles,
                California 90048, USA.
              </li>
            </ul>
            <p>
              For the purpose and scope of the data collection and the further
              processing and use of the data by the providers as well as their
              rights in this respect and setting options for the protection of
              your personal data please refer to the data protection notices of
              the respective providers:
            </p>
            <ul>
              <li>
                Facebook{' '}
                <a
                  href="https://de-de.facebook.com/privacy/explanation"
                  className="external"
                >
                  https://de-de.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Google{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Linkedin{' '}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  className="external"
                >
                  https://www.linkedin.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Twitter{' '}
                <a
                  href="https://twitter.com/privacy?lang=de"
                  className="external"
                >
                  https://twitter.com/privacy?lang=de
                </a>
              </li>
              <li>
                Xing{' '}
                <a href="https://www.xing.com/privacy" className="external">
                  https://www.xing.com/privacy
                </a>
              </li>
              <li>
                YouTube{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Kununu{' '}
                <a
                  href="https://www.kununu.com/de/info/datenschutz"
                  className="external"
                >
                  https://www.kununu.com/de/info/datenschutz
                </a>
              </li>
              <li>
                Vimeo{' '}
                <a href="https://vimeo.com/privacy" className="external">
                  https://vimeo.com/privacy
                </a>
              </li>
              <li>
                Pinterest{' '}
                <a
                  href="https://policy.pinterest.com/de/privacy-policy"
                  className="external"
                >
                  https://policy.pinterest.com/de/privacy-policy
                </a>
              </li>
              <li>
                Instagram{' '}
                <a
                  href="https://help.instagram.com/155833707900388"
                  className="external"
                >
                  https://help.instagram.com/155833707900388
                </a>
              </li>
              <li>
                SoundCloud{' '}
                <a
                  href="https://soundcloud.com/pages/privacy"
                  className="external"
                >
                  https://soundcloud.com/pages/privacy
                </a>
              </li>
              <li>
                Spotify{' '}
                <a
                  href="https://www.spotify.com/de/legal/privacy-policy/"
                  className="external"
                >
                  https://www.spotify.com/de/legal/privacy-policy/
                </a>
              </li>
              <li>
                Tumblr{' '}
                <a
                  href="https://www.tumblr.com/policy/de/privacy"
                  className="external"
                >
                  https://www.tumblr.com/policy/de/privacy
                </a>
              </li>
              <li>
                Myspace <u>https://myspace.com/pages/privacy</u>
              </li>
            </ul>
            <p>
              If you do not want the respective social medium to be able to
              allocate the visit to our site to your respective account then you
              must log-out of the respective service before you visit our
              website.
            </p>
            <p>
              <strong>
                Application of YouTube
                <br />
              </strong>
              Contained in the Niesmann+Bischoff GmbH website are functions of
              the service YouTube for display and playback of videos. These
              functions are offered by YouTube, LLC 901 Cherry Ave. San Bruno,
              CA 4066 USA. More information can be found in the data protection
              guidelines from YouTube.
              <br />
              Here, according to provider information, the extended data
              protection mode is used, initiating saving of user information
              only after playback of the video/s. When starting playback of
              embedded YouTube videos, YouTube uses cookies for collecting
              information regarding the user behaviour. According to information
              of YouTube, these are used among others to record video
              statistics, to improve user friendliness, and to prevent abusive
              activities. Independent from playback of the embedded videos, a
              connection to the Google network “DoubleClick” is made each time
              the Niesmann+Bischoff GmbH website is opened, which can initiate
              further data handling processes without the influence of
              Niesmann+Bischoff GmbH.
              <br />
              Further details on the application of cookies from YouTube the
              user can find in the YouTube data privacy statement in:{' '}
              <a
                href="http://www.youtube.com/t/privacy_at_youtube"
                className="external"
              >
                http://www.youtube.com/t/privacy_at_youtube
              </a>
            </p>
            <p>
              <strong>
                E-mail security
                <br />
              </strong>
              The user agrees with electronic communication as soon as he opens
              the electronic contact with Niesmann+Bischoff GmbH. The user is
              informed that e-mails during transmission can be read or changed
              unauthorised and unnoticed. Niesmann+Bischoff GmbH uses a software
              for screening undesired e-mails (spam-filter). E-mails can be
              rejected by the spam-filter if these were identified erroneously
              as spam because of certain characteristics.
            </p>
            <p>
              <strong>
                Right to information
                <br />
              </strong>
              According to Art. 15 DS-GVO, the user has a right to free
              information about his saved data, as well as a right to
              correction, blocking or deletion of these data (Art. 5 Abs. 1 d),
              e), Art. 12 and Art. 17-19 DS-GVO). Upon request,
              Niesmann+Bischoff GmbH informs the user in accordance with the
              applicable legislation in writing about the personal data of the
              user stored at Niesmann+Bischoff GmbH. This information is free of
              charge.<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                User’s right of objection
                <br />
              </strong>
              As far as the user does not want any active data use for internal
              purposes by Niesmann+Bischoff GmbH, according to Art. 21 Abs. 2-4
              DS-GVO,&nbsp; the user has the right to contradict the use and
              processing at any time. This requires only to send an according
              e-mail to{' '}
              <a
                href="mailto:pressestelle@niesmann-bischoff.com"
                className="local"
              >
                pressestelle@niesmann-bischoff.com
              </a>
              . The particular case of data locking required by law instead of a
              data deletion according to Art. 17-19 DS-GVO remains unaffected.
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Data transmission and logging for system-internal and
                statistical purposes
                <br />
              </strong>
              For technical reasons, the web browser of the user automatically
              transmits data to the web server when accessing this website. Each
              data set consists of date and time of access, URL of the referring
              web page, file retrieved, quantity of sent data, type and version
              of browser, operating system and the IP-address of the user. It is
              not possible to assign these data to a particular person. The
              stored data is evaluated only internally and exclusively for
              statistical purposes.
            </p>
            <p>
              <strong>
                Security by technical and organisational measures
                <br />
              </strong>
              For the security during processing of data according to Art. 32
              Abs. 1 DS-GVO, Niesmann+Bischoff GmbH uses technical and
              organisational measures to protect personal data against random or
              deliberate manipulations, loss, destruction or access of
              unauthorised persons. The used safety measures are continuously
              improved according to the technological development.
            </p>
            <p>
              <strong>
                Protection of minors
                <br />
              </strong>
              Any individual still not having reached the age of 16 years is not
              allowed to transmit personal data to Niesmann+Bischoff GmbH
              without the consent of the parents. Individuals still not having
              reached the age of 16 years are allowed to make personal
              information available to Niesmann+Bischoff GmbH only with express
              consent of the parents present, or these individuals have reached
              the age of 16 or more. These data are processed according to data
              protection declaration.
            </p>
            <p>
              <strong>
                Trademark protection
                <br />
              </strong>
              Each company brand or trademark here mentioned is property of the
              corresponding company. Mentioning brands and names is made for
              sole informative purposes.
            </p>
            <p>
              <strong>Cookies</strong>
              <br />
            </p>
            {document.getElementById('cookiebotsomething').firstElementChild !=
              null && (
              <div
                dangerouslySetInnerHTML={renderMarkup(
                  document.getElementById('cookiebotsomething')
                    .firstElementChild.innerHTML
                )}
              ></div>
            )}
            <div id="CookieDeclaration" />
            <p>
              <strong>
                These legal detail and the declaration for data protection were
                created by:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
                <br />
              </a>
              Stefan Fischerkeller
              <br />
              phone: +49 7542 / 94921-01
              <br />
              mailto:{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
