import React from 'react'

import Loading from './../Loading/Loading'
import SimpleSlider from './../Slider/SimpleSlider'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import SimpleTabs from './../Tabs/SimpleTabs'
import {
  redirectToStart,
  recalculatePricesAndWeights,
  loadExtendedAttributes,
  saveToCart,
  deleteInCart,
  doHaveInclusionsExclusions,
  filterOptionsNotInCart,
  filterOptionsInCart,
  getSlides,
  markItemsAsSelected,
  getActionConditions,
  getCartCodes,
  filterOptionsAlreadyInCart
} from '../../helpers'
import {
  getApiUrl,
  getLanguage,
  getTilesPath,
  FILTER_CLOULINE
} from '../../config'
import { AppContext } from '../../Data/ContextProvider'
import ConflictOverlay from './../Overlay/ConflictOverlay'
import MaxWeightOverlay from '../Overlay/MaxWeightOverlay'

var classNames = require('classnames')

class Exterieur extends React.Component {
  constructor(props) {
    super(props)
    this.handleExterieur = this.handleExterieur.bind(this)
    this.slider = React.createRef()
    this.state = {
      selected: [],
      exterieur: [],
      footnote: [],
      infobox: [],
      items: []
    }
  }

  handleSelectedTab = (parent_index) => {
    const currentUKID = this.state.exterieur[parent_index].UKID
    this.setState({ currentUKID })
  }

  closeMaxWeightOverlay = () => {
    this.context.maxWeightOverlay(false)
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const items = this.state.items

    items.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ items: items })
  }

  addToChapter = async (parent_index, item, chapter = this.props.chapter) => {
    const title = item.chapterTitle
    const new_cart = saveToCart(
      item,
      chapter,
      this.context.cart,
      parent_index,
      title
    )
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: await markItemsAsSelected(this.state.items, new_cart, chapter)
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  removeFromChapter = async (item) => {
    const new_cart = deleteInCart(this.context.cart, item)
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: markItemsAsSelected(
          this.state.items,
          new_cart,
          this.props.chapter
        )
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  resetConflictOverlayState = () => {
    this.setState({
      conflictItem: null,
      conflictAction: '',
      additionalInclusions: [],
      additionalItems: [],
      conflictingItem: null,
      conflictingItems: [],
      conflictingPackages: [],
      exclusions: [],
      optionsExclusions: [],
      optionsExcludedPackages: [],
      dependenciesForExclusions: [],
      exclusionInCombonation: []
    })
  }

  // Ausnahme Exterieur: Akzentdesign Kachel Austausch, wenn Außenfarbe wechselt
  changeDecorStandardImage = (parent_index, i) => {
    const exterieur = this.state.exterieur
    const img = exterieur[3].items[0].img

    if (parent_index === 1) {
      if (i === 0 && exterieur.length > 3) {
        exterieur[3].items[0].img = img
          .replace('iS69_de-se-g.jpg', 'iS69_de-se-w.jpg')
          .replace('iS69_de-se.jpg', 'iS69_de-se-w.jpg')
          .replace('A88_ms_g_EXCEPTION.jpg', 'A88_ms.jpg')
          .replace('A78_ms_g_EXCEPTION.jpg', 'A78_ms.jpg')
      }
      if (i === 1 && exterieur.length > 3) {
        exterieur[3].items[0].img = img
          .replace('iS69_de-se-w.jpg', 'iS69_de-se-g.jpg')
          .replace('iS69_de-se.jpg', 'iS69_de-se-g.jpg')
          .replace('A88_ms.jpg', 'A88_ms_g_EXCEPTION.jpg')
          .replace('A78_ms.jpg', 'A78_ms_g_EXCEPTION.jpg')
      }
    }

    this.setState({ exterieur })
  }

  addToExterieur = async (conflictItem) => {
    const parent_index = this.state.exterieur
      .map((e) => {
        return e.UKID
      })
      .indexOf(conflictItem.ukid)

    const i = this.state.exterieur[parent_index].items
      .map((e) => {
        return e.code
      })
      .indexOf(conflictItem.code)

    let selected = this.state.selected

    selected[parent_index] = {
      title: this.state.exterieur[parent_index].title,
      item: conflictItem
    }

    this.setState({ selected })

    this.state.exterieur[parent_index].items.map(function (value) {
      return (value.selected = false)
    })

    this.state.exterieur[parent_index].items[i].selected =
      !this.state.exterieur[parent_index].items[i].selected

    // Ausnahme Exterieur: Akzentdesign Kachel Austausch, wenn Außenfarbe wechselt
    this.changeDecorStandardImage(parent_index, i)

    this.context.updateExterieur(selected)
    const new_cart = recalculatePricesAndWeights(this.context.cart)
    this.context.updateCart(new_cart)
    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
    this.closeConflictOverlay()
  }

  addAdditionalItems = () => {
    if (
      !this.state.additionalItems ||
      !Array.isArray(this.state.additionalItems) ||
      this.state.additionalItems.length < 1
    ) {
      return
    }

    this.state.additionalItems.forEach((item) => {
      if (item.tab === 'exterieur') {
        // const new_cart = saveToCart(item, 'exterieur', this.context.cart)
        // this.context.updateCart(new_cart)
      } else if (item.tab === 'packages') {
        const new_cart = saveToCart(item, 'packages', this.context.cart)
        this.context.updateCart(new_cart)
      } else {
        // const parent_index = this.findParentAndIndexForItem(item)
        const parent_index = item.category
        const chapter = item.tab
        this.addToChapter(parent_index, item, chapter)
      }
    })

    return
  }

  addConflictingItem = async (overlayProps) => {
    this.addToExterieur(this.state.conflictItem)
    this.addAdditionalItems()

    this.closeConflictOverlay()

    return
  }

  closeConflictOverlay = () => {
    this.context.toggleConflictOverlay()
  }

  checkInclusions = async (relationItems) => {
    let conflict = null

    if (!relationItems.inclusions || relationItems.inclusions.length < 1) {
      return conflict
    }

    const inclusionsNotInCart = filterOptionsNotInCart(
      this.context.cart,
      relationItems.inclusions
    )

    if (!inclusionsNotInCart || inclusionsNotInCart.length < 1) {
      return conflict
    }

    const exclusionsForInclusionsInCart = await doHaveInclusionsExclusions(
      this.context.cart,
      inclusionsNotInCart
    )

    conflict = {
      additionalItems: inclusionsNotInCart,
      exclusions: exclusionsForInclusionsInCart
    }

    return conflict
  }

  checkExclusions = (relationItems, selectedItem) => {
    let conflict = null

    if (!relationItems.exclusions || relationItems.exclusions.length < 1) {
      return conflict
    }

    const exclusionsNotInCart = filterOptionsInCart(
      this.context.cart,
      relationItems.exclusions
    )

    if (!exclusionsNotInCart || exclusionsNotInCart.length < 1) {
      return conflict
    }

    conflict = {
      exclusions: exclusionsNotInCart
    }

    return conflict
  }

  async handleExterieur(parent_index, i) {
    this.resetConflictOverlayState()

    const selectedItem = this.state.exterieur[parent_index].items[i]

    const cartCodes = getCartCodes(this.context.cart)

    const actionConditions = await getActionConditions(
      cartCodes,
      selectedItem.code,
      'add',
      this.context.cart.layout.productId
    )

    if (actionConditions.conflict) {
      console.log('actionConditions', actionConditions)
    }

    const relationItems = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    const inclusionConflict = await this.checkInclusions(relationItems)

    const conflictingItems = await this.checkExclusions(
      relationItems,
      selectedItem
    )

    if (inclusionConflict) {
      this.setState(
        {
          ...inclusionConflict,
          conflictItem: selectedItem,
          conflictAction: 'add'
        },
        () => {
          this.closeConflictOverlay()
        }
      )
    } else if (conflictingItems) {
      const attributes = await loadExtendedAttributes(
        this.context.cart.layout.productId,
        selectedItem.code
      )
      const detailsExclusions = await filterOptionsAlreadyInCart(
        this.context.cart,
        attributes.exclusions
      )
      this.setState(
        {
          attributes,
          conflictItem: selectedItem,
          conflictingItems: detailsExclusions,
          conflictOverlayOpen: true,
          conflictAction: 'exl'
        },
        () => {
          this.context.toggleConflictOverlay()
        }
      )
    } else {
      this.addToExterieur(selectedItem)
    }

    return
  }

  removeConflictingPackage = (overlayProps) => {
    const packagesToRemove = overlayProps.conflictingPackages
    const packageToRemove = overlayProps.conflictingItem
    const itemsToRemove = overlayProps.conflictingItems
    const item = overlayProps.item

    const packages = this.context.cart.packages

    const dependenciesForExclusions = overlayProps.dependenciesForExclusions
      ? overlayProps.dependenciesForExclusions
      : []

    if (overlayProps.action === 'del' && item) {
      this.removeFromChapter(item)
    } else if (overlayProps.action === 'exl' && item) {
      this.addToExterieur(item)
    }

    if (itemsToRemove) {
      itemsToRemove.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (packageToRemove) {
      const p_i_p_remove = this.findParentAndIndexForPackage(packageToRemove)
      const packages = this.context.cart.packages
      packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      this.context.updatePackages(packages)
    }

    if (packagesToRemove) {
      packagesToRemove.forEach((iTr) => {
        let p_i_p_remove = this.findParentAndIndexForPackage(iTr)
        packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      })

      this.context.updatePackages(packages)
    }

    if (dependenciesForExclusions) {
      dependenciesForExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    const attributes =
      this.state.automaticHiddenRemoveAttributes &&
      Object.getPrototypeOf(this.state.automaticHiddenRemoveAttributes) ===
        Object.prototype &&
      Object.keys(this.state.automaticHiddenRemoveAttributes).length > 0
        ? this.state.automaticHiddenRemoveAttributes
        : this.state.attributes

    this.closeConflictOverlay()
  }

  filterClouLine() {
    if (!FILTER_CLOULINE.includes(window.currentLocalization.locale)) {
      return
    }

    if (this.context.cart.model.imageKey === 'F') {
      return
    }

    if (this.context.cart.model.imageKey === 'I') {
      return
    }

    const clouLine = this.context.cart.exterieur.filter((item) => {
      return item.item.imageKey === 'cl'
    })

    const clouLineCode = clouLine[0].item.code

    this.state.exterieur.map((category) => {
      if (category.UKID === '300') {
        category.items.map((item) => {
          if (item.code !== clouLineCode) {
            item.hidden = true
          }
          return null
        })
      }
      return null
    })

    return
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config.exterieurTab1 !== this.context.config.exterieurTab1) {
      if (this.slider && this.slider.current) {
        const currentUKID =
          this.state.exterieur[this.context.config.exterieurTab1].UKID

        switch (currentUKID) {
          case '301':
            this.slider.current.changeActiveSlide(5)
            break
          default:
            this.slider.current.changeActiveSlide(1)
        }
      }
    }
  }

  async componentDidMount() {
    await this.context.updateCurrentNav(3)
    await this.context.changeSelectedTab(0, 'exterieurTab1')
    this.context.updateJumpmarks([])
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/exterieur?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())

        .then(async (data) => {
          const new_exterieur = data.items ? data.items : []

          new_exterieur.forEach((category, index) => {
            category.items.forEach((item, i) => {
              const img = item.imageKey
                ? getTilesPath() +
                  this.context.cart.layout.imageKey +
                  '_' +
                  item.imageKey +
                  '.jpg'
                : ''
              return (item.img = img)
            })
          })

          // if user has already selected items
          if (this.context.cart.exterieur) {
            // set typekeys/imagekeys for Image-Preview
            const selected_array = this.context.cart.exterieur.map(
              (item) => item.item.code
            )

            if (selected_array.includes('99996')) {
              new_exterieur[3].items[0].img =
                new_exterieur[3].items[0].img.replace(
                  'iS69_de-se.jpg',
                  'iS69_de-se-w.jpg'
                )
            }

            // set selected Items
            for (var i = 0; i < new_exterieur.length; i++) {
              for (var j = 0; j < new_exterieur[i].items.length; j++) {
                if (selected_array.includes(new_exterieur[i].items[j].code)) {
                  new_exterieur[i].items[j].selected = true
                }
              }
            }

            this.setState({
              selected: this.context.cart.exterieur,
              exterieur: new_exterieur,
              infobox: data.infobox || [],
              footnote: data.footnote || []
            })
          } else {
            const new_selected = []

            // set first item in tab as selected (Serienausstattung)
            for (var k = 0; k < new_exterieur.length; k++) {
              new_selected[k] = {
                title: new_exterieur[k].title,
                item: new_exterieur[k].items[0]
              }
              new_exterieur[k].items[0].selected = true
            }

            if (new_exterieur.length > 3) {
              new_exterieur[3].items[0].img =
                new_exterieur[3].items[0].img.replace(
                  'iS69_de-se.jpg',
                  'iS69_de-se-w.jpg'
                )
            }

            this.setState({
              selected: new_selected,
              exterieur: new_exterieur,
              infobox: data.infobox || [],
              footnote: data.footnote || []
            })
            await this.context.addStepsCompleted(3)
            await this.context.updateExterieur(new_selected)
          }
          await this.filterClouLine()
          const new_cart = recalculatePricesAndWeights(this.context.cart)
          await this.context.updateCart(new_cart)
          await this.context.updateCurrentParentTabs(new_exterieur)
        })
    } catch (error) {
      console.log(error)
      redirectToStart()
    }
  }

  render() {
    const slides = this.context.cart.layout ? getSlides(this.context.cart) : []

    if (!this.state.exterieur.length) {
      return <Loading></Loading>
    }

    return (
      <div className="konfigurator__exterieur">
        <div className={classNames('slider__wrapper', 'text')}>
          <SimpleSlider
            ref={this.slider}
            length={slides.length}
            extraClasses="slider__slick-dots--position__higher slick-dotted v-inverted"
          >
            {slides.map((slide, index) => {
              return (
                <div
                  className="slider__item slider__item--exterieur"
                  key={index}
                  inverted={slide.inverted ? 1 : 0}
                >
                  <img src={slide.img} alt="slide" />
                </div>
              )
            })}
          </SimpleSlider>
        </div>
        <SimpleTabs
          name="exterieurTab1"
          items={this.state.exterieur}
          handleSelectedTab={this.handleSelectedTab}
          handleSelections={this.handleExterieur}
        />

        {Array.isArray(this.state.infobox) ? (
          this.state.infobox.map((content, index) => (
            <Infobox key={index} content={content} />
          ))
        ) : (
          <Infobox content={this.state.infobox} />
        )}

        {this.state.footnote.length > 0 && (
          <Footnote footnote={this.state.footnote} />
        )}

        {this.context.config.conflictOverlayOpen ? (
          <ConflictOverlay
            item={this.state.conflictItem}
            additionalItems={this.state.additionalItems}
            exclusions={this.state.exclusions}
            action={this.state.conflictAction}
            positiveCallBackAction={this.addConflictingItem}
            additionalInclusions={this.state.additionalInclusions}
            conflictingItem={this.state.conflictingItem}
            conflictingItems={this.state.conflictingItems}
            addItemWithOption={this.addItemWithOption}
            negativeCallBackAction={this.removeConflictingPackage}
            conflictingPackages={this.state.conflictingPackages}
            optionsExclusions={this.state.optionsExclusions}
            optionsExcludedPackages={this.state.optionsExcludedPackages}
            dependenciesForExclusions={this.state.dependenciesForExclusions}
            exclusionInCombonation={this.state.exclusionInCombonation}
            removeExclusionInCombonation={this.removeExclusionInCombonation}
          />
        ) : null}

        {this.context.maxWeightOverlayOpen && (
          <MaxWeightOverlay
            closeMaxWeightOverlay={this.closeMaxWeightOverlay}
            addItem={this.addToChapter}
            removeItem={this.removeFromChapter}
          />
        )}
      </div>
    )
  }
}

Exterieur.contextType = AppContext
export default Exterieur
