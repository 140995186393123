import React from 'react'
import SVG from './../SVG/SVG'
import InfoButton from './../Overlay/InfoButton'
import {
  formatPrice,
  checkPrice,
  formatWeight,
  translate,
  renderMarkup
} from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'

var classNames = require('classnames')

class ItemRow extends React.Component {
  renderButtons(child_item, parent_index) {
    if (child_item.serie) {
      return ''
    }
    if (child_item.selected || child_item.disabled) {
      return (
        <button
          className="button"
          name="remove"
          onClick={(e) => this.props.removeItem(child_item)}
        >
          <span>{translate('global', 'delete_option')}</span>
        </button>
      )
    } else {
      return (
        <button
          className="button button--cta"
          name="add"
          onClick={(e) => this.props.addItem(child_item, parent_index)}
        >
          <span>{translate('global', 'add_option')}</span>
        </button>
      )
    }
  }

  render() {
    return (
      <div
        className={classNames(
          'konfigurator__container__item g u-boxed u-boxed--has-nested u-margin-bottom--1x',
          this.props.child_item.selected ? 'is-active' : '',
          this.props.i % 2 === 0 ? 'bg_grey-50' : 'bg_grey-25',
          this.props.child_item.disabled ? 'is-disabled' : ''
        )}
      >
        <div className="g-9 g-nested">
          <h3
            dangerouslySetInnerHTML={renderMarkup(this.props.child_item.title)}
          />
          <p>{this.props.child_item.description}</p>
          <span className="konfigurator__container__item__code small">
            {translate('global', 'option_code')} {this.props.child_item.code}
          </span>
          <br />
          <span className="small">
            {translate('global', 'additional_weight')}{' '}
            {formatWeight(this.props.child_item.weight)}
            {this.props.child_item.ukid === '201' ? '**' : '*'}
          </span>
        </div>
        <div className="g-3 g-nested u-text-right">
          <strong className="konfigurator__container__price">
            <em>
              {checkPrice(
                this.props.child_item,
                this.context.cart.layout.PSMotor
              )}
            </em>
          </strong>
          {this.props.child_item.pricebpm &&
          this.props.child_item.pricebpm !== 0 ? (
            <span className="konfigurator__container__price-bpm">
              incl. Bpm {formatPrice(this.props.child_item.pricebpm)}
            </span>
          ) : null}
        </div>
        <div className="g-12 g-nested u-text-right">
          {this.props.child_item.disabled ? (
            <div className="konfigurator__container__item__infos">
              <p>{translate('global', 'choice_not_possible')}</p>
              <span>
                <SVG name="warning" />
              </span>
            </div>
          ) : (
            <InfoButton infos={this.props.child_item} {...this.props} />
          )}
          {this.renderButtons(this.props.child_item, this.props.parent_index)}
        </div>
      </div>
    )
  }
}

ItemRow.contextType = AppContext
export default ItemRow
