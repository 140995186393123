import React from 'react'
import { renderMarkup } from '../../helpers'

var classNames = require('classnames')

class Datenschutz extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Datenschutz</h1>
            <p>
              Gemäß § 13 Absatz 1 Telemediengesetz (TMG) hat der Anbieter eines
              Teledienstes seine Nutzer zu Beginn des Nutzungsvorgangs über Art,
              Umfang und Zwecke der Erhebung und Verwendung personenbezogener
              Daten in allgemein verständlicher Form zu unterrichten. Der Inhalt
              der Unterrichtung muss dabei für den Nutzer jederzeit abrufbar
              sein. Jedes Unternehmen ist verpflichtet, den Nutzer zu
              unterrichten, welche personenbezogenen Daten es erhebt oder
              verwendet.
            </p>
            <p>
              Die Niesmann+Bischoff GmbH legt größten Wert auf die Sicherheit
              der Daten der Nutzer und die Einhaltung datenschutzrechtlicher
              Bestimmungen. Die Erhebung, Verarbeitung und Nutzung
              personenbezogener Daten unterliegt den Bestimmungen der derzeit
              geltenden Gesetze und der EU-Datenschutz-Richtlinie. Als
              personenbezogene Daten werden gemäß Art. 4 Nr. 1 DS-GVO
              (EU-Datenschutzgrundverordnung) alle Informationen bezeichnet, die
              sich auf eine identifizierte oder identifizierbare natürliche
              Person beziehen.
            </p>
            <p>
              Persönliche Verhältnisse: Zum Beispiel – Name, Anschrift,
              E-Mail-Adresse, Geburtstag, Staatsangehörigkeit, Beruf,
              Gesundheitsdaten, Religion usw.
              <br />
              Sachliche Verhältnisse: Zum Beispiel – Einkommen, finanzielle
              Situation, Eigentum usw.
            </p>
            <p>
              <strong>
                Datenschutzerklärung
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH gewährleistet, dass sie die anfallenden
              Nutzerdaten lediglich im Zusammenhang mit der Abwicklung
              der&nbsp;Nutzeranfragen erhebt, bearbeitet, speichert und nutzt
              sowie nur zu internen&nbsp;Zwecken.
            </p>
            <p>
              <strong>
                Datenübermittlung an Dritte
                <br />
              </strong>
              Es findet keine Datenübermittlung an Dritte ohne Einwilligung des
              Betroffenen oder ohne gesetzliche Grundlage statt.
            </p>
            <p>
              <strong>
                Datenübertragung – SSL-Verschlüsselung
                <br />
              </strong>
              Auf dieser Website wird eine 256-bit-SSL (AES 256)
              Verschlüsselungstechnik eingesetzt. Daten von Nutzern werden
              ausschließlich verschlüsselt übertragen. Dies schließt die
              IP-Adresse des Nutzers mit ein.
            </p>
            <p>
              <strong>
                Cookies
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH nutzt Cookies. Bei Cookies handelt es
              sich um kleine Textdateien, die lokal im Zwischenspeicher des
              Internetbrowsers des Nutzers gespeichert werden. Die Cookies
              ermöglichen die Wiedererkennung des Internetbrowsers. Die Dateien
              werden genutzt um dem Browser zu helfen durch die Website zu
              navigieren und alle Funktionen vollumfänglich nutzen zu können.
              <br />
              Diese Website verwendet: Browser Cookies
            </p>
            <p>
              <strong>
                Kontrolle von Cookies durch den Nutzer
                <br />
              </strong>
              Der Nutzer kann alle Browser so einstellen, dass Cookies nur auf
              Nachfrage akzeptiert werden. Auch können per Einstellung nur
              Cookies akzeptiert werden, deren Seiten gerade besucht werden.
              Alle Browser bieten Funktionen, die das selektive Löschen von
              Cookies ermöglichen. Die Annahme von Cookies kann auch generell
              abgeschaltet werden, allerdings müssen dann gegeben falls
              Einschränkungen bei der Bedienungsfreundlichkeit dieser Website in
              Kauf genommen werden.
            </p>
            <p>
              <strong>
                Einsatz von First-Party Cookies (Google Analytics Cookie)
                <br />
              </strong>
              Google Analytics Cookies protokollieren:
            </p>
            <ul>
              <li>
                <strong>Eindeutige Nutzer</strong> – Google Analytics Cookies
                erfasst und gruppiert Nutzerdaten. Alle Aktivitäten während
                eines Besuchs werden zusammengefasst. Durch das Setzen von
                Google Analytics Cookies wird zwischen Nutzern und eindeutigen
                Nutzern unterschieden.
              </li>
              <li>
                <strong>Aktivitäten der Nutzer</strong> – Google Analytics
                Cookies speichern auch Daten über den Start- und End-Zeitpunkt
                eines Besuchs auf der Website und wie viele Seiten sich der
                Nutzer angesehen hat. Bei Schließen des Browsers oder bei
                längerer Inaktivität des Nutzers (Standard 30 Minuten), wird die
                Nutzersession beendet und das Cookie zeichnet den Besuch als
                beendet auf. Weiterhin werden das Datum und die Zeit des ersten
                Besuchs aufgezeichnet. Ebenfalls werden die Total Anzahl Besuche
                pro eindeutigen Nutzer protokolliert. Externer Link:{' '}
                <a
                  href="http://www.google.com/analytics/terms/de.html"
                  className="external"
                >
                  http://www.google.com/analytics/terms/de.html
                </a>
              </li>
            </ul>
            <p>
              Der Nutzer kann die Erfassung der durch das Cookie erzeugten und
              auf die Nutzung der Website bezogenen Daten (inkl. Der
              Nutzer-IP-Adresse) an Google sowie die Verarbeitung dieser Daten
              durch Google verhindern, indem er folgenden Link im
              Browser-Plug-In herunterlädt und installiert:
              <br />
              Externer Link:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              Weitere Informationen sind unter dem Punkt „Webanalysedienst
              Google Analytics / Universal Analytics“ zu finden.
            </p>
            <p>
              <strong>
                Lebensdauer der eingesetzten Cookies
                <br />
              </strong>
              Cookies werden vom Webserver dieser Website verwaltet. Diese
              Website nutzt:
              <br />
              Transiente Cookies/Session Cookies (einmaligem Nutzungsvorgang)
              <br />
              Lebensdauer: Bis zum Schließen dieser Website
            </p>
            <p>
              Persistenter Cookie (dauerhafter Browserkennung)
              <br />
              Lebensdauer: 30 Minuten
            </p>
            <p>
              <strong>
                Cookies deaktivieren oder entfernen (Opt-Out)
                <br />
              </strong>
              Jeder Webbrowser bietet Möglichkeiten, um Cookies einzuschränken
              und zu löschen. Weitere Informationen hierzu erhalten Sie auf
              folgenden Websiten:
            </p>
            <ul>
              <li>
                <em>
                  Internet Explorer:
                  <br />
                </em>
                <a
                  href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                  className="external"
                >
                  http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9
                </a>
              </li>
              <li>
                <em>
                  Firefox:
                  <br />
                </em>
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  className="external"
                >
                  https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies
                </a>
              </li>
              <li>
                <em>
                  Google Chrome:
                  <br />
                </em>
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  className="external"
                >
                  https://support.google.com/chrome/answer/95647?hl=en
                </a>
              </li>
              <li>
                <em>
                  Safari:
                  <br />
                </em>
                <a
                  href="https://support.apple.com/de-de/HT201265"
                  className="external"
                >
                  https://support.apple.com/de-de/HT201265
                </a>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>
                Webanalysedienst Google Analytics / Universal Analytics
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH nutzt Google Analytics, einen
              Webanalysedienst der Google Inc. („Google“). Google Analytics
              verwendet „Cookies“, Textdateien, die auf dem Computer des Nutzers
              gespeichert werden und die eine Analyse der Benutzung der Website
              ermöglichen. Die durch den Cookie erzeugten Informationen über die
              Nutzung dieser Website werden in der Regel an einen Server von
              Google in den USA übertragen und dort gespeichert. Im Falle der
              Aktivierung der IP-Anonymisierung auf dieser Website, wird die
              IP-Adresse des Nutzers von Google jedoch innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt.
            </p>
            <p>
              Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
              von Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen nutzen,
              um die Nutzung der Website auszuwerten, um Reports über die
              Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics vom Nutzer-Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt. Der Nutzer
              kann die Speicherung der Cookies durch eine entsprechende
              Einstellung in der Browser-Software verhindern. Die
              Niesmann+Bischoff GmbH weist jedoch darauf hin, dass in diesem
              Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
              vollumfänglich genutzt werden können.
              <br />
              Weiterhin nutzt die Niesmann+Bischoff GmbH Google
              Analytics-Berichte zur Erfassung demografischer Merkmale und
              Interessen.
              <br />
              Der Nutzer kann darüber hinaus die Erfassung der durch den Cookie
              erzeugten und auf die Nutzung der Website bezogenen Daten (inkl.
              Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
              durch Google verhindern, indem er folgenden Link im
              Browser-Plug-In herunterlädt und installiert: Link:
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              <br />
              Alternativ zum Browser-Plug-In oder innerhalb von Browsern auf
              mobilen Geräten gilt folgender Link, um ein Opt-Out-Cookie zu
              setzen, der die Erfassung durch Google Analytics innerhalb dieser
              Website zukünftig verhindert (dieses Opt-Out-Cookie funktioniert
              nur in diesem Browser und nur für diese Domain, löschen der
              Cookies in diesem Browser, Link erneut klicken): &lt;a
              href=“javascript:gaOptout()“&gt;Google Analytics
              deaktivieren&lt;/a&gt;
              <br />
              Hinweise zur Einbindung des Opt-Out-Cookies sind unter folgendem
              Link zu finden:&nbsp;
              <a
                href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable"
                className="external"
              >
                https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
              </a>
            </p>
            <p>
              Die Niesmann+Bischoff GmbH weist darauf hin, dass diese Website
              Google Analytics mit der Erweiterung „_anonymizeIp()“ verwendet
              und daher IP-Adressen nur gekürzt weiterverarbeitet werden, um
              eine direkte Personenbeziehbarkeit auszuschließen.
            </p>
            <p>
              <strong>
                Webanalysedienst Matomo (ehemals Piwik)
                <br />
              </strong>
              Auf dieser Website werden unter Einsatz der
              Webanalysedienst-Software Piwik (
              <a
                href="https://matomo.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                www.matomo.org
              </a>
              ) Daten zu Marketing- und Optimierungszwecken gesammelt und
              gespeichert. Aus diesen Daten werden unter einem Pseudonym
              Nutzungsprofile erstellt, hierzu werden Cookies eingesetzt. Bei
              Cookies handelt es sich um kleine Textdateien, die lokal im
              Zwischenspeicher des Internetbrowsers des Seitennutzers
              gespeichert werden. Die Cookies ermöglichen die Wiedererkennung
              des Internetbrowsers. Die mit der Matomo-Technologie erhobenen
              Daten (einschließlich Ihrer anonymisierten IP-Adresse) werden an
              den Server der Niesmann+Bischoff GmbH übertragen und zu
              Nutzungsanalysezwecken gespeichert, was der Websiteoptimierung
              durch die Niesmann+Bischoff GmbH dient. Die durch den Cookie
              erzeugten Informationen im pseudonymen Nutzerprofil werden nicht
              dazu benutzt, den Nutzer dieser Website persönlich zu
              identifizieren und nicht mit personenbezogenen Daten über den
              Träger des Pseudonyms zusammengeführt. Wenn der Nutzer mit der
              Speicherung und Auswertung dieser Daten aus dem Besuch der Website
              der Niesmann+Bischoff GmbH nicht einverstanden ist und für die
              Zukunft widersprechen möchten, kann der Nutzer die Verwendung von
              Cookies und damit die Teilnahme am Tracking verhindern. Hierzu
              kann der Nutzer seinen Browser so einstellen, dass der Nutzer über
              das Setzen von Cookies informiert werden und einzeln über deren
              Annahme entscheiden oder die Annahme von Cookies für bestimmte
              Fälle oder generell ausschließen. Bei der Nichtannahme von Cookies
              kann die Funktionalität der Website der Niesmann+Bischoff GmbH
              eingeschränkt sein.
            </p>
            <p>
              <strong>
                Webanalysedienst DoubleClick
                <br />
              </strong>
              Diese Website nutzt im Rahmen der Anwendung von Google Analytics
              den Webanalysedienst DoubleClick, welcher eine Wiedererkennung des
              Browsers des Nutzers beim Besuch anderer Websites ermöglicht. Die
              erhobenen Daten werden nur für statistische Zwecke und in
              anonymisierter Form ausgewertet. Die durch den Cookie erzeugten
              Informationen über den Besuch der Website der Niesmann+Bischoff
              GmbH werden an einen Server von Google in den USA übertragen und
              dort gespeichert. Die IP-Adresse wird vor der Übermittlung
              innerhalb der Mitgliedstaaten der Europäischen Union oder in
              anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum durch die Aktivierung der IP-Anonymisierung
              gekürzt.
              <br />
              Nur in Ausnahmefällen erfolgt eine vollständige Übertragung der
              IP-Adresse an einen Server von Google in den USA, bevor diese dort
              gekürzt wird. Die im Rahmen von Google Analytics vom Browser des
              Nutzers übermittelte anonymisierte IP-Adresse wird nicht mit
              anderen Daten von Google zusammengeführt. DoubleClick-Cookies
              enthalten keine personenbezogenen Daten. Die erzeugten
              Informationen werden genutzt, um Berichte über die Aktivitäten auf
              dieser Website zusammenzustellen und um weitere mit der Nutzung
              dieser Website verbundene Dienstleistungen zu erbringen. Sofern
              gesetzlich vorgeschrieben, überträgt Google diese Informationen
              ggf. an Dritte bzw. soweit Dritte diese Daten im Auftrag von
              Google verarbeiten. Der Nutzer hat jederzeit die Möglichkeit, der
              Tracking-Webanalyse für die Zukunft zu widersprechen bzw. den
              DoubleClick-Cookie zu deaktivieren. Die hierfür benötigte
              Erweiterung für den Browser kann auf der entsprechenden Website
              von Google heruntergeladen und installiert werden. Werden mehrere
              Endgeräte oder Browser durch den Nutzer genutzt, muss bei jedem
              Endgerät bzw. Browser das Opt-out vorgenommen werden. Hierfür kann
              der Nutzer folgenden Link anklicken:{' '}
              <a
                href="http://google.com/ads/preferences/html/opt-out.html"
                className="external"
              >
                http://google.com/ads/preferences/html/opt-out.html
              </a>
            </p>
            <p>
              <strong>
                Verwendung von Google Maps
                <br />
              </strong>
              Auf der Website der Niesmann+Bischoff GmbH wird Google Maps (API)
              der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA (“Google”) eingesetzt. Google Maps ist ein Webdienst
              zur Darstellung von interaktiven (Land-)Karten, um geographische
              Informationen visuell darzustellen. Bereits beim Aufrufen
              derjenigen Unterseiten, in die die Karte von Google Maps
              eingebunden ist, werden Informationen über die Nutzung der
              Webseite der Niesmann+Bischoff GmbH (wie z. B. die IP-Adresse des
              Nutzers) an Server von Google in den USA übertragen und dort
              gespeichert.
            </p>
            <p>
              Google wird diese Informationen ggf. weiter an Dritte übertragen,
              soweit dies gesetzlich vorgeschrieben ist oder Dritte diese Daten
              im Auftrag von Google verarbeiten. Google wird die IP-Adresse des
              Nutzers nicht mit anderen Daten von Google in Verbindung bringen.
              Dennoch wäre es technisch möglich, dass Google die durch die
              Nutzung von Google Maps erhaltenen Nutzerdaten zur Identifizierung
              der Nutzer verwendet, daraus Persönlichkeitsprofile von Nutzern
              erstellt oder für fremde Zwecke verarbeitet und verwendet, worauf
              die Niesmann+Bischoff GmbH jeweils keinen Einfluss hat und haben
              kann.
              <br />
              Die Nutzungsbedingungen von Google können unter{' '}
              <a
                href="http://www.google.de/intl/de/policies/terms/regional.html"
                className="external"
              >
                http://www.google.de/intl/de/policies/terms/regional.html
              </a>{' '}
              eingesehen werden.
              <br />
              Die zusätzlichen Nutzungsbedingungen für Google Maps sind
              einzusehen unter{' '}
              <a
                href="https://www.google.com/intl/de_US/help/terms_maps.html"
                className="external"
              >
                https://www.google.com/intl/de_US/help/terms_maps.html
              </a>
              .<br />
              Ausführliche Informationen zum Datenschutz im Zusammenhang mit der
              Verwendung von Google Maps sind auf der Internetseite von Google
              („Google Privacy Policy“) zu finden:{' '}
              <a
                href="http://www.google.de/intl/de/policies/privacy/"
                className="external"
              >
                http://www.google.de/intl/de/policies/privacy/
              </a>
            </p>
            <p>
              Wenn der Nutzer mit der künftigen Übermittlung seiner Daten an
              Google im Rahmen der Nutzung von Google Maps nicht einverstanden
              ist, besteht die Möglichkeit, den Webdienst von Google Maps
              vollständig zu deaktivieren, indem der Nutzer die Anwendung
              JavaScript in seinem Browser ausschaltet. Google Maps und damit
              auch die Kartenanzeige auf der Internetseite der Niesmann+Bischoff
              GmbH kann dann nicht genutzt werden.
            </p>
            <p>
              <strong>
                Google AdWords – Conversion-Tracking
                <br />
              </strong>
              Die Website der Niesmann+Bischoff GmbH nutzt das
              Online-Werbeprogramm „Google AdWords“ und im Rahmen von Google
              AdWords das Conversion-Tracking. Das Cookie für
              Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von
              Google geschaltete Anzeige klickt. Bei Cookies handelt es sich um
              kleine Textdateien, die auf Ihrem Computersystem abgelegt werden.
              Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen
              nicht der persönlichen Identifizierung. Besucht der Nutzer Seiten
              dieser Website und das Cookie ist noch nicht abgelaufen, können
              Google und die Niesmann+Bischoff GmbH erkennen, dass der Nutzer
              auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet
              wurde. Jeder Google AdWords-Nutzer erhält ein anderes Cookie.
              Cookies können somit nicht über die Websites von AdWords-Nutzern
              nachverfolgt werden.
            </p>
            <p>
              Die mithilfe des Conversion-Cookies eingeholten Informationen
              dienen dazu, Conversion-Statistiken für AdWords-Nutzer zu
              erstellen, die sich für Conversion-Tracking entschieden haben. Die
              Niesmann+Bischoff GmbH erfährt die Gesamtanzahl der Nutzer, die
              auf ihre Anzeige geklickt haben und zu einer mit einem
              Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
              Die Niesmann+Bischoff GmbH erhält jedoch keine Informationen, mit
              denen sich Nutzer persönlich identifizieren lassen. Wenn der
              Nutzer nicht am Tracking teilnehmen möchte, kann er dieser Nutzung
              widersprechen, indem er das Cookie des Google Conversion-Trackings
              über den Internet-Browser unter Nutzereinstellungen deaktiviert.
              Der Nutzer wird nachfolgend nicht in die Conversion-Tracking
              Statistiken aufgenommen. Weitere Informationen über die
              Datenschutzbestimmungen von Google:{' '}
              <a
                href="http://www.google.de/policies/privacy/"
                className="external"
              >
                http://www.google.de/policies/privacy/
              </a>
            </p>
            <p>
              <strong>
                Retargeting/Remarketing
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH verwendet die Retargeting-Technologie
              der Google Inc. („Google“). Diese ermöglicht es, Nutzer der
              Website der Niesmann+Bischoff GmbH gezielt mit personalisierter,
              interessenbezogener Werbung anzusprechen, die sich bereits für
              Angebote der Niesmann+Bischoff GmbH interessiert haben. Die
              Einblendung der Werbemittel erfolgt dabei auf Basis einer
              Cookie-basierten Analyse des früheren Nutzungsverhaltens, wobei
              aber keine personenbezogenen Daten gespeichert werden. In den
              Fällen der Retargeting-Technologie wird ein Cookie auf dem
              Computer des Nutzers oder mobilen Endgerät gespeichert, um
              anonymisierte Daten über Interessen zu erfassen und so die Werbung
              individuell auf die gespeicherten Informationen anzupassen. Diese
              Cookies sind kleine Textdateien, die auf Ihrem Computer oder
              mobilen Endgerät gespeichert werden. Der Nutzer bekommt dadurch
              Werbung angezeigt, die mit hoher Wahrscheinlichkeit dem jeweiligen
              Produkt- und Informationsinteressen entspricht. Der Nutzer kann
              der Setzung von Cookies für Anzeigenvorgaben dauerhaft
              widersprechen, indem er das unter folgendem Link verfügbare
              Browser-Plug-in herunterlädt und installiert:{' '}
              <a
                href="https://www.google.com/settings/ads/onweb/"
                className="external"
              >
                https://www.google.com/settings/ads/onweb/
                <br />
              </a>
              Weitergehende Informationen und die Datenschutzbestimmungen
              bezüglich Werbung und Google können hier eingesehen werden:{' '}
              <a href="http://www.google.com/privacy/ads/" className="external">
                http://www.google.com/privacy/ads/
              </a>
            </p>
            <p>
              <strong>
                Verwendung von Social Media
                <br />
              </strong>
              Auf unserer Webseite können Funktionen zu sozialen Medien genutzt
              werden.
              <br />
              Bei Abruf einer dieser Seiten, kann eine Verbindung zu den
              jeweiligen Servern der sozialen Medien aufgebaut werden. Dabei
              werden diese darüber informiert, dass Sie unsere Internetseite mit
              Ihrer IP-Adresse besucht haben. Sollten Sie nun etwas
              kommentieren, liken oder twittern etc. und Sie sind dabei in ihrem
              jeweiligen Account eingeloggt, ist es ggf. dem sozialen Medium
              möglich, Ihren Besuch auf unserer Webseite Ihnen und Ihrem
              Benutzerkonto zuzuordnen. Wir weisen Sie darauf hin, dass wir als
              Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
              Daten sowie deren Nutzung haben.
            </p>
            <p>Diese Dienste werden von den folgenden Unternehmen erbracht:</p>
            <ul>
              <li>
                <u>Facebook Inc</u>., 1601 S. California Ave, Palo Alto, CA
                94304, USA
              </li>
              <li>
                <u>Google+ Inc</u>., 1600 Amphitheatre Parkway, Mountain View,
                CA 94043, USA
              </li>
              <li>
                <u>Linkedin Inc</u>., 2029 Stierlin Court, Mountain View, CA
                94043, USA
              </li>
              <li>
                <u>Twitter Inc</u>., 1355 Market St., Suite 900, San Francisco,
                CA 94103, USA
              </li>
              <li>
                <u>Xing AG</u>, Dammtorstraße 30, 20354 Hamburg, Germany
              </li>
              <li>
                <u>YouTube LLC</u>, 901 Cherry Ave., San Bruno, CA 94066, USA
              </li>
              <li>
                <u>Kununu GmbH</u>, Wollzeile 1-3 Top 5.1, 1010 Wien, Austria
              </li>
              <li>
                <u>Pinterest Europe Ltd</u>., Palmerston House, 2nd Floor Fenian
                Street, Dublin 2, Ireland
              </li>
              <li>
                <u>Instagram LLC</u>, 1601 Willow Rd, Menlo Park CA 94025, USA
              </li>
            </ul>
            <p>
              Zweck und Umfang der Datenerhebung und die weitere Verarbeitung
              und Nutzung der Daten durch die Anbieter sowie Ihre
              diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
              Ihrer personenbezogenen Daten entnehmen Sie bitte den
              Datenschutzhinweisen der jeweiligen Anbieter:
            </p>
            <ul>
              <li>
                Facebook{' '}
                <a
                  href="https://de-de.facebook.com/privacy/explanation"
                  className="external"
                >
                  https://de-de.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Google{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Linkedin{' '}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  className="external"
                >
                  https://www.linkedin.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Twitter{' '}
                <a
                  href="https://twitter.com/privacy?lang=de"
                  className="external"
                >
                  https://twitter.com/privacy?lang=de
                </a>
              </li>
              <li>
                Xing{' '}
                <a href="https://www.xing.com/privacy" className="external">
                  https://www.xing.com/privacy
                </a>
              </li>
              <li>
                YouTube{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Kununu{' '}
                <a
                  href="https://www.kununu.com/de/info/datenschutz"
                  className="external"
                >
                  https://www.kununu.com/de/info/datenschutz
                </a>
              </li>
              <li>
                Pinterest{' '}
                <a
                  href="https://policy.pinterest.com/de/privacy-policy"
                  className="external"
                >
                  https://policy.pinterest.com/de/privacy-policy
                </a>
              </li>
              <li>
                Instagram{' '}
                <a
                  href="https://help.instagram.com/155833707900388"
                  className="external"
                >
                  https://help.instagram.com/155833707900388
                </a>
              </li>
            </ul>
            <p>
              Falls Sie nicht möchten, dass das jeweilige soziale Medium den
              Besuch auf unserer Seite Ihrem jeweiligen Account zuordnen kann,
              so müssen Sie vor Ihrem Besuch unserer Webseite sich bei dem
              jeweiligen Dienst ausloggen.
            </p>
            <p>
              <strong>
                Unsere Angebote auf Plattformen von Social Media (Social Media
                Links)
                <br />
              </strong>
              Wir stellen auf unterschiedlichen Social-Media-Plattformen
              Angebote (z.B. Fanpages) online zur Verfügung, die Informationen
              über Niesmann+Bischoff GmbH bereithalten und uns die Möglichkeit
              geben mit Ihnen in Kontakt zu treten. Wir weisen darauf hin, dass
              wir keinen Einfluss auf die Verarbeitung ihrer personenbezogenen
              Daten auf diesen Plattformen haben und lediglich der jeweilige
              Betreiber der Plattform vollumfänglich Kenntnis von dem Inhalt der
              übermittelten Daten und deren Nutzung hat.
              <br />
              In der Regel werden bei Ihrem Besuch der jeweiligen Plattform
              Cookies in Ihrem Browser gespeichert.
              <br />
              Von dieser Datenerhebung können Sie auch dann betroffen sein, wenn
              Sie nicht auf der jeweiligen Plattform registriert sind. Es
              entzieht sich unserer Kenntnis, ob die Daten außerhalb des
              Europäischen Wirtschaftsraumes gelangen.
              <br />
              Die Verarbeitung personenbezogener Daten auf den Plattformen durch
              uns beruht auf Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes
              Interesse liegt darin, Niesmann+Bischoff GmbH in vielfältiger
              Weise nach Außen darstellen zu können und die
              Kommunikationsmöglichkeit mit unseren Kunden so effektiv wie
              möglich zu nutzen.
              <br />
              Auch kann eine Einwilligung in die Datenverarbeitung gemäß Art. 6
              Abs. 1 lit. a DSGVO Rechtgrundlage sein, wenn Sie diese dem
              Plattformbetreiber gegenüber erteilt haben.
              <br />
              Sie erhalten ausführliche Informationen über die Datenverarbeitung
              der Plattformbetreiber zu den jeweiligen
              Widerspruchsmöglichkeiten, den Auskunftsrechten sowie spezifische
              Informationen zu den jeweiligen Plattformen über die folgenden
              Datenschutzhinweise der jeweiligen Betreiber:
            </p>
            <p>
              <strong>
                Facebook
                <br />
              </strong>
              Anbieter: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
              Harbour, Dublin 2, Irland
              <br />
              Datenschutzerklärung:{' '}
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://www.facebook.com/about/privacy/
              </a>
              <br />
              Opt-Out:{' '}
              <a
                href="https://www.facebook.com/settings?tab=ads"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://www.facebook.com/settings?tab=ads
              </a>
            </p>
            <p>
              Spezifische Informationen zu Facebook-Fanpages: Beim Besuch
              unserer Facebook-Fanpage verarbeitet Facebook Ihre
              personenbezogenen Daten (Facebook Insights). Diese Daten werden
              uns durch Facebook im Rahmen von Facebook Insights anonymisiert
              übermittelt. Bei diesen anonymisierten Daten handelt es sich um
              statistische Daten über unsere Fanpage-Abonnenten.
            </p>
            <p>
              Darüber hinaus bekommen wir von Facebook Profildaten von Ihnen
              mitgeteilt, wenn Sie mit uns bzw. unserer Seite interagieren,
              beispielsweise Beiträge liken oder kommentieren bzw. uns via
              Facebook anschreiben oder unserer Seite folgen.
            </p>
            <p>
              <strong>
                Google+ / Youtube
                <br />
              </strong>
              Anbieter: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA
              <br />
              Datenschutzerklärung:{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://policies.google.com/privacy
              </a>
              <br />
              Opt-Out:{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://adssettings.google.com/authenticated
              </a>
            </p>
            <p>
              Spezifische Informationen zu Youtube-Accounts oder -Kanälen: Beim
              Besuch unseres Youtube-Auftritts verarbeitet Youtube Ihre
              personenbezogenen Daten. Diese Daten werden uns durch Youtube im
              Rahmen der Youtube STUDIO-Nutzung anonymisiert übermittelt. Bei
              diesen anonymisierten Daten handelt es sich um statistische Daten
              über unsere Kanal-Abonnenten.
            </p>
            <p>
              <strong>
                Instagram
                <br />
              </strong>
              Anbieter: Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA
              <br />
              Datenschutzerklärung:{' '}
              <a
                href="https://help.instagram.com/155833707900388"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://help.instagram.com/155833707900388
              </a>
              <br />
              Opt-Out:{' '}
              <a
                href="https://help.instagram.com/1896641480634370?ref=ig"
                target="_blank"
                rel="noopener noreferrer"
                className="external"
              >
                https://help.instagram.com/1896641480634370?ref=ig
                <br />
              </a>
              Spezifische Informationen zu Instagram-Profilen: Beim Besuch
              unserer Instagram-Firmenseite verarbeitet Instagram Ihre
              personenbezogenen Daten. Diese Daten werden uns durch Instagram im
              Rahmen von Instagram Insights anonymisiert übermittelt. Bei diesen
              anonymisierten Daten handelt es sich um statistische Daten über
              unsere Profil-Abonnenten.
              <br />
              Darüber hinaus bekommen wir von Instagram Ihren Usernamen
              mitgeteilt, wenn Sie mit uns bzw. unserer Seite interagieren,
              beispielsweise Beiträge liken oder kommentieren bzw. unserer Seite
              folgen.
            </p>
            <p>
              <strong>
                Verwendung von YouTube
                <br />
              </strong>
              Auf der Website der Niesmann+Bischoff GmbH sind Funktionen des
              Dienstes YouTube zur Anzeige und Wiedergabe von Videos
              eingebunden. Diese Funktionen werden angeboten durch YouTube, LLC
              901 Cherry Ave. San Bruno, CA 94066 USA. Weitere Informationen
              sind in den Datenschutzrichtlinien von YouTube zu finden.
            </p>
            <p>
              Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach
              Anbieterangaben eine Speicherung von Nutzerinformationen erst bei
              Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe
              eingebetteter Youtube-Videos gestartet, setzt Youtube Cookies ein,
              um Informationen über das Nutzerverhalten zu sammeln. Hinweisen
              von Youtube zufolge dienen diese unter anderem dazu,
              Videostatistiken zu erfassen, die Nutzerfreundlichkeit zu
              verbessern und missbräuchliche Handlungsweisen zu unterbinden.
              Unabhängig von einer Wiedergabe der eingebetteten Videos wird bei
              jedem Aufruf der Website der Niesmann+Bischoff GmbH eine
              Verbindung zum Google-Netzwerk „DoubleClick“ aufgenommen, was ohne
              den Einfluss der Niesmann+Bischoff GmbH weitere
              Datenverarbeitungsvorgänge auslösen kann.
              <br />
              Weitere Details über den Einsatz von Cookies bei YouTube findet
              der Nutzer in der Datenschutzerklärung von YouTube unter:{' '}
              <a
                href="http://www.youtube.com/t/privacy_at_youtube"
                className="external"
              >
                http://www.youtube.com/t/privacy_at_youtube
              </a>
            </p>
            <p>
              <strong>
                E-Mail-Sicherheit
                <br />
              </strong>
              Der Nutzer ist mit elektronischer Kommunikation einverstanden,
              sobald er selbst den elektronischen Kontakt zur Niesmann+Bischoff
              GmbH eröffnet. Der Nutzer wird darauf hingewiesen, dass E-Mails
              auf dem Übertragungsweg unbefugt und unbemerkt mitgelesen oder
              verändert werden können. Die Niesmann+Bischoff GmbH verwendet eine
              Software zur Filterung von unerwünschten E-Mails (Spam-Filter).
              Durch den Spam-Filter können E-Mails abgewiesen werden, wenn diese
              durch bestimmte Merkmale fälschlich als Spam identifiziert wurden.
            </p>
            <p>
              <strong>
                Auskunftsrecht
                <br />
              </strong>
              Gemäß Art. 15 DS-GVO hat der Nutzer ein Recht auf unentgeltliche
              Auskunft über seine gespeicherten Daten sowie ggf. ein Recht auf
              Berichtigung, Sperrung oder Löschung dieser Daten (Art. 5 Abs. 1
              d), e), Art. 12 sowie Art. 17-19 DS-GVO). Auf Anforderung teilt
              die Niesmann+Bischoff GmbH dem Nutzer entsprechend geltendem Recht
              in schriftlicher Form mit, welche personenbezogenen Daten des
              Nutzers bei der Niesmann+Bischoff GmbH gespeichert sind. Diese
              Auskunft ist unentgeltlich.
            </p>
            <p>
              <strong>
                Widerspruchsrecht des Nutzers
                <br />
              </strong>
              Soweit der&nbsp;Nutzer eine aktive Datennutzung für interne Zwecke
              durch die Niesmann+Bischoff GmbH nicht möchte, ist der&nbsp;Nutzer
              gemäß Art. 21 Abs. 2-4 DS-GVO berechtigt, dieser Nutzung und
              Verarbeitung jederzeit zu widersprechen. Hierzu genügt es, eine
              entsprechende E-Mail an{' '}
              <a
                href="mailto:pressestelle@niesmann-bischoff.com"
                className="local"
              >
                pressestelle@niesmann-bischoff.com
              </a>{' '}
              zu senden. Der besondere Fall einer gesetzlich vorgeschriebenen
              Datensperrung anstelle einer Datenlöschung gemäß Art. 17-19 DS-GVO
              bleibt hierbei unberührt.
            </p>
            <p>
              <strong>
                Datenübermittlung und -protokollierung zu systeminternen und
                statistischen Zwecken
                <br />
              </strong>
              Der Internet-Browser des Nutzers übermittelt beim Zugriff auf
              diese Website aus technischen Gründen automatisch Daten an den
              Webserver. Jeder Datensatz besteht aus dem Datum und der Uhrzeit
              des Zugriffs, URL der verweisenden Website, abgerufener Datei,
              Menge der gesendeten Daten, Browsertyp und -version,
              Betriebssystem sowie der IP-Adresse des Nutzers. Eine Zuordnung
              dieser Daten zu einer bestimmten Person ist nicht möglich. Die
              gespeicherten Daten werden nur intern und ausschließlich zu
              statistischen Zwecken ausgewertet.
            </p>
            <p>
              <strong>
                Sicherheit durch technische und organisatorische Maßnahmen
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH setzt gemäß Art. 32 Abs. 1 DS-GVO zur
              Sicherheit bei der Verarbeitung von Daten technische und
              organisatorische Maßnahmen ein, um persönlichen Daten durch
              zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung
              oder den Zugriff unberechtigter Personen zu schützen. Die im
              Einsatz befindlichen Sicherheitsmaßnahmen werden entsprechend der
              technologischen Entwicklung fortlaufend verbessert.
            </p>
            <p>
              <strong>
                Schutz Minderjähriger
                <br />
              </strong>
              Personen, die das 16. Lebensjahr noch nicht vollendet haben,
              dürfen ohne Zustimmung der Erziehungsberechtigten keine
              personenbezogenen Daten an die Niesmann+Bischoff GmbH übermitteln.
              Persönliche Informationen dürfen Personen, die das 16. Lebensjahr
              noch nicht vollendet haben, der Niesmann+Bischoff GmbH nur dann
              zur Verfügung stellen, wenn das ausdrückliche Einverständnis der
              Erziehungsberechtigten vorliegt oder die Personen das 16.
              Lebensjahr vollendet haben oder älter sind. Diese Daten werden
              entsprechend dieser Datenschutzerklärung verarbeitet.
            </p>
            <p>
              <strong>
                Markenschutz
                <br />
              </strong>
              Jedes hier genannte Firmen- oder Markenzeichen ist Eigentum der
              jeweiligen Firma. Die Nennung von Marken und Namen geschieht zu
              rein informativen Zwecken.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            {document.getElementById('cookiebotsomething').firstElementChild !=
              null && (
              <div
                dangerouslySetInnerHTML={renderMarkup(
                  document.getElementById('cookiebotsomething')
                    .firstElementChild.innerHTML
                )}
              ></div>
            )}
            <div id="CookieDeclaration" />
          </div>
        </div>
      </div>
    )
  }
}

export default Datenschutz
