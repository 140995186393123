import React from 'react'
import { renderMarkup } from '../../helpers'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Protection des données</h1>
            <p>
              Conformément à l‘article 13, paragr. 1 de la loi sur les médias
              électroniques (TMG), le fournisseur de téléservices a l’obligation
              d’informer ses utilisateurs sur la nature, l’étendue et le but du
              prélèvement et de l’exploitation des données à caractère personnel
              en début de la procédure d’utilisation. L’utilisateur doit être en
              mesure de consulter le contenu de cette information à tout moment.
              Chaque entreprise a l’obligation d’informer l’utilisateur sur les
              données à caractère personnel qu’elle prélève et qu’elle
              exploite.La société Niesmann+Bischoff GmbH attache la plus grande
              importance à la sécurité des données des utilisateurs et au
              respect des dispositions régissant la protection des données. Le
              prélèvement, le traitement et l’exploitation des données à
              caractère personnel sont gérés par la législation actuelle en
              vigueur et la directive CE sur la protection des données.
              Conformément à l’article 4, No. 1 DS-GVO (règlement général CE sur
              la protection des données), toute information se référant à une
              personne naturelle identifiée ou identifiable est considérée comme
              donnée à caractère personnel.
              <br />
              Rapports personnels : par exemple – Nom, adresse, adresse E-Mail,
              date de naissance, nationalité, profession, données sur la santé,
              religion, etc.
              <br />
              Rapports matériels : par exemple – revenus, situation financière,
              bien, etc.
              <br />
              <br />
              <strong>
                Déclaration de confidentialité
                <br />
              </strong>
              La société Niesmann+Bischoff GmbH s’engage à ne prélever, traiter,
              mémoriser et exploiter que les données des utilisateurs servant au
              traitement des demandes de la part des utilisateurs et ceci
              uniquement pour des usages internes.
            </p>
            <p>
              <strong>
                Transfert de données à des tiers
                <br />
              </strong>
              Aucune donnée ne sera transférée à des tiers sans l’accord de la
              personne concernée ou sans base juridique.
              <strong>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </strong>
            </p>
            <p>
              <strong>
                Transmission des données – Cryptage SSL
                <br />
              </strong>
              Le présent site web utilise une technique de cryptage SSL de 256
              bit (AES 256). Les données des utilisateurs sont exclusivement
              transférées de manière cryptée. Ce procédé inclut l’adresse IP de
              l’utilisateur.
            </p>
            <p>
              <strong>
                Cookies
                <br />
              </strong>
              La société Niesmann+Bischoff GmbH utilise des cookies. Ces cookies
              sont des petits fichiers de texte sauvegardés dans la mémoire
              tampon du navigateur Internet et servent à l’identification du
              navigateur. Ces fichiers sont utilisés comme aide à la navigation
              dans le site web et pour exploiter toutes les fonctions de manière
              optimale.
              <br />
              Le présent site web utilise : Browser Cookies
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Contrôle des cookies par l‘utilisateur
                <br />
              </strong>
              L’utilisateur a la possibilité de régler tous les navigateurs de
              sorte que les cookies ne sont acceptés que sur demande. De même,
              les réglages permettent de n’accepter que les cookies des sites
              actuellement consultés. Tous les navigateurs proposent des
              fonctions permettant la suppression sélective de cookies.
              <br />
              L’acceptation de cookies peut également être désactivée de manière
              générale. Dans ce cas, la navigation dans le site web sera
              éventuellement moins aisée.
            </p>
            <p>
              <strong>
                Usage des First-Party Cookies (Google Analytics Cookie)
                <br />
              </strong>
              Enregistrement des Google Analytics Cookies :
            </p>
            <ul>
              <li>
                <strong>Utilisateurs uniques</strong> – Google Analytics Cookies
                enregistre et regroupe les données utilisateur. Toutes les
                activités effectuées durant une session sont enregistrées de
                manière groupée. La définition de Google Analytics Cookies
                différencie entre les utilisateurs et les utilisateurs uniques.
              </li>
              <li>
                <strong>Activités des utilisateurs</strong> – Les Google
                Analytics Cookies enregistrent également les données sur le
                début et la fin d’une visite d’un utilisateur sur le site ainsi
                que le nombre de pages qu’il a consulté. La session sera
                terminée avec la fermeture du navigateur ou lors d’une
                inactivité prolongée de l’utilisateur (30 minutes), et le cookie
                enregistre la visite comme étant terminée ainsi que la date et
                l’heure de la première visite. Enregistré est également le
                nombre de visites par utilisateur unique. Lien externe :{' '}
                <a
                  href="http://www.google.com/analytics/terms/de.html"
                  className="external"
                >
                  http://www.google.com/analytics/terms/de.html
                </a>
              </li>
            </ul>
            <p>
              L’utilisateur peut empêcher l’enregistrement des données générées
              par le cookie et celles en rapport avec l’utilisation du site web
              (y compris l’adresse IP) pour Google ainsi que le traitement de
              ces données par Google par téléchargement et installation du lien
              suivant dans le Plug-In du navigateur : Lien externe :{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .<br />
              Le point „Webanalysedienst Google Analytics / Universal Analytics“
              contient des informations complémentaires à ce sujet.
            </p>
            <p>
              <strong>
                Durée de vie des cookies
                <br />
              </strong>
              Les cookies sont gérés par le serveur Internet du présent site
              web. Ce site web utilise :<br />
              Cookies transitoires/cookies de session (opération d‘utilisation
              unique)
              <br />
              Durée de vie : jusqu’à la fermeture de ce site web
              <br />
              Cookie persistent (identification permanente du navigateur)
              <br />
              Durée de vie : 30 minutes
            </p>
            <p>
              <strong>
                Désactivation ou élimination des cookies (Opt-Out)
                <br />
              </strong>
              Chaque navigateur permet de limiter ou de supprimer les cookies.
              Les sites web suivants contiennent des informations plus
              détaillées à ce sujet :
            </p>
            <ul>
              <li>
                <em>
                  Internet Explorer :<br />
                </em>
                <a
                  href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                  className="external"
                >
                  http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9
                </a>
                <em>– </em>
              </li>
              <li>
                <em>Firefox :</em>
                <em>
                  <br />
                </em>
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  className="external"
                >
                  https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies
                </a>
              </li>
              <li>
                <em>Google Chrome:</em>
                <br />
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  className="external"
                >
                  https://support.google.com/chrome/answer/95647?hl=en
                  <br />
                </a>
              </li>
              <li>
                <em>
                  Safari :<br />
                </em>
                <a
                  href="https://support.apple.com/de-de/HT201265"
                  className="external"
                >
                  https://support.apple.com/de-de/HT201265
                </a>
              </li>
            </ul>
            <p>
              <strong>
                <br />
                Service d’analyse de site Internet Google Analytics / Universal
                Analytics
                <br />
              </strong>
              La société Niesmann+Bischoff GmbH Google Analytics, un service
              d’analyse de site Internet fourni par Google Inc. (“Google”).
              Google Analytics utilise des “Cookies”, en l’occurrence des
              fichiers texte enregistrés sur l’ordinateur de l‘utilisateur dans
              le but d‘analyser l’utilisation du site. Les informations générées
              par les cookies sur l’utilisation du présent site web sont, en
              règle générale, transmises et mémorisées par Google sur un serveur
              situé aux USA. Lors de l’activation pour l’anonymisation IP sur le
              présent site web, l’adresse IP de l’utilisateur sera auparavant
              tronquée par Google au sein des pays membres de l’Union Européenne
              ou des autres pays signataires de l’accord sur l’EspaceEconomique
              Européen.
              <br />
              L’adresse IP complète ne sera transmise à un serveur de Google aux
              USA pour y être tronquée que dans des cas exceptionnels. Sur
              demande de l’exploitant du présent site web, Google exploitera ces
              informations pour évaluer l’utilisation du site, pour établir des
              rapports sur les activités sur le site et fournir des services en
              relation avec l’utilisation du site et de l’Internet pour
              l’exploitant du site web. L’adresse IP transmise par le navigateur
              de l’utilisateur dans le cadre des Google Analytics ne sera pas
              associée avec d’autres données de Google. L’utilisateur peut
              empêcher la sauvegarde des cookies par un réglage correspondant
              dans le logiciel du navigateur. Toutefois, Niesmann+Bischoff GmbH
              attire l’attention sur le fait que les fonctions disponibles sur
              ce site web ne pourront pas être exploitées dans leur totalité.
              <br />
              De plus, Niesmann+Bischoff GmbH utilise les rapports de Google
              Analytics pour le prélèvement de caractéristiques démographiques
              et d’intérêts.&nbsp;Par ailleurs, l’utilisateur peut empêcher
              l’enregistrement des données en relation avec l’utilisation du
              site web (y compris l’adresse IP) généré par le cookie ainsi que
              le traitement de ces données par Google par téléchargement et
              installation du lien suivant dans le Plug-In du navigateur : Lien
              :{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </p>
            <p>
              En alternative au Plug-In du navigateur ou dans les navigateurs
              sur appareils mobiles, le lien suivant peut servir à définir un
              Opt-Out-Cookie qui, à l’avenir, empêche l’enregistrement par
              Google Analytics au sein du présent site web (cet Opt-Out-Cookie
              fonctionne uniquement dans ce navigateur et exclusivement pour ce
              domaine, suppression des cookies dans ce navigateur, cliquer une
              nouvelle fois sur le lien): &lt;a
              href=”javascript:gaOptout()”&gt;Google Analytics
              deaktivieren&lt;/a&gt;
              <br />
              Les indications sur l’intégration du Opt-Out-Cookie peuvent être
              consultées sous le lien suivant:&nbsp;
              <a
                href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable"
                className="external"
              >
                https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
              </a>
            </p>
            <p>
              Niesmann+Bischoff GmbH attire l’attention sur le fait que le
              présent site web utilise Google Analytics avec l’extension
              “_anonymizeIp()”, c’est-à-dire que les seulement les adresses IP
              tronquées sont traitées afin d’exclure toute identification
              directe des personnes.
            </p>
            <p>
              <strong>
                Service d’analyse de site Internet Matomo (autrefois Piwik)
                <br />
              </strong>
              Le présent site collecte et mémorise des données à des fins de
              marketing et d’optimisation au moyen du logiciel d’analyse de site
              Internet Matomo (
              <a href="https://matomo.org/" className="external">
                www.matomo.org
              </a>
              ). Ces données servent à établir des profils d’utilisation sous un
              pseudonyme avec utilisation de cookies. Les cookies sont des
              petits fichiers texte mémorisés localement dans la mémoire tampon
              du navigateur Internet pour permettre son identification. Les
              données prélevées à l’aide de la technologie Matomo (y compris
              votre adresse IP rendue anonyme) sont transférées au serveur de
              Niesmann+Bischoff GmbH et sauvegardées pour des analyses
              d’utilisation avec l’objectif d’optimiser le site web par
              Niesmann+Bischoff GmbH. A cet effet, les informations générées par
              le cookie dans le profil d’utilisation sous pseudonyme ne sont pas
              exploitées pour identifier l’utilisateur du site ou pour associer
              des données personnelles du porteur du pseudonyme. Lorsque
              l’utilisateur ne donne pas son accord pour la sauvegarde et
              l’exploitation de ces données issues de la visite sur le site web
              de Niesmann+Bischoff GmbH et qu’il désire s’y opposer à l’avenir,
              l’utilisateur a la possibilité d’empêcher l’utilisation des
              cookies et, en conséquence, la participation au suivi.
              L’utilisateur peut régler son navigateur de sorte qu’il soit
              informé sur chaque définition d’un cookie et qu’il ait la
              possibilité d’accepter ou de refuser un cookie, soit pour des cas
              spécifiques soit de manière générale. Toutefois, la fonctionnalité
              du site de Niesmann+Bischoff GmbH peut être entravée en cas d’un
              refus d’acceptation des cookies.
            </p>
            <p>
              <strong>
                Service d’analyse de site Internet DoubleClick
                <br />
              </strong>
              Dans le cadre de l’application de Google Analytics, le présent
              site web exploite le Service d’analyse de site Internet
              DoubleClick qui sert à l’identification du navigateur de
              l’utilisateur lors d’une visite d’autres sites web. Les données
              prélevées ne servent qu’à des fins statistiques et évaluées sous
              forme anonyme. Les informations générées par le cookie sur la
              visite du site web de Niesmann+Bischoff GmbH sont transmises et
              mémorisées par Google sur un serveur situé aux USA. Toutefois,
              avant le transfert au sein des pays membres de l’Union Européenne
              ou dans les autres pays signataires de l’accord sur l’Espace
              Economique Européen, l’adresse IP sera tronquée par activation de
              la fonction d’IP anonymat.
              <br />
              L’adresse IP complète ne sera transmise à un serveur de Google aux
              USA pour y être tronquée que dans des cas exceptionnels. L’adresse
              IP anonyme transmise par le navigateur de l’utilisateur dans le
              cadre des Google Analytics ne sera pas associée avec d’autres
              données de Google. Les DoubleClick-Cookies ne contiennent pas de
              données personnelles.
              <br />
              Les informations générées sont utilisées pour établir des rapports
              sur les activités sur le site web et fournir des services en
              relation avec l’utilisation du site. Lorsque la législation
              l’exige, Google transmettra ces informations à des tiers ou à des
              tiers qui traitent ces données pour le compte de Google.
            </p>
            <p>
              L’utilisateur à la possibilité, à tout moment, de faire opposition
              à un suivi de l‘analyse du site Internet ou de désactiver
              DoubleClick-Cookie. L’extension pour le navigateur requise à cet
              effet peut être téléchargée du site Internet Google correspondant
              et installée dans le navigateur. Lorsque l’utilisateur utilise
              plusieurs terminaux ou navigateurs, la fonction Opt-out devra être
              exécutée pour chaque terminal ou navigateur. Pour ce faire,
              l’utilisateur peut cliquer sur le lien suivant :{' '}
              <a
                href="http://google.com/ads/preferences/html/opt-out.html"
                className="external"
              >
                http://google.com/ads/preferences/html/opt-out.html
              </a>
            </p>
            <p>
              <strong>
                Utilisation de Google Maps
                <br />
              </strong>
              Le site web de Niesmann+Bischoff GmbH utilise Google Maps (API) de
              Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA (“Google”). Google Maps est un service Internet de
              représentation de cartes interactives pour la visualisation
              d’informations géographiques. Les informations sur l’utilisation
              du site de Niesmann+Bischoff GmbH (p. ex. l’adresse IP de
              l’utilisateur) seront transmises et stockées chez Google aux USA
              avec l’ouverture des pages subordonnées intégrées dans la carte de
              Google Maps.
              <br />
              Lorsque la législation l’exige, Google transmettra ces
              informations à des tiers ou à des tiers qui traitent ces données
              pour le compte de Google. Google n’associera pas l’adresse IP de
              l’utilisateur avec d’autres données de Google. Néanmoins, il
              serait possible que Google exploite les données issues d’une
              utilisation de Google Maps pour identifier l’utilisateur et créer
              des profils de personnalité, de les exploiter ou de les traiter à
              des fins étrangères sur dont Niesmann+Bischoff GmbH n’a et peut
              avoir aucune influence.
              <br />
              Les conditions d’utilisation de Google peuvent être consultées
              sous l’adresse suivante&nbsp;:{' '}
              <a
                href="http://www.google.de/intl/de/policies/terms/regional.html"
                className="external"
              >
                http://www.google.de/intl/de/policies/terms/regional.html
              </a>
              .<br />
              Les conditions d’utilisation complémentaires de Google peuvent
              être consultées sous l’adresse suivante&nbsp;:{' '}
              <a
                href="https://www.google.com/intl/de_US/help/terms_maps.html"
                className="external"
              >
                https://www.google.com/intl/de_US/help/terms_maps.html
              </a>
              .<br />
              Les informations détaillées sur la protection des données en
              liaison avec l’utilisation de Google Maps sont à prélever du site
              Internet („Google Privacy Policy“) :{' '}
              <a
                href="http://www.google.de/intl/de/policies/privacy/"
                className="external"
              >
                http://www.google.de/intl/de/policies/privacy/
              </a>
            </p>
            <p>
              Lorsque l’utilisateur s’oppose contre le transfert de ses données
              à Google dans le cadre de l’utilisation de Google Maps, il peut
              complètement désactiver le service Internet de Google Maps par
              désactivation de l’application JavaScript dans son navigateur. De
              cette manière, Google Maps ainsi que la représentation des cartes
              sur le site Internet de Niesmann+Bischoff GmbH ne pourront plus
              être utilisés.
            </p>
            <p>
              <strong>
                Google AdWords – Suivi des conversions
                <br />
              </strong>
              Le site web de Niesmann+Bischoff GmbH utilise le programme de
              publicité en ligne “Google AdWords” et, dans le cadre de Google
              AdWord, également le suivi des conversions. Le cookie pour le
              suivi des conversions est défini lorsque l’utilisateur clique sur
              une annone proposée par Google. Les cookies sont des fichiers
              texte enregistrés sur votre ordinateur. Ces cookies ne servent pas
              à l’identification personnelle et perdent leur validité après 30
              jours. Lorsque l’utilisateur visite le présent site web et que le
              cookie est encore valide, Google et Niesmann+Bischoff GmbH peuvent
              voir que l’utilisateur a cliqué sur l’annonce et qu’il a été
              redirigé sur cette page. Chaque utilisateur de Google AdWords
              reçoit un cookie différent de sorte qu’ils ne peuvent pas être
              suivis par l’intermédiaire des utilisateurs AdWords.
              <br />
              Les informations collectées à l’aide des cookies de conversion
              servent à établir des statistiques AdWords pour les utilisateurs
              AdWords qui ont opté pour le suivi des conversions.
              Niesmann+Bischoff GmbH reçoit les informations sur le nombre total
              des utilisateurs qui ont cliqué sur l’annonce et qui ont été
              redirigés vers une page avec un tag pour le suivi des conversions.
              Par contre, Niesmann+Bischoff GmbH ne reçoit pas d’informations
              permettant d’effectuer une identification de l’utilisateur.
              Lorsque l’utilisateur ne désire pas participer au suivi, celui-ci
              peut refuser cette utilisation en désactivant le cookie du suivi
              des conversions Google sur son navigateur Internet. Celui-ci ne
              sera alors pas pris en compte dans les statistiques des suivis.
              <br />
              Le site Internet suivant contient des informations complémentaires
              sur la protection des données de Google:{' '}
              <a
                href="http://www.google.de/policies/privacy/"
                className="external"
              >
                http://www.google.de/policies/privacy/
              </a>
            </p>
            <p>
              <strong>&nbsp;</strong>
              <strong>Retargeting/Remarketing</strong>
            </p>
            <p>
              Niesmann+Bischoff GmbH utilise le Retargeting de Google Inc.
              („Google“). C’est outil permet à Niesmann+Bischoff GmbH de
              proposer une publicité ciblée et personnalisée aux utilisateurs de
              leur site web et qui ont déjà montré leur intérêt pour des offres
              de Niesmann+Bischoff GmbH. La publicité est affichée en fonction
              d’une analyse du comportement d’utilisation sur la base des
              cookies sans que des données à caractère personnel soient
              enregistrées. La technologie du Retargeting consiste à mémoriser
              un cookie dans l’ordinateur ou les terminaux mobiles de
              l’utilisateur qui enregistre des données rendues anonymes sur les
              intérêts pour permettre d’adapter la publicité individuellement
              aux informations mémorisées.
            </p>
            <p>
              Ces cookies sont des petits fichiers texte enregistrés sur votre
              ordinateur ou terminaux mobiles. L’utilisateur reçoit alors des
              publicités qui correspondent avec une très haute probabilité aux
              produits ou aux informations pour lesquels l’utilisateur montre un
              intérêt. L’utilisateur peut définitivement refuser la définition
              de cookies par téléchargement et installation le plug-in du lien
              suivant :<br />
              <a
                href="https://www.google.com/settings/ads/onweb/"
                className="external"
              >
                https://www.google.com/settings/ads/onweb/
                <br />
              </a>
              Le site Internet suivant contient des informations complémentaires
              sur les règles en matière de protection des données de
              Google:&nbsp;
              <a href="http://www.google.com/privacy/ads/" className="external">
                http://www.google.com/privacy/ads/
              </a>
            </p>
            <p>
              <strong>
                Utilisation des réseaux sociaux
                <br />
              </strong>
              Des fonctions relatives aux réseaux sociaux peuvent être utilisées
              sur notre site Web.
              <br />
              En consultant une de ces pages, une connexion peut être établie
              aux serveurs respectifs des réseaux sociaux. Ceux-ci sont informés
              du fait que vous avez consulté notre site Internet avec votre
              adresse IP. Si vous deviez maintenant commenter, aimer ou twitter
              etc. quelque chose et que vous êtes connecté dans votre compte
              respectif, il est éventuellement possible au réseau social
              d’affecter votre visite sur notre site à vous-même et à votre
              compte d’utilisateur. Nous vous informons qu’en tant que
              prestataire des sites, nous n’avons pas connaissance du contenu
              des données transférées et de leur utilisation.
              <br />
              Ces services sont fournis par les entreprises suivantes :
            </p>
            <ul>
              <li>
                <u>Facebook Inc</u>., 1601 S. California Ave, Palo Alto, CA
                94304, USA
              </li>
              <li>
                <u>Google+ Inc</u>., 1600 Amphitheatre Parkway, Mountain View,
                CA 94043, USA
              </li>
              <li>
                <u>Linkedin Inc</u>., 2029 Stierlin Court, Mountain View, CA
                94043, USA
              </li>
              <li>
                <u>Twitter Inc</u>., 1355 Market St., Suite 900, San Francisco,
                CA 94103, USA
              </li>
              <li>
                <u>Xing AG</u>, Dammtorstraße 30, 20354 Hamburg, Allemagne
              </li>
              <li>
                <u>YouTube LLC</u>, 901 Cherry Ave., San Bruno, CA 94066, USA
              </li>
              <li>
                <u>Kununu GmbH</u>, Wollzeile 1-3 Top 5.1, 1010 Wien, Autriche
              </li>
              <li>
                <u>Vimeo LCC</u>, White Plains, Bundesstaat New York, USA
              </li>
              <li>
                <u>Pinterest Europe Ltd</u>., Palmerston House, 2nd Floor Fenian
                Street, Dublin 2, Irlande
              </li>
              <li>
                <u>Instagram LLC</u>, 1601 Willow Rd, Menlo Park CA 94025, USA
              </li>
              <li>
                <u>SoundCloud Ltd</u>., Rheinsberger Str. 76/77, 10115 Berlin,
                Allemagne
              </li>
              <li>
                <u>Spotify AB, </u>Birger Jarlsgatan 61, 113 56 Stockholm, Suède
              </li>
              <li>
                <u>Tumblr, Inc</u>., 35 East 21st St, 10th Floor, New York, NY
                10010, USA
              </li>
              <li>
                <u>Myspace LLC, </u>8391 Beverly Blvd., #349, Los Angeles,
                California 90048, USA.
              </li>
            </ul>
            <p>
              Le but et l’étendue du prélèvement de données et la poursuite du
              traitement et de l’exploitation des données par les prestataires
              ainsi que vos droits et possibilités de réglage en la matière pour
              protéger vos données personnelles figurent dans les remarques sur
              la protection des données des prestataires respectifs :
            </p>
            <ul>
              <li>
                Facebook{' '}
                <a
                  href="https://de-de.facebook.com/privacy/explanation"
                  className="external"
                >
                  https://de-de.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Google{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Linkedin{' '}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  className="external"
                >
                  https://www.linkedin.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Twitter{' '}
                <a
                  href="https://twitter.com/privacy?lang=de"
                  className="external"
                >
                  https://twitter.com/privacy?lang=de
                </a>
              </li>
              <li>
                Xing{' '}
                <a href="https://www.xing.com/privacy" className="external">
                  https://www.xing.com/privacy
                </a>
              </li>
              <li>
                YouTube{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Kununu{' '}
                <a
                  href="https://www.kununu.com/de/info/datenschutz"
                  className="external"
                >
                  https://www.kununu.com/de/info/datenschutz
                </a>
              </li>
              <li>
                Vimeo{' '}
                <a href="https://vimeo.com/privacy" className="external">
                  https://vimeo.com/privacy
                </a>
              </li>
              <li>
                Pinterest{' '}
                <a
                  href="https://policy.pinterest.com/de/privacy-policy"
                  className="external"
                >
                  https://policy.pinterest.com/de/privacy-policy
                </a>
              </li>
              <li>
                Instagram{' '}
                <a
                  href="https://help.instagram.com/155833707900388"
                  className="external"
                >
                  https://help.instagram.com/155833707900388
                </a>
              </li>
              <li>
                SoundCloud{' '}
                <a
                  href="https://soundcloud.com/pages/privacy"
                  className="external"
                >
                  https://soundcloud.com/pages/privacy
                </a>
              </li>
              <li>
                Spotify{' '}
                <a
                  href="https://www.spotify.com/de/legal/privacy-policy/"
                  className="external"
                >
                  https://www.spotify.com/de/legal/privacy-policy/
                </a>
              </li>
              <li>
                Tumblr{' '}
                <a
                  href="https://www.tumblr.com/policy/de/privacy"
                  className="external"
                >
                  https://www.tumblr.com/policy/de/privacy
                </a>
              </li>
              <li>
                Myspace <u>https://myspace.com/pages/privacy</u>
              </li>
            </ul>
            <p>
              Si vous ne souhaitez pas que le réseau social respectif puisse
              affecter la visite sur notre site à votre compte respectif, vous
              devez vous déconnecter du service respectif avant de consulter
              notre site.
            </p>
            <p>
              <strong>
                Utilisation de YouTube
                <br />
              </strong>
              Sur le site de Niesmann+Bischoff GmbH sont intégrées des fonctions
              du service YouTube pour l’affichage et la lecture de vidéos. Ces
              fonctions sont proposées par YouTube, LLC 901 Cherry Ave. San
              Bruno, CA 94066 USA. Les informations complémentaires à ce sujet
              sont à prélever des règles en matière de protection des données de
              YouTube.
              <br />
              Ce service utilise le mode de confidentialité avancé qui, selon
              les indications du fournisseur, n’enregistre les informations
              d’utilisateur qu’avec le lancement de la lecture de la/des vidéos.
              YouTube définit les cookies avec le lancement de la lecture d’une
              vidéo intégrée est lancée pour collecter les informations sur le
              comportement de l’utilisateur. D’après les indications de YouTube,
              ces informations servent, entre autres, à établir des statistiques
              vidéo, à améliorer la convivialité d’utilisation et à éviter les
              comportements abusifs. Indépendamment d’une lecture d’une vidéo
              intégrée, chaque consultation du site web de Niesmann+Bischoff
              GmbH établira une connexion avec le réseau Google „DoubleClick“
              pouvant engendrer des procédures de traitement de données
              supplémentaires sans influence de la part de Niesmann+Bischoff
              GmbH.
              <br />
              Les informations complémentaires sur l’utilisation de cookies chez
              YouTube sont à prélever des déclarations de confidentialité de
              YouTube sous l’adresse suivante :{' '}
              <a
                href="http://www.youtube.com/t/privacy_at_youtube"
                className="external"
              >
                http://www.youtube.com/t/privacy_at_youtube
              </a>
            </p>
            <p>
              <strong>
                Sécurité du courrier électronique
                <br />
              </strong>
              L’utilisateur accepte la communication électronique dès qu’il a
              pris contact de manière électronique avec Niesmann+Bischoff GmbH.
              L’utilisateur est conscient que les courriers électroniques
              peuvent être lus et/ou modifiés de manière inaperçue et non
              autorisée sur leur voie de transmission. Niesmann+Bischoff GmbH
              utilise un logiciel de filtrage des courriers électroniques
              indésirables (filtre spam). Ce logiciel permet de rejeter tout
              E-mail lorsque certaines caractéristiques ont été identifiées
              comme Spam.
            </p>
            <p>
              <strong>
                Droit d‘accès
                <br />
              </strong>
              Conformément à l‘article 15 DS-GVO, l’utilisateur a le droit
              d’être gratuitement informé sur ces données mémorisées et, le cas
              échéant, un droit de rectification, de blocage ou d’effacement de
              ces données (article 5, paragr. 1 d), e), article 12 ainsi que
              l’article 17-19 DS-GVO). Sur demande, Niesmann+Bischoff GmbH,
              informera par écrit et conformément à la législation
              correspondante applicable l’utilisateur sur les données à
              caractère personnel stockées chez Niesmann+Bischoff GmbH.
              L’information est gratuite.
            </p>
            <p>
              <strong>
                Droit d’opposition de l‘utilisateur
                <br />
              </strong>
              Si l’utilisateur ne désire pas une exploitation active des données
              pour des usages internes par Niesmann+Bischoff GmbH, l’utilisateur
              a le droit, conformément à l’article 21, paragr. 2-4 DS-GVO, de
              s’opposer à tout moment contre une exploitation et un traitement
              des données. A cet effet, il suffit d’envoyer un E-Mail au service
              de presse à l’adresse{' '}
              <a
                href="mailto:pressestelle@niesmann-bischoff.com"
                className="local"
              >
                pressestelle@niesmann-bischoff.com
              </a>
              . Le cas spécial d’un blocage des données prescrit par la loi à la
              place d’une suppression des données conformément aux articles
              17-19 DS-GVO n’est pas affecté.<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Transmission et enregistrement des données à de fins internes au
                système et statistiques
                <br />
              </strong>
              Pour des raisons techniques, le navigateur Internet de
              l’utilisateur transmet automatiquement des données au serveur
              Internet avec l’ouverture du présent site web. Chaque bloc de
              données contient la date et l’heure de l’accès au site, l’URL du
              renvoi vers le site web, le fichier consulté, la quantité des
              données transmises, le type et la version du navigateur, le
              système de gestion ainsi que l’adresse IP de l’utilisateur. Une
              attribution de ces données à une personne déterminée n’est pas
              possible. Les données enregistrées sont exclusivement évaluées en
              interne à des fins statistiques.
            </p>
            <p>
              <strong>
                Sécurité par des mesures techniques et organisationnelles
                <br />
              </strong>
              Conformément à l’article 32 paragr. 1 DS-GVO sur la sécurité en
              matière de traitement des données, la société Niesmann+Bischoff
              GmbH met en application des mesures techniques et
              organisationnelles dans le but de protéger les données
              personnelles contre une manipulation aléatoire ou préméditée, une
              perte, une destruction ou un accès par des personnes non
              autorisées. Les mesures de sécurité actuellement mises en œuvre
              sont soumises à une évolution technologique permanente.
            </p>
            <p>
              <strong>
                Protection des mineurs
                <br />
              </strong>
              Les personnes n’ayant pas atteint l’âge de 16 ans ne sont pas
              autorisées à transmettre des données personnelles à
              Niesmann+Bischoff GmbH sans le consentement de l’autorité
              parentale. Les informations personnelles des mineurs n’ayant pas
              atteint l’âge de 16 ans ne pourront être mises à disposition de
              Niesmann+Bischoff GmbH qu’avec l’autorisation expresse de
              l’autorité parentale ou les personnes sont âgées de plus de 16
              ans. Ces données sont traitées conformément à la présente
              déclaration de confidentialité.
            </p>
            <p>
              <strong>
                Protection des marques
                <br />
              </strong>
              Les marques de fabrique et déposées mentionnées dans le site sont
              la propriété de la société respective. La mention de marques et
              nom est purement informative.
            </p>
            <strong>Cookies</strong>
            <br />
            {document.getElementById('cookiebotsomething').firstElementChild !=
              null && (
              <div
                dangerouslySetInnerHTML={renderMarkup(
                  document.getElementById('cookiebotsomething')
                    .firstElementChild.innerHTML
                )}
              ></div>
            )}
            <div id="CookieDeclaration" />
            <p>
              <strong>
                Les mentions légales ainsi que la déclaration de confidentialité
                ont été établies par :<br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
                <br />
              </a>
              Stefan Fischerkeller
              <br />
              Tel: +49 7542 / 94921-01
              <br />
              mailto:{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
