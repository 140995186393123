import React from 'react'
import { Link } from 'react-router-dom'
import {
  getLanguage,
  getApiUrl,
  LAYOUT_PATH,
  getAssetUrl,
  MARKETING_LANDING_PAGES
} from '../../config'
import SimpleSlider from './../Slider/SimpleSlider'
import InfoButton from './../Overlay/InfoButton'
import {
  formatPrice,
  checkPrice,
  formatWeight,
  renderMarkup,
  translate,
  getSlides
} from '../../helpers'
import SVG from './../SVG/SVG'
import CartItemCard from './CartItemCard'
import CartItemRow from './CartItemRow'
import LegalInfos from '../LegalInfos/LegalInfos'

import * as ScrollMagic from 'scrollmagic'
const scrollController = new ScrollMagic.Controller()

class CartOverview extends React.Component {
  constructor(props) {
    super(props)
    this.handleGalleryOverlay = this.handleGalleryOverlay.bind(this)
  }

  marketingCTAURL = (cart) => {
    const pdfURL = encodeURIComponent(this.pdfURL(cart))
    const configurationURL = encodeURIComponent(this.configurationURL(cart))
    const locale = encodeURIComponent(window.currentLocalization.locale)
    const model = encodeURIComponent(cart.layout.manufacturerId)
    const country = window.currentLocalization.locale
      .split('_')[1]
      .toLowerCase()
    return `https://visit.niesmann-bischoff.com/${country}/konfig-dealer-${country}?cid=${cart.code}&cu=${configurationURL}&cd=${pdfURL}&vc=635&l=${locale}&pm=${model}`
  }

  configurationURL = (cart) => {
    return getApiUrl() + `/cart/${cart.code}`
  }

  pdfURL = (cart) => {
    return getApiUrl() + `/export/pdf?code=${cart.code}`
  }

  async handleGalleryOverlay(e, item) {
    e.preventDefault()

    let gallery_items = []
    let gallery_item = {
      title: item.title,
      src: item.imgGalleryFilename
        ? getAssetUrl() + LAYOUT_PATH + item.imgGalleryFilename
        : item.img_gallery,
      w: item.w,
      h: item.h
    }
    gallery_items.push(gallery_item)

    await this.props.updateGalleryOverlay(gallery_items)
    this.props.toggleGalleryOverlay()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      document.getElementsByClassName('slider__infos')[0] &&
      prevProps.config.cartoverview_open !== this.props.config.cartoverview_open
    ) {
      let scene = new ScrollMagic.Scene({
        triggerElement: document.getElementsByClassName('slider__infos')[0],
        triggerHook: 'onLeave',
        offset: -110
      }).setClassToggle('.konfigurator', 'konfigurator__overview--is-sticky')

      if (this.props.config.cartoverview_open === false) {
        scene = scene.destroy(true)
      } else {
        scrollController.addScene(scene)
      }
    }
  }

  renderFootnote() {
    if (this.props.cart.model.imageKey === 'A') {
      return '* ' + translate('global', 'arto_engangsavgift')
    }
    if (this.props.cart.model.imageKey === 'F') {
      return '* ' + translate('global', 'flair_engangsavgift')
    }
    if (this.props.cart.model.imageKey === 'I') {
      return '* ' + translate('global', 'ismove_engangsavgift')
    }
    return ''
  }

  renderRegionalUVP(PSMotor) {
    const nova = {
      PS140: '20',
      PS160: '26',
      PS178: '31',
      PS180: '31',
      PS210: '40'
    }

    const malus = {
      PS140: '1.260',
      PS160: '4.740',
      PS178: '4.740',
      PS180: '4.740',
      PS210: '7.380'
    }

    let uvp = translate('cart_overview', 'uvp')
    if (window.currentLocalization.locale === 'at_AT') {
      uvp = uvp.replace('{novasteuer}', nova[PSMotor])
      uvp = uvp.replace('{malusbetrag}', malus[PSMotor])
    }

    return (
      <small>
        <span
          dangerouslySetInnerHTML={{
            __html: uvp
          }}
        />
      </small>
    )
  }

  renderValue = (item) => {
    // Sitzplätze
    if (['16', '46', '77'].includes(item.id)) {
      return item.value
    }

    return item.value.toLocaleString(window.currentLocalization.code) + ' kg'
  }

  renderInfo = (item) => {
    // 5% addieren = x * 1,05
    const sumAdd = Math.round(item.value * 1.05)

    // 5% subtrahieren = x * 0,95
    const sumSub = Math.round(item.value * 0.95)

    return (
      '(' +
      sumSub.toLocaleString(window.currentLocalization.code) +
      ' - ' +
      sumAdd.toLocaleString(window.currentLocalization.code) +
      ' kg)'
    )
  }

  mapMultiSelection = (item) => {
    return item.items.map((multiItem, index) => {
      const number = index + 1
      const title = item.title + ' ' + number
      return <CartItemCard key={index} title={title} equipment={multiItem} />
    })
  }

  render() {
    const { cart } = { ...this.props }
    // const { cart, config } = { ...this.props };

    if (cart.layout === undefined) {
      return ''
    }

    const pdfURL = this.pdfURL(cart)

    const slides = getSlides(cart, true)

    return (
      <React.Fragment>
        <div className="konfigurator__overview">
          <div className="konfigurator__overview__body">
            <div className="slider__wrapper">
              <div className="slider__background" />

              <SimpleSlider
                length={slides.length}
                extraClasses="slider__slick-dots--position__higher slick-dotted v-inverted"
              >
                {slides !== undefined && slides.length !== 0 ? (
                  slides.map(function (slide, index) {
                    // if index is greater than 4, add class interieur
                    if (index > 4) {
                      return (
                        <div
                          className="slider__item slider__item--cart-interieur"
                          key={index}
                          inverted={slide.inverted ? 1 : 0}
                        >
                          <img src={slide.img} alt="slide" />
                        </div>
                      )
                    }
                    return (
                      <div
                        className="slider__item slider__item--exterieur"
                        key={index}
                        inverted={slide.inverted ? 1 : 0}
                      >
                        <img src={slide.img} alt="slide" />
                      </div>
                    )
                  })
                ) : (
                  <div className="slider__item" key="0"></div>
                )}
              </SimpleSlider>
              <p className="slider__description g g-inset small">
                {translate('global', 'image_disclaimer')}
              </p>
              <div className="slider__infos">
                <div className="slider__infos--top">
                  <h2>
                    {translate('cart_overview', 'personal_pronoun')}{' '}
                    {cart.layout.title}
                  </h2>
                  <div className="text">
                    {window.currentLocalization.locale === 'nl_NL' ? (
                      <p>
                        <strong>{formatPrice(cart.totalPrice + 1850)}</strong>
                        <br />
                        <small>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                'cart_overview',
                                'consume_price'
                              )
                            }}
                          />
                        </small>
                      </p>
                    ) : (
                      <p>
                        <strong>{formatPrice(cart.totalPrice)}</strong>
                        <br />
                        {this.renderRegionalUVP(cart.layout.PSMotor)}
                      </p>
                    )}
                    {window.currentLocalization.locale !== '' &&
                    ['Flair', 'Arto', 'iSmove'].includes(cart.model.title) ? (
                      <p>&nbsp;</p>
                    ) : (
                      <p>
                        <strong>
                          {cart.totalWeight !== undefined &&
                          cart.totalWeight !== null
                            ? formatWeight(cart.totalWeight)
                            : formatWeight(cart.layout.weight)}
                        </strong>
                        <br />
                        <small>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translate('cart_overview', 'total_weight')
                            }}
                          />
                          {}
                        </small>
                      </p>
                    )}
                  </div>
                </div>
                <div className="slider__infos--bottom">
                  {/*
                  <a href={this.marketingCTAURL(cart)} target="_blank" rel="noopener noreferrer" className="button"><span>{translate("cart_overview","send_configuration_to_me")}</span></a>
                  */}
                  {cart.code && (
                    <a
                      href={pdfURL}
                      className="button pre-icon pre-icon-catalogue"
                    >
                      <span>{translate('cart_overview', 'pdf')}</span>
                    </a>
                  )}

                  {getApiUrl() !==
                    'https://konfigurator.niesmann-bischoff.com/backend' &&
                  MARKETING_LANDING_PAGES.includes(getLanguage()) ? (
                    <a
                      href={this.marketingCTAURL(cart)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button--cta"
                      style={{ color: '#414650' }}
                    >
                      <span>
                        {translate('cart_overview', 'request_an_offer_now')}
                      </span>
                    </a>
                  ) : (
                    ''
                  )}

                  <div className="slider__infos--bottom__serie">
                    <InfoButton
                      name="model"
                      infos={cart.model}
                      {...this.props}
                    />
                    <small>{translate('global', 'standard_equipment')}</small>
                  </div>
                </div>
                {cart && cart.code && (
                  <div className="slider__infos--code">
                    <div className="code">
                      <div className="code__title">
                        {translate(
                          'cart_overview',
                          'configuration_code_displayed'
                        )}
                      </div>
                      <span className="code__key">{cart.code}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {
              // Grundriss
              cart.layout !== undefined && (
                <React.Fragment>
                  <hr />
                  <div className="g g-inset">
                    <div className="konfigurator__container">
                      <section className="g has-nested">
                        <div className="g-s--down-12 g-s--up-5 g-nested">
                          <h2>{translate('cart_overview', 'layout')}</h2>
                          <p>
                            <strong>
                              {translate(
                                'cart_overview',
                                'base_price_overview'
                              )}
                            </strong>{' '}
                            {formatPrice(cart.layout.price)}
                            <br />
                            <strong>
                              {translate('cart_overview', 'total_length')}
                            </strong>{' '}
                            {cart.layout.length} mm
                            <br />
                            <strong>
                              {translate('cart_overview', 'total_width')}
                            </strong>{' '}
                            {cart.layout.width} mm
                            <br />
                            <strong>
                              {translate('cart_overview', 'total_height')}
                            </strong>{' '}
                            {cart.layout.height} mm
                          </p>
                        </div>

                        <div className="g-s--down-12 g-s--up-7 g-nested">
                          {cart.layout.imgGalleryFilename ? (
                            <div className="konfigurator__thumbnail">
                              <a
                                href={
                                  getAssetUrl() +
                                  LAYOUT_PATH +
                                  cart.layout.imgGalleryFilename
                                }
                                data-width="2126"
                                data-height="1071"
                                tabIndex="-1"
                                className="overlay-gallery__trigger-open"
                                onClick={(e) =>
                                  this.handleGalleryOverlay(e, cart.layout)
                                }
                              >
                                <SVG name="zoom" />
                              </a>
                              <img
                                src={
                                  getAssetUrl() +
                                  LAYOUT_PATH +
                                  cart.layout.imgGalleryFilename
                                }
                                alt={cart.layout.title}
                              />
                            </div>
                          ) : (
                            <div className="konfigurator__thumbnail">
                              <a
                                href={cart.layout.img_gallery}
                                data-width="2126"
                                data-height="1071"
                                tabIndex="-1"
                                className="overlay-gallery__trigger-open"
                                onClick={(e) =>
                                  this.handleGalleryOverlay(e, cart.layout)
                                }
                              >
                                <SVG name="zoom" />
                              </a>
                              <img
                                src={cart.layout.img_gallery}
                                alt={cart.layout.title}
                              />
                            </div>
                          )}
                        </div>

                        <div className="g-12 g-nested text">
                          <p className="c_grey-400">
                            {translate('global', 'image_disclaimer')}
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>

                  <hr />
                  <div className="g g-inset">
                    <div className="konfigurator__container">
                      <section className="g has-nested">
                        <div className="g-s--down-12 g-s--up-5 g-nested">
                          <h2>
                            {translate(
                              'cart_overview',
                              'weight_specifications'
                            )}
                          </h2>
                          <p>
                            {cart.weightBlock.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <strong>{item.label}</strong>{' '}
                                  {this.renderValue(item)}
                                  {item.info
                                    ? ' ' + this.renderInfo(item)
                                    : null}
                                  <br />
                                </React.Fragment>
                              )
                            })}
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            {
              // Exterieur
              cart.exterieur !== undefined && (
                <React.Fragment>
                  <hr />
                  <div className="g g-inset">
                    <div className="konfigurator__container konfigurator__container--flex konfigurator__container--inline-headlines">
                      <h2>{translate('cart_overview', 'exterieur')}</h2>
                      <Link
                        to="/03-exterieur"
                        className="button"
                        onClick={() => {
                          this.props.toggleCartOverview()
                        }}
                      >
                        <span>
                          {translate('cart_overview', 'change_options')}
                        </span>
                      </Link>
                      <section className="g has-nested">
                        {cart.exterieur.map((item, i) => (
                          <CartItemCard
                            key={i}
                            title={item.title}
                            equipment={item.item}
                          />
                        ))}
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            {
              // Interieur
              cart.interieur !== undefined && (
                <React.Fragment>
                  <hr />
                  <div className="g g-inset">
                    <div className="konfigurator__container konfigurator__container--flex konfigurator__container--inline-headlines">
                      <h2>{translate('cart_overview', 'interieur')}</h2>
                      <Link
                        to="/04-interieur"
                        className="button"
                        onClick={() => {
                          this.props.toggleCartOverview()
                        }}
                      >
                        <span>
                          {translate('cart_overview', 'change_options')}
                        </span>
                      </Link>
                      <section className="g has-nested">
                        {cart.interieur.map((item, i) =>
                          item.items && item.items.length > 0 ? (
                            this.mapMultiSelection(item)
                          ) : (
                            <CartItemCard
                              key={i}
                              title={item.title}
                              equipment={item.item}
                            />
                          )
                        )}
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            {
              // Pakete
              cart.packages !== undefined &&
                !cart.packages.every((element) => element === null) && (
                  <React.Fragment>
                    <hr />
                    <div className="g g-inset">
                      <div className="konfigurator__container konfigurator__container--flex">
                        <h2>{translate('cart_overview', 'packages')}</h2>
                        <Link
                          to="/05-pakete"
                          className="button"
                          onClick={() => {
                            this.props.toggleCartOverview()
                          }}
                        >
                          <span>
                            {translate('cart_overview', 'change_options')}
                          </span>
                        </Link>
                        {cart.packages.map((items, i) => (
                          <div key={i}>
                            <h3>{items.title}</h3>
                            <section className="g has-nested">
                              {items.items.map((item, k) => (
                                <div
                                  className="konfigurator__container__item g-m--down-12 g-m--up-6 g-nested"
                                  key={k}
                                >
                                  <div className="g u-boxed">
                                    <div className="g-6">
                                      <h3>{item.title}</h3>
                                      <span className="konfigurator__container__item__code small">
                                        {translate('global', 'option_code')}{' '}
                                        {item.code}
                                      </span>
                                      <br />
                                      <span className="small">
                                        {translate(
                                          'global',
                                          'additional_weight'
                                        )}
                                        {' + '}
                                        {item.weight}{' '}
                                        {translate(
                                          'cart_overview',
                                          'unit_of_weight_measurement'
                                        )}
                                        *
                                      </span>
                                    </div>
                                    <div className="g-6 u-text-right">
                                      <strong>
                                        <em>
                                          {checkPrice(
                                            item,
                                            cart.layout.PSMotor
                                          )}
                                        </em>
                                      </strong>
                                      <br />
                                    </div>
                                    <hr />
                                    <ul>
                                      {item.details &&
                                        item.details.map((detail, j) => (
                                          <li key={j}>
                                            <div
                                              className="left"
                                              // dangerouslySetInnerHTML={renderMarkup(detail.meta)}
                                            >
                                              <span className="small">
                                                {detail.code}
                                              </span>
                                            </div>
                                            <div
                                              className="middle"
                                              dangerouslySetInnerHTML={renderMarkup(
                                                detail.description
                                              )}
                                            />
                                            <div className="right">
                                              {checkPrice(
                                                detail,
                                                cart.layout.PSMotor
                                              )}
                                              {detail.pricebpm &&
                                              detail.pricebpm !== 0 ? (
                                                <span className="small">
                                                  incl. Bpm{' '}
                                                  {formatPrice(detail.pricebpm)}
                                                </span>
                                              ) : null}
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </section>
                          </div>
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                )
            }

            {
              // Chassis
              cart.chassis !== undefined &&
                !cart.chassis.every(
                  (element) => element === null || element.items.length === 0
                ) && (
                  <React.Fragment>
                    <div className="konfigurator__container">
                      <div className="g g-inset">
                        <h2>{translate('cart_overview', 'chassis')}</h2>
                        <Link
                          to="/06-chassis"
                          className="button"
                          onClick={() => {
                            this.props.toggleCartOverview()
                          }}
                        >
                          <span>
                            {translate('cart_overview', 'change_options')}
                          </span>
                        </Link>
                        {cart.chassis.map(
                          (item, parent_index) =>
                            item !== null &&
                            item.items.length > 0 && (
                              <div
                                key={parent_index}
                                className="konfigurator__container"
                              >
                                <h3>{item.title}</h3>
                                <section>
                                  {item.items.map((child_item, i) => (
                                    <CartItemRow
                                      key={i}
                                      i={i}
                                      child_item={child_item}
                                    />
                                  ))}
                                </section>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
            }

            {
              // Aufbau
              cart.aufbau !== undefined &&
                !cart.aufbau.every((element) => element === null) && (
                  <React.Fragment>
                    <div className="konfigurator__container">
                      <div className="g g-inset">
                        <h2>{translate('cart_overview', 'structure')}</h2>
                        <Link
                          to="/07-aufbau"
                          className="button"
                          onClick={() => {
                            this.props.toggleCartOverview()
                          }}
                        >
                          <span>
                            {translate('cart_overview', 'change_options')}
                          </span>
                        </Link>
                        {cart.aufbau.map(
                          (item, parent_index) =>
                            item !== null && (
                              <div
                                key={parent_index}
                                className="konfigurator__container"
                              >
                                <h3>{item.title}</h3>
                                <section>
                                  {item.items.map((child_item, i) => (
                                    <CartItemRow
                                      key={i}
                                      i={i}
                                      child_item={child_item}
                                    />
                                  ))}
                                </section>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
            }

            {
              // Technik
              cart.technik !== undefined &&
                !cart.technik.every((element) => element === null) && (
                  <React.Fragment>
                    <div className="konfigurator__container">
                      <div className="g g-inset">
                        <h2>{translate('cart_overview', 'technology')}</h2>
                        <Link
                          to="/08-technik"
                          className="button"
                          onClick={() => {
                            this.props.toggleCartOverview()
                          }}
                        >
                          <span>
                            {translate('cart_overview', 'change_options')}
                          </span>
                        </Link>
                        {cart.technik.map(
                          (item, parent_index) =>
                            item !== null && (
                              <div
                                key={parent_index}
                                className="konfigurator__container"
                              >
                                <h3>{item.title}</h3>
                                <section>
                                  {item.items.map((child_item, i) => (
                                    <CartItemRow
                                      key={i}
                                      i={i}
                                      child_item={child_item}
                                    />
                                  ))}
                                </section>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
            }

            {
              // Fußnote NL
              <div className="konfigurator__container">
                <div className="g g-inset">
                  <div className="konfigurator__container">
                    {window.currentLocalization.locale === 'nl_NL' ? (
                      <p>
                        * De consumentenprijs rijklaar bestaat uit de basisprijs
                        (fiscale waarde) vermeerderd met de verplichte kosten
                        rijklaar maken à € 1.850,00 incl. 21 % btw. De
                        verplichte kosten rijklaar maken omvatten alle
                        onvermijdbare en voorzienbare kosten voor levering van
                        het voertuig (conform de richtlijn van de ACM), zoals
                        kosten voor transport, brandstof, inspectie, reiniging /
                        poetsen, recyclingsbijdragen, aanvraag van het kenteken
                        en kentekenplaten.
                      </p>
                    ) : null}
                    {window.currentLocalization.locale === 'no_NO' ? (
                      <p>{this.renderFootnote()}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            }

            <LegalInfos />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CartOverview
