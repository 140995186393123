import React from 'react'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../Data/ContextProvider'
import { getLanguage } from '../../config'
import { translate } from '../../helpers'

class Footnote extends React.Component {
  renderFootnote() {
    if (this.context.cart.model.imageKey === 'A') {
      return translate('global', 'arto_engangsavgift')
    }
    if (this.context.cart.model.imageKey === 'F') {
      return translate('global', 'flair_engangsavgift')
    }
    if (this.context.cart.model.imageKey === 'I') {
      return translate('global', 'ismove_engangsavgift')
    }
    return ''
  }

  render() {
    return (
      <div className={'footnote text'}>
        <div className={'footnote__row'}>
          {this.props.footnote.map((item, index) => {
            return (
              <div key={index} className={'footnote__row text'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.footnote__number
                  }}
                  className={'footnote__number'}
                />
                <div className={'footnote__text'}>{item.footnote__text}</div>
              </div>
            )
          })}
          {getLanguage() === 'no' ? (
            <div className={'footnote__row text'}>
              <div className={'footnote__number'}>
                <sup>a)</sup>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.renderFootnote()
                }}
                className={'footnote__text'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

Footnote.contextType = AppContext
export default withRouter(Footnote)
