import React from 'react'
import DatenschutzDe from './../Datenschutz/Datenschutz_de'
import DatenschutzEn from './../Datenschutz/Datenschutz_en'
import DatenschutzFr from './../Datenschutz/Datenschutz_fr'
import DatenschutzIt from './../Datenschutz/Datenschutz_it'
import DatenschutzSv from './../Datenschutz/Datenschutz_sv'
import { getLanguage } from '../../config'

class Datenschutz extends React.Component {
  render() {
    const { config } = { ...this.props }
    const language = getLanguage()

    if (language === 'de') {
      return <DatenschutzDe infos={config} {...this.props} />
    }
    if (language === 'fr') {
      return <DatenschutzFr infos={config} {...this.props} />
    }
    if (language === 'it') {
      return <DatenschutzIt infos={config} {...this.props} />
    }
    if (language === 'sv') {
      return <DatenschutzSv infos={config} {...this.props} />
    }
    return <DatenschutzEn infos={config} {...this.props} />
  }
}

export default Datenschutz
