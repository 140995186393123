import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Loading from './../Loading/Loading'
import InfoButton from './../Overlay/InfoButton'
import { formatPrice, translate } from '../../helpers'
import {
  getApiUrl,
  getAssetUrl,
  getLanguage,
  REJECT_ARTO,
  REJECT_FLAIR,
  REJECT_ISMOVE
} from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Modell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      models: []
    }
  }

  filterModel(jsondata) {
    let filtered = jsondata
    if (REJECT_ARTO.includes(window.currentLocalization.locale)) {
      filtered = filtered.filter((item) => {
        return item.title !== 'Arto'
      })
    }
    if (REJECT_FLAIR.includes(window.currentLocalization.locale)) {
      filtered = filtered.filter((item) => {
        return item.title !== 'Flair'
      })
    }
    if (REJECT_ISMOVE.includes(window.currentLocalization.locale)) {
      filtered = filtered.filter((item) => {
        return item.title !== 'iSmove'
      })
    }
    return filtered
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ isLoading: true })
    await this.context.updateCurrentNav(1)
    await this.context.updateJumpmarks([])

    fetch(getApiUrl() + '/data/modelle?lang=' + getLanguage())
      .then((response) => response.json())
      .then((jsondata) => this.filterModel(jsondata))
      .then((data) => this.setState({ models: data, isLoading: false }))
  }

  renderFootnote(item) {
    if (item.imageKey === 'A') {
      return '* ' + translate('global', 'arto_engangsavgift')
    }
    if (item.imageKey === 'F') {
      return '* ' + translate('global', 'flair_engangsavgift')
    }
    if (item.imageKey === 'I') {
      return '* ' + translate('global', 'ismove_engangsavgift')
    }
    return ''
  }

  render() {
    const { models, isLoading } = this.state

    if (isLoading) {
      return <Loading></Loading>
    }

    const divClassName =
      models.length === 4
        ? 'g-m--down-12 g-m--up-3 g-nested'
        : 'g-m--down-12 g-m--up-4 g-nested'
    const infoButtonClassName =
      models.length === 4
        ? 'g-m--down-7 g-m--up-12 g-md--up-12 g-xl--up-12 konfigurator__modell__teaser__infos__info text'
        : 'g-m--down-7 g-m--up-12 g-md--up-12 g-l--up-7 g-xl--up-7 konfigurator__modell__teaser__infos__info text'
    const chooseButtonClassName =
      models.length === 4
        ? 'g-m--down-5 g-m--up-12 g-md--up-12 g-xl--up-12 u-text-right text'
        : 'g-m--down-5 g-m--up-12 g-md--up-12 g-l--up-5 g-xl--up-5 u-text-right text'
    const paddingBottom =
      models.length === 4 ? { paddingBottom: '10px' } : { paddingBottom: '0' }

    let productlanguage = getLanguage()
    if (['ch', 'at'].includes(productlanguage)) {
      productlanguage = 'de'
    }
    if (['int', 'nl', 'no', 'be'].includes(productlanguage)) {
      productlanguage = 'en'
    }

    const classNameBasePriceLabel =
      getLanguage() === 'no' ? 'g-5 text' : 'g-7 text'
    const classNameBasePriceValue =
      getLanguage() === 'no'
        ? 'g-7 text u-text-right u-padding-bottom--1x'
        : 'g-5 text u-text-right u-padding-bottom--1x'

    return (
      <div className="konfigurator__modell">
        <div className="g g-inset has-nested konfigurator__modell_nested">
          {models.map((item, i) => (
            <div key={i} className={divClassName}>
              <div
                className="konfigurator__modell__teaser"
                style={paddingBottom}
              >
                <div
                  className="konfigurator__modell__teaser__image"
                  style={paddingBottom}
                >
                  <Link
                    to="#"
                    className="button button--cta"
                    onClick={(e) =>
                      this.props.updateInfoOverlay({
                        content: 'important_infos',
                        model: this.state.models[i]
                      })
                    }
                  >
                    <img src={getAssetUrl() + item.image} alt={item.title} />
                  </Link>
                </div>
                <h2>{item.title}</h2>
                {item.description && <p>{item.description}</p>}
                <div className="g konfigurator__modell__teaser__infos u-boxed bg_grey-50">
                  <div className={classNameBasePriceLabel}>
                    <strong>
                      <em>{translate('modell', 'base_price')}</em>
                    </strong>
                  </div>
                  <div className={classNameBasePriceValue}>
                    <strong>
                      <em>{formatPrice(item.price)}</em>
                    </strong>
                    <br />

                    <span className="small">
                      {translate('global', 'sales_tax', true)}
                    </span>
                  </div>
                  <div className={infoButtonClassName}>
                    {item.details && (
                      <React.Fragment>
                        <InfoButton name="model" infos={item} />
                        <small>
                          {translate('global', 'standard_equipment')}
                        </small>
                      </React.Fragment>
                    )}
                  </div>
                  <div className={chooseButtonClassName}>
                    <button
                      name="important_infos"
                      onClick={(e) =>
                        this.props.updateInfoOverlay({
                          content: 'important_infos',
                          model: this.state.models[i]
                        })
                      }
                      className="button button--cta"
                    >
                      <span>{translate('global', 'choose')}</span>
                    </button>
                  </div>
                  {item.pricelist.length > 0 ? (
                    <React.Fragment>
                      <hr />
                      <a
                        className="button button--outline u-width--full"
                        href={item.pricelist}
                        target="_blank"
                      >
                        <span>{translate('modell', 'price_list')}</span>
                      </a>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
                {getLanguage() === 'no' ? (
                  <React.Fragment>
                    <hr />
                    <div className="g-12 text">
                      <span className="small">{this.renderFootnote(item)}</span>
                    </div>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Modell.contextType = AppContext
export default withRouter(Modell)
