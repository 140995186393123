import React from 'react'

import { translate } from '../../helpers'

class ToolbarListItems extends React.Component {
  render() {
    return (
      <React.Fragment>
        <li className="toolbar__item pre-icon pre-icon-toolbar-catalog">
          <a
            className="toolbar__link"
            href={translate('toolbar', 'homepage_url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('toolbar', 'homepage')}
          </a>
        </li>
        <li className="toolbar__item pre-icon pre-icon-toolbar-dealer">
          <a
            className="toolbar__link"
            href={translate('toolbar', 'dealer_url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('toolbar', 'dealer')}
          </a>
        </li>
        <li className="toolbar__item pre-icon pre-icon-toolbar-support">
          <a
            className="toolbar__link"
            href={translate('toolbar', 'support_mail')}
          >
            {translate('toolbar', 'support')}
          </a>
        </li>
      </React.Fragment>
    )
  }
}

export default ToolbarListItems
