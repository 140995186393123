import React from 'react'
import { renderMarkup } from '../../helpers'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Protezione dei dati</h1>
            <p>
              Secondo il § 13, comma &nbsp;1 della legge telematica (TMG),
              l’offerente di un servizio telematico deve informare all’inizio di
              un processo di utilizzo il tipo, l’entità e lo scopo per il
              rilevamento e l’utilizzo dei dati personali in forma generalmente
              chiara. Il contenuto dell’informazione da parte dell’utente deve
              essere recuperabile in qualsiasi momento. Ogni azienda è obbligata
              ad informare l’utente sui dati personali che vengono rilevati
              oppure utilizzati.
            </p>
            <p>
              Niesmann+Bischoff GmbH da molta importanza alla sicurezza dei dati
              degli utenti e al rispetto delle normative sulla privacy. Il
              rilevamento, l’elaborazione e l’uso dei dati personali sono
              sottoposti a normative di legge, attualmente in vigore&nbsp; e
              sulle normative della privacy dell’Unione Europea. Tutti i dati in
              riferimento alle persone, secondo l’art. 4 n. 1 EU-DSGVO
              (EU-normativa di base sulle privacy) vengono indicate tutte le
              informazioni, che fanno riferimento ad una persona fisica
              identificata o identificabile.
            </p>
            <p>
              <strong>Relazioni personali:</strong>&nbsp;come per esempio –
              nome, indirizzo, email, data di nascita, nazionalità, professione,
              dati sulla salute, religione ecc.,
              <br />
              <strong>circostanze dei fatti:</strong>come per esempio – reddito,
              situazione finanziaria, proprietà ecc.
            </p>
            <p>
              <strong>Dichiarazioni sulla privacy&nbsp;</strong>
              <br />
              Niesmann+Bischoff GmbH garantisce che tutti i dati rilevati,
              elaborati, archiviati e utilizzati vengono raccolti esclusivamente
              per scopi interni.
            </p>
            <p>
              <strong>Trasmissione dei dati a terzi</strong>
              <br />
              Non avviene nessuna trasmissione dei dati a terzi, senza il
              consenso da parte dell’interessato oppure senza base legale.
            </p>
            <p>
              <strong>Trasmissione dei dati – codificazione SSL</strong>
            </p>
            <p>
              Su questo sito web è stato inserito una tecnologia di
              codificazione 256-bit-SSL (AES 256). I dati degli utilizzatori
              vengono trasmessi esclusivamente codificati. Questo sistema
              include l’indirizzo IP dell’utilizzatore.
            </p>
            <p>
              <strong>
                Cookies
                <br />
              </strong>
              Niesmann+Bischoff GmbH utilizza cookies. I cookies sono piccoli
              testi di dati che vengono archiviati localmente nella memoria di
              transito del browser di internet. I cookies permettono di
              riconoscere il browser. I dati vengono utilizzati per aiutare il
              browser a navigare sul sito e per poter utilizzare le funzioni in
              modo più ampio.
              <br />
              Questo sito utilizza browser cookies.
            </p>
            <p>
              <strong>
                Controllo di cookies da parte dell’utente
                <br />
              </strong>
              L’utente può impostare tutti i browser in modo che i cookies
              vengono accettati solo su richiesta. Con l’impostazione si possono
              accettare anche solo cookies per le pagine che si stanno
              visitando. Tutti i browser offrono funzioni che permettono la
              cancellazione selettiva di cookies. L’accettazione di cookies
              generalmente può essere anche interrotta, ma bisogna eventualmente
              accettare che in tal caso ci saranno limitazioni sulla funzione
              nell’utilizzo di questo sito.
            </p>
            <p>
              <strong>
                L’uso di cookies first-party (Google Analytics Cookies)
                <br />
              </strong>
              Il protocollo di Google Analytics Cookies:
            </p>
            <ul>
              <li>
                Trasparenza sull’utente – Google Analytics Cookies registra e
                divide in gruppi i dati degli utilizzatori. Tutte le attività
                durante una visita vengono raccolti. Con l’inserimento di Google
                Analytics Cookies c’è una distinzione tra utilizzatori e
                utilizzatori evidenti.
              </li>
              <li>
                Le attività degli utilizzatori – Google Analytics Cookies
                memorizzano anche i dati riguardanti l’inizio e la fine di una
                visita sul sito web e la quantità delle pagine che un
                utilizzatore ha visionato. Con la chiusura di un browser o in
                caso di inattività prolungata (standard 30 minuti), la sezione
                del visitatore viene chiusa e il cookie segnala la visita come
                chiusa. In aggiunta vengono registrati la data e l’ora della
                prima visita. Inoltre si protocolla il numero totale dei
                visitatori per ogni utilizzatore evidente. Link esterno:{' '}
                <a
                  href="http://www.google.com/analstics/terms.de.html"
                  className="external"
                >
                  http://www.google.com/analstics/terms.de.html
                </a>
              </li>
            </ul>
            <p>
              Il visitatore può impedire la registrazione che avviene attraverso
              i Cookies e l’utilizzo dei dati di riferimento del sito web
              (compreso l’indirizzo IP dell’utilizzatore) da parte di Google e
              l’elaborazione di questi dati attraverso Google, scaricando e
              installando il seguente link nel Browser-Plug-In: Link esterno:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              . Ulteriori informazioni sono disponibili al punto
              “Webanalysedienst Google Analytics / Universal Analytics”.
            </p>
            <p>
              <strong>La durata dei cookies inseriti</strong>
              <br />I cookies vengono gestiti dal server web di questo sito.
              Questo sito usa: cookies transitori/session cookies (per l’uso di
              una sola volta), in questo caso la loro durata termina con la
              chiusura del sito.
            </p>
            <p>
              <strong>
                Cookies persistenti (riconoscimento browser permanente)
                <br />
              </strong>
              Durata della vita: 30 minuti
            </p>
            <p>
              <strong>Disattivare o eliminare i cookies (Opt-Out)</strong>
              <br />
              Ogni browser web offre la possibilità di limitare o di cancellare
              cookies. Ulteriori informazioni in merito sono disponibili sulle
              seguenti pagine del web:
            </p>
            <ul>
              <li>
                <strong>
                  <em>Internet Explorer:</em>
                </strong>
                <br />
                <a
                  href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                  className="external"
                >
                  http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9
                </a>
              </li>
              <li>
                <strong>
                  <em>Firefox:</em>
                </strong>
                <br />
                <a
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirect-locale=en-US&amp;redirectslug=Cookies"
                  className="external"
                >
                  https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirect-locale=en-US&amp;redirectslug=Cookies
                </a>
              </li>
              <li>
                <strong>
                  <em>Google Chrome:</em>
                </strong>
                <br />
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                  className="external"
                >
                  https://support.google.com/chrome/answer/95647?hl=en
                </a>
              </li>
              <li>
                <strong>
                  <em>Safari:</em>
                </strong>
                <br />
                <a
                  href="https://support.apple.com/de-de/HT201265"
                  className="external"
                >
                  https://support.apple.com/de-de/HT201265
                </a>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>
                Servizio analisi Web Google Analytics / Universal Analytics
                <br />
              </strong>
              Niesmann+Bischoff GmbH utilizza Google Analytics, un servizio
              analisi web di Google Inc. (“Google”). Google Analytics usa
              “Cookies” cioè dati di testo, che vengono salvati sul computer
              dell’utente e in questo modo l’analisi può essere effettuata
              sull’utilizzo del sito web. Le informazioni ottenute attraverso i
              cookies sull’utilizzo di questo sito web vengono generalmente
              trasmessi e salvati su un server di Google negli Stati Uniti. In
              caso di attivazione dell’anonimato IP su questo sito web,
              l’indirizzo IP dell’utente di Google percorre una strada più corta
              restando comunque all’interno degli Stati membri dell’Unione
              Europea oppure in un altro Stato con il quale esistono accordi
              contrattuali nell’area economica europea.
            </p>
            <p>
              Solo in casi eccezionali l’indirizzo completo IP viene trasmesso
              al server di Google negli Stati Uniti e lì subisce
              un’abbreviazione. A nome dell’operatore di questo sito web Google
              utilizzerà queste informazioni, per valutare l’uso di questo sito
              web, per creare attraverso le attività del sito web relazioni e
              per poter realizzare ulteriori servizi collegati all’utilizzo del
              sito web e dell’uso di internet verso gli operatore del sito web.
              Nel contesto di Google Analytics l’indirizzo IP trasmesso dal
              browser dell’utente non viene unito con altri dati di Google.
              L’uente può evitare la registrazione dei Cookies attraverso
              un’apposita impostazione del software del browser.
              Niesmann+Bischoff GmbH indica però che in questo caso non tutte le
              funzioni di questo sito web potranno essere utilizzati
              completamente. Inoltre Niesmann+Bischoff GmbH usa i rapporti
              forniti da Google Analytics per la registrazione di
              caratteristiche demografiche ed interessi.
              <br />
              L’utente inoltre può evitare la registrazione dei dati che vengono
              prodotti con i Cookies e i dati di riferimento attraverso l’uso
              del sito web (compreso i dati del vostro indirizzo IP) a Google e
              l’elaborazione di questi dati attraverso Google, se scarica e
              installa il seguente link dal Browser-Plug-In: Link:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?h!=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?h!=de
                <br />
              </a>
              In alternativa al browser-plug-in oppure all’interno dei browser
              per apparecchiature mobili è valido il seguente link, per inserire
              un Opt-Out-Cookie, che evita in futuro la registrazione da parte
              di Google Analytics all’interno di questo sito web (questo
              Opt-Out-Cookie funziona solo con questo browser e solo per questo
              dominio, cancellando i cookies di questo browser, cliccando
              nuovamente sul link): &lt;a href=”javascript:gaOptout()”&gt;Google
              Analytics deaktivieren&lt;/a&gt;
              <br />
              Le indicazioni per l’inserimento di Opt-Out-Cookies si trovano
              sotto il seguente link:&nbsp;
              <a
                href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable"
                className="external"
              >
                https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
              </a>
            </p>
            <p>
              Niesmann+Bischoff GmbH indica che questo sito web di Google
              Analytics usa l’ampliamento „-anonymizeIp()“ e dunque elabora gli
              indirizzi IP solo abbreviati, per escludere un riferimento diretto
              con le persone.
            </p>
            <p>
              <strong>
                <em>Servizio di analisi Web Matomo (prima Piwik)</em>
              </strong>
              <br />
              Su questo sito vengono raccolti e archiviati i dati con lo scopo
              di marketing e di ottimizzazione utilizzando il servizio di
              analisi per il software Matomo (
              <a href="https://matomo.org/" className="external">
                www.matomo.org
              </a>
              ). Con questi dati sotto uno pseudonimo si creano profili di
              utilizzo. I cookies sono piccoli dati di testo che vengono
              archiviati localmente nella memoria di transito del browser di
              internet da parte dell’utente di una pagina. I cookies permettono
              il riconoscimento del browser internet. I dati raccolti dalla
              tecnologia Matomo (compreso il vostro indirizzo IP anonimo) vanno
              trasmessi al server di Niesmann+Bischoff GmbH e memorizzati con lo
              scopo di analizzare l’uso, che serve per ottimizzare le pagine del
              web da parte di Niesmann+Bischoff GmbH. Le informazioni prodotte
              dai cookies nel profilo dell’utente pseudonimo non vengono
              utilizzati per identificare l’utente di questo sito personalmente
              e i dati di riferimento della persona non vengono collegati
              attraverso il portatore dello pseudonimo. Se l’utente non da il
              suo consenso per la memorizzazione e la valutazione di questi dati
              visitando il sito di Niesmann+Bischoff GmbH e in futuro vuole
              opporsi, può evitare l’uso di cookies e dunque alla partecipazione
              del tracking. Per questo motivo l’utente può impostare il suo
              browser in modo che viene informato sull’impostazione di cookies e
              può decidere singolarmente se accettarle oppure escluderle in casi
              specifici o in generale, accettare i cookies. Se non vengono
              accettati, la funzione del sito di Niesmann+Bischoff GmbH potrebbe
              essere limitato.
            </p>
            <p>
              <strong>
                Servizio analisi web con doppio click
                <br />
              </strong>
              Questo sito web usa all’interno delle applicazioni di Google
              Analytics il servizio analitico web con doppio click, che permette
              il riconoscimento del browser da parte dell’utente durante la
              visita di un altro sito web. I dati rilevati vengono valutati solo
              per scopi statistici e in forma anonima. Le informazioni ottenute
              dai Cookies creano informazioni sulla visita del sito web di
              Niesmann+Bischoff GmbH e vengono trasmessi ad un server di Google
              negli Stati Uniti d’America dove i dati vengono salvati.
              L’indirizzo IP viene abbreviato prima della trasmissione entro gli
              Stati membri dell’Unione Europea o in altri Stati contrattuali
              dell’area economica europea attivando l’anonimato per l’IP. Solo
              in casi eccezionali avviene una trasmissione completa
              dell’indirizzo IP al server di Google negli Stati Uniti d’America,
              prima che lì avvenga l’abbreviazione.
              <br />
              All’interno di Google Analytics del browser dell’utente
              l’indirizzo IP anonimo trasmesso non verrà unito con altri dati di
              Google. I cookies del doppio click non ricevono i dati che fanno
              riferimento alle persone. Le informazioni ottenute vengono usate
              per creare i rapporti sulle attività di questo sito web e per
              realizzare altri servizi per l’uso di questo sito web. Se non
              previsto dalla legge, Google trasmette queste informazioni
              eventualmente a terzi oppure a terzi che elaborano questi dati
              perché incaricati da Google.
              <br />
              L’utente ha la possibilità in qualsiasi momento di contradire la
              trasmissione dell’analisi del web in futuro oppure di disattivare
              il doppio click dei Cookies. L’estensione del browser necessaria
              per questo può essere scaricata dal relativo sito web di Google.
              Se l’utente usa più apparecchiature o browser deve eseguire per
              ogni apparecchio o browser l’opt-out. L’utente può usare il
              seguente link:{' '}
              <a
                href="http://google.com/ads/preferences/html/opt-out.html"
                className="external"
              >
                http://google.com/ads/preferences/html/opt-out.html
              </a>
            </p>
            <p>
              <strong>L’uso di Google Maps</strong>
              <br />
              Il sito web di Niesmann+Bischoff GmbH usa Google Maps (API) di
              Google Inc.,1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA (“Google”). Google Maps è un servizio web per la
              rappresentazione di carte (geografiche) interattive per la visione
              informativa geografica. A partire dalla ricerca delle rispettive
              sottopagine che sono collegate con le carte geografiche di Google
              Maps vengono trasmesse le informazioni sull’uso del sito web di
              Niesmann+Bischoff GmbH (per esempio l’indirizzo IP dell’utente) al
              server di Google negli Stati Uniti d’America dove vengono salvati.
              <br />
              Google trasmette eventualmente questi dati a terzi, se previsto
              dalla legge oppure a terzi incaricati da Google per l’elaborazione
              dei dati. Google non metterà l’indirizzo IP dell’utente in
              relazione con altri dati di Google. Nonostante ciò esiste
              tecnicamente la possibilità che Google usi i dati dell’utente
              ottenuti attraverso l’uso di Google Maps per l’identificazione
              dell’utente e la rilevazione dei profili&nbsp; degli utenti oppure
              l’elaborazione e l’uso per scopi sconosciuti e su questi
              Niesmann+Bischoff non ha e non potrà avere nessuna influenza. Le
              condizioni sull’utilizzo di Google possono essere visionate sul
              link:{' '}
              <a
                href="http://www.google.de/intl/de/policies/terms/regional.html"
                className="external"
              >
                http://www.google.de/intl/de/policies/terms/regional.html
              </a>
            </p>
            <p>
              Informazioni dettagliate sulla protezione dei dati nel contesto
              dell’utilizzo di Google Maps si trovano sulla pagina internet di
              Google (“Google Privacy Policy”):&nbsp;
              <a
                href="http://www.google.de/intl/de/policies/privacy/"
                className="external"
              >
                http://www.google.de/intl/de/policies/privacy
              </a>{' '}
              Se l’utente in futuro non è d’accordo con la trasmissione dei suoi
              dati a Google durante l’uso di Google Maps, c’è la possibilità di
              disattivare completamente il servizio web di Google Maps
              disattivando l’uso di JavaScript dal browser dell’utente. In
              questo modo Google Maps e dunque anche le informazioni delle carte
              sulla pagina internet di Niesmann+Bischoff GmbH non potranno
              essere usate.
            </p>
            <p>
              <strong>
                Google AdWords – Conversion-Tracking
                <br />
              </strong>
              Il sito web di Niesmann+Bischoff GmbH usa il programma
              pubblicitario online “Google AdWords” e all’interno di Google
              AdWords anche Conversion-tracking. Il Cookie per il
              Conversion-Tracking viene inserito se un utente clicca su un
              annuncio di Google. I Cookies sono piccoli dati di testo che
              vengono inseriti sul vostro sistema di computer. Questi Cookies
              perdono la loro validità dopo 30 giorni e non servono per
              l’identificazione personale. Se l’utente visita questi siti web e
              il Cookie non è ancora scaduto, Google e Niesmann+Bischoff GmbH
              possono riconoscere che l’utente ha cliccato sull’annuncio ed è
              stato inoltrato a questa pagina. Ogni utente Google AdWords riceve
              un Cookie diverso. I Cookies dunque non possono essere seguiti
              attraverso il sito web degli utenti AdWords.
            </p>
            <p>
              Le informazioni ottentute attraverso l’aiuto di Cookies Conversion
              servono per rilasciare statistiche Conversion per gli utenti
              AdWords che hanno scelto Conversion-Tracking. Niesmann+Bischoff
              GmbH riceve il numero complessivo degli utenti che hanno cliccato
              il loro annuncio e che sono stati dirottati su una pagina che usa
              un Conversion-Tracking-Tag. Niesmann+Bischoff GmbH non riceve
              nessuna informazione con la quale possono essere identificati gli
              utenti personalmente. Se un utente non vuole partecipare al
              Tracking, può disdire quest’utilizzo disattivando le impostazioni
              del Cookie del Google Conversion-Tracking attraverso il suo
              browser internet. L’utente successivamente non verrà registrato
              nelle statistiche del Conversion-Tracking. Ulteriori informazioni
              sulle normative della protezione dei dati di Google sono
              disponibili sotto:{' '}
              <a
                href="http://www.google.de/policies/privacy/"
                className="external"
              >
                http://www.google.de/policies/privacy/
              </a>
            </p>
            <p>
              <strong>
                Retargeting/Remarketing
                <br />
              </strong>
              Niesmann+Bischoff GmbH usa le tecnologie Retargeting di Google
              Inc. (“Google”). Queste danno la possibilità di contattare
              l’utente del sito web di Niesmann+Bischoff GmbH in modo mirato con
              pubblicità personalizzata e di interesse per gli utenti che hanno
              già dimostrato interesse per le offerte di Niesmann+Bischoff.
              L’inserimento di mezzi pubblicitari avviene sulla base di Cookie
              che si basano sulle analisi del comportamento d’utilizzo
              precedenti ma senza registrare dati che fanno riferimento a
              persone. Nel caso della tecnologia retargeting un Cookie viene
              salvato sul computer dell’utente oppure sull’apparecchio mobile
              finale per rilevare i dati anonimi dell’interessato e per poter
              adeguare la pubblicità individualmente con le informazioni
              registrate. Questi Cookies sono piccoli dati di testo che vengono
              registrati sul computer oppure sull’apparecchio mobile finale. Gli
              utenti in questo modo ricevono la pubblicità, che con grande
              probabilità corrisponde agli interessi sul prodotto o sulle
              informazioni. L’utente ha la possibilità di contraddire
              l’impostazione di Cookies che indicano la pubblicità in modo
              permanente, scaricando e installando il seguente link disponibile
              sul browser-plug:&nbsp;
              <a
                href="http://www.google.com/setting/ads/onweb/"
                className="external"
              >
                http://www.google.com/setting/ads/onweb/
              </a>
              <br />
              Ulteriori informazioni e le normative sulla protezione dei dati
              riguardante la pubblicità e Google sono disponibili sotto il
              seguente link:{' '}
              <a href="http://www.google.com/privacy/ads/" className="external">
                http://www.google.com/privacy/ads/
              </a>
            </p>
            <p>
              <strong>
                Utilizzo di Social Media
                <br />
              </strong>
              Sul nostro sito web possono venir utilizzate funzioni di social
              media.
              <br />
              All’apertura di una di queste pagine, è possibile creare una
              connessione con i rispettivi server dei social media. Questi
              server vengono informati che ha visitato il nostro sito internet
              con il suo indirizzo IP. Se doveste inserire dei commenti, dei
              like, twittare, etc. dopo aver effettuato il login nel suo
              rispettivo account, eventualmente il social media potrà mettere in
              relazione la sua visita sul nostro sito web e il suo conto
              d’utente. La informiamo che noi, in veste di gestori dei siti non
              siamo a conoscenza del contenuto dei dati trasmessi e del loro
              utilizzo.
              <br />
              Questi servizi vengono forniti dalle aziende seguenti:
            </p>
            <ul>
              <li>
                <u>Facebook Inc</u>., 1601 S. California Ave, Palo Alto, CA
                94304, USA
              </li>
              <li>
                <u>Google+ Inc</u>., 1600 Amphitheatre Parkway, Mountain View,
                CA 94043, USA
              </li>
              <li>
                <u>Linkedin Inc</u>., 2029 Stierlin Court, Mountain View, CA
                94043, USA
              </li>
              <li>
                <u>Twitter Inc</u>., 1355 Market St., Suite 900, San Francisco,
                CA 94103, USA
              </li>
              <li>
                <u>Xing AG</u>, Dammtorstraße 30, 20354 Amburgo, Germania
              </li>
              <li>
                <u>YouTube LLC</u>, 901 Cherry Ave., San Bruno, CA 94066, USA
              </li>
              <li>
                <u>Kununu GmbH</u>, Wollzeile 1-3 Top 5.1, 1010 Vienna, Austria
              </li>
              <li>
                <u>Vimeo LCC</u>, White Plains, Bundesstaat New York, USA
              </li>
              <li>
                <u>Pinterest Europe Ltd</u>., Palmerston House, 2nd Floor Fenian
                Street, Dublin 2, Ireland
              </li>
              <li>
                <u>Instagram LLC</u>, 1601 Willow Rd, Menlo Park CA 94025, USA
              </li>
              <li>
                <u>SoundCloud Ltd</u>., Rheinsberger Str. 76/77, 10115 Berlino,
                Germania
              </li>
              <li>
                <u>Spotify AB, </u>Birger Jarlsgatan 61, 113 56 Stoccolma,
                Svezia
              </li>
              <li>
                <u>Tumblr, Inc</u>., 35 East 21st St, 10th Floor, New York, NY
                10010, USA
              </li>
              <li>
                <u>Myspace LLC, </u>8391 Beverly Blvd., #349, Los Angeles,
                California 90048, USA.
              </li>
            </ul>
            <p>
              Lo scopo e l’entità del rilevamento dei dati e la rielaborazione
              ed utilizzo dei dati da parte dei gestori, così come i rispettivi
              diritti e le possibilità di impostazione per la protezione dei
              suoi dati personali possono essere consultati sull’informativa
              sulla protezione dei dati del rispettivo gestore:
            </p>
            <ul>
              <li>
                Facebook{' '}
                <a
                  href="https://de-de.facebook.com/privacy/explanation"
                  className="external"
                >
                  https://de-de.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Google{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Linkedin{' '}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  className="external"
                >
                  https://www.linkedin.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Twitter{' '}
                <a
                  href="https://twitter.com/privacy?lang=de"
                  className="external"
                >
                  https://twitter.com/privacy?lang=de
                </a>
              </li>
              <li>
                Xing{' '}
                <a href="https://www.xing.com/privacy" className="external">
                  https://www.xing.com/privacy
                </a>
              </li>
              <li>
                YouTube{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Kununu{' '}
                <a
                  href="https://www.kununu.com/de/info/datenschutz"
                  className="external"
                >
                  https://www.kununu.com/de/info/datenschutz
                </a>
              </li>
              <li>
                Vimeo{' '}
                <a href="https://vimeo.com/privacy" className="external">
                  https://vimeo.com/privacy
                </a>
              </li>
              <li>
                Pinterest{' '}
                <a
                  href="https://policy.pinterest.com/de/privacy-policy"
                  className="external"
                >
                  https://policy.pinterest.com/de/privacy-policy
                </a>
              </li>
              <li>
                Instagram{' '}
                <a
                  href="https://help.instagram.com/155833707900388"
                  className="external"
                >
                  https://help.instagram.com/155833707900388
                </a>
              </li>
              <li>
                SoundCloud{' '}
                <a
                  href="https://soundcloud.com/pages/privacy"
                  className="external"
                >
                  https://soundcloud.com/pages/privacy
                </a>
              </li>
              <li>
                Spotify{' '}
                <a
                  href="https://www.spotify.com/de/legal/privacy-policy/"
                  className="external"
                >
                  https://www.spotify.com/de/legal/privacy-policy/
                </a>
              </li>
              <li>
                Tumblr{' '}
                <a
                  href="https://www.tumblr.com/policy/de/privacy"
                  className="external"
                >
                  https://www.tumblr.com/policy/de/privacy
                </a>
              </li>
              <li>
                Myspace <u>https://myspace.com/pages/privacy</u>
              </li>
            </ul>
            <p>
              Se non vuole che il rispettivo social media metta la sua visita
              sul nostro sito in relazione al suo rispettivo account, prima di
              visitare il nostro sito web deve effettuare il log out dal
              rispettivo servizio.
            </p>
            <p>
              <strong>L’uso di YouTube</strong>
              <br />
              Sul sito di Niesmann+Bischoff GmbH sono impostate le funzioni del
              servizio YouTube per la visualizzazione di video. Queste funzioni
              vengono offerte da YouTube, LLC 901 Cherry Ave. San Bruno, CA
              94066 USA. Ulteriori informazioni si trovano nelle normative sulla
              privacy di YouTube.
            </p>
            <p>
              Per questa funzione si usa il modulo allargato per la privacy, che
              secondo le indicazioni dell’offerente mette un salvataggio sulle
              informazioni dell’utente solo nel momento della riproduzione di
              uno o più video. Se viene attivata la riproduzione di un video
              posto su YouTube, la stessa inserisce cookies, per raccogliere
              informazioni sul comportamento dell’utente. Le indicazioni di
              YouTube in seguito servono tra l’altro a rilevare statistiche sui
              video, per migliorare la facilità nell’uso e per evitare pratiche
              abusive. Indipendentemente dalla riproduzione di un video postato
              con ogni visita sul sito web di Niesmann+Bischoff avviene un
              collegamento con la rete Google „DoubleClick“, che può attivare
              altri processi di elaborazione dati, sui quali Niesmann+Bischoff
              non ha nessuna influenza. Ulteriori dettagli sull’utilizzo di
              cookies presso YouTube sono disponibili nella dichiarazione della
              protezione dei dati di YouTube al link:{' '}
              <a
                href="http://www.youtube.com/t/privacy_at_youtube"
                className="external"
              >
                http://www.youtube.com/t/privacy_at_youtube
              </a>
            </p>
            <p>
              <strong>L’uso di Google Maps</strong>
              <br />
              Il sito di Niesmann+Bischoff GmbH usa Google Maps per la
              raffigurazione di carte e la realizzazione di indicazioni del
              percorso di raggiungimento. Google Maps viene gestita da Google
              Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Con
              l’utilizzo di questo sito, l’utente dichiara di accettare il
              rilevamento, l’elaborazione e l’uso automatico dei dati inseriti
              dall’utente (compreso l’indirizzo IP) da parte di Google, di uno
              dei suoi rappresentanti oppure da terzi offerenti. Le condizioni
              sull’utilizzo per Google Maps si trovano sotto il seguente
              link&nbsp;
              <a
                href="https://www.google.it/intl/it/policies/terms/regional.html"
                className="external"
              >
                https://www.google.it/intl/it/policies/terms/regional.html
                <br />
              </a>
              Tutti i dettagli sulla trasparenza e la possibilità di scelta e le
              normative sulla privacy si trovano nel centro per la privacy di
              google.de,{' '}
              <a
                href="https://www.google.de/intl/de/policies/privacy/?fg=1"
                className="external"
              >
                https://www.google.de/intl/de/policies/privacy/?fg=1
              </a>
            </p>
            <p>
              <strong>La sicurezza delle email</strong>
              <br />
              L’utente da il suo consenso alla comunicazione elettronica, appena
              apre lui stesso il contatto elettronico con Niesmann+Bischoff
              GmbH. L’utente viene informato che le email possono essere lette o
              modificate in modo inosservato attraverso la trasmissione.
              Niesmann+Bischoff GmbH usa un software per il filtraggio di email
              indesiderate (filtro spam). Attraverso il filtro spam le mail
              possono essere respinte se queste attraverso certe caratteristiche
              vengono identificate erroneamente come spam.
            </p>
            <p>
              <strong>Trasmissione dei dati – criptaggio SSL</strong>
              <br />
              Su questo sito viene inserita la tecnica di criptaggio 128-bit-SSL
              (AES 128). I dati dell’utente successivamente vengono trasmessi
              esclusivamente in forma criptata. Questi comprendono anche
              l’indirizzo IP dell’utente.
            </p>
            <p>
              <strong>Diritto sulle informazioni</strong>
              <br />
              Secondo l‘articolo 15 EU-DSGVO l’utente ha il diritto di riceve
              informazioni gratuite attraverso i suoi dati memorizzati ed
              eventualmente ha diritto alla rettifica, al blocco o alla
              cancellazione di questi dati (art. 5, comma 1 d), art. 12&nbsp; e
              art. 17-19 EU-DSGVO). Su richiesta Niesmann+Bischoff GmbH comunica
              all’utente secondo la normativa valida e in forma scritta, quali
              sono i dati dell’utente che si riferiscono alla persona con i
              quali è stato archiviato presso Niesmann+Bischoff GmbH.
              Quest’informazione non è a pagamento.
            </p>
            <p>
              <strong>Diritto d‘opposizione dell’utente</strong>
              <br />
              Se l’utente non è d’accordo con l’uso dei dati attivi per scopi
              interni da parte di Niesmann+Bischoff, ha il diritto secondo
              l’art. 21 comma 2-4 EU-DSGVO di revocare quest’uso e
              l’elaborazione in qualsiasi momento. Inviando un’email a
              info@niesmann-bischoff.de. Nel caso particolare di un blocco dei
              dati previsto dalla legge al posto della cancellazione dei dati
              secondo l’art. 17-19 EU-DSGVO rimane quindi inalterato.
            </p>
            <p>
              <strong>
                Trasmissione dei dati e verbalizzazione per scopi interni e
                statistici del sistema&nbsp;
              </strong>
              <br />
              Con l’accesso al sito, il browser internet dell’utente
              trasmette,&nbsp; per motivi tecnici, automaticamente dati al
              server del sito. Ogni kit di dati è composto da data e ora
              d’acceso, URL delle diverse pagine web, dati recuperati, quantità
              dei dati inviati, tipo e versione del browser, sistema del
              funzionamento e indirizzo IP dell’utente. Un’attribuzione di
              questi dati ad una certa persona non è possibile. I dati
              memorizzati vengono valutati solo internamente ed esclusivamente
              per scopi statistici.
            </p>
            <p>
              <strong>
                La sicurezza attraverso provvedimenti tecnici ed organizzativi
              </strong>
              <br />
              Niesmann+Bischoff GmbH secondo l’art. 32 comma 1 EU-DSGVO utilizza
              per l’elaborazione dei dati dispositivi tecnici ed organizzativi
              per proteggere i dati personali attraverso una casuale oppure
              intenzionale manipolazione, perdita, distruzione oppure l’accesso
              da parte di persone non autorizzate. I provvedimenti in uso
              vengono migliorati continuamente secondo lo sviluppo tecnologico.
            </p>
            <p>
              <strong>Protezione minorile</strong>
              <br />
              Persone che non hanno ancora raggiunto il 16esimo anno di età non
              possono trasmettere dati di riferimento alla persona senza il
              consenso espresso delle autorità parentali. Informazioni personali
              possono essere messe a disposizione di Niesmann+Bischoff GmbH solo
              se non hanno &nbsp;compiuto il 16esimo anno di età ma dispongono
              dell’approvazione esplicita della persona autorizzata
              all’educazione oppure di persone che hanno raggiunto il 16esimo
              anno di età o più vecchie. &nbsp;Queste informazioni secondo la
              dichiarazione sulla protezione dei dati possono essere elaborate.
            </p>
            <strong>Cookies</strong>
            <br />
            {document.getElementById('cookiebotsomething').firstElementChild !=
              null && (
              <div
                dangerouslySetInnerHTML={renderMarkup(
                  document.getElementById('cookiebotsomething')
                    .firstElementChild.innerHTML
                )}
              ></div>
            )}
            <div id="CookieDeclaration" />
            <p>
              <strong>Protezione del marchio</strong>
              <br />
              Ogni dato aziendale e marchio nominato nella presente sono
              proprietà delle rispettive aziende. La nomina di marchi e nomi
              avviene esclusivamente per motivi informativi. Questo colofone e
              la dichiarazione per la protezione della privacy sono state
              eseguite dal Ufficio sulla protezione dei dati tedesco, office
              protezione dei dati Monaco&nbsp;&nbsp;
              <strong>
                www.deutsche-datenschutzkanzlei.de
                <br />
              </strong>
              Stefan Fischerkeller
              <br />
              Tel: +49 7542 / 94921-01
              <br />
              mailto:&nbsp;
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
              <strong>
                <br />
              </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
