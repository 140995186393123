import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { translate } from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'
import { history } from '../../Data/Store'
import { getApiUrl, getLanguage } from '../../config'

var classNames = require('classnames')

class ImportantInfos extends React.Component {
  state = {
    content: {
      important_infos_headline: '',
      important_infos_text: ''
    }
  }

  handleModel = async (event) => {
    event.preventDefault()

    const model = this.context.config.infoOverlay.model

    if (this.context.cart.model !== undefined) {
      if (this.context.cart.model.title !== model.title) {
        await this.context.clearModel()
        await this.context.clearStepsCompleted()
      }
    }
    await this.context.updateModel(model)
    await this.context.addStepsCompleted(1)
    await this.context.updateUser(window.configurator.user)
    await this.props.toggleInfoOverlay()
    history.push('/02-grundriss')
  }

  componentDidUpdate = () => {
    if (this.props.config.infoOverlay_open) {
      document.getElementById('konfigurator__overlay').scrollTo(0, 0)
    }
  }

  componentDidMount = async () => {
    fetch(getApiUrl() + '/data/important-infos?lang=' + getLanguage())
      .then((response) => response.json())
      .then((content) => this.setState({ content }))
  }

  render() {
    return (
      <div
        id="konfigurator__overlay"
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? 'is-active' : ''
        )}
      >
        {process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? (
          <Link
            className="button button--cta konfigurator__overlay__close-view"
            to="/02-grundriss"
            onClick={(e) => this.handleModel(e)}
          >
            <span>{translate('global', 'read-accept-and-continue')}</span>
          </Link>
        ) : (
          ''
        )}

        <div className="page page--plain">
          <div className="text--copy">
            <h1>{this.state.content.important_infos_headline}</h1>

            <div
              className="hinweis-container"
              dangerouslySetInnerHTML={{
                __html: this.state.content.configurator_important_infos_text
              }}
            />

            <Link
              className="button button--cta hinweis-button"
              to="/02-grundriss"
              onClick={(e) => this.handleModel(e)}
            >
              <span>{translate('global', 'read-accept-and-continue')}</span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

ImportantInfos.contextType = AppContext
export default withRouter(ImportantInfos)
