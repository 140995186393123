import React from 'react'

class SVG extends React.Component {
  render() {
    return (
      <span
        className={`icon icon-${this.props.name} ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <svg role="img">
          <use xlinkHref={`#icon-${this.props.name}`}></use>
        </svg>
        <span className="icon-click-target"></span>
      </span>
    )
  }
}

export default SVG
