import React from 'react'
import { renderMarkup } from '../../helpers'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Sekretesskydd</h1>
            <p>
              <strong>Sekretesspolic</strong>
            </p>
            <p>
              Enligt § 13 (1) TMG (tysk telemedialag) måste leverantören av en
              teletjänst redan i början av användningen i förståelig form
              informera sina användare om på vilket sätt, i vilken omfattning
              och för vilket ändamål personrelaterade data samlas in och
              används. Användaren måste när som helst ha möjlighet att ta del av
              dessa uppgifter. Varje företag är skyldigt att informera
              användaren om vilka personrelaterade uppgifter som företaget
              samlar in eller använder.
            </p>
            <p>
              Niesmann+Bischoff GmbH lägger största vikt vid att användarnas
              personliga uppgifter hanteras på säkert sätt och att alla
              lagstadgade bestämmelser om dataskydd följs. Insamling, behandling
              och användning av personrelaterade uppgifter sker enligt
              bestämmelserna för nu gällande lagar och EU:s
              dataskyddsförordning. Personrelaterade uppgifter är enligt § 4 (1)
              GDPR (EU-dataskyddsförordning) alla informationer som avser en
              identifierad eller identifierbar fysisk person.
            </p>
            <p>
              Personliga förhållanden: Till exempel – Namn, adress,
              e-postadress, födelsedatum, medborgarskap, yrke, hälsorelaterade
              data, religion osv.
              <br />
              Sakliga förhållanden: Till exempel – Inkomst, ekonomisk situation,
              egendom osv.
            </p>
            <p>
              <strong>Förklaring av&nbsp;</strong>
              <strong>sekretesspolicy</strong>
              <br />
              Niesmann+Bischoff GmbH garanterar att inkommande personliga data
              endast samlas in, behandlas, lagras och används i samband
              med&nbsp; handläggningen av användarens förfrågningar samt för
              internt bruk.
            </p>
            <p>
              <strong>Dataöverföring till tredje part</strong>
              <br />
              Det sker ingen delning av data till tredje part utan samtycke från
              berörd person eller utan rättslig grund.<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>Dataöverföring – SSL-kryptering</strong>
              <br />
              Vi använder oss på denna webbplats av 256-bit-SSL (AES 256)
              krypteringsteknik. Användarens personliga uppgifter överförs
              uteslutande i krypterad form. Detta omfattar även användarens
              IP-adress.
            </p>
            <p>
              <strong>
                Cookies (kakor)
                <br />
              </strong>
              Niesmann+Bischoff GmbH använder cookies. Cookies är små textfiler
              som lagras lokalt i cachen i användarens webbläsare. Med cookies
              är det möjligt att identifiera webbläsaren. Filerna används för
              att hjälpa webbläsaren att navigera på webbplatsen och att använda
              alla funktioner fullt ut.
            </p>
            <p>Den här webbplatsen använder: Webbläsarcookies</p>
            <p>
              <strong>
                Kontroll av cookies genom användaren
                <br />
              </strong>
              Användaren kan ställa in alla webbläsare så att cookies endast
              accepteras på begäran. Det är också möjligt att ställa in
              webbläsaren så att den bara accepterar cookies på sidor som för
              tillfället är öppna. Alla webbläsare erbjuder funktioner som gör
              det möjligt att radera cookies selektivt. Godkännandet av cookies
              kan även stängas av generellt men kan då eventuellt vara
              förknippat med vissa begränsningar i fråga om webbplatsens
              användarvänlighet.
            </p>
            <p>
              <strong>
                Användning av förstapartscookies (Google Analytics Cookie)
                <br />
              </strong>
              Protokollering av Google Analytics-cookies:
            </p>
            <ul>
              <li>
                <strong>Unika användare</strong> – Google Analytics-cookies
                registrerar och grupperar användardata. Alla aktiviteter under
                ett besök sammanfattas. Genom att sätta cookies från Google
                Analytics skiljer man mellan användare och unika användare.
              </li>
              <li>
                <strong>Användarnas aktiviteter</strong> – Google Analytics
                cookies protokollerar även när ett besök på webbplatsen börjar
                och slutar och hur många sidor som användaren har tittat på. Om
                användaren stänger webbläsaren eller är inaktiv under en längre
                tidsperiod (standard 30 minuter) avslutas användarens session
                och cookien registrerar besöket som avslutat. Dessutom
                registreras datum och tid för det första besöket. Likaså
                protokolleras det totala antalet besök per unik användare.
                Extern länk:{' '}
                <a
                  href="http://www.google.com/analytics/terms/de.html"
                  className="external"
                >
                  http://www.google.com/analytics/terms/de.html
                </a>
              </li>
            </ul>
            <p>
              Användaren kan förhindra insamling av data som genereras av
              cookien och som är relaterad till användningen av webbplatsen
              (inklusive användarens IP-adress) till Google och Googles
              behandling av dessa data genom att hämta och installera följande
              länk i webbläsarens plugin: Extern länk:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              För ytterligare informationer se “Webbanalystjänst Google
              Analytics / Universal Analytics”.
            </p>
            <p>
              <strong>
                Cookies livslängd
                <br />
              </strong>
              Cookies förvaltas av webbservern för denna webbplats. Den här
              webbplatsen använder:
              <br />
              Temporära cookies/sessioncookies (engångsanvändning)
              <br />
              Livslängd: Tills användaren stänger webbplatsen
            </p>
            <p>
              Permanent cookie (permanent webbläsaridentifiering)
              <br />
              Livslängd: 30 minuter
            </p>
            <p>
              <strong>
                Deaktivera eller radera cookies (opt-out)
                <br />
              </strong>
              Varje webbläsare erbjuder möjligheter till att begränsa och radera
              cookies.&nbsp; För ytterligare informationer hänvisar vi till
              följande webbplatser:
              <br />
              <em>– Internet Explorer: </em>
              <a
                href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                className="external"
              >
                <br />
                http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9
                <br />
              </a>
              <em>
                – Firefox:
                <br />
              </em>
              <a
                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                className="external"
              >
                https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies
                <br />
              </a>
              –&nbsp;<em>Google Chrome:</em>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en"
                className="external"
              >
                <br />
                https://support.google.com/chrome/answer/95647?hl=en
                <br />
              </a>
              –&nbsp;
              <em>
                Safari:
                <br />
              </em>
              <a
                href="https://support.apple.com/de-de/HT201265"
                className="external"
              >
                https://support.apple.com/de-de/HT201265
              </a>
            </p>
            <p>
              <strong>
                Webbanalystjänst Google Analytics / Universal Analytics
                <br />
              </strong>
              Niesmann+Bischoff GmbH använder Google Analytics, en
              webbanalystjänst från Google Inc. (“Google”). Google Analytics
              använder “cookies” (kakor), textfiler, som lagras på användarens
              dator och som gör det möjligt att analysera användningen av
              webbplatsen. De informationer som genereras av cookien vid besök
              på den här webbplatsen överförs vanligtvis till en server hos
              Google i USA och lagras där. Om IP-anonymisering är aktiverad på
              den här webbplatsen, förkortar Google emellertid användarens
              IP-adress inom Europeiska unionens medlemsstater eller andra
              länder som är med i avtalet om Europeiska ekonomiska
              samarbetsområdet.
              <br />
              Endast i undantagsfall överförs den kompletta IP-adressen till en
              server hos Google i USA och förkortas där. På uppdrag av
              innehavaren av denna webbplats använder Google dessa uppgifter
              till att utvärdera användningen av webbplatsen, att sammanställa
              rapporter om webbplatsaktiviteter och att förse
              webbplatsinnehavaren med andra tjänster relaterade till
              webbplatsaktivitet och internetanvändning. IP-adressen som
              överförs av Google Analytics från användarwebbläsaren kommer inte
              att sammanföras med andra data från Google. Med hjälp av en
              speciell inställning i webbläsaren kan användaren förhindra att
              cookies lagras.&nbsp; Niesmann+Bischoff GmbH hänvisar emellertid
              till att användaren i så fall eventuellt inte fullt ut kan
              utnyttja samtliga funktioner på webbplatsen.
            </p>
            <p>
              Dessutom använder Niesmann+Bischoff GmbH rapporterna från Google
              Analytics för att få information om demografiska variabler och
              intressen.
              <br />
              Därutöver kan användaren förhindra insamling av data som genereras
              av cookien och som är relaterad till användningen av webbplatsen
              (inkl. IP-adress) till Google samt behandlingen av dessa data
              genom Google genom att hämta följande länk i webbläsarens plugin
              och installera den: Länk:{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                className="external"
              >
                http://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              <br />
              Som alternativ till webbläsarens plugin eller i webbläsare på
              mobila enheter kan följande länk användas, med vilken man kan
              sätta en opt-out-cookie, som därefter förhindrar att Google
              Analytics samlar in data på webbplatsen. (Denna opt-out-cookie
              fungerar bara i den här webbläsaren och endast för denna domän.
              För att radera cookies i den aktuella webbläsaren, klickar man en
              gång till på länken): &lt;a href=”javascript:gaOptout()”&gt;Google
              Analytics deaktivieren&lt;/a&gt;
              <br />
              För information om hur opt-out-cookies integreras hänvisar vi till
              följande länk:&nbsp;
              <a
                href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable"
                className="external"
              >
                https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable
                <br />
              </a>
              Niesmann+Bischoff GmbH informerar om att den här webbplatsen
              använder Google Analytics med tillägget “_anonymizeIp ()”.
              IP-adresser överförs och behandlas därför bara i förkortad form
              för att utesluta att de kan hänföras direkt till enskilda
              personer.
            </p>
            <p>
              <strong>
                Webbanalystjänst Matomo (tidigare Piwik)
                <br />
              </strong>
              Den här webbplatsen använder webbanalysprogrammet Matomo (
              <a href="https://matomo.org/" className="external">
                www.matomo.org
              </a>
              ) för att samla in och lagra data i marknadsförings- och
              optimeringssyfte. Med hjälp av dessa data skapas användarprofiler
              under en pseudonym, för detta ändamål används cookies. Cookies är
              små textfiler som lagras lokalt i cachen i användarens webbläsare.
              Cookies gör det möjligt att känna igen webbläsaren. Uppgifterna
              som samlats in med Matomo-tekniken (inklusive din anonymiserade
              IP-adress) överförs till Niesmann+Bischoff GmbH:s server och
              lagras där för användningsanalys. Därigenom kan Niesmann+Bischoff
              GmbH optimera sin webbplats. Informationen som genereras av
              cookien i den pseudonyma användarprofilen kommer inte att användas
              för att identifiera användaren av denna webbplats personligen och
              kommer inte att sammanföras med personuppgifter om pseudonymens
              bärare. Om användaren inte ger sitt samtycke till att dessa
              informationer från besöket på Niesmann+Bischoff GmbH:s webbplats
              lagras och utvärderas och invänder mot att det sker i framtiden,
              kan användaren förhindra användning av cookies och tracking. Detta
              sker genom att användaren ställer in sin webbläsare så att
              användaren informeras när cookies sätts och i varje enskilt fall
              kan avgöra om han/hon vill ge sitt samtycke resp. utesluta
              accepteringen av cookies i särskilda fall eller generellt.&nbsp;
              Om användaren inte accepterar cookies kan funktionaliteten hos
              Niesmann+Bischoff GmbH:s webbplats försämras.
            </p>
            <p>
              <strong>
                Webbanalystjänst DoubleClick
                <br />
              </strong>
              Den här webbplatsen använder webbanalystjänsten DoubleClick som en
              del av tillämpningen av Google Analytics. DoubleClick gör det
              möjligt att identifiera användarens webbläsare vid besök på andra
              webbplatser.&nbsp; Insamlade uppgifter utvärderas endast för
              statistiska ändamål och i anonymiserad form.&nbsp; De
              informationer som genereras av cookien vid besök på
              Niesmann+Bischoff GmbH:s webbplats överförs vanligtvis till en
              server hos Google i USA och lagras där. Genom aktivering av
              IP-anonymiseringen kortas IP-adressen av innan den överförs inom
              Europeiska unionens medlemsstater eller andra länder som är med i
              avtalet om Europeiska ekonomiska samarbetsområdet.
              <br />
              Endast i undantagsfall överförs den kompletta IP-adressen till en
              server hos Google i USA och förkortas där. IP-adressen som
              genereras av Google Analytics från användarens webbläsare kommer
              inte att sammanföras med andra data från Google
              DoubleClick-cookies innehåller inga personrelevanta uppgifter.
            </p>
            <p>
              Den information som genereras används för att sammanställa
              rapporter om aktiviteterna på denna webbplats och tillhandahålla
              andra tjänster relaterade till användningen av webbplatsen. Om det
              krävs enligt lag kan Google överföra denna information till tredje
              part resp. i den mån tredje part behandlar dessa uppgifter på
              uppdrag av Google.
              <br />
              Användaren har när som helst möjlighet att invända mot framtida
              tracking/webbanalys resp. att deaktivera DoubleClick-cookien. Det
              tillägg till webbläsaren som behövs för detta ändamål kan laddas
              ner på motsvarande webbsida hos Google och installeras.&nbsp; Om
              användaren använder flera enheter eller webbläsare måste
              opt-out-funktionen ställas in på varje enhet resp. på varje
              webbläsare. Användaren kan i detta fall klicka på följande länk:{' '}
              <a
                href="http://google.com/ads/preferences/html/opt-out.html"
                className="external"
              >
                http://google.com/ads/preferences/html/opt-out.html
              </a>
            </p>
            <p>
              <strong>
                Användning av Google Maps
                <br />
              </strong>
              Niesmann+Bischoff GmbH använder på sin webbplats Google Maps (API)
              från Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA (“Google”). Google Maps är en webbtjänst med
              interaktiva kartor som visar geografiska informationer visuellt.
              Redan när man ropar upp de sidor i webbplatsen, där kartan från
              Google Maps är integrerad, överförs informationer om användningen
              av Niesmann+Bischoff GmbH:s webbsida (som exempelvis användarens
              IP-adress) till Googles server i USA och lagras där.
              <br />
              Google kommer i förekommande fall att överföra dessa informationer
              till tredje part, om så krävs enligt lag resp. om tredje part
              behandlar dessa informationer på uppdrag av Google. Google kommer
              inte att koppla ihop användarens IP-adress med andra informationer
              från Google. Det skulle emellertid vara tekniskt möjligt för
              Google att använda användardata som erhållits genom användning av
              Google Maps för att identifiera användarna, att sammanställa
              personlighetsprofiler av användare eller att bearbeta och använda
              dem för främmande ändamål, vilket Niesmann + Bischoff GmbH inte
              har någon inverkan på. Googles användarvillkor tillhandahålls
              under följande länk{' '}
              <a
                href="http://www.google.de/intl/de/policies/terms/regional.html"
                className="external"
              >
                http://www.google.de/intl/de/policies/terms/regional.html
              </a>
              .<br />
              De speciella användarvillkoren för Google Maps hittar du under{' '}
              <a
                href="https://www.google.com/intl/de_US/help/terms_maps.html"
                className="external"
              >
                https://www.google.com/intl/de_US/help/terms_maps.html
              </a>
              .<br />
              Utförliga informationer om dataskydd vid användning av Google Maps
              tillhandahålls på Googles webbplats (“Google Privacy
              Policy”):&nbsp;{' '}
              <a
                href="http://www.google.de/intl/de/policies/privacy/"
                className="external"
              >
                http://www.google.de/intl/de/policies/privacy/
                <br />
              </a>
              Om användaren inte är införstådd med att Google i fortsättningen
              överför data i samband med användningen av Google Maps, kan
              användaren deaktivera webbtjänsten Google Maps helt genom att
              stänga av JavaScript-användningen i sin webbläsare.&nbsp;
              Användaren kan då inte längre använda Google Maps eller de kartor
              som visas på Niesmann+Bischoff GmbH:s webbplats.
            </p>
            <p>
              <strong>
                Google AdWords – Conversion-Tracking
                <br />
              </strong>
              Niesmann+Bischoff GmbH:s webbplats använder
              online-reklamprogrammet “Google AdWords” och inom denna användning
              även conversion tracking. Cookien för conversion tracking sätts
              när en användare klickar på en annons som Google publicerat.&nbsp;
              Cookies är små textfiler som lagras i din dator.&nbsp; Dessa
              cookies förlorar sin giltighet efter 30 dagar och används inte för
              personrelevant identifiering. Om användaren besöker sidor på denna
              webbplats så länge cookien inte har gått ut, kan Google och
              Niesmann+Bischoff GmbH se att användaren har klickat på annonsen
              och ledes vidare till den här sidan. Varje Google AdWord-användare
              får en egen cookie. Det är därmed inte möjligt att följa
              AdWords-användare över flera webbplatser.
              <br />
              Med de informationer som samlas in med hjälp av conversion-cookies
              sammanställs conversion-statistik för de AdWords-användare som
              valt conversion tracking.&nbsp; Niesmann+Bischoff GmbH får veta
              det totala antalet användare som har klickat på Niesmann+Bischoffs
              annons och som leddes vidare till en sida med en conversion
              tracking-tag. Niesmann+Bischoff GmbH erhåller emellertid inga
              informationer som gör det möjligt att identifiera användaren
              personligen. Om användaren inte accepterar tracking är det möjligt
              att invända mot användningen genom att deaktivera cookien för
              Google-conversion tracking under användarinställningarna i
              webbläsaren. Användaren kommer då inte längre att komma med i
              statistiken från conversion tracking.
              <br />
              Ytterligare informationer om Googles dataskyddsbestämmelser:{' '}
              <a
                href="http://www.google.de/policies/privacy/"
                className="external"
              >
                http://www.google.de/policies/privacy/
              </a>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Retargeting/Remarketing
                <br />
              </strong>
              Niesmann+Bischoff GmbH använder retargeting-tekniken från Google
              Inc. (“Google”). Med den tekniken är det möjligt att
              presentera&nbsp; personlig, intressebaserad reklam för de besökare
              på Niesmann+Bischoff GmbH:s webbplats som redan har intresserat
              sig för Niesmann+Bischoff GmbH:s produkter. Placeringen av reklam
              sker efter en cookiebaserad analys av användarens tidigare
              beteende utan att personrelevant data lagras. I de fall där
              retargeting-tekniken används lagras en cookie på användarens dator
              eller mobila enhet för att registrera anonymiserade informationer
              om användarens intressen och anpassa reklamen individuellt. Dessa
              cookies är små textfiler som lagras på din dator eller mobila
              enhet. För användaren presenteras därigenom reklam som med stor
              sannolikhet motsvarar de produkter och informationer som han/hon
              intresserar sig för. Användaren kan blockera cookies för
              annonspreferenser permanent genom att i följande länk ladda ner
              tillhandahållen webbläsar-plugin och installera den:{' '}
              <a
                href="https://www.google.com/settings/ads/onweb/"
                className="external"
              >
                https://www.google.com/settings/ads/onweb/
              </a>
              <br />
              Ytterligare informationer och dataskyddsbestämmelserna angående
              reklam och Google finns i följande länk:{' '}
              <a href="http://www.google.com/privacy/ads/" className="external">
                http://www.google.com/privacy/ads/
              </a>
            </p>
            <p>
              <strong>
                Användande av Social Media
                <br />
              </strong>
              På våra websidor kan det förekomma att funktioner från
              &nbsp;sociala medier används.
              <br />
              Vid öppnandet av dessa sidor förekommer det att en förbindelse
              byggs upp med en socialmedia-server. Dessa informeras om att ni
              med er IP-adress besökt vår internetsida. Om ni gör kommenterar,
              använder likes eller twittrar etc. och är inloggad på ert
              respektive Account, kan det vara möjligt för det aktuella sociala
              mediet att registrera&nbsp; besöket och ert användarkonto. Vi
              hänvisar till att vi som operatörer inte känner till vilken data
              som förmedlas och hur denna används.
              <br />
              Dessa tjänster erbjuds av följande företag:
            </p>
            <ul>
              <li>
                <u>Facebook Inc</u>., 1601 S. California Ave, Palo Alto, CA
                94304, USA
              </li>
              <li>
                <u>Google+ Inc</u>., 1600 Amphitheatre Parkway, Mountain View,
                CA 94043, USA
              </li>
              <li>
                <u>LinkedIn Inc</u>., 2029 Stierlin Court, Mountain View, CA
                94043, USA
              </li>
              <li>
                <u>Twitter Inc</u>., 1355 Market St., Suite 900, San Francisco,
                CA 94103, USA
              </li>
              <li>
                <u>Xing AG</u>, Dammtorstraße 30, 20354 Hamburg, Germany
              </li>
              <li>
                <u>YouTube LLC</u>, 901 Cherry Ave., San Bruno, CA 94066, USA
              </li>
              <li>
                <u>Kununu GmbH</u>, Wollzeile 1-3 Top 5.1, 1010 Wien, Austria
              </li>
              <li>
                <u>Vimeo LCC</u>, White Plains, Bochesstaat New York, USA
              </li>
              <li>
                <u>Pinterest Europe Ltd</u>., Palmerston House, 2nd Floor Fenian
                Street, Dublin 2, Ireland
              </li>
              <li>
                <u>Instagram LLC</u>, 1601 Willow Rd, Menlo Park CA 94025, USA
              </li>
              <li>
                <u>SoundCloud Ltd</u>., Rhensberger Str. 76/77, 10115 Berlin,
                Germany
              </li>
              <li>
                <u>Spotify AB, </u>Birger Jarlsgatan 61, 113 56 Stockholm,
                Sverige
              </li>
              <li>
                <u>Tumblr, Inc</u>., 35 East 21st St, 10th Floor, New York, NY
                10010, USA
              </li>
              <li>
                <u>Myspace LLC, </u>8391 Beverly Blvd., #349, Los Angeles,
                California 90048, USA.
              </li>
            </ul>
            <p>
              Syftet och omfattningen med datainsamling och bearbetning och dess
              användande, besökarens rättigheter och inställningsmöjligheter för
              att skydda personuppgifterna finns i de respektive operatörernas
              dataskydsbestämmelser:
            </p>
            <ul>
              <li>
                Facebook{' '}
                <a
                  href="https://de-de.facebook.com/privacy/explanation"
                  className="external"
                >
                  https://de-de.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Google{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                LinkedIn{' '}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy?_l=de_DE"
                  className="external"
                >
                  https://www.LinkedIn.com/legal/privacy-policy?_l=de_DE
                </a>
              </li>
              <li>
                Twitter{' '}
                <a
                  href="https://twitter.com/privacy?lang=de"
                  className="external"
                >
                  https://twitter.com/privacy?lang=de
                </a>
              </li>
              <li>
                Xing{' '}
                <a href="https://www.xing.com/privacy" className="external">
                  https://www.xing.com/privacy
                </a>
              </li>
              <li>
                YouTube{' '}
                <a
                  href="https://www.google.de/intl/de/policies/privacy/"
                  className="external"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
              <li>
                Kununu{' '}
                <a
                  href="https://www.kununu.com/de/info/datenschutz"
                  className="external"
                >
                  https://www.kununu.com/de/info/datasäkerhet
                </a>
              </li>
              <li>
                Vimeo{' '}
                <a href="https://vimeo.com/privacy" className="external">
                  https://vimeo.com/privacy
                </a>
              </li>
              <li>
                Pinterest{' '}
                <a
                  href="https://policy.pinterest.com/de/privacy-policy"
                  className="external"
                >
                  https://policy.pinterest.com/de/privacy-policy
                </a>
              </li>
              <li>
                Instagram{' '}
                <a
                  href="https://help.instagram.com/155833707900388"
                  className="external"
                >
                  https://help.instagram.com/155833707900388
                </a>
              </li>
              <li>
                SoundCloud{' '}
                <a
                  href="https://soundcloud.com/pages/privacy"
                  className="external"
                >
                  https://SoundCloud.com/pages/privacy
                </a>
              </li>
              <li>
                Spotify{' '}
                <a
                  href="https://www.spotify.com/de/legal/privacy-policy/"
                  className="external"
                >
                  https://www.spotify.com/de/legal/privacy-policy/
                </a>
              </li>
              <li>
                Tumblr{' '}
                <a
                  href="https://www.tumblr.com/policy/de/privacy"
                  className="external"
                >
                  https://www.tumblr.com/policy/de/privacy
                </a>
              </li>
              <li>
                Myspace <u>https://myspace.com/pages/privacy</u>
              </li>
            </ul>
            <p>
              Om ni inte vill att det aktuella sociala mediet skall registrera
              era besök på våra sidor eller allokera till ett visst Account,
              skall ni innan ni besöker våra websidor logga ut er från den
              respektive tjänsten.
            </p>
            <p>
              <strong>
                Användning av YouTube
                <br />
              </strong>
              Niesmann+Bischoff GmbH:s webbplats innehåller funktioner från
              YouTube-tjänsten som gör det möjligt att visa och spela upp
              videoklipps. Dessa funktioner erbjuds av YouTube, LLC 901, Cherry
              Ave. San Bruno, CA 94066 USA. För mer information se YouTube:s
              dataskyddsdirektiv.
              <br />
              Här gäller det s.k. avancerade sekretessläget, där
              användarinformation enligt leverantören endast lagras om
              videon/videoklippen spelas upp.&nbsp; När uppspelningen av
              inbäddade Youtube-videor startas, använder Youtube cookies för att
              samla in informationer om användarens beteende. Enligt Youtube:s
              information är dessa uppgifter bland annat till för att ta fram
              videostatistik, förbättra användarvänlighet och förebygga
              missbruk. Oavsett om en inbäddad videoklipp spelas upp eller inte,
              ansluts Niesmann+Bischoff GmbH:s webbplats vid varje besök till
              Googles nätverk “DoubleClick”, vilket kan utlösa ytterligare
              databehandling som Niesmann+Bischoff GmbH inte har någon möjlighet
              att påverka.
              <br />
              För mer informationer om användningen av cookies hos YouTube
              hänvisas användaren till YouTubes sekretesspolicy:{' '}
              <a
                href="http://www.youtube.com/t/privacy_at_youtube"
                className="external"
              >
                http://www.youtube.com/t/privacy_at_youtube
              </a>
            </p>
            <p>
              <strong>
                E-postsäkerhet
                <br />
              </strong>
              Användaren godkänner elektronisk kommunikation i och med att
              han/hon själv inleder den elektroniska kontakten med
              Niesmann+Bischoff GmbH.&nbsp; Användaren informeras om att
              e-postmeddelanden kan läsas eller ändras obemärkt och av obehöriga
              under överföringen. Niesmann+Bischoff GmbH använder ett program
              som filtrerar oönskade e-postmeddelanden (spamfilter). Med hjälp
              av speciella funktioner kan spamfiltret avvisa e-post som
              felaktigt identifierats som skräppost.<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Upplysningsrätt
                <br />
              </strong>
              Enligt § 15 GDPR har användaren rätt att kräva avgiftsfritt utdrag
              över lagrade data samt i förekommande fall även kräva att dessa
              informationer rättas, blockeras eller raderas (§ 5, (1) d), e), §
              12 samt §§ 17-19 (GDPR). På begäran informerar Niesmann+Bischoff
              GmbH enligt gällande lag och i skriftlig form användaren vilka
              personrelevanta uppgifter som Niesmann+Bischoff GmbH lagrar om
              användaren. Detta utdrag är kostnadsfritt.<strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                Användarens invändningsrätt
                <br />
              </strong>
              I den mån användaren inte godkänner att Niesmann+Bischoff GmbH
              aktivt använder data för interna ändamål har användaren enligt §
              21 (2-4) GDPR när som helst rätt att invända mot denna användning
              och behandling. För detta ändamål räcker det att användaren
              skickar ett e-postmeddelande till{' '}
              <a
                href="mailto:pressestelle@niesmann-bischoff.com"
                className="local"
              >
                pressestelle@niesmann-bischoff.com
              </a>
              . Det speciella fallet med lagligt föreskriven datablockering
              istället för radering enligt §§ 17-19 GDPR berörs i så fall inte.
            </p>
            <p>
              <strong>&nbsp;</strong>
              <strong>
                Dataöverföring och -protokollering för systeminternt och
                statistiskt bruk
                <br />
              </strong>
              Användarens webbläsare sänder av tekniska skäl automatiskt data
              till webbservern så fort användaren går in på webbplatsen.&nbsp;
              Varje post består av datum och tid för åtkomsten, webbadressen
              till den refererande webbplatsen, filen som hämtats, mängden data
              som skickats, webbläsartypen och versionen, operativsystemet och
              användarens IP-adress. Det är inte möjligt att koppla en viss
              person till dessa uppgifter. Lagrade data kommer endast att
              utvärderas internt och för statistiska ändamål.
            </p>
            <p>
              <strong>
                Säkerhet genom tekniska och organisatoriska åtgärder
                <br />
              </strong>
              Enligt § 32 (1) GDPR använder Niesmann + Bischoff GmbH sig av
              tekniska och organisatoriska åtgärder för att uppnå största
              möjliga säkerhet vid databehandlingen och för att skydda
              personliga data genom oavsiktlig eller avsiktlig manipulation,
              förlust, förstörelse eller åtkomst genom obehöriga. De
              säkerhetsåtgärder som används förbättras kontinuerligt i takt med
              den tekniska utvecklingen.
            </p>
            <p>
              <strong>
                Skydd för minderåriga
                <br />
              </strong>
              Personer som är under 16 år får inte lämna ut personuppgifter till
              Niesmann+Bischoff GmbH utan vårdnadshavarens samtycke. Personer
              som ännu inte fyllt 16 år får endast lämna ut personliga uppgifter
              till Niesmann+Bischoff GmbH om personen i fråga har uttryckligt
              samtycke från vårdnadshavaren att göra detta eller om personerna
              är 16 år eller äldre. Dessa uppgifter behandlas i enlighet med
              denna sekretesspolicy.
            </p>
            <p>
              <strong>
                Varumärkesskydd
                <br />
              </strong>
              Alla här nämnda företagsnamn eller varumärken är respektive
              företags egendom. Omnämningen av varumärken eller namn sker endast
              i rent informativt syfte.
            </p>
            <strong>Cookies</strong>
            <br />
            {(document.getElementById('cookiebotsomething').firstElementChild != null) &&
              <div dangerouslySetInnerHTML={renderMarkup(document.getElementById('cookiebotsomething').firstElementChild.innerHTML)}></div>
            }
            <div id="CookieDeclaration" />
            <p>
              <strong>
                Denna text och förklaringen av vår sekretesspolicy har utfärdats
                av:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de/"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
                <br />
              </a>
              Stefan Fischerkeller
              <br />
              Tel: +49 7542 / 94921-01
              <br />
              mailto:&nbsp;
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
