import React from 'react'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Colofone</h1>
            <p>
              Colofone combinato (Identificazione offerente) secondo la legge
              per i media televisiva (TMG), contratto statale per radiofonia,
              media televisiva (RStV) e informazioni secondo il decreto per i
              servizi e gli obblighi informativi (DL-InfoV) basati sulle
              normative dell’Unione Europea per i servizi (RL 2006/123/EG della
              gazzetta ufficiale EU2006, L376/36 „DRLR“)
            </p>
            <p>
              L’offerente responsabile per il sito commerciale e per l’uso
              commerciale del § 5 della legge dei media televisivi in funzione
              della legge centrale sull’unificazione della comunicazione
              elettronica commerciale ElGVG e della legge che regola le
              condizioni base per i servizi informativi e di comunicazione
              (IuKDG), è Niesmann+Bischoff GmbH, rappresentata
              dall’amministratore delegato Hubert Brandl e dal procuratore Josef
              Henrichs.
            </p>
            <p>
              Informazioni secondo § 2 comma 1 del regolamento per gli obblighi
              dei servizi e le informazioni (DL-InfoV).
            </p>
            <p>Questa presenza su internet è un offerta informativa di</p>
            <p>
              <strong>
                Niesmann+Bischoff GmbH
                <br />
              </strong>
              Clou-Straße 1<br />
              D-56751 Polch
              <br />
              E-Mail:{' '}
              <a href="mailto:info@niesmann-bischoff.com" className="local">
                info@niesmann-bischoff.com
                <br />
              </a>
              Web:{' '}
              <a href="http://www.niesmann-bischoff.com" className="local">
                www.niesmann-bischoff.com
                <br />
              </a>
              Telefono: +49 2654 933-0
              <br />
              Fax: +49 2654 933-100
            </p>
            <p>
              Sede di Niesmann+Bischoff GmbH: D-56751 Polch
              <br />
              Ufficio giudiziario di prima istanza Mayen, HRB 23440
              <br />
              Forma giuridica: Società con responsabilità limitata (GmbH)
            </p>
            <p>
              Diritto applicabile: Diritto della Repubblica Federale Tedesca
              (BRD)
            </p>
            <p>
              Ufficio delle imposte
              <br />
              Mayen, Partita Iva: DE 148 734 460
            </p>
            <p>
              Amministratori delegati autorizzati alla rappresentanza: Hubert
              Brandl, procuratore Josef Henrichs
            </p>
            <p>
              <strong>Competente Camera dell’Industria e del Commercio</strong>
              <br />
              Camera dell’Industria e del Commercio di Coblenza
              <br />
              Schlossstraße 8, D-56068 Coblenza
              <br />
              Telefono: +49 261 106-0
              <br />
              Fax: +49 261 106-234
              <br />
              E-Mail:{' '}
              <a href="mailto:info@koblenz.ihk.de" className="local">
                info@koblenz.ihk.de
                <br />
              </a>
              Sito:{' '}
              <a href="http://www.ihk-koblenz.de" className="external">
                www.ihk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Competente Camera dell’Artigianato
                <br />
              </strong>
              Camera dell’Artigianato di Coblenza
              <br />
              Friedrich-Ebert-Ring 33, D-56068 Coblenza
              <br />
              Telefono: +49 261 398-0
              <br />
              Fax: +49 261 398-398
              <br />
              E-Mail:{' '}
              <a href="mailto:hwk@hwk-koblenz.de" className="local">
                hwk@hwk-koblenz.de
              </a>
              <br />
              Sito:{' '}
              <a href="https://hwk-koblenz.de" className="external">
                https://hwk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Informazioni per l’assegnazione delle controversie online
                secondo l‘art. 14 paragrafo 1 ODR-VO:
              </strong>
              <strong>
                <br />
              </strong>
              La commissione dell’Unione Europea mette a disposizione per
              l’assegnazione delle controversie (OS) una piattaforma sotto il
              link{' '}
              <a href="http://ec.eu-ropa.eu/consumers/odr" className="external">
                http://ec.eu-ropa.eu/consumers/odr
              </a>
              . La piattaforma OS serve come punto di contatto per il
              componimento extragiudiziale per controversie che riguardano gli
              impegni contrattuali che possono verificarsi in caso di contratti
              d’acquisto online.
            </p>
            <p>
              Noi non siamo obbligati ne disposti a partecipare ai procedimenti
              in caso di risoluzione di controversie davanti ad un ente per la
              risoluzione di controversie per i consumatori.
            </p>
            <p>
              <strong>
                Responsabile per gli articoli editoriali giornalistici secondo §
                55 comma 2 del contratto di stato per radiofonia e media
                televisiva (RStV):
              </strong>
              <br />
              Philipp Komers (direttore alle vendite per la Germania, l’Austria
              e la Svizzera)
              <br />
              <a
                href="mailto:philipp.komers@niesmann-bischoff.de"
                className="local"
              >
                philipp.komers@niesmann-bischoff.de
              </a>
              <br />
              Clou-Straße 1, D-56751 Polch
            </p>
            <p>
              <strong>
                Responsabile esterno per la protezione dei dati secondo
                l’articolo 37 EU-DSGVO i. V. m. § 4 f comma 1 S. 1 della legge
                federale per la protezione dei dati (BDSG)
              </strong>
              <br />
              Stefan Fischerkeller
              <br />
              Ufficio tedesco per la protezione dei dati
            </p>
            <p>
              Ufficio Bodensee
              <br />
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
                <br />
              </a>
              Contatti del responsabile della protezione dei dati via E-Mail:
              <br />
              <a
                href="mailto:fischerkeller@deutsche-datenschutzkanzlei.de"
                className="local"
              >
                fischerkeller@deutsche-datenschutzkanzlei.de
                <br />
              </a>
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
              <br />
              oppure attraverso il sito della protezione dei dati&nbsp;
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              <strong>Assistenza tecnica per questo sito web</strong>
              <br />
              nbsp GmbH, Sig. mario Kandler, Küstriner Straße 14, D-94315
              Straubing
              <br />
              Telefono: +49 9421 78470, E-Mail:{' '}
              <a href="mailto:info@nbsp.de" className="local">
                info@nbsp.de
              </a>
            </p>
            <p>
              <strong>
                Concezione di questo sito web
                <br />
              </strong>
              3st kommunikation GmbH, Sig. Florian Heine, Taunusstraße 56-61,
              D-55118 Mainz
              <br />
              Telefono: +49 6131 49961-0, E-Mail:{' '}
              <a href="mailto:fheine@3st.de" className="local">
                fheine@3st.de
              </a>
            </p>
            <p>
              <strong>
                Caratteristiche dei servizi di Niesmann+Bischoff GmbH:{' '}
              </strong>
              Produzione e commercio di autocaravan, caravan e unità abitative e
              accessori.
            </p>
            <p>
              <strong>
                Indicazioni dei prezzi secondo § 4, regolamento degli obblighi
                per i servizi e le informazioni (DL-InfoV) e il regolamento
                sulle indicazioni del prezzo (PAngV)
                <br />
              </strong>
              Le informazioni riguardo i prezzi sono in visione sul sito web di
              Niesmann+Bischoff GmbH.
              <br />
              Niesmann+Bischoff GmbH su richiesta, rilascia volentieri un
              preventivo.
            </p>
            <p>
              <strong>
                Responsabilità
                <br />
              </strong>
              Niesmann+Bischoff GmbH si riserva il diritto e secondo la propria
              discrezione e senza prendersi carico di nessuna responsabilità, di
              modificare completamente o in parte o chiudere l’operato di questo
              sito web in qualsiasi momento e senza preavviso. Con
              l’impostazione di un link verso siti web estranei (“iperlink”)
              Niesmann+Bischoff non fa proprie queste pagine ne il loro
              contenuto.&nbsp; Niesmann+Bischoff GmbH inoltre non è responsabile
              della disponibilità di questi siti web o dei loro contenuti.
            </p>
            <p>
              <strong>© Copyright</strong>
              <br />
              Ci riserviamo tutti i diritti. I testi, le immagini e i grafici e
              la loro disposizione sul sito web sono sottoposti alla protezione
              del diritto d’autore e ad altre leggi sulla protezione. Il
              contenuto di questo sito web per motivi commerciali non può essere
              copiato, divulgato, modificato o avere un accesso da parte di
              terzi.
            </p>
            <p>
              <strong>© Copyright, fonti delle immagini: </strong>
              Niesmann+Bischoff GmbH
            </p>
            <p>
              Indicazioni: Legge generale per l’uguaglianza (AGG)
              <br />
              Per motivi di lettura facilitata si rinuncia alla differenziazione
              specifica sui sessi. I rispettivi termini nel senso
              dell’uguaglianza sono validi per entrambi i sessi.
            </p>
            <p>
              Questo colofone e la dichiarazione per la protezione dei dati sono
              stati rilasciati dall’Ufficio Tedesco per la Protezione dei Dati –
              ufficio di Monaco (Germania)-
              <br />
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
