import React from 'react'
import Loading from './../Loading/Loading'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import ChapterListView from './../_Common/ChapterListView'
import {
  markItemsAsSelected,
  redirectToStart,
  addStandardEquipmentToCart
} from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Technik extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      technik: [],
      infobox: [],
      footnote: []
    }
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const technik = this.state.technik

    technik.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ technik: technik })
  }

  findParentAndIndexForItem = (item) => {
    let found = false
    let parent_index = 0

    if (this.props.items) {
      this.props.items.forEach((chassi, index) => {
        if (!found) {
          parent_index = index
        }
        chassi.items.forEach((chassi_item) => {
          if (chassi_item.code === item.code) {
            if (!found) {
              found = true
            }
          }
        })
      })
    }
    return parent_index
  }

  componentDidMount() {
    this.context.updateCurrentNav(8)
    this.setState({ isLoading: true })
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/technik?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const new_technik = data.items ? data.items : []
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : []
          this.setState({
            technik: new_technik,
            infobox,
            footnote,
            isLoading: false
          })

          let headings = []
          this.state.technik.map((item, i) => {
            return headings.push(item.title)
          })

          if (headings.length > 1) {
            this.context.updateJumpmarks(headings)
          }

          // if user has already selected items
          if (
            typeof this.context.cart.technik !== 'undefined' &&
            Object.keys(this.context.cart.technik).length !== 0
          ) {
            this.setState({
              technik: markItemsAsSelected(
                this.state.technik,
                this.context.cart,
                'technik'
              )
            })
          } else {
            this.context.addStepsCompleted(8)
          }

          this.filterAlreadySelectedItems()
        })
        .then(async () => {
          // 2021-07-20 Hr. Kaiser: Für alle Sprachen: Alle Serienausstattungen mit Betrag 0,- sollen auch ins PDF
          const technik = await addStandardEquipmentToCart(
            this.context,
            this.state.technik,
            'technik'
          )
          this.setState({ technik })
        })
    } catch (error) {
      redirectToStart()
    }
  }

  componentWillUnmount() {
    this.context.updateJumpmarks([])
  }

  render() {
    const { technik, isLoading } = { ...this.state }

    if (isLoading) {
      return <Loading></Loading>
    }

    return (
      <div>
        <ChapterListView items={technik} chapter="technik"></ChapterListView>
        <Infobox content={this.state.infobox} />
        {this.state.footnote.length > 0 ? (
          <Footnote footnote={this.state.footnote} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

Technik.contextType = AppContext
export default Technik
