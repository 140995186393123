import React from 'react'
import Loading from './../Loading/Loading'
import ConflictOverlay from './../Overlay/ConflictOverlay'
import ItemRow from './../ItemRow/ItemRow'
import {
  slugify,
  filterOptionsAlreadyInCart,
  isAtLeastOneOptionInCart,
  filterOptionsNotInCart,
  doHaveInclusionsExclusions,
  doHaveExclusionsDependencies,
  doHaveOptionsExclusions,
  doHaveOptionsInclusions,
  doHaveOptionsOptions,
  recalculatePricesAndWeights,
  loadExtendedAttributes,
  findSelectedPackagesByCode,
  findtPackagesByDetailItems,
  saveToCart,
  deleteInCart,
  // getActionConditions,
  // getCartCodes,
  markItemsAsSelected
} from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'
import MaxWeightOverlay from '../Overlay/MaxWeightOverlay'

class ChapterListView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      items: [],
      conflictItem: null,
      conflictingItem: null,
      conflictAction: 'del',
      attributes: {}
    }
  }

  resetConflictOverlayState = () => {
    this.setState({
      conflictItem: null,
      additionalInclusions: [],
      additionalItems: [],
      conflictingItem: null,
      conflictingItems: [],
      conflictingPackages: [],
      exclusions: [],
      optionsExclusions: [],
      optionsExcludedPackages: [],
      dependenciesForExclusions: [],
      exclusionInCombonation: []
    })
  }

  closeConflictOverlay = () => {
    this.setState(
      {
        items: markItemsAsSelected(
          this.props.items,
          this.context.cart,
          this.props.chapter
        )
      },
      () => {
        this.context.closeConflictOverlay()
        this.filterAlreadySelectedItems()
      }
    )
  }

  closeMaxWeightOverlay = () => {
    this.context.maxWeightOverlay(false)
  }

  addXClusions = async () => {
    if (this.state.attributes.xExclusions) {
      for (const item of this.state.attributes.xExclusions) {
        const new_cart = await deleteInCart(this.context.cart, item)
        await this.context.updateCart(new_cart)
      }
    }

    if (this.state.attributes.xInclusions) {
      for (const item of this.state.attributes.xInclusions) {
        if (item.tab === 'exterieur') {
          const new_cart = await saveToCart(
            item,
            'exterieur',
            this.context.cart
          )
          await this.context.updateCart(new_cart)
        }
      }
    }

    const new_cart = await recalculatePricesAndWeights(this.context.cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {} })

    return
  }

  removeXClusions = async (attributes = this.state.attributes) => {
    let new_cart = this.context.cart

    if (attributes.xInclusions) {
      for (const item of attributes.xInclusions) {
        new_cart = await deleteInCart(new_cart, item)
      }
    }

    if (attributes.xExclusions) {
      for (const item of attributes.xExclusions) {
        if (item.tab === 'exterieur') {
          new_cart = await saveToCart(item, 'exterieur', new_cart)
        }
      }
    }

    new_cart = await recalculatePricesAndWeights(new_cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {}, automaticHiddenRemoveAttributes: {} })

    return
  }

  addConflictingPackage = async (overlayProps) => {
    await this.addXClusions()
    const additionalItems = overlayProps.additionalItems
    const item = overlayProps.item
    const itemsToRemove = overlayProps.conflictingItems
      ? overlayProps.conflictingItems
      : []
    const exclusions = overlayProps.exclusions ? overlayProps.exclusions : []
    const optionsExclusions = overlayProps.optionsExclusions
      ? overlayProps.optionsExclusions
      : []

    if (
      (overlayProps.action === 'add' || overlayProps.action === 'addexl') &&
      additionalItems
    ) {
      additionalItems.forEach((item) => {
        if (item.tab === 'exterieur') {
          const new_cart = saveToCart(item, 'exterieur', this.context.cart)
          this.context.updateCart(new_cart)
        } else if (item.tab === 'packages') {
          const new_cart = saveToCart(item, 'packages', this.context.cart)
          this.context.updateCart(new_cart)
          this.closeConflictOverlay()
          return
        } else {
          const parent_index = this.findParentAndIndexForItem(item)
          this.addToChapter(parent_index, item)
        }
      })
    }

    if (
      (overlayProps.action === 'add' || overlayProps.action === 'addexl') &&
      item
    ) {
      let parent_index = this.findParentAndIndexForItem(item)
      this.addToChapter(parent_index, item)
    }

    if (exclusions) {
      exclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (optionsExclusions) {
      optionsExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (overlayProps.action === 'addexl' && itemsToRemove) {
      itemsToRemove.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    this.closeConflictOverlay()
  }

  removeExclusionInCombonation = async (item) => {
    this.closeConflictOverlay()
    const parent_index = await this.findParentAndIndexForItem(
      this.state.conflictItem
    )
    await this.addToChapter(parent_index, this.state.conflictItem)
    await this.removeItem(item)
  }

  removeConflictingPackage = async (overlayProps) => {
    const packagesToRemove = overlayProps.conflictingPackages
    const packageToRemove = overlayProps.conflictingItem
    const itemsToRemove = overlayProps.conflictingItems
    const item = overlayProps.item

    const packages = this.context.cart.packages

    const dependenciesForExclusions = overlayProps.dependenciesForExclusions
      ? overlayProps.dependenciesForExclusions
      : []

    if (overlayProps.action === 'del' && item) {
      this.removeFromChapter(item)
    } else if (overlayProps.action === 'exl' && item) {
      const parent_index = this.findParentAndIndexForItem(item)
      this.addToChapter(parent_index, item)
    }

    if (itemsToRemove) {
      itemsToRemove.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    if (packageToRemove) {
      const p_i_p_remove = this.findParentAndIndexForPackage(packageToRemove)
      const packages = this.context.cart.packages
      packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      this.context.updatePackages(packages)
    }

    if (packagesToRemove) {
      packagesToRemove.forEach((iTr) => {
        let p_i_p_remove = this.findParentAndIndexForPackage(iTr)
        packages[p_i_p_remove.parent_index].items.splice(p_i_p_remove.index, 1)
      })

      this.context.updatePackages(packages)
    }

    if (dependenciesForExclusions) {
      dependenciesForExclusions.map((iTr) => {
        return this.removeFromChapter(iTr)
      })
    }

    const attributes =
      this.state.automaticHiddenRemoveAttributes &&
      Object.getPrototypeOf(this.state.automaticHiddenRemoveAttributes) ===
        Object.prototype &&
      Object.keys(this.state.automaticHiddenRemoveAttributes).length > 0
        ? this.state.automaticHiddenRemoveAttributes
        : this.state.attributes

    await this.removeXClusions(attributes)

    this.closeConflictOverlay()
  }

  addItemWithOption = (overlayProps, option) => {
    let atIndex = 0
    const parent_index = this.findParentAndIndexForItem(overlayProps.item)
    this.addToChapter(parent_index, overlayProps.item)

    const additionalInclusions = this.state.additionalInclusions
    if (additionalInclusions.length > 0) {
      additionalInclusions.map((item) => {
        atIndex = this.findParentAndIndexForItem(item)
        if (item.tab === 'packages') {
          const new_cart = saveToCart(
            item,
            'packages',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'chassis') {
          const new_cart = saveToCart(
            item,
            'chassis',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'technology') {
          const new_cart = saveToCart(
            item,
            'technik',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'structure') {
          const new_cart = saveToCart(
            item,
            'aufbau',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'exterieur') {
          const new_cart = saveToCart(
            item,
            'exterieur',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        }
        return ''
      })
    }

    atIndex = this.findParentAndIndexForItem(option)
    if (option.tab === 'packages') {
      const new_cart = saveToCart(
        option,
        'packages',
        this.context.cart,
        atIndex
      )
      this.context.updateCart(new_cart)
    } else if (option.tab === 'chassis') {
      const new_cart = saveToCart(option, 'chassis', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'technology') {
      const new_cart = saveToCart(option, 'technik', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'structure') {
      const new_cart = saveToCart(option, 'aufbau', this.context.cart, atIndex)
      this.context.updateCart(new_cart)
    } else if (option.tab === 'exterieur') {
      const new_cart = saveToCart(
        option,
        'exterieur',
        this.context.cart,
        atIndex
      )
      this.context.updateCart(new_cart)
    }

    // Exclusionen der gewaehlten Option loeschen
    if (option.exclusionsInCart) {
      option.exclusionsInCart.map((iTr) => {
        return deleteInCart(this.context.cart, iTr)
      })
    }

    // Inclusionen der gewaehlten Option hinzufügen
    if (option.inclusionsNotInCart) {
      option.inclusionsNotInCart.forEach((item) => {
        atIndex = this.findParentAndIndexForItem(item)
        if (item.tab === 'packages') {
          const new_cart = saveToCart(
            item,
            'packages',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (option.tab === 'chassis') {
          const new_cart = saveToCart(
            option,
            'chassis',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'technology') {
          const new_cart = saveToCart(
            item,
            'technik',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'structure') {
          const new_cart = saveToCart(
            item,
            'aufbau',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        } else if (item.tab === 'exterieur') {
          const new_cart = saveToCart(
            item,
            'exterieur',
            this.context.cart,
            atIndex
          )
          this.context.updateCart(new_cart)
        }
      })
    }

    if (overlayProps.optionsExclusions) {
      overlayProps.optionsExclusions.map((iTr) => {
        return deleteInCart(this.context.cart, iTr)
      })
    }

    if (overlayProps.optionsExcludedPackages) {
      overlayProps.optionsExcludedPackages.map((iTr) => {
        return deleteInCart(this.context.cart, iTr)
      })
    }

    this.closeConflictOverlay()
  }

  findParentAndIndexForItem = (item) => {
    let found = false
    let parent_index = 0

    if (this.props.items) {
      this.props.items.forEach((chassi, index) => {
        if (!found) {
          parent_index = index
        }
        chassi.items.forEach((chassi_item) => {
          if (chassi_item.code === item.code) {
            if (!found) {
              found = true
            }
          }
        })
      })
    }
    return parent_index
  }

  findParentAndIndexForPackage = (item) => {
    let p_i = { parent_index: 0, index: 0 }

    if (this.context.cart) {
      this.context.cart.packages.forEach((packages, index) => {
        p_i.parent_index = index
        packages.items.forEach((package_item, paket_index) => {
          if (package_item.code === item.code) {
            p_i.index = paket_index
          }
        })
      })
    }
    return p_i
  }

  addToChapter = async (parent_index, item, chapter = this.props.chapter) => {
    const new_cart = saveToCart(
      item,
      chapter,
      this.context.cart,
      parent_index,
      item.chapterTitle
    )
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: markItemsAsSelected(
          this.props.items,
          new_cart,
          this.props.chapter
        )
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  removeFromChapter = async (item) => {
    const new_cart = deleteInCart(this.context.cart, item)
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: markItemsAsSelected(
          this.props.items,
          new_cart,
          this.props.chapter
        )
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  checkConflicts = async (selectedItem) => {
    let please_add = true

    const attributes = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    // Exception for language: NO, FI: Adding package version of item needs Package - Package includes Clou Line Design (exchange price version with 0 EUR version)
    let specialAttributes = {}
    if (attributes.inclusions) {
      attributes.inclusions.map((item, key) => {
        if (item.xInclusions || item.xExclusions) {
          return (specialAttributes = item)
        }
        return ''
      })
    }
    this.setState({ attributes: specialAttributes })

    let automaticHiddenRemoveAttributes = {}
    if (attributes.exclusions) {
      attributes.exclusions.map((item) => {
        if (item.xInclusions || item.xExclusions) {
          return (automaticHiddenRemoveAttributes = item)
        }
        return ''
      })
    }
    this.setState({ automaticHiddenRemoveAttributes })

    // Sonderausstattung ist nicht mit einer anderen Sonderausstattung kombinierbar
    const exclusions = await filterOptionsAlreadyInCart(
      this.context.cart,
      attributes.exclusions
    )

    if (exclusions) {
      const packagesExclusions = findSelectedPackagesByCode(
        this.context.cart,
        exclusions
      )
      if (
        (exclusions && exclusions.length > 0) ||
        (packagesExclusions && packagesExclusions.length > 0)
      ) {
        let dependenciesForExclusions = await doHaveExclusionsDependencies(
          this.context.cart,
          exclusions
        )

        if (attributes.dependencies) {
          const itemDependenciesCodes = attributes.dependencies.map((item) => {
            return item.code
          })

          dependenciesForExclusions = dependenciesForExclusions.filter(
            (item) => {
              return !itemDependenciesCodes.includes(item.code)
            }
          )
        }

        this.setState({
          conflictItem: selectedItem,
          conflictingItems: exclusions,
          conflictingPackages: packagesExclusions,
          conflictAction: 'exl',
          dependenciesForExclusions: dependenciesForExclusions
        })
        please_add = false
      }
    }

    // Sonderausstattung ist nicht mit mehreren Sonderausstattungen in bestimmter Kombination kombinierbar

    if (
      attributes.exclusionInCombination &&
      attributes.exclusionInCombination.length > 1
    ) {
      const codesExclusionInCombination = attributes.exclusionInCombination.map(
        (item) => {
          return item.code
        }
      )

      const exclusionInCombination = await filterOptionsAlreadyInCart(
        this.context.cart,
        attributes.exclusionInCombination
      )

      if (
        exclusionInCombination.length ===
        attributes.exclusionInCombination.length
      ) {
        const filteredExclusionInCombination = exclusionInCombination.filter(
          (item) => {
            return !codesExclusionInCombination.includes(item.code)
          }
        )

        if (!filteredExclusionInCombination.length) {
          this.setState({
            conflictItem: selectedItem,
            conflictAction: 'noText',
            exclusionInCombonation: attributes.exclusionInCombination
          })
          please_add = false
        }
      }
    }

    // Produkt hat zwingende Ergänzungen / Erweiterungen => ConflictOverlay
    const inclusions = filterOptionsNotInCart(
      this.context.cart,
      attributes.inclusions
    )

    if (inclusions && inclusions.length) {
      const exclusionsForInclusions = await doHaveInclusionsExclusions(
        this.context.cart,
        inclusions
      )

      const conflictAction =
        exclusions && exclusions.length > 0 ? 'addexl' : 'add'

      this.setState({
        conflictItem: selectedItem,
        additionalItems: inclusions,
        conflictAction,
        exclusions: exclusionsForInclusions
      })
      please_add = false
    }

    // Produkt hat optionale Ergänzungen / Erweiterungen => ConflictOverlay
    const options = attributes.options
    const oneOptionInCart = await isAtLeastOneOptionInCart(
      this.context.cart,
      options
    )

    if (options && !oneOptionInCart && options.length) {
      // warum wird optionsExclusions nicht im state gespeichert?
      const optionsExclusions = await doHaveOptionsExclusions(
        this.context.cart,
        options
      )

      // warum ist hier optionsExclusions drin?
      const optionsInclusions = doHaveOptionsInclusions(
        this.context.cart,
        optionsExclusions,
        selectedItem
      )

      const optionOptions = doHaveOptionsOptions(
        this.context.cart,
        options,
        selectedItem
      )

      const packagesExclusions = findtPackagesByDetailItems(
        this.context.cart,
        exclusions
      )

      const additionalInclusions =
        JSON.stringify(inclusions) === JSON.stringify(optionsInclusions)
          ? []
          : inclusions

      this.setState({
        conflictItem: selectedItem,
        additionalInclusions: additionalInclusions,
        additionalItems: optionsInclusions,
        optionOptions,
        optionsExclusions: exclusions,
        optionsExcludedPackages: packagesExclusions,
        conflictAction: 'inc'
      })

      please_add = false
    }

    return please_add
  }

  addItem = async (selectedItem, parent_index) => {
    this.resetConflictOverlayState()

    const compatible = await this.checkConflicts(selectedItem)

    if (compatible) {
      this.addToChapter(parent_index, selectedItem)
    } else {
      this.context.toggleConflictOverlay()
    }
  }

  removeItem = async (selectedItem) => {
    // const cartCodes = getCartCodes(this.context.cart)

    // const actionConditions = await getActionConditions(
    //   cartCodes,
    //   selectedItem.code,
    //   'remove',
    //   this.context.cart.layout.productId
    // )

    // console.log('actionConditions', actionConditions)

    let please_remove = true

    this.resetConflictOverlayState()

    const attributes = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    // Haengen von diesem Produkt andere Produkte ab?
    const dependencies = await filterOptionsAlreadyInCart(
      this.context.cart,
      attributes.dependencies
    )

    if (dependencies && dependencies.length > 0) {
      const depsDeps = await doHaveExclusionsDependencies(
        this.context.cart,
        dependencies
      )

      if (depsDeps && depsDeps.length) {
        dependencies.push(...depsDeps)
      }

      this.setState({
        attributes,
        conflictItem: selectedItem,
        conflictingItems: dependencies,
        conflictAction: 'del'
      })

      please_remove = false
    } else if (selectedItem.disabled) {
      const exclusion = this.findPackageForItem(selectedItem)

      const attributes = await loadExtendedAttributes(
        this.context.cart.layout.productId,
        exclusion.code
      )

      this.setState({ attributes })

      const dependenciesForExclusions = await doHaveExclusionsDependencies(
        this.context.cart,
        [exclusion]
      )

      this.setState({
        conflictItem: selectedItem,
        conflictingItem: exclusion,
        conflictAction: 'del',
        dependenciesForExclusions: dependenciesForExclusions
      })

      please_remove = false
    } else if (selectedItem.selected && selectedItem.dependencies) {
      const deps = this.findDependencies(selectedItem)
      if (deps.length > 0) {
        this.setState({
          conflictItem: selectedItem,
          conflictingItems: deps,
          conflictAction: 'del'
        })

        please_remove = false
      }
    }

    if (please_remove) {
      this.removeFromChapter(selectedItem)
    } else {
      this.context.toggleConflictOverlay()
    }
  }

  findDependencies = (selectedItem) => {
    let dependencies = []

    if (this.props.items) {
      this.props.items.forEach((chassi) => {
        chassi.items.forEach((item) => {
          if (selectedItem.dependencies.includes(item.code) && item.selected) {
            dependencies.push(item)
          }
        })
      })
    }
    return dependencies
  }

  findPackageForItem = (selectedItem) => {
    let selectedPackage = null

    if (this.context.cart.packages) {
      this.context.cart.packages.forEach((paket) => {
        if (paket !== null) {
          paket.items.forEach((item) => {
            item.details.forEach((detail) => {
              if (detail.code === selectedItem.code) {
                selectedPackage = item
              }
            })
          })
        }
      })
    }
    return selectedPackage
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const items = this.props.items

    items.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ items: items })
  }

  render() {
    if (!this.props.items.length) {
      return <Loading></Loading>
    }

    return (
      <div className="konfigurator__chassis konfigurator__accessories">
        <div className="g g-inset">
          {this.props.items.map((item, parent_index) => (
            <div
              key={parent_index}
              className="konfigurator__container"
              id={slugify(item.title)}
            >
              <h2 className="js-tab-headline">{item.title}</h2>
              <section>
                {item.items.map((child_item, i) => (
                  <ItemRow
                    key={i}
                    i={i}
                    child_item={child_item}
                    parent_index={parent_index}
                    removeItem={this.removeItem}
                    addItem={this.addItem}
                  />
                ))}
              </section>
            </div>
          ))}
        </div>
        {this.context.config.conflictOverlayOpen && (
          <ConflictOverlay
            item={this.state.conflictItem}
            additionalInclusions={this.state.additionalInclusions}
            additionalItems={this.state.additionalItems}
            conflictingItem={this.state.conflictingItem}
            conflictingItems={this.state.conflictingItems}
            action={this.state.conflictAction}
            addItemWithOption={this.addItemWithOption}
            negativeCallBackAction={this.removeConflictingPackage}
            positiveCallBackAction={this.addConflictingPackage}
            conflictingPackages={this.state.conflictingPackages}
            exclusions={this.state.exclusions}
            optionsExclusions={this.state.optionsExclusions}
            optionsExcludedPackages={this.state.optionsExcludedPackages}
            dependenciesForExclusions={this.state.dependenciesForExclusions}
            exclusionInCombonation={this.state.exclusionInCombonation}
            removeExclusionInCombonation={this.removeExclusionInCombonation}
          />
        )}

        {this.context.maxWeightOverlayOpen && (
          <MaxWeightOverlay
            closeMaxWeightOverlay={this.closeMaxWeightOverlay}
            addItem={this.addToChapter}
            removeItem={this.removeItem}
          />
        )}
      </div>
    )
  }
}

ChapterListView.contextType = AppContext
export default ChapterListView
