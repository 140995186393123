import React from 'react'
import { Link } from 'react-router-dom'

import { history } from '../../Data/Store'
import SVG from './../SVG/SVG'
import {
  loadCartDataForCode,
  getAssetUrl,
  getLanguage,
  domainForLanguage,
  START_BACKGROUND_IMAGE_AWARDS_EXCEPTIONS,
  START_IMAGE_BASE_NAME
} from '../../config'
import { translate } from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'

class Start extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      load_config: false,
      code: '',
      invalideCode: false
    }
  }

  componentDidMount() {
    this.context.updateCurrentNav(0)
  }

  handleChange = (e) => {
    const code = e.target.value
    this.setState({ code: code, invalideCode: false })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.state.code.length !== 10) {
      this.setState({ invalideCode: true })
    } else {
      loadCartDataForCode(this.state.code)
        .then((config) => {
          // Redirect to proper domain
          if (config.language !== getLanguage()) {
            window.location =
              domainForLanguage(config.language) + '/cart/' + this.state.code
          } else {
            const json = JSON.parse(config.configuration)
            json.cart.code = this.state.code
            this.context.initCart(json.cart)
            this.context.initStepsCompleted(json.steps_completed)
            history.push('/03-exterieur')
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({ invalideCode: true })
        })
    }
  }

  toggleLoadConfig = (i) => {
    let load_config = this.state.load_config
    this.setState({ load_config: !load_config })
  }

  renderBackground = () => {
    if (
      START_BACKGROUND_IMAGE_AWARDS_EXCEPTIONS.includes(
        window.currentLocalization.locale
      )
    ) {
      return (
        START_IMAGE_BASE_NAME + '-' + window.currentLocalization.locale + '.jpg'
      )
    }

    return START_IMAGE_BASE_NAME + '.jpg'
  }

  render() {
    const { load_config, code } = { ...this.state }

    const underConstruction =
      window.location.search.substring(1) === 'underConstruction'

    return (
      <div className="konfigurator__start">
        <div className="konfigurator__start__background">
          <div className="konfigurator__start__background--mobile"></div>
          {underConstruction ? (
            <React.Fragment>
              <img
                src={
                  getAssetUrl() + '/assets/00-start/' + this.renderBackground()
                }
                alt="background"
                className="konfigurator__start__gray"
              />
              <div className="konfigurator__start__hinweis-box">
                <h1>Wichtiger Hinweis!</h1>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </div>
            </React.Fragment>
          ) : (
            <img
              src={
                getAssetUrl() + '/assets/00-start/' + this.renderBackground()
              }
              alt="background"
            />
          )}
        </div>
        {!load_config && (
          <div className="konfigurator__start__buttons">
            <Link to="/01-modell" className="button button--cta button--l">
              <span>{translate('start', 'choose_model')}</span>
            </Link>
            <button
              onClick={() => this.toggleLoadConfig()}
              className="button button--dark button--l"
            >
              <span>{translate('start', 'load_model')}</span>
            </button>
          </div>
        )}
        <div
          className={
            'konfigurator__start__overlay ' + (load_config ? 'is-active' : '')
          }
        >
          <button
            onClick={() => this.toggleLoadConfig()}
            className="konfigurator__start__overlay__close"
          >
            <SVG name="close" />
          </button>

          <div className="konfigurator__start__overlay__content">
            <form
              className="form form--infield-labels form--inverted"
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div className="form-control form-item form-text is-empty">
                <input
                  type="text"
                  placeholder={translate('start', 'placeholder_code')}
                  value={code}
                  onChange={this.handleChange}
                />
                {this.state.invalideCode && (
                  <span className="error">
                    {translate('start', 'placeholder_error')}
                  </span>
                )}
              </div>
              <button className="button button--cta button--l">
                <span>{translate('start', 'load_configuration')}</span>
              </button>
              <div className="form-control form-item form-text is-empty">
                <p>{translate('start', 'placeholder_code_annotation')}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

Start.contextType = AppContext
export default Start
