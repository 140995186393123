import React from 'react'
import Loading from './../Loading/Loading'
import { getApiUrl, getLanguage } from '../../config'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'

class LegalInfos extends React.Component {
  state = {
    content: {
      legal_info_headline: '',
      legal_info_text: ''
    },
    footnote: [],
    infobox: ''
  }

  componentDidMount = async () => {
    fetch(getApiUrl() + '/data/legal-infos?lang=' + getLanguage())
      .then((response) => response.json())
      .then((content) => {
        const infobox = content.infobox ? content.infobox : ''
        const footnote = content.footnote ? content.footnote : []
        this.setState({ content, infobox, footnote })
      })
  }

  render() {
    if (!this.state.infobox) {
      return <Loading></Loading>
    }

    return (
      <div className="legal-infos">
        <div className="page page--plain">
          <div className="text--copy">
            <h1>{this.state.content.legal_info_headline}</h1>
            <div
              className="hinweis-container"
              dangerouslySetInnerHTML={{
                __html: this.state.content.legal_info_text
              }}
            />
          </div>
        </div>
        {this.state.infobox.map((box, index) => (
          <Infobox key={index} content={box.content} />
        ))}
        {this.state.footnote.length > 0 ? (
          <Footnote footnote={this.state.footnote} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default LegalInfos
