import React from 'react'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Mentions légales</h1>

            <p>
              Mentions légales combinées (identification du prestataire) de la
              loi sur les médias électroniques (TMG), traité d’Etat pour la
              radiodiffusion et Télémédia (RStV) et informations conformes au
              règlement de service d’information (DL-InfoV) basées sur la
              directive «&nbsp;Service&nbsp;» (RL 2006/123/EG journal officiel
              EU2006, L376/36 „DRLR“)
            </p>
            <p>
              Fournisseur et, par conséquent, responsable pour le site web
              commercial et professionnel au sens de l’article 5 de la loi sur
              les médias électroniques dans sa fonction de la législation
              majeure de la loi d’harmonisation sur les transactions
              commerciales électroniques (ElGVG) et la loi sur la régulation des
              conditions générales pour les services d’information et de
              communication (IuKDG) est la société Niesmann+Bischoff GmbH,
              représentée par son directeur MM. Hubert Brandl ainsi que le fonde
              de pouvoir M. Josef Henrichs.
            </p>
            <p>
              Informations conformes à l‘article 2, paragraphe 1 du règlement de
              service d’information (DL-InfoV)
              <br />
              Cette présence Internet est une offre d’information de la société
            </p>
            <p>
              <strong>
                Niesmann+Bischoff GmbH
                <br />
              </strong>
              Cloustraße 1<br />
              D-56751 Polch/GERMANY
              <br />
              E-Mail :{' '}
              <a href="mailto:info@niesmann-bischoff.com" className="local">
                info@niesmann-bischoff.com
              </a>
              <br />
              Internet :{' '}
              <a href="http://www.niesmann-bischoff.com" className="local">
                www.niesmann-bischoff.com
              </a>
              <br />
              Téléphone : +49 2654 933-0
              <br />
              Télécopie : +49 2654 933-100
            </p>
            <p>
              Siège de Niesmann+Bischoff GmbH : D-56751 Polch
              <br />
              Amtsgericht Koblenz, HRB 23440 (Tribunal cantonal)
              <br />
              Forme juridique : Société à responsabilité limitée (GmbH)
            </p>
            <p>
              Droit applicable : droit de la République Fédérale d’Allemagne
              (BRD)
              <br />
              Administration fiscale Mayen
              <br />
              Numéro d’identification T.V.A. : DE 148 734 460
            </p>
            <p>
              Représentants légaux de la société : Hubert Brandl, fondé de
              pouvoir Josef Henrichs
            </p>
            <p>
              <strong>
                Chambre de commerce et d’industrie compétente
                <br />
              </strong>
              Industrie- und Handelskammer Koblenz
              <br />
              Schlossstraße 8, D-56068 Koblenz
              <br />
              Téléphone : +49 261 106-0
              <br />
              Télécopie : +49 261 106-234
              <br />
              E-Mail :{' '}
              <a href="mailto:info@koblenz.ihk.de" className="local">
                info@koblenz.ihk.de
                <br />
              </a>
              Internet :{' '}
              <a href="http://www.ihk-koblenz.de" className="external">
                www.ihk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Chambre de commerce compétente
                <br />
              </strong>
              Handwerkskammer Koblenz
              <br />
              Friedrich-Ebert-Ring 33, D-56068 Koblenz
              <br />
              Téléphone : +49 261 398-0
              <br />
              Télécopie : +49 261 398-398
              <br />
              E-Mail:{' '}
              <a href="mailto:hwk@hwk-koblenz.de" className="local">
                hwk@hwk-koblenz.de
                <br />
              </a>
              Internet :{' '}
              <a href="http://www.hwk-koblenz.de" className="external">
                www.hwk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Informations sur le règlement en ligne des litiges conformément
                à l‘article 14 paragr.{' '}
              </strong>
              <strong>
                1 ODR-VO :<br />
              </strong>
              La Commission Européenne met une plateforme pour le règlement en
              ligne des litiges (OS) accessible sous le site web{' '}
              <a href="http://ec.europa.eu/consumers/odr" className="external">
                http://ec.europa.eu/consumers/odr
              </a>
              . Cette plateforme sert d’interlocuteur pour le règlement
              extrajudiciaire de litiges des obligations contractuelles
              concernant les contrats de vente conclus en ligne.
            </p>
            <p>
              Nous ne sommes ni obligés ni disposés à participer à une procédure
              de règlement de litiges devant un organe de conciliation des
              consommateurs.
            </p>
            <p>
              <strong>
                Responsable pour les contenus &nbsp;journalistiques et
                rédactionnels{' '}
              </strong>
              <strong>conformément à </strong>
              <strong>
                l’article 55, paragr. 2 du traité d’Etat pour la radiodiffusion
                et Télémédia (RStV) :<br />
              </strong>
              Philipp Komers, directeur des ventes D-A-CH,{' '}
              <a
                href="mailto:philipp.komers@niesmann-bischoff.com"
                className="local"
              >
                philipp.komers@niesmann-bischoff.com
                <br />
              </a>
              Cloustraße 1, D-56751 Polch
            </p>
            <p>
              <strong>
                Délégué externe à la protection des données, conformément à
                l’article 37 DS-GVO en liaison avec l’article 38 paragr. 1
                BDSG-neu :<br />
              </strong>
              Stefan Fischerkeller
              <br />
              Office allemand de la protection des données
              <br />
              Bureau Bodensee
              <br />
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              Contact par E-Mail du délégué à la protection des données :{' '}
              <a
                href="mailto:fischerkeller@deutsche-datenschutzkanzlei.de"
                className="local"
              >
                fischerkeller@deutsche-datenschutzkanzlei.de
              </a>
              ;{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>{' '}
              ou par le site web suivant :{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              <strong>
                Support technique du site web :<br />
              </strong>
              nbsp GmbH, M. Mario Kandler, Küstriner Straße 14, D-94315
              Straubing
              <br />
              Téléphone : +49 9421 78470, E-Mail:{' '}
              <a href="mailto:info@nbsp.de" className="local">
                info@nbsp.de
              </a>
            </p>
            <p>
              <strong>
                Conception du site web:
                <br />
              </strong>
              3st Kommunikation GmbH, M. Florian Heine, Taunusstraße 56, D-55118
              Mainz
              <br />
              Téléphone : +49 6131 49961-0, E-Mail:{' '}
              <a href="mailto:fheine@3st.de" className="local">
                fheine@3st.de
              </a>
            </p>
            <p>
              <strong>
                Caractéristiques des services de Niesmann+Bischoff
              </strong>{' '}
              <strong>GmbH : </strong>fabrication et commercialisation de
              camping-cars, caravanes, unités d’habitation et accessoires.
            </p>
            <p>
              <strong>
                Indications sur les tarifs conformément à l‘article 4 du
                règlement de service d’information (DL-InfoV) règlement relatif
                aux indications de prix (PAngV)
                <br />
              </strong>
              Les informations sur les tarifs sont à prélever du site web de la
              société Niesmann+Bischoff GmbH.
              <br />
              Sur demande, Niesmann+Bischoff GmbH peut vous faire parvenir un
              devis.
            </p>
            <p>
              <strong>
                Responsabilité
                <br />
              </strong>
              La société Niesmann+Bischoff GmbH peut modifier totalement ou en
              partie ou cesser l’activité du présent site web à sa seule
              discrétion sans engagement de sa responsabilité à tout moment et
              sans aucun préavis. La société Niesmann+Bischoff GmbH ne
              s’approprie ni le site web ni son contenu pour le renvoi vers un
              lien d’un site (“Hyperlinks”). De plus, la société
              Niesmann+Bischoff GmbH ne peut pas être tenue responsable pour la
              disponibilité de ce site web ou de ses contenus.
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                © Copyright
                <br />
              </strong>
              Tous droits réservés. Les textes, images et graphiques ainsi que
              leur mise en page sur le site web sont protégés par des droites
              d’auteur et autre législation protectrice. Le contenu du présent
              site web ne peut pas être copié, diffusé, modifié ou mis à
              disposition à des tiers à des buts commerciaux.
            </p>
            <p>
              <strong>© Copyright sur la source des images : </strong>
              Niesmann+Bischoff GmbH
            </p>
            <p>
              <strong>
                Remarque : la Loi générale relative à l’égalité de traitement
                (AGG)
                <br />
              </strong>
              Pour des raisons de facilité de lecture, le responsable du site a
              renoncé à une différenciation spécifique au genre. Les termes
              respectifs s’appliquent pour les deux sexes dans le sens de
              l’égalité du traitement.
            </p>
            <p>
              <strong>
                Ces mentions légales ainsi que la déclaration sur la protection
                des données ont été établies par:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –&nbsp;
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
