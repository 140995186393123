import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
var classNames = require('classnames')

function NextArrow(props) {
  const { className, style, onClick } = props
  let newClassName = className.replace(
    'slick-next',
    ' slick-arrow--right icon icon-chevron-s'
  )
  return (
    <span className={newClassName} style={{ ...style }} onClick={onClick}>
      <svg role="img">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#icon-chevron-s"
        ></use>
      </svg>
      <span className="icon-click-target"></span>
    </span>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  let newClassName = className.replace(
    'slick-prev',
    ' slick-arrow--left icon icon-chevron-s'
  )
  return (
    <span className={newClassName} style={{ ...style }} onClick={onClick}>
      <svg role="img">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#icon-chevron-s"
        ></use>
      </svg>
      <span className="icon-click-target"></span>
    </span>
  )
}

class SimpleSlider extends React.Component {
  constructor(props) {
    super(props)
    this.changeActiveCounter = this.changeActiveCounter.bind(this)
    this.changeActiveSlide = this.changeActiveSlide.bind(this)
    this.changeClasses = this.changeClasses.bind(this)
    this.slider = React.createRef()
    this.state = {
      active: 1,
      lastActive: false,
      inverted: false
    }
  }

  changeActiveCounter(i) {
    this.setState({ lastActive: this.state.active }, () => {
      this.setState({ active: i + 1 })
    })
  }

  changeActiveSlide(i) {
    this.slider.current.slickGoTo(i - 1)
  }

  changeClasses(i) {
    let inverted = this.props.children[i].props.inverted
    this.setState({ inverted: inverted })
  }

  render() {
    var settings = {
      dots: this.props.dots || true,
      speed: this.props.speed || 500,
      autoplaySpeed: 3000,
      waitForAnimate: false,
      arrows: this.props.arrows || true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: false,
      cssEase: 'cubic-bezier(.16,.66,.3,.99)',
      infinite: false,
      fade: this.props.fade || false,
      // ==== BeforeChange-BUG: https://github.com/akiran/react-slick/issues/136 (FIX: SliderWrapper and shouldUpdate = false) ====
      // beforeChange: function(oldIndex, newIndex) {
      //   this.changeClasses(newIndex);
      // }.bind(this),
      afterChange: function (i) {
        this.changeActiveCounter(i)
      }.bind(this),
      appendDots: (dots) => (
        <div
          data-counter-after={'/ ' + this.props.length}
          data-counter-before={this.state.active}
          style={{
            padding: '10px'
          }}
        >
          <span />
        </div>
      )
    }

    return (
      <div>
        <Slider
          ref={this.slider}
          className={classNames('slider', this.props.extraClasses)}
          {...settings}
          {...this.props}
        />
      </div>
    )
  }
}

export default SimpleSlider
