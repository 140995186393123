import React from 'react'
import SVG from './../SVG/SVG'
import { AppContext } from '../../Data/ContextProvider'

class InfoButton extends React.Component {
  constructor(props) {
    super(props)
    this.updateInfoOverlay = this.updateInfoOverlay.bind(this)
  }

  updateInfoOverlay(event, infos) {
    //set Overlay-Theme
    if (event.currentTarget.name) {
      infos.theme = event.currentTarget.name
    }

    this.context.updateInfoOverlay(infos)
  }

  render() {
    const { infos } = { ...this.props }

    //render if infos has details (pakete) or an image
    return infos.details || infos.img ? (
      <button
        name={this.props.name}
        onClick={(e) => this.updateInfoOverlay(e, infos)}
      >
        <SVG name="info" className="info" />
      </button>
    ) : (
      <div></div>
    )
  }
}

InfoButton.contextType = AppContext
export default InfoButton
