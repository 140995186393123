import React from 'react'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Impressum</h1>
            <p>
              Kombinationsimpressum (Anbieterkennzeichnung) aus Telemediengesetz
              (TMG), Staatsvertrag für Rundfunk und Telemedien (RStV) und
              Informationen gemäß
              Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV)
              basierend auf der EU-Dienstleistungsrichtlinie (RL 2006/123/EG
              Amtsblatt der EU2006, L376/36 „DRLR“)
            </p>
            <p>
              Anbieter und somit verantwortlich für die kommerzielle und
              geschäftsmäßige Website im Sinne des § 5 des Telemediengesetzes in
              Funktion als Kerngesetz des
              Elektronischer-Geschäftsverkehr-Vereinheitlichungsgesetz ElGVG und
              des Gesetzes zur Regelung der Rahmenbedingungen für Informations-
              und Kommunikationsdienste (IuKDG), ist die Niesmann+Bischoff GmbH,
              vertreten durch den Geschäftsführer Hubert Brandl sowie dem
              Prokuristen Josef Henrichs.
            </p>
            <p>
              Informationen gemäß § 2 Absatz 1
              Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV)
              <br />
              Diese Internetpräsenz ist ein Informationsangebot der
            </p>
            <p>
              <strong>
                Niesmann+Bischoff GmbH
                <br />
              </strong>
              Cloustraße 1<br />
              D-56751 Polch/GERMANY
              <br />
              E-Mail:{' '}
              <a href="mailto:info@niesmann-bischoff.com" className="local">
                info@niesmann-bischoff.com
              </a>
              <br />
              Web:{' '}
              <a href="http://www.niesmann-bischoff.com" className="local">
                www.niesmann-bischoff.com
              </a>
              <br />
              Telefon: +49 2654 933-0
              <br />
              Telefax: +49 2654 933-100
            </p>
            <p>
              Sitz der Niesmann+Bischoff GmbH: D-56751 Polch
              <br />
              Amtsgericht Koblenz, HRB 23440
              <br />
              Rechtsform: Gesellschaft mit beschränkter Haftung (GmbH)
              <br />
              Anwendbares Recht: Recht der Bundesrepublik Deutschland (BRD)
            </p>
            <p>
              Finanzamt Mayen
              <br />
              Umsatzsteuer-Identifikationsnummer: DE 148 734 460
              <br />
              Vertretungsberechtigte Geschäftsführer: Hubert Brandl, Prokurist
              Josef Henrichs
            </p>
            <p>
              <strong>
                Zuständige Industrie- und Handelskammer
                <br />
              </strong>
              Industrie- und Handelskammer Koblenz
              <br />
              Schlossstraße 8, D-56068 Koblenz
              <br />
              Telefon: +49 261 106-0
              <br />
              Telefax: +49 261 106-234
              <br />
              E-Mail:{' '}
              <a href="mailto:info@koblenz.ihk.de" className="local">
                info@koblenz.ihk.de
                <br />
              </a>
              Web:{' '}
              <a href="http://www.ihk-koblenz.de" className="external">
                www.ihk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Zuständige Handwerkskammer
                <br />
              </strong>
              Handwerkskammer Koblenz
              <br />
              Friedrich-Ebert-Ring 33, D-56068 Koblenz
              <br />
              Telefon: +49 261 398-0
              <br />
              Telefax: +49 261 398-398
              <br />
              E-Mail:{' '}
              <a href="mailto:hwk@hwk-koblenz.de" className="local">
                hwk@hwk-koblenz.de
                <br />
              </a>
              Web:{' '}
              <a href="http://www.hwk-koblenz.de" className="external">
                www.hwk-koblenz.de
              </a>
            </p>
            <p>
              <strong>
                Informationen zur Online-Streitbeilegung gemäß Art. 14 Abs. 1
                ODR-VO:
                <br />
              </strong>
              Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung
              (OS) bereit, die Sie unter{' '}
              <a href="http://ec.europa.eu/consumers/odr" className="external">
                http://ec.europa.eu/consumers/odr
              </a>{' '}
              finden. Die OS-Plattform dient als Anlaufstelle zur
              außergerichtlichen Beilegung von Streitigkeiten betreffend
              vertraglicher Verpflichtungen, die aus Online-Kaufverträgen
              erwachsen.
              <br />
              Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor
              einer Verbraucherschlichtungsstelle weder verpflichtet noch
              bereit.
            </p>
            <p>
              <strong>
                Verantwortlicher für journalistisch redaktionelle Beiträge gemäß
                § 55 Absatz 2 Staatsvertrag für Rundfunk und Telemedien (RStV):
                <br />
              </strong>
              Philipp Komers, Vertriebsleiter D-A-CH,{' '}
              <a
                href="mailto:philipp.komers@niesmann-bischoff.com"
                className="local"
              >
                philipp.komers@niesmann-bischoff.com
                <br />
              </a>
              Cloustraße 1, D-56751 Polch
            </p>
            <p>
              <strong>
                Externer Datenschutzbeauftragter gemäß Art. 37 DS-GVO i. V. m. §
                38 Abs. 1 BDSG-neu:
                <br />
              </strong>
              Stefan Fischerkeller
              <br />
              Deutsche Datenschutzkanzlei
              <br />
              Office Bodensee
              <br />
              Kontakt Datenschutzbeauftragter via E-Mail:{' '}
              <a
                href="mailto:fischerkeller@deutsche-datenschutzkanzlei.de"
                className="local"
              >
                fischerkeller@deutsche-datenschutzkanzlei.de
              </a>
              &nbsp;;{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
              </a>
              &nbsp; oder über folgende Datenschutz-Website:{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
            <p>
              <strong>
                Technische Betreuung dieser Website:
                <br />
              </strong>
              nbsp GmbH, Herr Mario Kandler, Küstriner Straße 14, D-94315
              Straubing
              <br />
              Telefon: +49 9421 78470, E-Mail:{' '}
              <a href="mailto:info@nbsp.de" className="local">
                info@nbsp.de
              </a>
            </p>
            <p>
              <strong>
                Konzeption dieser Website:
                <br />
              </strong>
              3st Kommunikation GmbH, Herr Florian Heine, Taunusstraße 56,
              D-55118 Mainz
              <br />
              Telefon: +49 6131 49961-0, E-Mail:{' '}
              <a href="mailto:fheine@3st.de" className="local">
                fheine@3st.de
              </a>
            </p>
            <p>
              <strong>Merkmale der Dienstleistung der Niesmann+Bischoff</strong>{' '}
              <strong>GmbH: </strong>Die Produktion von und der Handel mit
              Reisemobilen, Caravans und Wohneinheiten und Zubehör.
            </p>
            <p>
              <strong>
                Preisangaben gemäß § 4
                Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV) und
                Preisangabenverordnung (PAngV)
                <br />
              </strong>
              Informationen zu den Preisen sind der Website der
              Niesmann+Bischoff GmbH zu entnehmen.
              <br />
              Die Niesmann+Bischoff GmbH erstellt Ihnen auf Anfrage gerne ein
              Angebot.
            </p>
            <p>
              <strong>
                Haftung
                <br />
              </strong>
              Die Niesmann+Bischoff GmbH kann diese Website nach eigenem
              Ermessen und ohne Übernahme einer Haftung jederzeit ohne
              Ankündigung ganz oder teilweise verändern und deren Betrieb
              einstellen. Durch das Setzen eines Links zu fremden Websites
              („Hyperlinks“) macht sich die Niesmann+Bischoff GmbH weder diese
              Website noch deren Inhalt zu Eigen. Ferner ist die
              Niesmann+Bischoff GmbH nicht verantwortlich für die Verfügbarkeit
              dieser Websites oder von deren Inhalten.
            </p>
            <p>
              <strong>
                © Copyright
                <br />
              </strong>
              Alle Rechte vorbehalten. Text, Bilder und Grafiken sowie deren
              Anordnung auf der Website unterliegen dem Schutz des Urheberrechts
              und anderer Schutzgesetze. Der Inhalt dieser Websites darf nicht
              zu kommerziellen Zwecken kopiert, verbreitet, verändert oder
              Dritten zugänglich gemacht werden.
            </p>
            <p>
              <strong>© Copyright Bildquellen: </strong>Niesmann+Bischoff GmbH
            </p>
            <p>
              <strong>
                Hinweis: Allgemeines Gleichbehandlungsgesetz (AGG)
                <br />
              </strong>
              Aus Gründen der leichteren Lesbarkeit wird auf eine
              geschlechterspezifische Differenzierung verzichtet. Entsprechende
              Begriffe gelten im Sinne der Gleichbehandlung für beide
              Geschlechter.
            </p>
            <p>
              <strong>
                Dieses Impressum und die Erklärung zum Datenschutz wurden
                erstellt von:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
