import React from 'react'
import { NavLink } from 'react-router-dom'
import * as Scroll from 'react-scroll'
import { Link } from 'react-scroll'
import {
  formatPrice,
  formatWeight,
  slugify,
  recalculateWeights,
  recalculatePrices,
  translate
} from '../../helpers'
import SVG from './../SVG/SVG'
import {
  getApiUrl,
  getLanguage,
  MODEL_PATH,
  getAssetUrl,
  getUser
} from '../../config'

import Button from '../Button/Button'
import WeightBlock from '../WeightBlock/WeightBlock'
import ToolbarListItems from '../Toolbar/ToolbarListItems'
import logo from './../../assets/images/N+B_Logo-Kachel-grau_RGB--small.svg'
import { AppContext } from '../../Data/ContextProvider'

import * as ScrollMagic from 'scrollmagic'
const scrollController = new ScrollMagic.Controller()
var classNames = require('classnames')
let scroll = Scroll.animateScroll

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickToggle = this.handleClickToggle.bind(this)
    this.state = {
      menuOpened: false,
      previousDisabled: false,
      nextDisabled: false,
      header_navigation: [
        {
          title: 'Start',
          path: '/',
          checkbox: true
        },
        {
          title: translate('global', 'nav_model'),
          path: '/01-modell',
          checkbox: true
        },
        {
          title: translate('global', 'nav_layout'),
          path: '/02-grundriss',
          checkbox: true
        },
        {
          title: translate('global', 'nav_exterieur'),
          path: '/03-exterieur',
          checkbox: true
        },
        {
          title: translate('global', 'nav_interieur'),
          path: '/04-interieur',
          checkbox: true
        },
        {
          title: translate('global', 'nav_pakete'),
          path: '/05-pakete',
          checkbox: true
        },
        {
          title: translate('global', 'nav_chassis'),
          path: '/06-chassis',
          checkbox: true
        },
        {
          title: translate('global', 'nav_aufbau'),
          path: '/07-aufbau',
          checkbox: true
        },
        {
          title: translate('global', 'nav_technique'),
          path: '/08-technik',
          checkbox: true
        }
      ]
    }
  }

  componentDidMount() {
    new ScrollMagic.Scene({
      triggerElement: document.getElementsByClassName('konfigurator__body')[0],
      triggerHook: 'onLeave',
      offset: 5
    })
      .setClassToggle('.konfigurator', 'konfigurator__header--is-sticky')
      .addTo(scrollController)

    new ScrollMagic.Scene({
      triggerElement: document.getElementsByClassName('konfigurator__body')[0],
      triggerHook: 'onEnter',
      offset: 2000
    })
      .setClassToggle(
        '.konfigurator',
        'konfigurator__header__navigation__totop--is-visible'
      )
      .addTo(scrollController)
  }

  handleClickToggle() {
    this.setState({
      menuOpened: !this.state.menuOpened
    })
  }

  toggleCartOverview = (e, cart, config) => {
    e.preventDefault()
    this.handleSaveCart(cart, config)
    this.props.toggleCartOverview()
  }

  handleSaveCart = (cart, config) => {
    const user = getUser()
    const language = getLanguage()

    if (cart) {
      const url = `${getApiUrl()}/import/cart?user=${user}&lang=${language}`

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          cart: cart,
          code: cart.code,
          steps_completed: config.steps_completed
        })
      })
        .then((response) => response.json())
        .then((data) => {
          let cart = data.cart
          cart.code = data.code
          this.props.initCart(cart)
          this.props.initStepsCompleted(data.steps_completed)
        })
    }
  }

  renderPrice = (cart) => {
    let price = recalculatePrices(cart)
    return price || ''
  }

  renderWeight = (cart) => {
    let weight = recalculateWeights(cart)
    return weight || ''
  }

  render() {
    let headerClassNames = classNames('konfigurator__header', {
      'konfigurator__header__navigation__menu--is-visible':
        this.state.menuOpened
    })

    let menuClassNames = classNames('konfigurator__header__navigation__menu', {
      'is-visible': this.state.menuOpened
    })

    let menuToggleClassNames = classNames(
      'konfigurator__header__navigation__controls__toggle',
      {
        'is-active': this.state.menuOpened
      }
    )

    let NavigationSummaryClassNames = classNames(
      'konfigurator__header__navigation__summary',
      {
        // Set stores unique values. if more than 8 steps are completed, highlight summary
        'is-highlighted':
          [...new Set(this.props.config.steps_completed)].length > 8
            ? 'konfigurator__header__navigation__summary--is-active'
            : ''
      }
    )

    const { config, cart } = { ...this.context }

    return (
      <div className={headerClassNames}>
        <div className="konfigurator__header__bar">
          <NavLink to="/" className="konfigurator__header__bar__logo">
            <img src={logo} alt="Logo" />
          </NavLink>

          {this.context.config.cartoverview_open &&
          !this.context.config.infoOverlay_open ? (
            <React.Fragment>
              <h1>{translate('cart_overview', 'configuration')}</h1>
              <button
                onClick={(e) => this.toggleCartOverview(e)}
                className="button konfigurator__overview__back"
              >
                <span>{translate('global', 'back')}</span>
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h1>{translate('global', 'header_bar_title')}</h1>
              <Button />
              {this.context.config.infoOverlay_open &&
                !['360°', 'important_infos'].includes(
                  config.infoOverlay.content
                ) && (
                  <button
                    onClick={this.props.toggleInfoOverlay}
                    className="konfigurator__overlay__close"
                  >
                    <SVG name="close" />
                  </button>
                )}
            </React.Fragment>
          )}
        </div>

        {this.context.config.current_nav !== 0 && (
          <div className="konfigurator__header__navigation">
            <div className="konfigurator__header__navigation__wrapper">
              <ul className="konfigurator__header__navigation__list">
                {this.state.header_navigation.map((item, i) => (
                  <li key={i}>
                    {
                      // if more than 3 steps are completed, make all links clickable
                      this.context.config.steps_completed.length > 3 ? (
                        <NavLink
                          to={item.path}
                          activeClassName="is-active"
                          exact
                        >
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {this.context.config.steps_completed.includes(
                                i
                              ) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </NavLink>
                      ) : i > 2 ? (
                        <button>
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {this.context.config.steps_completed.includes(
                                i
                              ) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </button>
                      ) : (
                        <NavLink
                          to={item.path}
                          activeClassName="is-active"
                          exact
                        >
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {this.context.config.steps_completed.includes(
                                i
                              ) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </NavLink>
                      )
                    }
                  </li>
                ))}
              </ul>

              {Object.keys(cart).length !== 0 && cart.model && cart.layout ? (
                <div className="konfigurator__header__navigation__summary__wrapper">
                  <button
                    className="konfigurator__header__navigation__totop"
                    onClick={scroll.scrollToTop}
                  >
                    ▲ <span>Top</span>
                  </button>
                  <div className={NavigationSummaryClassNames}>
                    <a
                      href=""
                      onClick={(e) => this.toggleCartOverview(e, cart, config)}
                      className="konfigurator__header__navigation__summary__text"
                    >
                      <strong>
                        {translate('cart_overview', 'your')} {cart.layout.title}
                      </strong>
                      {window.currentLocalization.locale === 'nl_NL' ? (
                        <strong>
                          {formatPrice(this.renderPrice(cart) + 1850)}
                        </strong>
                      ) : (
                        <strong>{formatPrice(this.renderPrice(cart))}</strong>
                      )}
                      {window.currentLocalization.locale !== '' &&
                      ['Flair', 'Arto', 'iSmove'].includes(cart.model.title) ? (
                        <span></span>
                      ) : (
                        <span>{formatWeight(this.renderWeight(cart))}</span>
                      )}

                      <span className="u-big-target"></span>
                      <span className="next">
                        {translate('cart_overview', 'configuration_ready')}
                      </span>
                    </a>
                    <span className="konfigurator__header__navigation__summary__image">
                      {cart.layout.thumbFilename ? (
                        <img
                          src={
                            getAssetUrl() +
                            MODEL_PATH +
                            cart.layout.thumbFilename
                          }
                          alt={cart.layout.title}
                        />
                      ) : (
                        <img src={cart.layout.thumb} alt={cart.layout.title} />
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="konfigurator__header__navigation__wrapper--mobile">
              <ul className={menuClassNames}>
                {this.state.header_navigation.map((item, index) => (
                  <li key={index}>
                    {
                      // if more than 3 steps are completed, make all links clickable
                      this.context.config.steps_completed.length > 3 ? (
                        <NavLink
                          to={item.path}
                          activeClassName="is-selected"
                          exact
                          onClick={this.handleClickToggle}
                        >
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {config.steps_completed.includes(index) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </NavLink>
                      ) : index > 2 ? (
                        <button>
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {config.steps_completed.includes(index) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </button>
                      ) : (
                        <NavLink
                          to={item.path}
                          activeClassName="is-active"
                          exact
                          onClick={this.handleClickToggle}
                        >
                          {item.title}
                          {item.checkbox && (
                            <React.Fragment>
                              {config.steps_completed.includes(index) ? (
                                <SVG name="checkbox-green" />
                              ) : (
                                <SVG name="checkbox-grey" />
                              )}
                            </React.Fragment>
                          )}
                        </NavLink>
                      )
                    }
                  </li>
                ))}
                <ToolbarListItems />
              </ul>
              <div className="konfigurator__header__navigation__controls">
                <NavLink
                  className="konfigurator__header__navigation__controls__previous"
                  to={this.state.header_navigation[config.current_nav - 1].path}
                />
                <NavLink
                  className="konfigurator__header__navigation__controls__current"
                  activeClassName="is-active"
                  to={this.state.header_navigation[config.current_nav].path}
                >
                  {this.state.header_navigation[config.current_nav].title}
                </NavLink>
                {this.context.config.current_nav <
                  this.state.header_navigation.length - 1 &&
                this.context.config.steps_completed.length > 3 ? (
                  <NavLink
                    className="konfigurator__header__navigation__controls__next"
                    to={
                      this.state.header_navigation[config.current_nav + 1].path
                    }
                  ></NavLink>
                ) : (
                  <button className="konfigurator__header__navigation__controls__next is-disabled"></button>
                )}
                <button
                  className={menuToggleClassNames}
                  onClick={this.handleClickToggle}
                />
              </div>
            </div>

            {Object.keys(config).length !== 0 &&
              config.jumpmarks.length !== 0 && (
                <div className="konfigurator__header__navigation__tabs">
                  <div className="konfigurator__header__navigation__tabs__items">
                    {config.jumpmarks.map((item, i) => (
                      <Link
                        key={i}
                        spy={true}
                        smooth={true}
                        activeClass="is-selected"
                        hashSpy={true}
                        offset={-200}
                        duration={500}
                        to={slugify(item)}
                      >
                        {item}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}
        <WeightBlock />
      </div>
    )
  }
}

Header.contextType = AppContext
export default Header
