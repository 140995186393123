import React from 'react'

var classNames = require('classnames')

class Impressum extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__overlay ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <div className="page page--plain">
          <div className="text--copy">
            <h1>Imprint</h1>
            <p>
              Combined imprint (provider identification) in accordance with the
              German broadcast media act (Telemediengesetz (TMG)), the
              Interstate Broadcasting Agreement (Rundfunkstaatsvertrag (RStV))
              and the information required pursuant to the ordinance on service
              providers’ duty to inform
              (Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV))
              based on the EU Services Directive (2006/123/EC Official Journal
              of the EU2006, L376/36 “DRLR”)
            </p>
            <p>
              The provider and thus the entity responsible for the commercial
              and business website as defined by § 5 of the TMG in its function
              as the core law of the electronic commerce standardisation act
              (Elektronischer-Geschäftsverkehr-Vereinheitlichungsgesetz (ElGVG))
              and the law on the regulation of the framework conditions for
              information and communication services (Gesetz zur Regelung der
              Rahmenbedingungen für Informations- und Kommunikationsdienste
              (IuKDG)), is Niesmann+Bischoff GmbH, represented by its managing
              director, Hubert Brandl&nbsp; as well as its authorised
              representative, Josef Henrichs.
            </p>
            <p>
              <strong>Information pursuant to § 2 (1) DL-InfoV</strong>
              <strong>
                <br />
              </strong>
              This online presence is an information service provided by:
            </p>
            <p>
              <strong>Niesmann+Bischoff GmbH</strong>
              <br />
              Clou-Straße 1, D-56751 Polch
              <br />
              Tel.: +49 2654 933-0
              <br />
              Fax: +49 2654 933-100
              <br />
              info@niesmann-bischoff.com
              <br />
              www.niesmann-bischoff.com
            </p>
            <p>
              <strong>Registered office of Niesmann+Bischoff GmbH</strong>
              <strong>
                <br />
              </strong>
              D-56751 Polch, Amtsgericht Mayen, HRB 23440
            </p>
            <p>
              <strong>Legal form</strong>
              <br />
              Company with limited liability (Gesellschaft mit beschränkter
              Haftung (GmbH))
            </p>
            <p>
              <strong>Governing law</strong>
              <br />
              The law of the Federal Republic of Germany (BRD)
            </p>
            <p>
              <strong>Place of jurisdiction</strong>
              <br />
              Koblenz, HRB, 23440
            </p>
            <p>
              <strong>Tax authority</strong>
              <br />
              Mayen, VAT ID: DE 148 734 460
            </p>
            <p>
              <strong>
                Managing directors with the power of individual representation
              </strong>
              <br />
              Hubert Brandl as well as the authorised representative, Josef
              Henrichs
            </p>
            <p>
              <strong>Responsible chambers and associations</strong>
              <strong>
                <br />
              </strong>
              Industrie- und Handelskammer Koblenz,
              <br />
              Schlossstraße 8, D-56068 Koblenz
              <br />
              Phone: +49 261 106-0
              <br />
              Fax.: +49 261 106-234
              <br />
              info@koblenz.ihk.de
              <br />
              www.ihk-koblenz.de
            </p>
            <p>
              <strong>Chamber of Crafts (Handwerkskammer) Koblenz</strong>
              <br />
              Friedrich-Ebert-Ring 33, D-56068 Koblenz
              <br />
              Phone: +49 261 398-0
              <br />
              Phone: +49 261 398-398
              <br />
              hwk@hwk-koblenz.de
              <br />
              www.hwk-koblenz.de
            </p>
            <p>
              <strong>
                Information about online dispute resolution (ODR) pursuant to
                Article 14 (1) of the ODR Act (Verordnung über
                Online-Streitbeilegung (ODR-VO):
              </strong>
              <strong>
                <br />
              </strong>
              The EU Commission provides a platform for online resolution of
              disputes at http://ec.eu-ropa.eu/consumers/odr. The ODR platform
              serves as starting point for the out-of-court settlement of
              disputes concerning contractual obligations arising from online
              purchase contracts.
            </p>
            <p>
              We are neither obliged nor willing to participate in a dispute
              resolution process before a consumer arbitration board.
            </p>
            <p>
              <strong>
                Responsible for journalistic editorial
                contributions&nbsp;pursuant to § 55 (2) of the Interstate
                Broadcasting Agreement (RStV):
              </strong>
              <strong>
                <br />
              </strong>
              Philipp Komers (Sales Director for Germany, Austria,
              Switzerland),&nbsp;Clou-Straße 1, D-56751
              Polch,&nbsp;philipp.komers@niesmann-bischoff.de
            </p>
            <p>
              <strong>
                External data protection officer&nbsp;pursuant to Article 37,
                EU-GDPR in conjunction with § 4 (1) sentence 1, German Federal
                Data Protection Act (Bundesdatenschutzgesetz (BDSG)):
              </strong>
              <strong>
                <br />
              </strong>
              Stefan Fischerkeller
              <br />
              German Data Protection Chamber (Deutsche Datenschutzkanzlei, Lake
              Constance Office
              <br />
              www.deutsche-datenschutzkanzlei.de
            </p>
            <p>
              Contact the data protection officer by e-mail:&nbsp;
              <a
                href="mailto:fischerkeller@deutsche-datenschutzkanzlei.de"
                className="local"
              >
                fischerkeller@deutsche-datenschutzkanzlei.de
              </a>{' '}
              or{' '}
              <a
                href="mailto:datenschutz@niesmann-bischoff.com"
                className="local"
              >
                datenschutz@niesmann-bischoff.com
                <br />
              </a>
              or via the following data-protection
              website:&nbsp;www.deutsche-datenschutzkanzlei.de
            </p>
            <p>
              <strong>Conception and design of this website:</strong>
              <br />
              3st Kommunikation GmbH,
              <br />
              Taunusstraße 56-61, D-55118 Mainz
              <br />
              Phone: +49 6131 49961-0
              <br />
              info@3st.de
            </p>
            <p>
              <strong>Technical support for this website:</strong>
              <strong>
                <br />
              </strong>
              nbsp GmbH,
              <br />
              Küstriner Straße 14, D-94315 Straubing
              <br />
              Phone: +49 9421 78470
              <br />
              info@nbsp.de
            </p>
            <p>
              <strong>
                Features of the services provided by Niesmann+Bischoff GmbH:
              </strong>
              <strong>
                <br />
              </strong>
              Production of and trade in motorhomes, caravans, residential units
              and accessories.
            </p>
            <p>
              <strong>
                Price information pursuant to § 4 DL-InfoV and the price
                disclosure regulation (Preisangabenverordnung (PAngV))
              </strong>
              <strong>
                <br />
              </strong>
              Information on prices can be found on the Niesmann+Bischoff GmbH
              website.&nbsp;Niesmann+Bischoff GmbH will be happy to provide you
              with a quotation on request.
            </p>
            <p>
              <strong>
                Liability
                <br />
              </strong>
              The Niesmann+Bischoff GmbH may change this website at their own
              discretion in whole or in part without accepting any liability and
              discontinue the service without announcement. With a link to
              third-party websites (“hyperlinks”) Niesmann+Bischoff GmbH does
              not take ownership of this website nor of its content.
              Niesmann+Bischoff GmbH furthermore is not responsible for the
              availability of this website or of the contents.
            </p>
            <p>
              <strong>© Copyright</strong>
              <strong>
                <br />
              </strong>
              All rights reserved. Text, pictures and graphics as well as their
              arrangement on the website are subject to copyright and other
              protective laws. The content of these websites may not be copied,
              distributed, modified or made available to third parties for
              commercial purposes.
            </p>
            <p>
              <strong>© Copyright image sources: </strong>Niesmann+Bischoff GmbH
            </p>
            <p>
              <strong>
                Note: General Equal Treatment Act (AGG)
                <br />
              </strong>
              For <strong>reasons</strong> of better legibility it is refrained
              from a gender-specific differentiation. According terms are valid
              for both genders according to equal treatment.
              <strong>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
              </strong>
            </p>
            <p>
              <strong>
                These legal details and the declaration for data protection were
                created by:
                <br />
              </strong>
              Deutsche Datenschutzkanzlei Datenschutz-Office München –{' '}
              <a
                href="http://www.deutsche-datenschutzkanzlei.de"
                className="external"
              >
                www.deutsche-datenschutzkanzlei.de
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Impressum
