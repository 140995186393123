import React from 'react'

class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.state = { counter: 0 }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.updateCounter(), 150)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  updateCounter() {
    // random steps based on counter state, slow down at the end
    let step = Math.floor(Math.random() * 5 + 3)
    if (this.state.counter > 50 && this.state.counter < 75) {
      step = Math.floor(Math.random() * 3 + 2)
    } else if (this.state.counter >= 75) {
      step = 1
    }

    if (this.state.counter < 99) {
      this.setState({
        counter: this.state.counter + step
      })
    }
  }

  render() {
    return (
      <div className="loading__wrapper">
        <div className="loading">
          <div className="spinner"></div>
          <span>{this.state.counter} %</span>
        </div>
      </div>
    )
  }
}

export default Loading
