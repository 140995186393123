const API_URL = process.env.REACT_APP_API_URL
const ASSET_URL = 'https://konfigurator.niesmann-bischoff.com'

// i.e. REJECT_ISMOVE = ['fi_FI']
export const REJECT_ISMOVE = []
export const REJECT_FLAIR = []
export const REJECT_ARTO = []

// Configure image rendering for exterieur: ['static'/'api']
export const EXTERIEUR_IMAGE_RENDERING = 'static'

// Schrankausstattung Unterkategorie ID: 993
// Küchenausstattung Unterkategorie ID: 996
export const MULTISELECT_SUBCATEGORY_ID = ['993', '996', '980', '970']

// Serienausstattung Küche: VV99965F, VV99965A
// Serienausstattung Schrank: VV99970F, VV99970A
export const DEFAULT_EQUIPMENT = ['VV99960F', 'VV99965F', 'VV99965A']

export const FILTER_CLOULINE = ['sv_SE', 'no_NO']

export const START_BACKGROUND_IMAGE_AWARDS_EXCEPTIONS = [
  'en_GB',
  'nl_NL',
  'it_IT'
]

export const START_IMAGE_BASE_NAME = 'NIESMANN+BISCHOFF-Konfigurator-Startbild'

// export const MARKETING_LANDING_PAGES = ['de', 'en', 'it', 'fr', 'sv']
export const MARKETING_LANDING_PAGES = []

export const MODEL_PATH = '/assets/01-modelle/'

export const LAYOUT_PATH = '/assets/02-grundrisse/'

// Slider Images
const SLIDES_PATH = '/assets/03-exterieur/slides/'
// Felgen
const RIMS_PATH = '/assets/03-exterieur/slides_rims/'
// Kacheln
const TILES_PATH = '/assets/03-exterieur/tiles/'

export const seatCodes = [
  'V4062612A1',
  'V4062612A2',
  'V4044024F',
  'V4062612F',
  'V4044024',
  'V4062612I'
]

export const specialSeatCodes = ['V4044023A']

export function getApiUrl() {
  return API_URL
}

export function getAssetUrl() {
  return ASSET_URL
}

export function getSlidesPath() {
  return SLIDES_PATH
}

export function getRimsPath() {
  return RIMS_PATH
}

export function getTilesPath() {
  return TILES_PATH
}

export const psMotore = {
  // Arto
  V100601425A: 'PS178',
  V100601607A: 'PS178',
  // Flair
  V100601513: 'PS210',
  // iSmove
  V100601605: 'PS140',
  V100601604: 'PS178',
  V100601607I: 'PS178'
}

// ! Geo Location / IP Überprüfung in App.js !
export function setLocalization2() {
  setLocalization('de-DE', 'int_INT', 'EUR')

  let href = window.location.host.split('.')

  // niesmann-bischoff.com
  if (href[0] === 'konfigurator') {
    setLocalization('de-DE', 'de_DE', 'EUR')
  }
  if (href[0] === 'configurateur') {
    setLocalization('fr-FR', 'fr_FR', 'EUR')
  }
  if (href[0] === 'configurator') {
    setLocalization('en-GB', 'en_GB', 'GBP')
  }
  if (href[0] === 'configuratore') {
    setLocalization('it-IT', 'it_IT', 'EUR')
  }
  if (href[0] === 'sv') {
    setLocalization('sv-SE', 'sv_SE', 'SEK')
  }
  if (href[0] === 'at') {
    setLocalization('de-AT', 'at_AT', 'EUR')
  }
  if (href[0] === 'int') {
    setLocalization('de-DE', 'int_INT', 'EUR')
  }
  if (href[0] === 'nl') {
    setLocalization('nl-NL', 'nl_NL', 'EUR')
  }
  if (href[0] === 'be') {
    setLocalization('be-BE', 'be_BE', 'EUR')
  }
  if (href[0] === 'fi') {
    setLocalization('fi-FI', 'fi_FI', 'EUR')
  }

  // 3st-dev.de
  if (href[0] === 'nibi-konfigurator' || href[0] === 'nibi-de') {
    setLocalization('de-DE', 'de_DE', 'EUR')
  }
  if (href[0] === 'nibi-en') {
    setLocalization('en-GB', 'en_GB', 'GBP')
  }
  if (href[0] === 'nibi-fr') {
    setLocalization('fr-FR', 'fr_FR', 'EUR')
  }
  if (href[0] === 'nibi-it') {
    setLocalization('it-IT', 'it_IT', 'EUR')
  }
  if (href[0] === 'nibi-sv') {
    setLocalization('sv-SE', 'sv_SE', 'SEK')
  }
  if (href[0] === 'nibi-ch') {
    setLocalization('de-CH', 'ch_CH', 'EUR')
  }
  if (href[0] === 'nibi-int') {
    setLocalization('de-DE', 'int_INT', 'EUR')
  }
  if (href[0] === 'nibi-nl') {
    setLocalization('nl-NL', 'nl_NL', 'EUR')
  }
  if (href[0] === 'nibi-at') {
    setLocalization('de-AT', 'at_AT', 'EUR')
  }
  if (href[0] === 'nibi-no') {
    setLocalization('no-NO', 'no_NO', 'NOK')
  }
  if (href[0] === 'nibi-be') {
    setLocalization('be-BE', 'be_BE', 'EUR')
  }
  if (href[0] === 'nibi-fi') {
    setLocalization('fi-FI', 'fi_FI', 'EUR')
  }
}

export function getUser() {
  return window.configurator.user
}

export function getLanguage() {
  return window.currentLocalization.locale.split('_')[0]
}

export function domainForLanguage(language) {
  if (getApiUrl() === 'http://nibi-konfigurator.alpha.3st-dev.de/backend') {
    const devDomains = {
      int: 'http://nibi-int.alpha.3st-dev.de/',
      de: 'http://nibi-konfigurator.alpha.3st-dev.de',
      ch: 'http://nibi-ch.alpha.3st-dev.de/',
      it: 'http://nibi-it.alpha.3st-dev.de/',
      fr: 'http://nibi-fr.alpha.3st-dev.de/',
      en: 'http://nibi-en.alpha.3st-dev.de/',
      sv: 'http://nibi-sv.alpha.3st-dev.de/',
      nl: 'http://nibi-nl.alpha.3st-dev.de/',
      at: 'http://nibi-at.alpha.3st-dev.de/',
      no: 'http://nibi-no.alpha.3st-dev.de/',
      be: 'http://nibi-be.alpha.3st-dev.de/',
      fi: 'http://nibi-fi.alpha.3st-dev.de/'
    }
    return devDomains[language] || devDomains['de']
  }

  const domains = {
    de: ASSET_URL,
    // de: "https://konfigurator.niesmann-bischoff.com",
    it: 'https://configuratore.niesmann-bischoff.com',
    fr: 'https://configurateur.niesmann-bischoff.com',
    sv: 'https://sv.konfigurator.niesmann-bischoff.com',
    en: 'https://configurator.niesmann-bischoff.com'
  }
  return domains[language] || domains['de']
}

export function setLocalization(code, locale, currency) {
  window.currentLocalization = {
    code: code,
    locale: locale,
    currency: currency
  }
}

// AJAX Calls
export function loadCartDataForCode(code) {
  return fetch(getApiUrl() + `/import/cart?code=${code}`).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      throw new Error({ status: response.status })
    }
  })
}
