import React from 'react'
import Loading from './../Loading/Loading'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import ChapterListView from './../_Common/ChapterListView'
import {
  markItemsAsSelected,
  redirectToStart,
  addStandardEquipmentToCart
} from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Aufbau extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      aufbau: [],
      infobox: [],
      footnote: []
    }
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            item.details.map((detail) => {
              return selectedItems.push(detail.code)
            })
            return null
          })
        }
        return null
      })
    }

    const aufbau = this.state.aufbau

    aufbau.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ aufbau: aufbau })
  }

  findParentAndIndexForItem = (item) => {
    let found = false
    let parent_index = 0

    if (this.props.items) {
      this.props.items.forEach((chassi, index) => {
        if (!found) {
          parent_index = index
        }
        chassi.items.forEach((chassi_item) => {
          if (chassi_item.code === item.code) {
            if (!found) {
              found = true
            }
          }
        })
      })
    }
    return parent_index
  }

  componentDidMount() {
    this.context.updateCurrentNav(7)
    this.setState({ isLoading: true })
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/aufbau?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const new_aufbau = data.items ? data.items : []
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : []
          this.setState({
            aufbau: new_aufbau,
            footnote,
            infobox,
            isLoading: false
          })

          let headings = []
          this.state.aufbau.map((item, i) => {
            return headings.push(item.title)
          })

          if (headings.length > 1) {
            this.context.updateJumpmarks(headings)
          }

          // if user has already selected items
          if (
            typeof this.context.cart.aufbau !== 'undefined' &&
            Object.keys(this.context.cart.aufbau).length !== 0
          ) {
            this.setState({
              aufbau: markItemsAsSelected(
                this.state.aufbau,
                this.context.cart,
                'aufbau'
              )
            })
          } else {
            this.context.addStepsCompleted(7)
          }
          this.filterAlreadySelectedItems()
        })
        .then(async () => {
          // 2021-07-20 Hr. Kaiser: Für alle Sprachen: Alle Serienausstattungen mit Betrag 0,- sollen auch ins PDF
          const aufbau = await addStandardEquipmentToCart(
            this.context,
            this.state.aufbau,
            'aufbau'
          )
          this.setState({ aufbau })
        })
    } catch (error) {
      redirectToStart()
    }
  }

  componentWillUnmount() {
    this.context.updateJumpmarks([])
  }

  render() {
    const { aufbau, isLoading } = { ...this.state }

    if (isLoading) {
      return <Loading></Loading>
    }

    return (
      <div>
        <ChapterListView items={aufbau} chapter="aufbau"></ChapterListView>
        <Infobox content={this.state.infobox} />
        {this.state.footnote.length > 0 ? (
          <Footnote footnote={this.state.footnote} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

Aufbau.contextType = AppContext
export default Aufbau
