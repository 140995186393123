import React from 'react'
import {
  getApiUrl,
  getLanguage,
  seatCodes,
  specialSeatCodes
} from '../../config'
import { AppContext } from '../../Data/ContextProvider'
import { flatCart, renderMarkup, translate, getCartCodes } from '../../helpers'
import ItemRow from '../ItemRow/ItemRow'

class MaxWeightOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      weightUpgrade: [],
      dataSitzplatz: []
    }
  }

  toggleCartOverview = (e, cart, config) => {
    e.preventDefault()
    this.handleSaveCart(cart, config)
    this.context.toggleCartOverview()
  }

  handleSaveCart = (cart, config) => {
    if (cart) {
      fetch(getApiUrl() + `/import/cart?lang=` + getLanguage(), {
        method: 'POST',
        body: JSON.stringify({
          cart: cart,
          code: cart.code,
          steps_completed: config.steps_completed
        })
      })
        .then((response) => response.json())
        .then((data) => {
          let cart = data.cart
          cart.code = data.code
          this.context.initCart(cart)
          this.context.initStepsCompleted(data.steps_completed)
        })
    }
  }

  addItem = (parent_index, item, chapter) => {
    this.props.addItem(parent_index, item, chapter)

    if (this.state.removeEquipment) {
      this.props.removeItem(this.state.removeEquipment)
    }
  }

  async componentDidMount() {
    const auflastungUrl =
      getApiUrl() +
      `/data/auflastung?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
      getLanguage()

    const sitzplatzUrl =
      getApiUrl() +
      `/data/sitzplatz?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
      getLanguage()

    try {
      if (
        this.context.cart.model.title === 'Arto' &&
        this.context.cart.layout.title !== 'Arto 79 R'
      ) {
        Promise.all([
          fetch(auflastungUrl).then((response) => response.json()),
          fetch(sitzplatzUrl).then((response) => response.json())
        ]).then((data) => {
          const cardCodes = getCartCodes(this.context.cart)

          const weightUpgrade = data[0].filter((item) => {
            if (!cardCodes.includes(item.code)) {
              return item
            }
            return null
          })

          const dataSitzplatz = data[1]

          this.setState({
            weightUpgrade,
            dataSitzplatz
          })
        })
      } else {
        fetch(auflastungUrl)
          .then((response) => response.json())
          .then((data) => {
            const cardCodes = getCartCodes(this.context.cart)

            const weightUpgrade = cardCodes.includes('V100601607I')
              ? []
              : data.filter((item) => {
                  if (!cardCodes.includes(item.code)) {
                    return item
                  }
                  return null
                })

            const removeEquipment =
              weightUpgrade.length > 0 &&
              weightUpgrade[0].code === 'V100601607I'
                ? data[0]
                : null

            this.setState({
              weightUpgrade,
              removeEquipment
            })
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  renderSeats = (propsCart) => {
    let cart = flatCart(propsCart)

    // filter regionale Serienausstattungen, die nicht abwählbar sind - z.B. FR: V4044024
    cart = cart.filter((item) => !item.serie)

    const codesInCart = cart.map((item) => item.code)
    const found = seatCodes.some((code) => codesInCart.includes(code))

    return found
  }

  renderSeatItems = (propsCart) => {
    const cart = flatCart(propsCart)
    if (cart.length === 0) {
      return []
    }

    const selectedItems = []
    const codes = seatCodes

    cart.forEach((item) => {
      if (codes.includes(item.code)) {
        selectedItems.push(item)
      }
    })

    return selectedItems
  }

  renderSpecialSeat = (propsCart) => {
    const cart = flatCart(propsCart)
    if (cart.length === 0) {
      return []
    }

    const codesInCart = cart.map((item) => item.code)
    const codes = specialSeatCodes
    const found = codes.some((code) => codesInCart.includes(code))

    return !found
  }

  render() {
    const { cart } = { ...this.context }
    const specialSeat = this.state.dataSitzplatz
    const seatItems = this.renderSeatItems(cart)

    const overweightValue = (
      cart.totalWeight - cart.maxAddWeight
    ).toLocaleString(window.currentLocalization.code)

    const weightWarningHeadline = translate(
      'max_weight_overlay',
      'weight_warning_headline'
    ).replace('{weightToken}', overweightValue)

    return (
      <div className="konfigurator__conflict konfigurator__overlay is-active">
        <div className="g g-inset">
          <div className="g konfigurator__overlay__infos text">
            <h2>{translate('max_weight_overlay', 'headline')}</h2>

            <div
              className="max-weight-overlay text-red"
              dangerouslySetInnerHTML={{
                __html: weightWarningHeadline
              }}
            />

            <h3>{translate('max_weight_overlay', 'subheadline')}</h3>
            <p className="u-margin-top--1x">
              {translate('max_weight_overlay', 'intro')}
            </p>
            <hr />
          </div>
          <div className="konfigurator__container">
            {this.state.weightUpgrade.length > 0 ? (
              <React.Fragment>
                <section className="g">
                  <h3
                    className="u-margin-bottom--2x"
                    dangerouslySetInnerHTML={renderMarkup(
                      translate(
                        'max_weight_overlay',
                        'weight_increase_headline'
                      )
                    )}
                  ></h3>

                  {this.state.weightUpgrade.map((item, i) => (
                    <ItemRow
                      key={i}
                      i={i}
                      child_item={item}
                      addItem={() => this.addItem(0, item, 'chassis')}
                      removeItem={() => this.props.removeItem(item)}
                    />
                  ))}
                </section>

                <hr />
              </React.Fragment>
            ) : null}

            {this.renderSeats(cart) && (
              <React.Fragment>
                <section className="g">
                  <h3
                    className="u-margin-bottom--2x"
                    dangerouslySetInnerHTML={renderMarkup(
                      translate('max_weight_overlay', 'seat_headline')
                    )}
                  ></h3>

                  {this.renderSpecialSeat(cart) && (
                    <React.Fragment>
                      {specialSeat.map((item, i) => (
                        <ItemRow
                          key={i}
                          i={i}
                          child_item={item}
                          addItem={() => this.props.addItem(0, item, 'aufbau')}
                          removeItem={() => this.props.removeItem(item)}
                        />
                      ))}
                    </React.Fragment>
                  )}

                  {seatItems.map((item, i) => (
                    <ItemRow
                      key={i}
                      i={i}
                      child_item={item}
                      removeItem={() => this.props.removeItem(item)}
                    />
                  ))}
                </section>

                <hr />
              </React.Fragment>
            )}

            <section className="g">
              <h3
                className="u-margin-bottom--2x"
                dangerouslySetInnerHTML={renderMarkup(
                  translate('max_weight_overlay', 'equiptment_headline')
                )}
              ></h3>
              <div className="konfigurator__container__item g u-boxed u-boxed--has-nested u-margin-bottom--1x bg_grey-50 u-text-right">
                <div className="g-m--up-4 g-m--down-12 g-nested">
                  <button
                    className="button button--cta"
                    name="delete"
                    onClick={this.props.closeMaxWeightOverlay}
                  >
                    <span>
                      {translate('max_weight_overlay', 'back_button')}
                    </span>
                  </button>
                </div>
              </div>
            </section>

            <hr />

            <div className="g-m--down-12 u-text-right">
              <button
                className="button"
                name="cancel"
                onClick={this.props.closeMaxWeightOverlay}
              >
                <span>{translate('global', 'error_overlay_cancel')}</span>
              </button>
            </div>
          </div>
          {/*

          // TODO INFOBOX ("extra weight disclaimer") in MaxWeightOverlay

          <div className="infobox">
            <div
              dangerouslySetInnerHTML={{ __html: 'INFOBOX 1' }}
              className="infobox__content text"
            />
          </div>
          <div className="infobox">
            <div
              dangerouslySetInnerHTML={{ __html: 'INFOBOX 2' }}
              className="infobox__content text"
            />
          </div>

          */}
        </div>
      </div>
    )
  }
}

MaxWeightOverlay.contextType = AppContext
export default MaxWeightOverlay
