import React from 'react'
import Loading from './../Loading/Loading'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import ConflictOverlay from './../Overlay/ConflictOverlay'
import PaketItem from './PaketItem'
import {
  recalculatePricesAndWeights,
  saveToCart,
  deleteInCart,
  redirectToStart,
  loadExtendedAttributes,
  filterOptionsAlreadyInCart,
  markItemsAsSelected
} from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'
import MaxWeightOverlay from '../Overlay/MaxWeightOverlay'

class Pakete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pakete: [],
      footnote: [],
      items: [],
      infobox: '',
      attributes: {},
      conflictItem: null,
      conflictingItem: null,
      conflictingItems: [],
      conflictAction: 'del',
      negativeCallBackAction: this.removeConflictingPackage
    }
  }

  closeConflictOverlay = () => {
    this.context.toggleConflictOverlay()
  }

  closeMaxWeightOverlay = () => {
    this.context.maxWeightOverlay(false)
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const items = this.state.items

    items.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ items: items })
  }

  addToChapter = async (parent_index, item, chapter = this.props.chapter) => {
    const title = item.chapterTitle
    const new_cart = saveToCart(
      item,
      chapter,
      this.context.cart,
      parent_index,
      title
    )
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: await markItemsAsSelected(this.state.items, new_cart, chapter)
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  removeFromChapter = async (item) => {
    const new_cart = deleteInCart(this.context.cart, item)
    await this.context.updateCart(new_cart)
    this.setState(
      {
        items: markItemsAsSelected(
          this.state.items,
          new_cart,
          this.props.chapter
        )
      },
      async () => {
        this.filterAlreadySelectedItems()
      }
    )

    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)
  }

  addConflictingPackage = (overlayProps) => {
    const item = overlayProps.item
    const itemsToRemove = overlayProps.conflictingItems

    if (itemsToRemove) {
      itemsToRemove.forEach((iTr) => {
        deleteInCart(this.context.cart, iTr)
      })
    }

    this.addPackage(item)

    this.closeConflictOverlay()
  }

  removeConflictingPackage = (overlayProps) => {
    const packageToRemove = overlayProps.conflictingItem
    const packageToAdd = overlayProps.item
    const itemsToRemove = overlayProps.conflictingItems || []
    const dependenciesForExclusions =
      overlayProps.dependenciesForExclusions || []

    let new_cart = this.context.cart

    itemsToRemove.forEach((item) => {
      deleteInCart(new_cart, item)
    })

    if (packageToAdd && !packageToRemove) {
      this.addPackage(packageToAdd)
    }

    if (packageToRemove) {
      this.removePackage(packageToRemove)
    }

    dependenciesForExclusions.forEach((item) => {
      deleteInCart(new_cart, item)
    })

    this.closeConflictOverlay()
  }

  selectPackage = async (selectedItem) => {
    let please_add = true
    const attributes = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    this.setState({ attributes })

    const detailsExclusions = await filterOptionsAlreadyInCart(
      this.context.cart,
      attributes.exclusions
    )

    if (detailsExclusions && detailsExclusions.length > 0) {
      this.setState({
        conflictItem: selectedItem,
        conflictingItems: detailsExclusions,
        conflictOverlayOpen: true,
        conflictAction: 'exl',
        positiveCallBackAction: this.addConflictingPackage
      })
      please_add = false
    }

    if (please_add) {
      this.addPackage(selectedItem)
    } else {
      this.context.toggleConflictOverlay()
    }
  }

  addXClusions = async () => {
    if (this.state.attributes.length < 1) {
      return
    }

    if (this.state.attributes.xExclusions) {
      for (const item of this.state.attributes.xExclusions) {
        const new_cart = await deleteInCart(this.context.cart, item)
        await this.context.updateCart(new_cart)
      }
    }

    if (this.state.attributes.xInclusions) {
      for (const item of this.state.attributes.xInclusions) {
        if (item.tab === 'exterieur') {
          const new_cart = await saveToCart(
            item,
            'exterieur',
            this.context.cart
          )
          await this.context.updateCart(new_cart)
        }
      }
    }

    const new_cart = await recalculatePricesAndWeights(this.context.cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {} })

    return
  }

  removeXClusions = async () => {
    if (this.state.attributes.xInclusions) {
      for (const item of this.state.attributes.xInclusions) {
        const new_cart = await deleteInCart(this.context.cart, item)
        await this.context.updateCart(new_cart)
      }
    }

    if (this.state.attributes.xExclusions) {
      for (const item of this.state.attributes.xExclusions) {
        if (item.tab === 'exterieur') {
          const new_cart = await saveToCart(
            item,
            'exterieur',
            this.context.cart
          )
          await this.context.updateCart(new_cart)
        }
      }
    }

    const new_cart = await recalculatePricesAndWeights(this.context.cart)
    await this.context.updateCart(new_cart)

    this.setState({ attributes: {} })

    return
  }

  addPackage = async (newPackage) => {
    await this.addXClusions()
    const new_cart = saveToCart(newPackage, 'packages', this.context.cart)
    this.context.updateCart(new_cart)
    this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)

    this.setState({
      pakete: this.markItemsAsSelected(this.state.pakete, new_cart)
    })
  }

  removePackage = async (selectedItem) => {
    let please_remove = true

    const attributes = await loadExtendedAttributes(
      this.context.cart.layout.productId,
      selectedItem.code
    )

    this.setState({ attributes })

    // Haengen von diesem Produkt andere Produkte ab?
    const dependencies = await filterOptionsAlreadyInCart(
      this.context.cart,
      attributes.dependencies
    )

    if (dependencies && dependencies.length > 0) {
      this.setState({
        conflictItem: null,
        conflictingItem: selectedItem,
        conflictingItems: dependencies,
        conflictAction: 'del',
        negativeCallBackAction: this.removeConflictingPackage
      })
      please_remove = false
    }

    if (please_remove) {
      const new_cart = await deleteInCart(this.context.cart, selectedItem)
      await this.context.updateCart(new_cart)
      this.context.maxWeightOverlay(this.context.cart.reachedMaxWeight)

      await this.removeXClusions()

      this.setState({
        pakete: this.markItemsAsSelected(this.state.pakete, new_cart)
      })
    } else {
      this.context.toggleConflictOverlay()
    }
  }

  markItemsAsSelected = (items, cart) => {
    const selected = []

    if (!cart.packages) {
      return items
    }

    cart.packages[0].items.forEach((item) => {
      selected.push(item.code)
    })

    // set selected Items
    items[0].items.forEach((item, index) => {
      items[0].items[index].selected = selected.includes(item.code)
    })
    return items
  }

  async componentDidMount() {
    await this.context.updateCurrentNav(5)
    this.context.updateJumpmarks([])
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/pakete?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const new_package = data.items ? data.items : []
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : ''
          this.setState({ pakete: new_package, footnote, infobox })
          // if user has already selected items
          if (
            this.context.cart.packages &&
            this.context.cart.packages.length &&
            this.context.cart.packages[0] !== null
          ) {
            this.setState({
              pakete: this.markItemsAsSelected(
                this.state.pakete,
                this.context.cart
              )
            })
          }
          this.props.addStepsCompleted(5)
        })
        .then(() => {
          // 2021-07-20 Hr. Kaiser: Für alle Sprachen: Alle Serienausstattungen mit Betrag 0,- sollen auch ins PDF
          const specialItem = this.state.pakete
            .flatMap((cg) => cg.items)
            .filter((c) => c.serie && !c.price)

          specialItem.map(async (item) => {
            await this.removePackage(item)
            await this.addPackage(item)
            return ''
          })
        })
    } catch (error) {
      redirectToStart()
    }
  }

  render() {
    if (!this.state.pakete.length) {
      return <Loading></Loading>
    }

    return (
      <div className="konfigurator__pakete konfigurator__accessories">
        <div className="g g-inset">
          {this.state.pakete.map((item, index) => (
            <div
              key={index}
              className="konfigurator__container konfigurator__container--flex"
            >
              <h2 className="js-tab-headline">{item.title}</h2>
              <section className="g has-nested">
                {item.items.map((child_item, i) => (
                  <PaketItem
                    key={i}
                    item={child_item}
                    removePackage={this.removePackage}
                    selectPackage={this.selectPackage}
                    psmotor={this.context.cart.layout.PSMotor}
                  />
                ))}
              </section>
            </div>
          ))}
        </div>
        {this.context.config.conflictOverlayOpen && (
          <ConflictOverlay
            item={this.state.conflictItem}
            conflictingItem={this.state.conflictingItem}
            action={this.state.conflictAction}
            negativeCallBackAction={this.state.negativeCallBackAction}
            positiveCallBackAction={this.addConflictingPackage}
            closeConflictOverlay={this.closeConflictOverlay}
            conflictingItems={this.state.conflictingItems}
            dependenciesForExclusions={this.state.dependenciesForExclusions}
          />
        )}

        {this.context.maxWeightOverlayOpen && (
          <MaxWeightOverlay
            closeMaxWeightOverlay={this.closeMaxWeightOverlay}
            addItem={this.addToChapter}
            removeItem={this.removeFromChapter}
          />
        )}

        <Infobox content={this.state.infobox} />
        {this.state.footnote.length > 0 ? (
          <Footnote footnote={this.state.footnote} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

Pakete.contextType = AppContext
export default Pakete
