import React from 'react'
import { checkPrice, renderMarkup, translate } from '../../helpers'
import { getAssetUrl } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class CartItemCard extends React.Component {
  render() {
    if (!this.props.equipment) {
      return null
    }

    return (
      <div className="g-s--down-12 g-s--up-6 g-m--up-4 g-lg--up-3 g-nested">
        <h3>{this.props.title}</h3>
        <div className="konfigurator__container__item g u-boxed u-boxed--has-nested">
          <div className="g-8 g-nested">
            <strong>
              <em>
                <div
                  dangerouslySetInnerHTML={renderMarkup(
                    this.props.equipment.title
                  )}
                />
              </em>
            </strong>
            <br />
            <span className="konfigurator__container__item__code small">
              {translate('global', 'option_code')} {this.props.equipment.code}
            </span>
            <br />
            <span className="small">
              {translate('global', 'additional_weight')}{' '}
              {this.props.equipment.weight}{' '}
              {translate('cart_overview', 'unit_of_weight_measurement')}*
            </span>
          </div>
          <div className="g-4 u-text-right">
            <strong>
              <em>
                {checkPrice(
                  this.props.equipment,
                  this.context.cart.layout.PSMotor
                )}
              </em>
            </strong>
            <br />
          </div>
          <hr />
          <img
            src={getAssetUrl() + this.props.equipment.img}
            className="img--inset"
            alt={this.props.equipment.title}
          />
        </div>
      </div>
    )
  }
}

CartItemCard.contextType = AppContext
export default CartItemCard
