import React from 'react'

import ToolbarListItems from './ToolbarListItems'

class Toolbar extends React.Component {
  handleHover(event) {
    let toolbar = document.querySelector('#js-toolbar')
    let html = document.querySelector('html')

    if (html.classList.contains('ios')) {
      if (!toolbar.classList.contains('hover')) {
        event.preventDefault()
        toolbar.classList.add('hover')

        window.setTimeout(function () {
          toolbar.classList.remove('hover')
        }, 5000)
      } else {
        toolbar.classList.remove('hover')
      }
    }
  }

  render() {
    return (
      <div className="toolbar" id="js-toolbar">
        <ul onClick={(e) => this.handleHover(e)}>
          <ToolbarListItems />
        </ul>
      </div>
    )
  }
}

export default Toolbar
