import React from 'react'
import FooterLink from './../Overlay/FooterLink'
import { NavLink } from 'react-router-dom'
import { translate } from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      footer_navigation: [
        {
          text: 'Start',
          path: '/'
        },
        {
          text: translate('global', 'nav_model'),
          path: '/01-modell'
        },
        {
          text: translate('global', 'nav_layout'),
          path: '/02-grundriss'
        },
        {
          text: translate('global', 'nav_exterieur'),
          path: '/03-exterieur'
        },
        {
          text: translate('global', 'nav_interieur'),
          path: '/04-interieur'
        },
        {
          text: translate('global', 'nav_pakete'),
          path: '/05-pakete'
        },
        {
          text: translate('global', 'nav_chassis'),
          path: '/06-chassis'
        },
        {
          text: translate('global', 'nav_aufbau'),
          path: '/07-aufbau'
        },
        {
          text: translate('global', 'nav_technique'),
          path: '/08-technik'
        },
        {
          text: translate('cart_overview', 'configuration'),
          path: '/08-technik'
        }
      ]
    }
  }

  showCookiebot = () => {
    if (document.getElementById('CybotCookiebotDialog')) {
      document.getElementById('CybotCookiebotDialog').style.opacity = 1
      document.getElementById('CybotCookiebotDialog').style.display = 'block'
    }
  }

  toggleCartOverview = (e, cart, config) => {
    e.preventDefault()
    this.handleSaveCart(cart, config)
    this.context.toggleCartOverview()
  }

  handleSaveCart = (cart, config) => {
    if (cart) {
      fetch(getApiUrl() + `/import/cart?lang=` + getLanguage(), {
        method: 'POST',
        body: JSON.stringify({
          cart: cart,
          code: cart.code,
          steps_completed: config.steps_completed
        })
      })
        .then((response) => response.json())
        .then((data) => {
          let cart = data.cart
          cart.code = data.code
          this.context.initCart(cart)
          this.context.initStepsCompleted(data.steps_completed)
        })
    }
  }

  render() {
    const { config, cart } = { ...this.context }
    const next_nav = this.state.footer_navigation[config.current_nav + 1]
    let currentParentTabs = config.currentParentTabs

    let footer_text
    let footer_button

    // If current navigation is not Technik (9)
    if (config.current_nav + 1 !== 9) {
      // If current navigation is not Exterieur (3) and Interieur (4)
      if (config.current_nav !== 3 && config.current_nav !== 4) {
        footer_text = <p>{next_nav.text}</p>
        footer_button = (
          <NavLink to={next_nav.path} className="button">
            <span>{translate('global', 'next_page_button')}</span>
          </NavLink>
        )
      } else {
        // If current navigation is Exterieur (3)
        if (currentParentTabs && config.current_nav === 3) {
          if (currentParentTabs.length <= config.exterieurTab1 + 1) {
            footer_text = <p>{next_nav.text}</p>
            footer_button = (
              <NavLink to={next_nav.path} className="button">
                <span>{translate('global', 'next_page_button')}</span>
              </NavLink>
            )
          } else {
            footer_text = (
              <p>{currentParentTabs[config.exterieurTab1 + 1].title}</p>
            )
            footer_button = (
              <button
                className="button"
                onClick={(e) => {
                  this.context.changeSelectedTab(
                    config.exterieurTab1 + 1,
                    'exterieurTab1'
                  )
                }}
              >
                {translate('global', 'next_page_button')}
              </button>
            )
          }
          // If current navigation is Interieur (4)
        } else if (currentParentTabs && config.current_nav === 4) {
          if (currentParentTabs.length <= config.exterieurTab2 + 1) {
            footer_text = <p>{next_nav.text}</p>
            footer_button = (
              <NavLink to={next_nav.path} className="button">
                <span>{translate('global', 'next_page_button')}</span>
              </NavLink>
            )
          } else {
            footer_text = (
              <p>{currentParentTabs[config.exterieurTab2 + 1].title}</p>
            )
            footer_button = (
              <button
                className="button"
                onClick={(e) => {
                  this.context.changeSelectedTab(
                    config.exterieurTab2 + 1,
                    'exterieurTab2'
                  )
                }}
              >
                {translate('global', 'next_page_button')}
              </button>
            )
          }
        }
      }
    } else {
      footer_text = <p>{next_nav.text}</p>
      footer_button = (
        <button
          className="button"
          onClick={(e) => {
            this.toggleCartOverview(e, cart, config)
          }}
        >
          {translate('global', 'next_page_button')}
        </button>
      )
    }

    return (
      <div className="konfigurator__footer text">
        {config.current_nav > 2 && (
          <div className="konfigurator__footer__top g g-inset">
            {footer_text}
            {footer_button}
          </div>
        )}
        <div className="konfigurator__footer__bottom g g-inset has-nested">
          <div className="konfigurator__footer__bottom--left g-sm--down-12 g-sm--up-12 g-nested">
            <FooterLink
              name={translate('global', 'imprint', true)}
              infos={{ content: 'imprint' }}
              {...this.context}
            />
            <FooterLink
              name={translate('global', 'data_protection', true)}
              infos={{ content: 'data_protection' }}
              {...this.context}
            />
            <button
              name={translate('global', 'cookiesettings', true)}
              onClick={this.showCookiebot}
            >
              {translate('global', 'cookiesettings', true)}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

Footer.contextType = AppContext
export default Footer
