import React from 'react'
import Loading from './../Loading/Loading'
import Infobox from './../Infobox/Infobox'
import Footnote from './../Footnote/Footnote'
import ChapterListView from './../_Common/ChapterListView'
import {
  markItemsAsSelected,
  redirectToStart,
  addStandardEquipmentToCart
} from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Chassis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      infobox: [],
      chassis: [],
      footnote: []
    }
  }

  filterAlreadySelectedItems = () => {
    let selectedItems = []

    if (this.context.cart.packages) {
      this.context.cart.packages.map((paket) => {
        if (paket !== null) {
          paket.items.map((item) => {
            if (item.details) {
              item.details.map((detail) => {
                return selectedItems.push(detail.code)
              })
            }
            return null
          })
        }
        return null
      })
    }

    const chassis = this.state.chassis

    chassis.map((level, index) => {
      level.items.map((item) => {
        if (selectedItems.includes(item.code)) {
          return (item.disabled = true)
        } else {
          return (item.disabled = false)
        }
      })
      return null
    })

    this.setState({ chassis: chassis })
  }

  componentDidMount() {
    this.context.updateCurrentNav(6)
    window.scrollTo(0, 0)

    try {
      fetch(
        getApiUrl() +
          `/data/chassis?modell=${this.context.cart.model.title}&grundriss=${this.context.cart.layout.title}&lang=` +
          getLanguage()
      )
        .then((response) => response.json())
        .then((data) => {
          const new_chassis = data.items ? data.items : []
          const footnote = data.footnote ? data.footnote : []
          const infobox = data.infobox ? data.infobox : []

          this.setState({ chassis: new_chassis, infobox, footnote })

          let headings = []
          this.state.chassis.map((item, i) => {
            return headings.push(item.title)
          })

          if (headings.length > 1) {
            this.context.updateJumpmarks(headings)
          }

          // if user has already selected items
          if (
            typeof this.context.cart.chassis !== 'undefined' &&
            Object.keys(this.context.cart.chassis).length !== 0
          ) {
            this.setState({
              chassis: markItemsAsSelected(
                this.state.chassis,
                this.context.cart,
                'chassis'
              )
            })
          }
          this.context.addStepsCompleted(6)
          this.filterAlreadySelectedItems()
        })
        .then(async () => {
          // 2021-07-20 Hr. Kaiser: Für alle Sprachen: Alle Serienausstattungen mit Betrag 0,- sollen auch ins PDF
          // 2024-07-12 Hr. Kaiser: Alle Serienausstattungen (Pflichtausstattungen) werden in den Warenkorb/PDF übernommen (auch mit Preis)
          const chassis = await addStandardEquipmentToCart(
            this.context,
            this.state.chassis,
            'chassis'
          )
          this.setState({ chassis })
        })
    } catch (error) {
      redirectToStart()
    }
  }

  componentWillUnmount() {
    this.context.updateJumpmarks([])
  }

  render() {
    if (!this.state.chassis.length) {
      return <Loading></Loading>
    }

    return (
      <div>
        <ChapterListView
          items={this.state.chassis}
          chapter="chassis"
        ></ChapterListView>

        {this.state.infobox.map((box, index) => (
          <Infobox key={index} content={box.content} />
        ))}
        {this.state.footnote.length > 0 ? (
          <Footnote footnote={this.state.footnote} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

Chassis.contextType = AppContext
export default Chassis
