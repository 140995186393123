import React from 'react'
import { withRouter } from 'react-router-dom'
import { history } from '../../Data/Store'
import { loadCartDataForCode } from '../../config'
import { redirectToStart } from '../../helpers'

class CartInit extends React.Component {
  componentDidMount() {
    try {
      const code = this.props.match.params.code

      loadCartDataForCode(code).then((config) => {
        const json = JSON.parse(config.configuration)
        json.cart.code = code
        this.props.initCart(json.cart)
        this.props.initStepsCompleted(json.steps_completed)
        history.push('/03-exterieur')
      })
    } catch (error) {
      redirectToStart()
    }
  }

  render() {
    return ''
  }
}

export default withRouter(CartInit)
