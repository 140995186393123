import React, { createContext, Component } from 'react'

export const AppContext = createContext()

const defaultConfig = {
  current_nav: 0,
  conflictOverlayOpen: false,
  cartoverview_open: false,
  steps_completed: [0], // Start must be completed for mobile menu
  jumpmarks: [],
  infoOverlay: {},
  infoOverlay_open: false,
  galleryOverlay: {},
  galleryOverlayOptions: {
    history: false,
    closeOnScroll: false,
    zoomEl: false,
    shareEl: false,
    index: 0
  },
  galleryOverlay_open: false,
  maxWeightOverlayOpen: false,
  exterieurTab1: 0,
  exterieurTab2: 0,
  currentParentTabs: []
}

const defaultCart = {
  reachedMaxWeight: false
}

class ContextProvider extends Component {
  state = {
    config: defaultConfig,
    cart: defaultCart,

    initCart: (cart) => {
      // HOTFIX 2024-09-20: Old carts could not be loaded because some interieur items were null
      // Remove interieur items that are null
      if (cart.interieur) {
        cart.interieur = cart.interieur.filter(Boolean)
      }

      this.setState(
        {
          cart
        },
        () => {
          return true
        }
      )
    },

    initStepsCompleted: (steps_completed) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            steps_completed
          }
        },
        () => {
          return true
        }
      )
    },

    addStepsCompleted: (index) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            steps_completed: [...this.state.config.steps_completed, index]
          }
        },
        () => {
          return true
        }
      )
    },

    clearStepsCompleted: () => {
      this.setState(
        {
          config: {
            ...this.state.config,
            steps_completed: [0]
          }
        },
        () => {
          return true
        }
      )
    },

    closeConflictOverlay: () => {
      this.setState(
        {
          config: {
            ...this.state.config,
            conflictOverlayOpen: false
          }
        },
        () => {
          return true
        }
      )
    },

    toggleConflictOverlay: () => {
      this.setState(
        {
          config: {
            ...this.state.config,
            conflictOverlayOpen: !this.state.config.conflictOverlayOpen
          }
        },
        () => {
          return true
        }
      )
    },

    toggleCartOverview: () => {
      this.setState(
        {
          config: {
            ...this.state.config,
            cartoverview_open: !this.state.config.cartoverview_open
          }
        },
        () => {
          return true
        }
      )
    },

    updateCurrentNav: (index) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            current_nav: index
          }
        },
        () => {
          return true
        }
      )
    },

    updateCurrentParentTabs: (currentParentTabs) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            currentParentTabs
          }
        },
        () => {
          return true
        }
      )
    },

    changeSelectedTab: (tabs, tabNamespace) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            [tabNamespace]: tabs
          }
        },
        () => {
          return true
        }
      )
    },

    updateJumpmarks: (jumpmarks) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            jumpmarks
          }
        },
        () => {
          return true
        }
      )
    },

    updateInfoOverlay: (item) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            infoOverlay: item,
            infoOverlay_open: !this.state.config.infoOverlay_open
          }
        },
        () => {
          return true
        }
      )
    },

    toggleInfoOverlay: () => {
      this.setState(
        {
          config: {
            ...this.state.config,
            infoOverlay_open: !this.state.config.infoOverlay_open
          }
        },
        () => {
          return true
        }
      )
    },

    updateUser: (user) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            user
          }
        },
        () => {
          return true
        }
      )
    },

    updateModel: (model) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            model
          }
        },
        () => {
          return true
        }
      )
    },

    updateLayout: (layout) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            layout
          }
        },
        () => {
          return true
        }
      )
    },

    updateWeightBlock: (weightBlock) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            weightBlock
          }
        },
        () => {
          return true
        }
      )
    },

    updateExterieur: (exterieur) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            exterieur
          }
        },
        () => {
          return true
        }
      )
    },

    updateInterieur: (interieur) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            interieur
          }
        },
        () => {
          return true
        }
      )
    },

    updatePackages: (packages) => {
      this.setState(
        {
          cart: {
            ...this.state.cart,
            packages
          }
        },
        () => {
          return true
        }
      )
    },

    updateCart: (cart) => {
      this.setState(
        {
          cart
        },
        () => {
          return true
        }
      )
    },

    toggleGalleryOverlay: (e, i) => {
      if (e !== undefined) {
        e.preventDefault()
      }

      if (i !== undefined) {
        this.setState(
          {
            config: {
              ...this.state.config,
              galleryOverlayOptions: {
                ...this.state.galleryOverlayOptions,
                index: i
              },
              galleryOverlay_open: !this.state.config.galleryOverlay_open
            }
          },
          () => {
            return true
          }
        )
      } else {
        this.setState(
          {
            config: {
              ...this.state.config,
              galleryOverlay_open: !this.state.config.galleryOverlay_open
            }
          },
          () => {
            return true
          }
        )
      }
    },

    updateGalleryOverlay: (gallery) => {
      this.setState(
        {
          config: {
            ...this.state.config,
            galleryOverlay: gallery
          }
        },
        () => {
          return true
        }
      )
    },

    clearModel: () => {
      this.setState(
        {
          cart: defaultCart
        },
        () => {
          return true
        }
      )
    },

    clearLayout: (model) => {
      this.setState(
        {
          cart: {
            ...defaultCart,
            model
          }
        },
        () => {
          return true
        }
      )
    },

    maxWeightOverlay: (bool) => {
      this.setState(
        {
          maxWeightOverlayOpen: bool
        },
        () => {
          return true
        }
      )
    }
  }

  componentDidUpdate() {
    const store = {
      cart: this.state.cart,
      config: this.state.config
    }
    sessionStorage.setItem('store', JSON.stringify(store))
  }

  UNSAFE_componentWillMount() {
    const store = sessionStorage.getItem('store')
      ? JSON.parse(sessionStorage.getItem('store'))
      : {}

    let cart = {}
    let config = defaultConfig

    if (store.cart) {
      cart = store.cart
    }

    if (store.config) {
      config = store.config
    }

    this.setState({
      cart,
      config
    })
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default ContextProvider
