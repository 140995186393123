import React from 'react'
import SVG from './../SVG/SVG'
import Impressum from './../Impressum/Impressum'
import ImportantInfos from './../ImportantInfos/ImportantInfos'
import Datenschutz from './../Datenschutz/Datenschutz'
import View360 from './../View360/View360'
import { formatPrice, renderMarkup, translate } from '../../helpers'
import { getAssetUrl } from '../../config'
import Footnote from '../Footnote/Footnote'

var classNames = require('classnames')

class InfoOverlay extends React.Component {
  handleDetailClick(event) {
    let id = event.target.dataset.trigger
    let entry = document.querySelector('[data-entry="' + id + '"]')

    if (entry) {
      entry.classList.toggle('details__entry--active')
    }
  }

  render() {
    const { config } = { ...this.props }

    return Object.keys(config).length !== 0 &&
      Object.keys(config.infoOverlay).length ? (
      <React.Fragment>
        {config.infoOverlay.content === 'imprint' ? (
          <Impressum infos={config} {...this.props} />
        ) : config.infoOverlay.content === 'data_protection' ? (
          <Datenschutz infos={config} {...this.props} />
        ) : config.infoOverlay.content === '360°' ? (
          <View360 infos={config} {...this.props} />
        ) : config.infoOverlay.content === 'important_infos' ? (
          <ImportantInfos infos={config} {...this.props} />
        ) : (
          <div
            className={classNames(
              'konfigurator__overlay ',
              config.infoOverlay_open ? 'is-active' : ''
            )}
          >
            <div className="g g-inset">
              <div className="g konfigurator__overlay__infos">
                <div className="g-6 g-nested">
                  {config.infoOverlay.title && (
                    <strong>
                      {config.infoOverlay.title}
                      <br />
                    </strong>
                  )}
                  {config.infoOverlay.code && (
                    <span className="small">
                      {translate('global', 'option_code')}{' '}
                      {config.infoOverlay.code}
                      <br />
                    </span>
                  )}
                  {config.infoOverlay.weight && (
                    <span className="small">
                      {translate('global', 'additional_weight')}{' '}
                      {config.infoOverlay.weight} kg
                    </span>
                  )}
                </div>

                {config.infoOverlay.discount > 0 ? (
                  <div className="g-6 u-text-right">
                    <strong>
                      {formatPrice(config.infoOverlay.price)}
                      <br />
                    </strong>
                    <div className="text">
                      {translate('global', 'price_advantage')}{' '}
                      {formatPrice(config.infoOverlay.discount)}
                    </div>
                  </div>
                ) : (
                  <div className="g-6 u-text-right">
                    <strong>
                      {config.infoOverlay.theme &&
                      config.infoOverlay.theme === 'model'
                        ? translate('global', 'price_advantage_prefix') + ' '
                        : null}
                      {formatPrice(config.infoOverlay.price)}
                      <br />
                    </strong>
                  </div>
                )}
              </div>

              <hr />
              {config.infoOverlay.image ? (
                <div>
                  <div className="g-s--up-6 g-s--down-12">
                    <img
                      src={getAssetUrl() + config.infoOverlay.image}
                      alt={config.infoOverlay.title}
                    />
                  </div>
                </div>
              ) : null}
              {config.infoOverlay.img ? (
                <div>
                  <div className="g-s--up-6 g-s--down-12">
                    <img
                      src={getAssetUrl() + config.infoOverlay.img}
                      alt={config.infoOverlay.title}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {config.infoOverlay.details !== undefined &&
            config.infoOverlay.theme !== 'model'
              ? config.infoOverlay.details.map((detail, i) => (
                  <div className="konfigurator__overlay__details" key={i}>
                    <div
                      className="g g-inset u-padding-top--2x u-padding-bottom--2x details__entry"
                      data-entry={i}
                      key={i}
                    >
                      <div className="g-m--up-1 g-m--down-2 g-nested">
                        {detail.img && (
                          <button
                            onClick={(e) => this.handleDetailClick(e)}
                            className="details__trigger"
                            data-trigger={i}
                          >
                            <SVG name="chevrondown" className="chevrondown" />
                            <span className="details__indicator"></span>
                          </button>
                        )}
                      </div>
                      <div className="g-m--up-2 g-m--down-3 g-nested text">
                        {config.infoOverlay.code && (
                          <div className="text">
                            <span className="small">
                              {detail.code}
                              <br />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="g-m--up-8 g-m--down-3 g-nested text">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={renderMarkup(
                            detail.description
                          )}
                        />
                      </div>
                      <div className="g-m--up-1 g-m--down-4 g-nested text u-text-right">
                        {formatPrice(detail.price)}
                      </div>
                      {detail.img && (
                        <div
                          className="g-m--up-10 g-m--up-2--ps g-m--down-8 g-m--down-4--ps g-nested details__content"
                          data-content={i}
                        >
                          <img
                            src={getAssetUrl() + detail.img}
                            alt={detail.description}
                          />
                        </div>
                      )}
                    </div>
                    <div className="g g-inset" data-entry={i} key={99 - i}>
                      <hr className="no-margin" />
                    </div>
                  </div>
                ))
              : ''}
            {config.infoOverlay.details !== undefined &&
            config.infoOverlay.theme === 'model' ? (
              <div
                className="konfigurator__overlay__details"
                key={config.infoOverlay.code}
              >
                <div className="g g-inset has-nested u-margin-bottom--4x konfigurator__overlay__details__column--2">
                  {config.infoOverlay.details.items.map((detail, i) => (
                    <div
                      key={i}
                      className="konfigurator__container g-nested text"
                    >
                      <h2>{detail.title}</h2>
                      <ul className="dotted">
                        {detail.list.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {config.infoOverlay.details.footnote.length > 0 ? (
                  <Footnote footnote={config.infoOverlay.details.footnote} />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </React.Fragment>
    ) : (
      <div></div>
    )
  }
}

export default InfoOverlay
