import React from 'react'

class FooterLink extends React.Component {
  constructor(props) {
    super(props)
    this.updateInfoOverlay = this.updateInfoOverlay.bind(this)
  }

  updateInfoOverlay(event, infos) {
    this.props.updateInfoOverlay(infos)
  }

  render() {
    const { infos } = { ...this.props }

    //render if infos has details (pakete) or an image
    return infos ? (
      <button
        name={this.props.name}
        onClick={(e) => this.updateInfoOverlay(e, infos)}
      >
        {this.props.name}
      </button>
    ) : (
      <div></div>
    )
  }
}

export default FooterLink
