import React from 'react'
import {
  formatPrice,
  checkPrice,
  formatWeight,
  translate,
  renderMarkup
} from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'

var classNames = require('classnames')

class CartItemRow extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          'konfigurator__container__item g u-boxed u-boxed--has-nested u-margin-bottom--1x',
          this.props.i % 2 === 0 ? 'bg_grey-50' : 'bg_grey-25'
        )}
      >
        <div className="g-8 g-nested">
          <h3
            dangerouslySetInnerHTML={renderMarkup(this.props.child_item.title)}
          />
          <p>{this.props.child_item.description}</p>
          <span className="konfigurator__container__item__code small">
            {translate('global', 'option_code')} {this.props.child_item.code}
          </span>
          <br />
          <span className="small">
            {translate('global', 'additional_weight')}{' '}
            {formatWeight(this.props.child_item.weight)}
            {this.props.child_item.ukid === '201' ? '**' : '*'}
          </span>
        </div>
        <div className="g-4 g-nested u-text-right">
          <strong className="konfigurator__container__price">
            <em>
              {checkPrice(
                this.props.child_item,
                this.context.cart.layout.PSMotor
              )}
              <br />
              {this.props.child_item.pricebpm &&
              this.props.child_item.pricebpm !== 0 ? (
                <span className="small">
                  incl. Bpm {formatPrice(this.props.child_item.pricebpm)}
                </span>
              ) : null}
            </em>
          </strong>
        </div>
      </div>
    )
  }
}

CartItemRow.contextType = AppContext
export default CartItemRow
