import React from 'react'
import { translate } from '../../helpers'

var classNames = require('classnames')

class view360 extends React.Component {
  render() {
    const { config } = { ...this.props }

    return (
      <div
        className={classNames(
          'konfigurator__overlay konfigurator__overlay--fullheight ',
          this.props.config.infoOverlay_open ? ' is-active' : ''
        )}
      >
        <button
          onClick={this.props.toggleInfoOverlay}
          className="button button--dark konfigurator__overlay__close-view"
        >
          {translate('global', 'exit_view')}
        </button>
        <iframe
          title="view360"
          src={config.infoOverlay.link}
          width="100%"
          height="99.7%"
        ></iframe>
      </div>
    )
  }
}

export default view360
