import { createBrowserHistory } from 'history'
// import { createStore, applyMiddleware } from 'redux';
// import { routerMiddleware } from 'react-router-redux'
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

// import rootReducer from './reducers/index';

export const history = createBrowserHistory()

// const middleware = [
//   thunk,
//   routerMiddleware(history)
// ]

// // SessionStorage by Dan Abramov:
// // https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app/35675304#35675304

// const persistedState = sessionStorage.getItem('reduxState') ? JSON.parse(sessionStorage.getItem('reduxState')) : {}

// const store = createStore(
//     rootReducer,
//     persistedState,
//     composeWithDevTools(
//       applyMiddleware(
//         ...middleware
//       ),
//     )
// );

// store.subscribe(()=>{
//   sessionStorage.setItem('reduxState', JSON.stringify(store.getState()))
// })

// export default store;