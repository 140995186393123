import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
// import { ConnectedRouter } from 'react-router-redux'

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as actionCreators from './Data/actions/actionCreators';
import { history } from './Data/Store'

import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'

import CartOverview from './Components/Cart/CartOverview'
import CartInit from './Components/Cart/CartInit'

import InfoOverlay from './Components/Overlay/InfoOverlay'
import OverlayGallery from './Components/Overlay/OverlayGallery'

import Start from './Components/00_Start/Start'
import Modell from './Components/01_Modell/Modell'
import Grundriss from './Components/02_Grundriss/Grundriss'
import Exterieur from './Components/03_Exterieur/Exterieur'
import Interieur from './Components/04_Interieur/Interieur'
import Pakete from './Components/05_Pakete/Pakete'
import Chassis from './Components/06_Chassis/Chassis'
import Aufbau from './Components/07_Aufbau/Aufbau'
import Technik from './Components/08_Technik/Technik'
import Toolbar from './Components/Toolbar/Toolbar'

import { AppContext } from './Data/ContextProvider'

import { setLocalization2 } from './config'

import './stylesheet.css'

// to make it work in IE
import 'airbnb-js-shims'

var classNames = require('classnames')
require('headjs/dist/1.0.0/head.js')

class App extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    // In local-DEV-mode use setLocalization2(); DEV is not using metatags.php
    if (window.location.host.includes(':300')) {
      setLocalization2()
      window.configurator = { user: '3st' }
    }
    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) return ''

    var globalClasses = classNames({
      konfigurator: true,
      'konfigurator__header--is-visible': this.context.config.current_nav,
      'konfigurator__header__jumpmarks--is-visible':
        this.context.config.jumpmarks.length > 0,
      'konfigurator__overview--is-visible':
        this.context.config.cartoverview_open,
      'konfigurator__conflict--is-visible':
        this.context.maxWeightOverlayOpen ||
        this.context.config.conflictOverlayOpen,
      'konfigurator__overlay--is-visible': this.context.config.infoOverlay_open,
      'konfigurator__header__navigation__summary--is-visible':
        Object.keys(this.context.cart).length !== 0 &&
        this.context.cart.model &&
        this.context.cart.layout
    })

    return (
      <Router history={history}>
        <div
          className={`${globalClasses} konfigurator__nav--${this.context.config.current_nav}`}
        >
          <Header {...this.context} />
          <div className="konfigurator__body">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Start {...this.context} />}
              />
              <Route
                exact
                path="/cart/:code"
                render={(props) => <CartInit {...this.context} />}
              />
              <Route
                exact
                path="/01-modell"
                render={(props) => <Modell {...this.context} />}
              />
              <Route
                exact
                path="/02-grundriss"
                render={(props) => <Grundriss {...this.context} />}
              />
              <Route
                exact
                path="/03-exterieur"
                render={(props) => <Exterieur {...this.context} />}
              />
              <Route
                exact
                path="/04-interieur"
                render={(props) => <Interieur {...this.context} />}
              />
              <Route
                exact
                path="/05-pakete"
                render={(props) => <Pakete {...this.context} />}
              />
              <Route
                exact
                path="/06-chassis"
                render={(props) => <Chassis {...this.context} />}
              />
              <Route
                exact
                path="/07-aufbau"
                render={(props) => <Aufbau {...this.context} />}
              />
              <Route
                exact
                path="/08-technik"
                render={(props) => <Technik {...this.context} />}
              />
            </Switch>
          </div>
          <Footer />
          <CartOverview {...this.context} />
          <InfoOverlay {...this.context} />
          <OverlayGallery {...this.context} />
          <Toolbar {...this.context} />
        </div>
      </Router>
    )
  }
}

// function mapStateToProps(state) {
//   return {
//     config: state.config,
//     cart: state.cart,
//   }
// }

// function mapDispachToProps(dispatch) {
//   return bindActionCreators(actionCreators, dispatch);
// }

// export default connect(mapStateToProps, mapDispachToProps, null, {pure: false})(App);
App.contextType = AppContext
export default App
