import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
var classNames = require('classnames')

function NextArrow(props) {
  const { className, style, onClick } = props
  let newClassName = className.replace(
    'slick-next',
    ' slick-arrow--right icon icon-chevron-s'
  )
  return (
    <span className={newClassName} style={{ ...style }} onClick={onClick}>
      <svg role="img">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#icon-chevron-s"
        ></use>
      </svg>
      <span className="icon-click-target"></span>
    </span>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  let newClassName = className.replace(
    'slick-prev',
    ' slick-arrow--left icon icon-chevron-s'
  )
  return (
    <span className={newClassName} style={{ ...style }} onClick={onClick}>
      <svg role="img">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#icon-chevron-s"
        ></use>
      </svg>
      <span className="icon-click-target"></span>
    </span>
  )
}

class LayoutSlider extends React.Component {
  constructor(props) {
    super(props)
    this.changeActive = this.changeActive.bind(this)
    this.changeClasses = this.changeClasses.bind(this)
    this.state = {
      active: 1,
      length: '',
      inverted: false
    }
  }

  componentDidMount() {
    let length = this.props.children.length
    this.setState({ length: length })

    // new ScrollMagic.Scene({
    //   triggerElement: document.getElementsByClassName("konfigurator__grundriss")[0],
    //   triggerHook: 'onEnter',
    //   offset: 2000,
    // })
    // .setClassToggle('.konfigurator', "konfigurator__header__navigation__totop--is-visible")
    // .addTo(scrollController);
  }

  changeActive(i) {
    this.setState({ active: i + 1 })
  }

  changeClasses(i) {
    let inverted = this.props.children[i].props.inverted
    this.setState({ inverted: inverted })
  }

  render() {
    var settings = {
      dots: false,
      speed: 800,
      autoplaySpeed: 3000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      autoplay: false,
      cssEase: 'cubic-bezier(.16,.66,.3,.99)',
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 499,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      // ==== BeforeChange-BUG: https://github.com/akiran/react-slick/issues/136 (FIX: SliderWrapper and shouldUpdate = false) ====
      // beforeChange: function(oldIndex, newIndex) {
      //   this.changeClasses(newIndex);
      // }.bind(this),
      afterChange: function (i) {
        this.changeActive(i)
      }.bind(this)
    }

    return (
      <Slider
        className={classNames('slider slider--layout', this.props.extraClasses)}
        {...settings}
        {...this.props}
      />
    )
  }
}

export default LayoutSlider
