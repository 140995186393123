import React from 'react'
import ImpressumDe from './../Impressum/Impressum_de'
import ImpressumEn from './../Impressum/Impressum_en'
import ImpressumFr from './../Impressum/Impressum_fr'
import ImpressumIt from './../Impressum/Impressum_it'
import ImpressumSv from './../Impressum/Impressum_sv'
import { getLanguage } from '../../config'

class Impressum extends React.Component {
  render() {
    const { config } = { ...this.props }
    const language = getLanguage()

    if (language === 'de') {
      return <ImpressumDe infos={config} {...this.props} />
    }
    if (language === 'fr') {
      return <ImpressumFr infos={config} {...this.props} />
    }
    if (language === 'it') {
      return <ImpressumIt infos={config} {...this.props} />
    }
    if (language === 'sv') {
      return <ImpressumSv infos={config} {...this.props} />
    }
    return <ImpressumEn infos={config} {...this.props} />
  }
}

export default Impressum
