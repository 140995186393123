import React from 'react'
import {
  formatPrice,
  checkPrice,
  formatWeight,
  renderMarkup,
  translate,
  getDiscountPrice
} from '../../helpers'
import { AppContext } from '../../Data/ContextProvider'
import PaketItem from '../05_Pakete/PaketItem'

var classNames = require('classnames')

const styles = {
  backgroundColor: {
    backgroundColor: '#f9f9f9'
  }
}

class ConflictOverlay extends React.Component {
  addOption = (item) => {
    this.props.addItemWithOption(this.props, item)
  }

  deleteOption = (item) => {
    this.props.removeExclusionInCombonation(item)
  }

  positiveAction = () => {
    this.props.positiveCallBackAction(this.props)
  }

  negativeAction = () => {
    this.props.negativeCallBackAction(this.props)
  }

  formatPrice = (item) => {
    return checkPrice(item, this.context.cart.layout.PSMotor)
  }

  // just row (withButtons: deleteOption)
  renderRow = (item, i, withButtons) => {
    return (
      <div
        key={i}
        className={classNames(
          'konfigurator__container__item g u-boxed u-boxed--has-nested u-margin-bottom--1x bg_grey-50'
        )}
      >
        <div className="g-m--up-10 g-m--down-12 g-nested">
          <h3 dangerouslySetInnerHTML={renderMarkup(item.title)} />
          <p dangerouslySetInnerHTML={renderMarkup(item.description)} />
          <span className="konfigurator__container__item__code small">
            {translate('global', 'option_code')} {item.code}
          </span>
          <br />
          <span className="small">
            {translate('global', 'additional_weight')}{' '}
            {formatWeight(item.weight)}
          </span>
        </div>

        <div className="g-m--up-2 g-m--down-12 g-nested u-text-right">
          <strong className="konfigurator__container__price">
            <em>{this.formatPrice(item)}</em>
          </strong>
          <br />
          {withButtons && (
            <button
              className="button button--cta"
              name="delete"
              onClick={(e) => this.deleteOption(item)}
            >
              <span>{translate('global', 'delete_option')}</span>
            </button>
          )}
        </div>
      </div>
    )
  }

  // 'g-11 g-1-ps' (Styling: Eingerückte Optionsreihen)
  // withButtons: addOption
  renderOptionRow = (item, i, withButtons) => {
    return (
      <div
        key={i}
        className={classNames(
          'konfigurator__container__item g g-11 g-1-ps u-boxed u-boxed--has-nested u-margin-bottom--1x bg_grey-50'
        )}
        style={styles.backgroundColor}
      >
        <div className="g-m--up-10 g-m--down-12 g-nested">
          <h3 dangerouslySetInnerHTML={renderMarkup(item.title)} />
          <p dangerouslySetInnerHTML={renderMarkup(item.description)} />
          <span className="konfigurator__container__item__code small">
            {translate('global', 'option_code')} {item.code}
          </span>
          <br />
          <span className="small">
            {translate('global', 'additional_weight')}{' '}
            {formatWeight(item.weight)}
          </span>
        </div>
        <div className="g-m--up-2 g-m--down-12 g-nested u-text-right">
          <strong className="konfigurator__container__price">
            <em>{this.formatPrice(item)}</em>
          </strong>
          <br />
          {withButtons && (
            <button
              className="button button--cta"
              name="add"
              onClick={(e) => this.addOption(item)}
            >
              <span>{translate('global', 'add_option')}</span>
            </button>
          )}
        </div>
      </div>
    )
  }

  renderOptions = (item, i, withButtons) => {
    return (
      <React.Fragment key={i}>
        <div
          key={i}
          className={classNames(
            'konfigurator__container__item g u-boxed u-boxed--has-nested u-margin-bottom--1x bg_grey-50'
          )}
        >
          <div className="g-m--up-10 g-m--down-12 g-nested">
            <h3 dangerouslySetInnerHTML={renderMarkup(item.title)} />
            <p>{item.description}</p>
            <span className="konfigurator__container__item__code small">
              {translate('global', 'option_code')} {item.code}
            </span>
            <br />
            <span className="small">
              {translate('global', 'additional_weight')}{' '}
              {formatWeight(item.weight)}
            </span>
          </div>

          <div className="g-m--up-2 g-m--down-12 g-nested u-text-right">
            <strong className="konfigurator__container__price">
              <em>{this.formatPrice(item)}</em>
            </strong>
            <br />
            {withButtons && (
              <button
                className="button button--cta"
                name="add"
                onClick={(e) => this.addOption(item)}
              >
                <span>{translate('global', 'add_option')}</span>
              </button>
            )}
          </div>
        </div>

        {item.exclusionsInCart && item.exclusionsInCart.length ? (
          <h3 className="u-margin-bottom--2x g-11 g-1-ps">
            {translate('global', 'error_overlay_option_delete')}
          </h3>
        ) : (
          ''
        )}
        {item.exclusionsInCart &&
          item.exclusionsInCart.map((exclusion, eindex) => {
            return this.renderOptionRow(exclusion, eindex, false)
          })}
        {item.inclusionsNotInCart &&
          item.inclusionsNotInCart.map((exclusion, eindex) => {
            return this.renderOptionRow(exclusion, eindex, false)
          })}
        {item.optionsNotInCart &&
          item.optionsNotInCart.map((exclusion, eindex) => {
            return this.renderOptionRow(exclusion, eindex, true)
          })}
      </React.Fragment>
    )
  }

  renderBigBox = (item, index) => {
    const discount = getDiscountPrice(item, this.context.cart.layout.PSMotor)

    return (
      <div
        key={index}
        className="konfigurator__container__item g-m--down-12 g-m--up-6 bg_grey-50"
      >
        <div className="g u-boxed">
          <div className="g-6">
            <h3>{item.title}</h3>
            <span className="konfigurator__container__item__code small">
              {translate('global', 'option_code')} {item.code}
            </span>
            <br />
            <span className="small">
              {translate('global', 'additional_weight')}{' '}
              {formatWeight(item.weight)}
            </span>
          </div>
          <div className="g-6 u-text-right">
            <strong>
              <em>{this.formatPrice(item)}</em>
            </strong>
            <br />
            {discount && (
              <span className="small">
                {translate('global', 'price_advantage')} {formatPrice(discount)}
              </span>
            )}
            <br />
          </div>
          <div className="g-12 u-text-right"></div>
          <hr />
          <ul>
            {item.details &&
              item.details.map((detail, i) => {
                return (
                  <li key={i}>
                    <div
                      className="left"
                      dangerouslySetInnerHTML={renderMarkup(detail.meta)}
                    />
                    <div
                      className="middle"
                      dangerouslySetInnerHTML={renderMarkup(detail.description)}
                    />
                    <div className="right">{this.formatPrice(detail)}</div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  }

  render() {
    const action = this.props.action ? this.props.action : 'add'
    const additionalItems = this.props.additionalItems
      ? this.props.additionalItems
      : []
    const additionalInclusions = this.props.additionalInclusions
      ? this.props.additionalInclusions
      : []
    const conflictingItem = this.props.conflictingItem
      ? this.props.conflictingItem
      : null
    const conflictingItems = this.props.conflictingItems
      ? this.props.conflictingItems
      : []
    const conflictingPackages = this.props.conflictingPackages
      ? this.props.conflictingPackages
      : []
    const exclusions = this.props.exclusions ? this.props.exclusions : []
    const dependenciesForExclusions = this.props.dependenciesForExclusions
      ? this.props.dependenciesForExclusions
      : []
    const optionsExclusions = this.props.optionsExclusions
      ? this.props.optionsExclusions
      : []
    const optionsExcludedPackages = this.props.optionsExcludedPackages
      ? this.props.optionsExcludedPackages
      : []

    const showButtons = action === 'inc' ? true : false

    if (
      !this.props.conflictingItem &&
      (!this.props.conflictingItems ||
        this.props.conflictingItems.length < 1) &&
      !this.props.item
    ) {
      this.context.closeConflictOverlay()
      return null
    }

    return (
      <div className="konfigurator__conflict konfigurator__overlay is-active">
        <div className="g g-inset">
          <div className="g konfigurator__overlay__infos text">
            <h2>{translate('global', 'error_overlay_title')}</h2>
            {this.props.item ? (
              <React.Fragment>
                <hr />
                <h3 className="u-margin-bottom--2x">
                  {translate('global', 'error_overlay_option_used')}
                </h3>
              </React.Fragment>
            ) : null}
          </div>

          {this.props.item ? (
            this.props.item.details && this.props.item.details.length > 0 ? (
              <div className="konfigurator__container konfigurator__container--flex">
                <section className="g has-nested">
                  <PaketItem key={0} item={this.props.item} hideCTA={true} />
                </section>
              </div>
            ) : (
              this.renderRow(this.props.item, 0)
            )
          ) : null}

          <hr />

          <div className="konfigurator__container">
            {(action === 'add' || action === 'inc' || action === 'addexl') && (
              <h3 className="u-margin-bottom--2x">
                {translate('global', 'error_overlay_option_necessary')}
              </h3>
            )}

            {(action === 'add' || action === 'inc' || action === 'addexl') && (
              <section className="g">
                {additionalInclusions.length > 0
                  ? additionalInclusions.map((option, index) => {
                      return this.renderOptions(option, index, false)
                    })
                  : ''}

                {additionalItems.map((option, index) => {
                  if (
                    option.optionsNotInCart &&
                    option.optionsNotInCart.length > 0
                  ) {
                    return this.renderOptions(option, index, false)
                  } else {
                    return this.renderOptions(option, index, showButtons)
                  }
                })}
              </section>
            )}

            {(action === 'exl' || action === 'del' || action === 'addexl') && (
              <h3 className="u-margin-bottom--2x">
                {translate('global', 'error_overlay_option_delete')}
              </h3>
            )}

            {this.props.exclusionInCombonation &&
              this.props.exclusionInCombonation.length > 1 && (
                <React.Fragment>
                  {this.props.exclusionInCombonation.map((item, index) => {
                    return (
                      <section key={index} className="g">
                        {this.renderRow(item, index, true)}
                      </section>
                    )
                  })}
                  <hr />
                </React.Fragment>
              )}

            {action === 'del' && conflictingItem && (
              <React.Fragment>
                <section className="g">
                  {this.renderBigBox(conflictingItem, 0)}
                </section>
                <hr />
              </React.Fragment>
            )}

            {(action === 'del' || action === 'exl' || action === 'addexl') &&
              conflictingItems && (
                <React.Fragment>
                  <section className="g">
                    {conflictingItems.map((option, index) => {
                      return option.details && option.details.length > 0 ? (
                        <div
                          key={index}
                          className="konfigurator__container konfigurator__container--flex"
                        >
                          <section className="g has-nested">
                            <PaketItem item={option} hideCTA={true} />
                          </section>
                        </div>
                      ) : (
                        this.renderRow(option, index)
                      )
                    })}
                  </section>

                  {dependenciesForExclusions &&
                    dependenciesForExclusions.length && (
                      <section className="g">
                        {dependenciesForExclusions.map((option, index) => {
                          return this.renderRow(option, index)
                        })}
                      </section>
                    )}
                </React.Fragment>
              )}

            {optionsExclusions && optionsExclusions.length && (
              <React.Fragment>
                <hr />

                <h3 className="u-margin-bottom--2x">
                  {translate('global', 'error_overlay_option_delete')}
                </h3>
                <section className="g">
                  {optionsExclusions.map((option, index) => {
                    return this.renderRow(option, index)
                  })}
                </section>

                {optionsExcludedPackages.length > 0 && (
                  <section className="g">
                    {optionsExcludedPackages.map((option, index) => {
                      return this.renderRow(option, index)
                    })}
                  </section>
                )}
              </React.Fragment>
            )}

            {conflictingPackages.length > 0 && (
              <section className="g">
                {conflictingPackages.map((option, index) => {
                  return this.renderBigBox(option, index)
                })}
              </section>
            )}
          </div>

          {exclusions && exclusions.length && (
            <React.Fragment>
              <hr />
              <div className="konfigurator__container">
                <h3 className="u-margin-bottom--2x">
                  {translate('global', 'error_overlay_option_delete_info')}
                </h3>

                <section className="g">
                  {exclusions.map((option, index) => {
                    return this.renderRow(option, index)
                  })}
                </section>
              </div>
            </React.Fragment>
          )}

          <hr />

          <div className="g-m--up-6 g-m--down-12">
            {action === 'add' || action === 'inc' ? (
              <span className="small">
                {translate('global', 'error_overlay_option_necessary_info')}
                <br />
              </span>
            ) : (
              <span className="small">
                {translate('global', 'error_overlay_option_delete_info')}
                <br />
              </span>
            )}
          </div>

          <div className="g-m--up-6 g-m--down-12 u-text-right">
            <div className="conflict-overlay-cta">
              <button
                className="button"
                name="cancel"
                onClick={this.context.toggleConflictOverlay}
              >
                <span>{translate('global', 'error_overlay_cancel')}</span>
              </button>

              {(action === 'add' || action === 'addexl') && (
                <button
                  className="button button--cta"
                  name="add"
                  onClick={this.positiveAction}
                >
                  <span>
                    {translate(
                      'global',
                      'error_overlay_option_necessary_confirm'
                    )}
                  </span>
                </button>
              )}
              {(action === 'del' || action === 'exl') && (
                <button
                  className="button button--cta"
                  name="remove"
                  onClick={this.negativeAction}
                >
                  <span>
                    {translate('global', 'error_overlay_option_delete_confirm')}
                  </span>
                </button>
              )}
            </div>
          </div>

          {/*

          // TODO INFOBOX ("extra weight disclaimer") in Conflict Overlay

          <div className="infobox">
            <div
              dangerouslySetInnerHTML={{ __html: 'INFOBOX 1' }}
              className="infobox__content text"
            />
          </div>
          <div className="infobox">
            <div
              dangerouslySetInnerHTML={{ __html: 'INFOBOX 2' }}
              className="infobox__content text"
            />
          </div>

          */}
        </div>
      </div>
    )
  }
}

ConflictOverlay.contextType = AppContext
export default ConflictOverlay
