import React from 'react'
import { NavLink } from 'react-router-dom'
import { translate } from '../../helpers'
import { getApiUrl, getLanguage } from '../../config'
import { AppContext } from '../../Data/ContextProvider'

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      footer_navigation: [
        {
          text: 'Start',
          path: '/'
        },
        {
          text: translate('global', 'nav_model'),
          path: '/01-modell'
        },
        {
          text: translate('global', 'nav_layout'),
          path: '/02-grundriss'
        },
        {
          text: translate('global', 'nav_exterieur'),
          path: '/03-exterieur'
        },
        {
          text: translate('global', 'nav_interieur'),
          path: '/04-interieur'
        },
        {
          text: translate('global', 'nav_pakete'),
          path: '/05-pakete'
        },
        {
          text: translate('global', 'nav_chassis'),
          path: '/06-chassis'
        },
        {
          text: translate('global', 'nav_aufbau'),
          path: '/07-aufbau'
        },
        {
          text: translate('global', 'nav_technique'),
          path: '/08-technik'
        },
        {
          text: translate('cart_overview', 'configuration'),
          path: '/08-technik'
        }
      ]
    }
  }

  toggleCartOverview = (e, cart, config) => {
    e.preventDefault()
    this.handleSaveCart(cart, config)
    this.context.toggleCartOverview()
  }

  handleSaveCart = (cart, config) => {
    if (cart) {
      fetch(getApiUrl() + `/import/cart?lang=` + getLanguage(), {
        method: 'POST',
        body: JSON.stringify({
          cart: cart,
          code: cart.code,
          steps_completed: config.steps_completed
        })
      })
        .then((response) => response.json())
        .then((data) => {
          let cart = data.cart
          cart.code = data.code
          this.context.initCart(cart)
          this.context.initStepsCompleted(data.steps_completed)
        })
    }
  }

  render() {
    const { config, cart } = { ...this.context }
    const next_nav = this.state.footer_navigation[config.current_nav + 1]

    let footer_text
    let footer_button

    // If current navigation is not Technik (9)
    if (config.current_nav + 1 !== 9) {
      footer_text = <p>{next_nav.text}</p>
      footer_button = (
        <NavLink to={next_nav.path} className="button">
          <span>{translate('global', 'next_page_button')}</span>
        </NavLink>
      )
    } else {
      footer_text = <p>{next_nav.text}</p>
      footer_button = (
        <button
          className="button"
          onClick={(e) => {
            this.toggleCartOverview(e, cart, config)
          }}
        >
          {translate('global', 'next_page_button')}
        </button>
      )
    }

    if (this.context.maxWeightOverlayOpen || config.conflictOverlayOpen) {
      return null
    }

    return (
      <React.Fragment>
        {config.current_nav > 2 && (
          <div className="konfigurator__header__bar__button">
            {footer_text}
            {footer_button}
          </div>
        )}
      </React.Fragment>
    )
  }
}

Button.contextType = AppContext
export default Button
